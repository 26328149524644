import { Divisa } from './divisa.model';
import { Periodicidad } from './periodicidad.model';
import { MontoTipo } from './monto-tipo.model';
import { EstadoCuentaEstado } from './estado-cuenta-estado.model';
import { Utils } from '../shared/utils';

export class ContratoConfiguracion {

    private _id: string;
    private _contratoId: string;
    private _divisaId: string;
    private _divisaCobroId: string;
    private _divisaConversionId: number;
    private _reajusteMontoTipoId: number;
    private _reajusteMonto: number;
    private _reajustePeriodicidadValor: number;
    private _reajustePeriodicidadId: number;
    private _retrasoMultaMonto: number;
    private _retrasoMultaMontoTipoId: number;
    private _retrasoMultaMontoDivisa: string;
    private _retrasoCobroMultaLiqEnCurso: boolean;
    private _retrasoInteresMonto: number;
    private _retrasoInteresMontoDivisa: string;
    private _retrasoInteresMontoTipoId: number;
    private _retrasoInteresPorDia: boolean;
    private _retrasoInteresNoAplicar: boolean;
    private _retrasoInteresPeriodicidadId: number;
    private _retrasoInteresPeriodicidadValor: number;
    private _retrasoInteresTipoId: number;
    private _diaGeneracion: number;
    private _diaCobro: number;
    private _diaCorteArriendo: number;
    private _diaCorteGastoComun: number;
    private _diaCorteServicios: number;
    private _diaPago: number;
    private _folioInicio: number;
    private _envioManualEstadoCuenta: boolean;
    private _cobroPeriodicidadId: number;
    private _liquidacionCreacionEstadoId: number;
    private _interesPorDia: boolean;
    private _avisoTerminoDias: number;
    private _isRenovacionAutomatica: boolean;
    private _isGeneracionAutomatica: boolean;

    private _divisa: Divisa;
    private _divisaCobro: Divisa;
    private _estadoCuentaEstado: EstadoCuentaEstado;
    private _cobroPeriodicidad: Periodicidad;
    private _reajusteMontoTipo: MontoTipo;
    private _reajustePeriodicidad: Periodicidad;
    private _retrasoMultaMontoTipo: MontoTipo;
    private _retrasoInteresMontoTipo: MontoTipo;
    private _retrasoInteresPeriodicidad: Periodicidad;


    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get contratoId(): string {
        return this._contratoId;
    }

    set contratoId(value: string) {
        this._contratoId = value;
    }

    get divisaId(): string {
        return this._divisaId;
    }

    set divisaId(value: string) {
        this._divisaId = value;
    }

    get divisaCobroId(): string {
        return this._divisaCobroId;
    }

    set divisaCobroId(value: string) {
        this._divisaCobroId = value;
    }

    get divisaConversionId(): number {
        return this._divisaConversionId;
    }

    set divisaConversionId(value: number) {
        this._divisaConversionId = value;
    }

    get reajusteMontoTipoId(): number {
        return this._reajusteMontoTipoId;
    }

    set reajusteMontoTipoId(value: number) {
        this._reajusteMontoTipoId = value;
    }

    get reajusteMonto(): number {
        return this._reajusteMonto;
    }

    set reajusteMonto(value: number) {
        this._reajusteMonto = value;
    }

    get reajustePeriodicidadValor(): number {
        return this._reajustePeriodicidadValor;
    }

    set reajustePeriodicidadValor(value: number) {
        this._reajustePeriodicidadValor = value;
    }

    get reajustePeriodicidadId(): number {
        return this._reajustePeriodicidadId;
    }

    set reajustePeriodicidadId(value: number) {
        this._reajustePeriodicidadId = value;
    }

    get retrasoMultaMonto(): number {
        return this._retrasoMultaMonto;
    }

    set retrasoMultaMonto(value: number) {
        this._retrasoMultaMonto = value;
    }

    get retrasoMultaMontoTipoId(): number {
        return this._retrasoMultaMontoTipoId;
    }

    set retrasoMultaMontoTipoId(value: number) {
        this._retrasoMultaMontoTipoId = value;
    }

    get retrasoMultaMontoDivisa(): string {
        return this._retrasoMultaMontoDivisa;
    }

    set retrasoMultaMontoDivisa(value: string) {
        this._retrasoMultaMontoDivisa = value;
    }

    get retrasoCobroMultaLiqEnCurso(): boolean {
        return this._retrasoCobroMultaLiqEnCurso;
    }

    set retrasoCobroMultaLiqEnCurso(value: boolean) {
        this._retrasoCobroMultaLiqEnCurso = value;
    }
    get retrasoInteresMonto(): number {
        return this._retrasoInteresMonto;
    }

    set retrasoInteresMonto(value: number) {
        this._retrasoInteresMonto = value;
    }

    get retrasoInteresMontoDivisa(): string {
        return this._retrasoInteresMontoDivisa;
    }

    set retrasoInteresMontoDivisa(value: string) {
        this._retrasoInteresMontoDivisa = value;
    }

    get retrasoInteresMontoTipoId(): number {
        return this._retrasoInteresMontoTipoId;
    }

    set retrasoInteresMontoTipoId(value: number) {
        this._retrasoInteresMontoTipoId = value;
    }

    get retrasoInteresPorDia(): boolean {
        return this._retrasoInteresPorDia;
    }

    set retrasoInteresPorDia(value: boolean) {
        this._retrasoInteresPorDia = value;
    }

    get retrasoInteresNoAplicar(): boolean {
        return this._retrasoInteresNoAplicar;
    }

    set retrasoInteresNoAplicar(value: boolean) {
        this._retrasoInteresNoAplicar = value;
    }

    get retrasoInteresPeriodicidadId(): number {
        return this._retrasoInteresPeriodicidadId;
    }

    set retrasoInteresPeriodicidadId(value: number) {
        this._retrasoInteresPeriodicidadId = value;
    }

    get retrasoInteresPeriodicidadValor(): number {
        return this._retrasoInteresPeriodicidadValor;
    }

    set retrasoInteresPeriodicidadValor(value: number) {
        this._retrasoInteresPeriodicidadValor = value;
    }

    get retrasoInteresTipoId(): number {
        return this._retrasoInteresTipoId;
    }

    set retrasoInteresTipoId(value: number) {
        this._retrasoInteresTipoId = value;
    }

    get diaGeneracion(): number {
        return this._diaGeneracion;
    }

    set diaGeneracion(value: number) {
        this._diaGeneracion = value;
    }

    get diaCobro(): number {
        return this._diaCobro;
    }

    set diaCobro(value: number) {
        this._diaCobro = value;
    }

    get diaCorteArriendo(): number {
        return this._diaCorteArriendo;
    }

    set diaCorteArriendo(value: number) {
        this._diaCorteArriendo = value;
    }

    get diaCorteGastoComun(): number {
        return this._diaCorteGastoComun;
    }

    set diaCorteGastoComun(value: number) {
        this._diaCorteGastoComun = value;
    }

    get diaCorteServicios(): number {
        return this._diaCorteServicios;
    }

    set diaCorteServicios(value: number) {
        this._diaCorteServicios = value;
    }

    get diaPago(): number {
        return this._diaPago;
    }

    set diaPago(value: number) {
        this._diaPago = value;
    }

    get folioInicio(): number {
        return this._folioInicio;
    }

    set folioInicio(value: number) {
        this._folioInicio = value;
    }

    get envioManualEstadoCuenta(): boolean {
        return this._envioManualEstadoCuenta;
    }

    set envioManualEstadoCuenta(value: boolean) {
        this._envioManualEstadoCuenta = value;
    }

    get cobroPeriodicidadId(): number {
        return this._cobroPeriodicidadId;
    }

    set cobroPeriodicidadId(value: number) {
        this._cobroPeriodicidadId = value;
    }

    get liquidacionCreacionEstadoId(): number {
        return this._liquidacionCreacionEstadoId;
    }

    set liquidacionCreacionEstadoId(value: number) {
        this._liquidacionCreacionEstadoId = value;
    }

    get interesPorDia(): boolean {
        return this._interesPorDia;
    }

    set interesPorDia(value: boolean) {
        this._interesPorDia = value;
    }

    get avisoTerminoDias(): number {
        return this._avisoTerminoDias;
    }

    set avisoTerminoDias(value: number) {
        this._avisoTerminoDias = value;
    }

    get isRenovacionAutomatica(): boolean {
        return this._isRenovacionAutomatica;
    }

    set isRenovacionAutomatica(value: boolean) {
        this._isRenovacionAutomatica = value;
    }

    get isGeneracionAutomatica(): boolean {
        return this._isGeneracionAutomatica;
    }

    set isGeneracionAutomatica(value: boolean) {
        this._isGeneracionAutomatica = value;
    }

    get divisa(): Divisa {
        return this._divisa;
    }

    set divisa(value: Divisa) {
        this._divisa = value;
    }

    get divisaCobro(): Divisa {
        return this._divisaCobro;
    }

    set divisaCobro(value: Divisa) {
        this._divisaCobro = value;
    }

    get estadoCuentaEstado(): EstadoCuentaEstado {
        return this._estadoCuentaEstado;
    }

    set estadoCuentaEstado(value: EstadoCuentaEstado) {
        this._estadoCuentaEstado = value;
    }

    get cobroPeriodicidad(): Periodicidad {
        return this._cobroPeriodicidad;
    }

    set cobroPeriodicidad(value: Periodicidad) {
        this._cobroPeriodicidad = value;
    }

    get reajusteMontoTipo(): MontoTipo {
        return this._reajusteMontoTipo;
    }

    set reajusteMontoTipo(value: MontoTipo) {
        this._reajusteMontoTipo = value;
    }

    get reajustePeriodicidad(): Periodicidad {
        return this._reajustePeriodicidad;
    }

    set reajustePeriodicidad(value: Periodicidad) {
        this._reajustePeriodicidad = value;
    }

    get retrasoMultaMontoTipo(): MontoTipo {
        return this._retrasoMultaMontoTipo;
    }

    set retrasoMultaMontoTipo(value: MontoTipo) {
        this._retrasoMultaMontoTipo = value;
    }

    get retrasoInteresMontoTipo(): MontoTipo {
        return this._retrasoInteresMontoTipo;
    }

    set retrasoInteresMontoTipo(value: MontoTipo) {
        this._retrasoInteresMontoTipo = value;
    }

    get retrasoInteresPeriodicidad(): Periodicidad {
        return this._retrasoInteresPeriodicidad;
    }

    set retrasoInteresPeriodicidad(value: Periodicidad) {
        this._retrasoInteresPeriodicidad = value;
    }

    fechaSiguienteReajuste(reajusteFechaAnexo: string): Date {
      const fechaPrimerReajuste = new Date(reajusteFechaAnexo.substr(0, 10) + ' 00:00:00');
      return this.calcularFechaReajuste(fechaPrimerReajuste);
    }

    calcularFechaReajuste(date: Date) {
      if (!this.reajustePeriodicidadValor) {
        return null
      }
      switch (this.reajustePeriodicidadId) {
          case 1:
              date.setDate(date.getDate() + this.reajustePeriodicidadValor);
              break;
          case 2:
              date.setMonth(date.getMonth() + this.reajustePeriodicidadValor);
              break;
          case 3:
              date.setFullYear(date.getFullYear() + this.reajustePeriodicidadValor);
              break;
      }
      return date;
  }
}
