import {Injectable} from "@angular/core";
import {Adapter} from "./adapter";

import {Reserva} from "../models/reserva.model";
import {ReservaUnidadAdapter} from "./reserva-unidad.adapter";
import {ReservaEstadoAdapter} from "./reserva-estado.adapter";
import {PropiedadAdapter} from "./propiedad.adapter";
import {ContactoAdapter} from "./contacto.adapter";
import {UserAdapter} from "./user.adapter";

@Injectable({
  providedIn: 'root'
})
export class ReservaAdapter implements Adapter<Reserva> {
  constructor(
    public reservaUnidadAdapter: ReservaUnidadAdapter,
    public reservaEstadoAdapter: ReservaEstadoAdapter,
    public propiedadAdapter: PropiedadAdapter,
    public contactoAdapter: ContactoAdapter,
    public userAdapter: UserAdapter
  ) {}

  adapt(item: any): Reserva {
    const reserva = new Reserva();
    reserva.id = item.attributes.id;
    reserva.folio = item.attributes.folio;
    reserva.fecha = item.attributes.fecha;
    reserva.dias_vigencia = item.attributes.dias_vigencia;
    reserva.dias_pagar = item.attributes.dias_pagar;
    reserva.fecha_vencimiento = item.attributes.fecha_vencimiento;
    reserva.fecha_vigencia = item.attributes.fecha_vigencia;
    reserva.divisa_id = item.attributes.divisa_id;
    reserva.monto = item.attributes.monto;
    reserva.estadoId = item.attributes.estado_id;
    reserva.propiedadId = item.attributes.propiedad_id;
    reserva.contactoId = item.attributes.contacto_id;
    reserva.ejecutivoId = item.attributes.ejecutivo_id;

    if (item.included) {
      item.included.forEach(include => {
        switch (include.type) {
          case 'ReservaEstado':
            reserva.estado = this.reservaEstadoAdapter.adapt(include);
            break;
          case 'Propiedad':
            reserva.propiedad = this.propiedadAdapter.adapt(include);
            break;
          case 'Contacto':
            reserva.contacto = this.contactoAdapter.adapt(include);
            break;
          case 'User':
            reserva.ejecutivo = this.userAdapter.adapt(include);
            break
        }
      });
    }

    reserva.unidades = [];
    if (item.relationship && item.relationship.reservaUnidad) {
      item.relationship.reservaUnidad.data.forEach(ru => {
        reserva.unidades.push(this.reservaUnidadAdapter.adapt(ru));
      });
    }

    reserva.contratos = [];
    if (item.relationship && item.relationship.contratos) {
      item.relationship.contratos.data.forEach(contrato => {
        reserva.contratos.push({
          id: contrato.attributes.id,
          folio: contrato.attributes.folio,
          estado: contrato.attributes.estado_id,
          fecha_inicio: contrato.attributes.fecha_inicio,
          fecha_termino: contrato.attributes.fecha_fin
        });
      });
    }

    return reserva;
  }
}
