import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { CotizacionUnidad } from '../models/cotizacion-unidad.model';

@Injectable({
    providedIn: 'root'
  })
  export class CotizacionUnidadAdapter implements Adapter<CotizacionUnidad> {
    adapt(item: any): CotizacionUnidad {
        const cotizacionUnidad = new CotizacionUnidad();
        cotizacionUnidad.id = item.attributes.id;
        cotizacionUnidad.cotizacionId = item.attributes.cotizacion_id;
        cotizacionUnidad.unidadId = item.attributes.unidad_id;
        cotizacionUnidad.createdAt = item.attributes.created_at;
        cotizacionUnidad.createdBy = item.attributes.created_by;
        return cotizacionUnidad;
    }
  }
