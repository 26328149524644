import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {ContratoAnexoUnidad} from '../models/contrato-anexo-unidad.model';
import {UnidadAdapter} from './unidad.adapter';
import {UnidadTipoAdapter} from './unidad-tipo.adapter';
import {UnidadEstadoAdapter} from './unidad-estado.adapter';
import {UnidadTipo} from '../models/unidad-tipo.model';
import {ContratoAnexoUnidadConfiguracionAdapter} from './contrato-anexo-unidad-configuracion.adapter';
import {ContratoAnexoUnidadDetalleAdapter} from './contrato-anexo-unidad-detalle.adapter';

@Injectable({
  providedIn: 'root'
})
export class ContratoAnexoUnidadAdapter implements Adapter<ContratoAnexoUnidad> {
  constructor(
    private unidadAdapter: UnidadAdapter,
    private unidadTipoAdapter: UnidadTipoAdapter,
    private unidadEstadoAdapter: UnidadEstadoAdapter,
    private contratoAnexoUnidadConfiguracionAdapter: ContratoAnexoUnidadConfiguracionAdapter,
    private contratAnexoUnidadDetalleAdapter: ContratoAnexoUnidadDetalleAdapter
  ) {
  }
  adapt(item: any): ContratoAnexoUnidad {
    const contratoAnexoUnidad = new ContratoAnexoUnidad();
    contratoAnexoUnidad.id = item.attributes.id;
    contratoAnexoUnidad.contratoAnexoId = item.attributes.contrato_anexo_id;
    contratoAnexoUnidad.unidadId = item.attributes.unidad_id;
    if (item.included) {
      item.included.filter((valor) => {
        switch (valor.type) {
          case 'Unidad':
            contratoAnexoUnidad.unidad = this.unidadAdapter.adapt(valor);
            break;
          case 'UnidadTipo':
            contratoAnexoUnidad.unidad.unidadTipo = new UnidadTipo();
            contratoAnexoUnidad.unidad.unidadTipo.id = valor.attributes.id;
            contratoAnexoUnidad.unidad.unidadTipo.nombre = valor.attributes.nombre;
            contratoAnexoUnidad.unidad.unidadTipo.lang_resource = valor.attributes.lang_resource;
            contratoAnexoUnidad.unidad.unidadTipo.orden = valor.attributes.orden;
            contratoAnexoUnidad.unidad.unidadTipo.isActive = valor.attributes.is_active;
            contratoAnexoUnidad.unidadTipo = this.unidadTipoAdapter.adapt(valor);
            break;
          case 'UnidadEstado':
            contratoAnexoUnidad.unidadEstado = this.unidadEstadoAdapter.adapt(valor);
            break;
          case 'ContratoAnexoUnidadConfiguracion':
            contratoAnexoUnidad.contratoAnexoUnidadConfiguracion = this.contratoAnexoUnidadConfiguracionAdapter.adapt(valor)
            break;
        }
      })
    }
    if (item.relationship && item.relationship.contratoAnexoUnidadDetalle) {
      contratoAnexoUnidad.contratoAnexoUnidadDetalle = item.relationship.contratoAnexoUnidadDetalle.data.map(unidadDetalle =>
        this.contratAnexoUnidadDetalleAdapter.adapt(unidadDetalle)
      )
    }
    return contratoAnexoUnidad;
  }
}
