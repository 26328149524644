<div>
    {{expireMsg}} <b>{{secondsValidation}}</b> {{timeUnit}}, {{stillActive}}
</div>
<br/>
<div class="text-center">
    <button [class]="'boton-anterior'" (click)="onCancel()">
        {{cancelaBtn}}
    </button>
    <button type="button" [class]="'boton-agp'" (click)="onAccept()">
        {{aceptarBtn}}
    </button>
</div>