import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { GooglePlacesComponent } from './google-places.component';
import { HttpClientJsonpModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { AbilityModule } from '@casl/angular';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        HttpClientJsonpModule,
        TranslateModule,
        AbilityModule
    ],
    declarations: [GooglePlacesComponent],
    exports: [GooglePlacesComponent]
})

export class GooglePlacesModule { }
