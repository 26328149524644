import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContratoTemplateAdapter } from './../../adapter/contrato-template.adapter';
import { ContratoTemplate } from 'src/app/models/model.index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContratoTemplateService {

  public parametros: any;

  constructor(
    public http: HttpClient,
    public contratoTemplateAdapter: ContratoTemplateAdapter
  ) { }

  cargarContratosTemplates(filtro?): Observable <ContratoTemplate[]> {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'contrato_template?' + this.parametros)
      .pipe(
        map( (resp: any) => resp.data.map(contratoTemplate => this.contratoTemplateAdapter.adapt(contratoTemplate)) )
      );
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = '';
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('contrato_template_filter', filtro);
  }
}
