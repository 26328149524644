import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {ContratoAnexoContacto} from '../models/contrato-anexo-contacto.model';
import {ContactoAdapter} from './contacto.adapter';
import {ContratoContactoTipoAdapter} from './contrato-contacto-tipo.adapter';
import {PaisAdapter} from './pais.adapter';
import {IdentificadorTipoAdapter} from './identificador-tipo.adapter';

@Injectable({
  providedIn: 'root'
})
export class ContratoAnexoContactoAdapter implements Adapter<ContratoAnexoContacto> {
  constructor(
    private contactoAdapter: ContactoAdapter,
    private contratoContactoTipo: ContratoContactoTipoAdapter,
    private paisAdapter: PaisAdapter,
    private identificadorTipoAdapter: IdentificadorTipoAdapter
  ) {
  }

  adapt(item: any): ContratoAnexoContacto {
    const contratoAnexoContacto = new ContratoAnexoContacto();
    contratoAnexoContacto.id = item.attributes.id;
    contratoAnexoContacto.contactoId = item.attributes.contacto_id;
    contratoAnexoContacto.contratoAnexoId = item.attributes.contrato_anexo_id;
    contratoAnexoContacto.contratoContactoTipoId = item.attributes.contrato_contacto_tipo_id;
    if (item.included) {
      item.included.filter((valor) => {
        switch (valor.type) {
          case 'Contacto':
            contratoAnexoContacto.contacto = this.contactoAdapter.adapt(valor);
            break;
          case 'ContratoContactoTipo':
            contratoAnexoContacto.contratoContactoTipo = this.contratoContactoTipo.adapt(valor);
            break;
          case 'Pais':
            contratoAnexoContacto.pais = this.paisAdapter.adapt(valor);
            break;
          case 'IdentificadorTipo':
            contratoAnexoContacto.identificadorTipo = this.identificadorTipoAdapter.adapt(valor);
        }
      })
    }
    return contratoAnexoContacto;
  }
}
