import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ReversoService {

  public filterAttributes: any = [];
  public reverso: any;

  constructor(
    public http: HttpClient,
    public utils: Utils
  ) { }


  crearReverso(reverso): Observable <any> {
    return this.http.post(environment.urlApi + 'reverso', reverso);
  }

  formatJson() {
    return Utils.toJson(this.reverso, this.filterAttributes);
  }

}
