import {Contacto} from './contacto.model';
import {MontoTipo} from './monto-tipo.model';
import {Divisa} from "./divisa.model";

export class UnidadPropietario {
  private _id: string;
  private _unidadId: string;
  private _contactoId: string;
  private _porcentaje: number;
  private _activo: boolean;
  private _comisionTipoId: number;
  private _comisionValor: number;
  private _comisionDivisaId: string;
  private _comisionValorImpuesto: string;
  private _divisaId: string;

  private _contacto: Contacto;
  private _montoTipo: MontoTipo;
  private _divisa: Divisa;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get unidadId(): string {
    return this._unidadId;
  }

  set unidadId(value: string) {
    this._unidadId = value;
  }

  get contactoId(): string {
    return this._contactoId;
  }

  set contactoId(value: string) {
    this._contactoId = value;
  }

  get porcentaje(): number {
    return this._porcentaje;
  }

  set porcentaje(value: number) {
    this._porcentaje = value;
  }

  get activo(): boolean {
    return this._activo;
  }

  set activo(value: boolean) {
    this._activo = value;
  }

  get comisionTipoId(): number {
    return this._comisionTipoId;
  }

  set comisionTipoId(value: number) {
    this._comisionTipoId = value;
  }

  get comisionValor(): number {
    return this._comisionValor;
  }

  set comisionValor(value: number) {
    this._comisionValor = value;
  }

  get contacto(): Contacto {
    return this._contacto;
  }

  set contacto(value: Contacto) {
    this._contacto = value;
  }

  get montoTipo(): MontoTipo {
    return this._montoTipo;
  }

  set montoTipo(value: MontoTipo) {
    this._montoTipo = value;
  }

  get comisionDivisaId(): string {
    return this._comisionDivisaId;
  }

  set comisionDivisaId(value: string) {
    this._comisionDivisaId = value;
  }

  get comisionValorImpuesto(): string {
    return this._comisionValorImpuesto;
  }

  set comisionValorImpuesto(value: string) {
    this._comisionValorImpuesto = value;
  }

  get divisaId(): string {
    return this._divisaId;
  }

  set divisaId(value: string) {
    this._divisaId = value;
  }

  get divisa(): Divisa {
    return this._divisa;
  }

  set divisa(value: Divisa) {
    this._divisa = value;
  }
}
