import { Injectable } from '@angular/core';

import { Ability, AbilityBuilder } from '@casl/ability';
interface AbilityRule {
  action: string | string[]
  subject?: string | string[]
  fields?: string[]
  conditions?: any
  inverted?: boolean
  reason?: string
}

@Injectable({
  providedIn: 'root'
})
export class AbilityService {

  constructor(
    private ability: Ability
  ) { }

  /**
   * Actualizar habilidades para usar en Angular
   * @param roles
   * @param acciones
   */
  public updateAbility(roles: string[], acciones: string[]) {
    const { can, rules } = new AbilityBuilder<Ability>();
    roles.forEach((rol)=>{
      can(rol, "Rol");
    });
    acciones.forEach((accion)=>{
      can(accion, "Permiso");
    });
    this.ability.update(rules);
  }

  /**
   * Limpiar habilidades
   */
  public clearAbilities(){
    this.ability.update([]);
  }

  /**
   * Validar si un usuario tiene un rol
   */
  public hasRole(role: string): boolean{
    let roles = JSON.parse(localStorage.getItem('identity')).roles;
    return (roles.indexOf(role)!==-1);
  }

  /**
   * Validar si un usuario tiene una accion
   */
  public hasAccion(accion: string): boolean{
    let acciones = JSON.parse(localStorage.getItem('identity')).acciones;
    return (acciones.indexOf(accion)!==-1);
  }

  /**
   * Validar si el usuario puede ver propiedades
   */
  public canViewPropiedades():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('propiedad_creador') ||
            this.hasRole('propiedad_editor') ||
            this.hasRole('propiedad_admin') ||
            this.hasRole('propiedad_ver') ||
            this.canViewPropiedadConfigura();
  }

  /**
   * Validar si el usuario puede ver unidades
   */
  public canViewUnidades():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('unidad_admin') ||
            this.hasRole('unidad_ver') ||
            this.hasRole('unidad_editar') ||
            this.hasRole('unidad_crear');
  }

  /**
   * Validar si el usuario puede ver clientes
   */
  public canViewClientes():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('contacto_creador') ||
            this.hasRole('contacto_editor') ||
            this.hasRole('contacto_ver') ||
            this.hasRole('contacto_admin');
  }

  /**
   * Validar si el usuario puede ver balances
   */
  public canViewBalance():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('balance_creador') ||
            this.hasRole('balance_editor') ||
            this.hasRole('balance_ver') ||
            this.hasRole('balance_admin');
  }

  public canViewOwners():boolean{
    return  this.hasRole('superadmin')
  }

  /**
   * Validar si el usuario puede ver checkstate
   */
  public canViewCheckstate():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('checkstate_ver') ||
            this.hasRole('checkstate_admin') ||
            this.hasRole('checkstate_creador') ||
            this.hasRole('checkstate_editor');
  }

  /**
   * Validar si el usuario puede ver publicaciones
   */
  public canViewPublicaciones():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('publicaciones_creador') ||
            this.hasRole('publicaciones_editor') ||
            this.hasRole('publicaciones_ver') ||
            this.hasRole('publicaciones_admin');
  }

  /**
   * Validar si el usuario puede ver residentes
   */
  public canViewResidentes():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('residente_admin') ||
            this.hasRole('residente_creador') ||
            this.hasRole('residente_editor') ||
            this.hasRole('residente_ver');
  }

  /**
   * Validar si el usuario puede ver cotizaciones
   */
  public canViewCotizaciones():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('cotizacion_creador') ||
            this.hasRole('cotizacion_editor') ||
            this.hasRole('cotizacion_ver') ||
            this.hasRole('cotizacion_admin');
  }

  /**
   * Validar si el usuario puede ver contratos
   */
  public canViewContratos():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('contrato_creador') ||
            this.hasRole('contrato_editor') ||
            this.hasRole('contrato_ver') ||
            this.hasRole('contrato_admin');
  }

  /**
   * Validar si el usuario puede ver evaluaciones
   */
  public canViewEvaluaciones():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('evaluacion_creador') ||
            this.hasRole('evaluacion_editor') ||
            this.hasRole('evaluacion_ver') ||
            this.hasRole('evaluacion_admin');
  }

  /**
   * Validar si el usuario puede ver contactos
   */
  public canViewContactos():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('contrato_creador') ||
            this.hasRole('cotizacion_creador') ||
            this.hasRole('cotizacion_admin') ||
            this.hasRole('contrato_admin') ||
            this.hasRole('evaluacion_admin') ||
            this.hasRole('checkstate_ver') ||
            this.hasRole('checkstate_admin') ||
            this.hasRole('publicaciones_ver') ||
            this.hasRole('publicaciones_admin');
  }

  /**
   * Validar si el usuario puede ver modulo de configuracion de propiedades
   */
   public canViewPropiedadConfigura():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('propiedad_admin') ||
            this.hasRole('propiedad_configura') ||
            this.hasRole('notificacion_admin') ||
            this.hasRole('carga_masiva_admin');
  }

  /**
   * Validar si el usuario puede ver modulo de notificaciones dentro de configuracion de propiedades
   */
   public canViewConfiguraNotificaciones():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('notificacion_admin');
  }

  /**
   * Validar si el usuario puede ver modulo de carga masiva dentro de configuracion de propiedades
   */
   public canViewCargaMasiva():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('carga_masiva_admin');
  }

  /**
   * Validar si el usuario puede ver modulo de espacios comunes
   */
   public canViewEspaciosComunes():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('espacio_comun_admin') ||
            this.hasRole('espacio_comun_creador') ||
            this.hasRole('espacio_comun_editor') ||
            this.hasRole('espacio_comun_ver')
  }

  /**
   * Validar si el usuario puede ver modulo de anexos
   */
   public canViewAnexos():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('anexo_admin') ||
            this.hasRole('anexo_creador') ||
            this.hasRole('anexo_editor') ||
            this.hasRole('anexo_ver')
  }



  /**
   * Validar si el usuario puede vere el modulo de cotizaciones
   */
   public canLoadCotizacion():boolean{
    return this.hasRole('cotizacion_admin') ||
           this.hasRole('cotizacion_creador') ||
           this.hasRole('cotizacion_editor') ||
           this.hasRole('cotizacion_ver');
  }

  /**
   * Validar si con un permiso alternativo se puede cargar el modulo de propiedades
   */
  public canLoadPropiedades():boolean{
    return this.canViewPropiedades() ||
           this.canViewConfiguraNotificaciones() ||
           this.canViewCheckstate() ||
           this.canViewCargaMasiva()
  }

  /**
  * Validar si el usuario puede ver bitacora
  */
  public canViewBitacora():boolean{
    return  this.hasRole('superadmin') ||
            this.hasRole('bitacora_admin') ||
            this.hasRole('bitacora_ver') ||
            this.hasRole('bitacora_creador') ||
            this.hasRole('bitacora_editor');
  }

  public canConciliarPago(): boolean { // TODO: definir roles
    return this.hasRole('superadmin') ||
      this.hasRole('contabilidad_admin');
  }

  public canViewReservas():boolean{
    return  this.hasRole('superadmin')
     ||
      this.hasRole('reserva_creador') ||
      this.hasRole('reserva_editor') ||
      this.hasRole('reserva_ver') ||
      this.hasRole('reserva_admin');
  }
}
