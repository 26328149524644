export class UnidadFirmante {
  private _id: string;
  private _nombre: string;
  private _email: string;
  private _identificador: string;
  private _isActive: boolean;
  private _unidadId: string;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get nombre(): string {
    return this._nombre;
  }

  set nombre(value: string) {
    this._nombre = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get identificador(): string {
    return this._identificador;
  }

  set identificador(value: string) {
    this._identificador = value;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
  }

  get unidadId(): string {
    return this._unidadId;
  }

  set unidadId(value: string) {
    this._unidadId = value;
  }
}
