import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { MotivoReverso } from '../models/motivo-reverso.model';

@Injectable({
    providedIn: 'root'
  })
  export class MotivoReversoAdapter implements Adapter<MotivoReverso> {
    adapt(item: any): MotivoReverso {
        const motivoReverso = new MotivoReverso();
        motivoReverso.id = item.attributes.id;
        motivoReverso.nombre = item.attributes.nombre;
        return motivoReverso;
    }
  }
