<!-- Sidebar -->
<div id="sidebar-wrapper" tabindex="-1">
    <div class="options-container">
        <ng-container *ngFor="let menu of _sidebar.menu">
            <div  *ngIf="menu.isActive" [ngClass]="['option-container', getContainerClass(menu.titulo)]">
                <div
                        class="tittle-and-icon"
                        [ngClass]="['option-container', getClass(menu.url)]"
                        (mouseover)="changeHoverElement(menu.titulo)"
                        (mouseout)="changeHoverElement('')"
                        (click)="onclickInOption(menu.titulo)"
                        data-toggle="collapse"
                        data-target="#secondary-options"
                        aria-expanded="false"
                        aria-controls="secondary-options"
                >
                    <div>
                        <app-icono
                                [type]="menu.icono"
                                [color]="hoverElement === menu.titulo || getClass(menu.url) === 'current-url' ? '#F7BB10' : '#ffffff'"
                                [width]="24">
                        </app-icono>
                        <span class="option-title">{{ menu.titulo | translate }}</span>
                    </div>
                    <i *ngIf="menu.options.length" [ngClass]="['fa', showOptions === menu.titulo ? 'fa-angle-up' : 'fa-angle-down']"></i>
                </div>
                <div *ngIf="showOptions === menu.titulo" class="collapse" id="secondary-options">
                    <ng-container *ngFor="let option of menu.options">
                        <div
                                class="tittle-and-icon"
                                [ngClass]="['option-container', getClass(option.url)]"
                                (mouseover)="changeHoverElement(option.titulo)"
                                (mouseout)="changeHoverElement('')"
                                (click)="goToUrl(option.url)"
                        >
                            <div>
                                <app-icono
                                        [type]="option.icono"
                                        [color]="hoverElement === option.titulo || getClass(option.url) === 'current-url' ? '#F7BB10' : '#ffffff'"
                                        [width]="24">
                                </app-icono>
                                <span class="option-title">{{ option.titulo | translate }}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <div>
        <div class="option-container">
            <div
                class="tittle-and-icon"
                [ngClass]="['option-container', getClass('/manuales')]"
                (mouseover)="changeHoverElement('help.title')"
                (mouseout)="changeHoverElement('')"
                [routerLink]="['/ayuda/manuales']"
            >
                <div>
                    <app-icono
                        type="ayuda"
                        [color]="hoverElement === 'help.title' || getClass('/manuales') === 'current-url' ? '#F7BB10' : '#ffffff'"
                        [width]="24">
                    </app-icono>
                    <span class="option-title">{{ 'help.title' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
