import { Adapter } from './adapter';
import { TransaccionConcepto } from '../models/model.index';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class TransaccionConceptoAdapter implements Adapter<TransaccionConcepto> {

    adapt(item: any): TransaccionConcepto {
        const transaccionConcepto = new TransaccionConcepto();
        transaccionConcepto.id = item.attributes.id;
        transaccionConcepto.nombre = item.attributes.nombre;
        transaccionConcepto.transaccionTipoId = item.attributes.transaccion_tipo_id;
        return transaccionConcepto;
    }

}
