import update from 'immutability-helper';
import {
  Home,
  MonthlyValue,
  MovementValue,
  UnitStatisticsHome,
  UnitInfo,
} from '../models/home.model';
import { HomeActions, HomeActionTypes } from '../actions/home.action';
import { ChartData } from '../../shared/advanced-pie-chart/advanced-pie-chart.model';
import { A, W } from '@angular/cdk/keycodes';

const initialItems: {
  [id: number]: UnitInfo,
} = {
  100: {
    color: 'green',
    title: 'units.avaiable',
    key: 'disponibles',
    value: 0,
    percentage: 0,
    id: 100,
  },
  200: {
    color: 'orange',
    title: 'units.rented',
    key: 'arrendadas',
    value: 0,
    percentage: 0,
    id: 200,
  },
  300: {
    color: 'red',
    title: 'units.booked',
    key: 'reservadas',
    value: 0,
    percentage: 0,
    id: 300,
  },
  400: {
    color: 'gray',
    title: 'units.unavailable',
    key: 'no disponibles',
    value: 0,
    percentage: 0,
    id: 400,
  },
};

const initialLocale = localStorage.getItem('locale-user') && localStorage.getItem('locale-user') !== 'null' && localStorage.getItem('locale-user') !== '' ? 
    localStorage.getItem('locale-user'): 'es-CL'; 
localStorage.setItem('locale-user',initialLocale);

const initialState: Home = {
  contractsInfo: [],
  loaded: {
    contractsInfo: false,
    expiration: false,
    monthlyValues: false,
    movements: false,
    publications: false,
    unitsInfo: false,
    logbooks: false,
  },
  expiration: [],
  monthlyValues: [
    { color: 'green', title: 'status.paid', key: 'pagado', values: [0] },
    { color: 'yellow', title: 'status.pending', key: 'pendiente', values: [0] },
    { color: 'red', title: 'status.slow_payer', key: 'moroso', values: [0] },
  ],
  movements: [
    { color: 'blue', title: 'movements.total', key: 'total', values: [0] },
    { color: 'green', title: 'movement.revenue', key: 'ingreso', values: [0] },
    { color: 'red', title: 'movements.expenses', key: 'egreso', values: [0] },
  ],
  properties: [],
  publications: [],
  logbooks: [],
  selectedProperty: null,
  unitsInfo: [],
  locale: initialLocale,
  refresh_token: {},
};

export const HomeReducer = (
  state: Home = initialState,
  action: HomeActions
) => {
  switch (action.type) {
    case HomeActionTypes.SET_MONTHLY_VALUE: {
      return update(state, {
        monthlyValues: {
          $apply: (monthlyValues: MonthlyValue[]) => {
            let newMonthlyValues: MonthlyValue[] = monthlyValues;
            const monthlyValuesIndex = new Map(
              monthlyValues.map((e, i) => [e.key, i])
            );

            action.payload.forEach((e) => {
              if (monthlyValuesIndex.has(e.type)) {
                newMonthlyValues = update(newMonthlyValues, {
                  [monthlyValuesIndex.get(e.type)]: {
                    values: { $set: e.values },
                  },
                });
              }
            });
            return newMonthlyValues;
          },
        },
        loaded: {
          monthlyValues: {
            $set: true,
          },
        },
      });
    }

    case HomeActionTypes.SET_SELECTED_PROPERTY: {
      return update(state, {
        selectedProperty: { $set: action.payload },
      });
    }

    case HomeActionTypes.SET_PROPERTIES: {
      return update(state, {
        properties: { $set: action.payload },
      });
    }

    case HomeActionTypes.SET_TOKEN_REFRESH: {
      return update(state, {
        refresh_token: {
          $apply: () => {
            return action.payload;
          },
        },
      });
    }

    case HomeActionTypes.SET_INFORMATION_LOADED: {
      return update(state, {
        loaded: {
          $apply: (loaded) => {
            let newLoaded = loaded;

            action.payload.forEach((e) => {
              newLoaded = update(newLoaded, {
                [e.type]: {
                  $set: e.value,
                },
              });
            });

            return newLoaded;
          },
        },
      });
    }

    case HomeActionTypes.SET_MOVEMENTS: {
      return update(state, {
        movements: {
          $apply: (movements: MovementValue[]) => {
            let newMovements: MovementValue[] = movements;
            const movementsIndex = new Map(movements.map((e, i) => [e.key, i]));

            action.payload.forEach((e) => {
              if (movementsIndex.has(e.type)) {
                newMovements = update(newMovements, {
                  [movementsIndex.get(e.type)]: {
                    values: { $set: e.values },
                  },
                });
              }
            });
            return newMovements;
          },
        },
        loaded: {
          movements: {
            $set: true,
          },
        },
      });
    }

    case HomeActionTypes.SET_EXPIRATION: {
      const { selectedProperty } = state;

      return update(state, {
        expiration: {
          $apply: () => {
            let newExpiration: ChartData[] = [];
            if (action.payload.length) {
              let total = 0;
              const colors = {
                ['91-120']: '#47BC4C',
                ['61-90']: '#F7BB0F',
                ['31-60']: '#F16250',
                ['0-30']: '#F8AD65',
              };

              action.payload.forEach((e) => {
                total += Number(e.cantidad);
              });

              action.payload.forEach((e) => {
                const range = `${e.desde}-${e.hasta}`;

                newExpiration.push({
                  name: `${range} días`,
                  value: Number(e.cantidad),
                  color: colors[range],
                  tooltipText: `${Math.trunc(
                    (Number(e.cantidad) * 100) / total
                  )}% ${range} días = ${e.cantidad} contratos`,
                  information: {
                    filter: {
                      fechaFinMax:
                        e.fecha_hasta.split(' ')[0] + 'T05:00:00.000Z',
                      fechaFinMin:
                        e.fecha_desde.split(' ')[0] + 'T05:00:00.000Z',
                      estado: [300],
                      ...(selectedProperty !== 'allProperties'
                        ? { propiedadId: selectedProperty }
                        : {}),
                    },
                  },
                });
              });
            }

            return newExpiration;
          },
        },
        loaded: {
          expiration: {
            $set: true,
          },
        },
      });
    }

    case HomeActionTypes.SET_CONTRACTS_INFO: {
      const { selectedProperty } = state;

      return update(state, {
        contractsInfo: {
          $apply: () => {
            let newContractsInfo: ChartData[] = [];
            if (action.payload.indicadores.length) {
              let total = 0;

              const orderSet = new Set(
                action.payload.indicadores_grafico.map((e) => e.id)
              );

              const states: Map<number, number> = new Map(
                action.payload.indicadores.map((e) => [
                  e.id,
                  Number(e.cantidad),
                ])
              );
              action.payload.indicadores.forEach((e) => {
                if (orderSet.has(e.id)) {
                  total += Number(e.cantidad);
                }
              });

              action.payload.indicadores_grafico.forEach((e) => {
                let cantidad = 0;

                if (states.has(e.id)) {
                  cantidad = states.get(e.id);
                }

                if (cantidad) {
                  newContractsInfo.push({
                    name: e.nombre,
                    value: cantidad,
                    color: e.color,
                    tooltipText: `${Math.trunc((cantidad * 100) / total)}% ${
                      e.nombre
                    } = ${cantidad} contratos`,
                    information: {
                      filter: {
                        estado: [e.id],
                        ...(selectedProperty !== 'allProperties'
                          ? { propiedadId: selectedProperty }
                          : {}),
                      },
                    },
                  });
                }
              });
            }
            return newContractsInfo;
          },
        },
        loaded: {
          contractsInfo: {
            $set: true,
          },
        },
      });
    }

    case HomeActionTypes.SET_UNITS_INFO: {
      const { selectedProperty } = state;
      const { sortedUnitTypes, unitsInfo } = action.payload

      return update(state, {
        unitsInfo: {
          $apply: () => {
            let newUnitsInfo: UnitStatisticsHome[] = [];
      
            if (unitsInfo.length) {
              const addedUnitsWithIndex: Map<string, number> = new Map();
              const parkingTypeId = new Set([
                'cc732cbb-fbc2-4b51-8438-699ad2c4ebb2',
                '2bf8ad62-ffc4-4d72-8b9f-0b2fa14df3e9',
                '934ece8e-ed18-45e4-9652-c0bdbfa5266c',
                '8bf8b974-265e-41c1-ab67-a86c5d5ed085',
                'f6e6ab9c-2741-46d5-8ad5-3f0fa5a2f590',
              ]);
              const unitTypeIdToTranslateResource = new Map(sortedUnitTypes.map(e => [e.id, e.langResource]))
              unitsInfo.forEach((e) => {
                if (parkingTypeId.has(e.unidadTipoId)) {
                  if (addedUnitsWithIndex.has('parkingId')) {
                    const idIndex = newUnitsInfo[
                      addedUnitsWithIndex.get('parkingId')
                    ].items.findIndex((f) => f.id === e.unidadEstadoId);
      
                    if (idIndex !== -1) {
                      newUnitsInfo[addedUnitsWithIndex.get('parkingId')].items[
                        idIndex
                      ].value =
                        newUnitsInfo[addedUnitsWithIndex.get('parkingId')].items[
                          idIndex
                        ].value + e.cantidad;
                    } else {
                      newUnitsInfo[addedUnitsWithIndex.get('parkingId')].items.push(
                        update(initialItems[e.unidadEstadoId], {
                          value: {
                            $set: e.cantidad,
                          },
                        })
                      );
                    }
                    newUnitsInfo[addedUnitsWithIndex.get('parkingId')].total =
                      newUnitsInfo[addedUnitsWithIndex.get('parkingId')].total +
                      e.cantidad;
                  } else {
                    addedUnitsWithIndex.set('parkingId', newUnitsInfo.length);
                    newUnitsInfo.push({
                      id: 'parkingId',
                      title: 'home.parking',
                      filter: {
                        unidadTipoId: [...parkingTypeId],
                        ...(selectedProperty !== 'allProperties'
                          ? { propiedadId: selectedProperty }
                          : {}),
                      },
                      total: e.cantidad,
                      items: [
                        update(initialItems[e.unidadEstadoId], {
                          value: {
                            $set: e.cantidad,
                          },
                        }),
                      ],
                    });
                  }
                } else {
                  if (addedUnitsWithIndex.has(e.unidadTipoId)) {
                    newUnitsInfo[addedUnitsWithIndex.get(e.unidadTipoId)].items.push(
                      update(initialItems[e.unidadEstadoId], {
                        value: {
                          $set: e.cantidad,
                        },
                      })
                    );
                    newUnitsInfo[addedUnitsWithIndex.get(e.unidadTipoId)].total =
                      newUnitsInfo[addedUnitsWithIndex.get(e.unidadTipoId)].total +
                      e.cantidad;
                  } else {
                    addedUnitsWithIndex.set(e.unidadTipoId, newUnitsInfo.length);
                    newUnitsInfo.push({
                      id: e.unidadTipoId,
                      title: unitTypeIdToTranslateResource.get(e.unidadTipoId),
                      filter: {
                        unidadTipoId: [e.unidadTipoId],
                        ...(selectedProperty !== 'allProperties'
                          ? { propiedadId: selectedProperty }
                          : {}),
                      },
                      total: e.cantidad,
                      items: [
                        update(initialItems[e.unidadEstadoId], {
                          value: {
                            $set: e.cantidad,
                          },
                        }),
                      ],
                    });
                  }
                }
              });
      
              newUnitsInfo.forEach((e) => {
                const sortedItems: UnitInfo[] = [];
                for (const id in initialItems) {
                  const itemWithValues = e.items.find((f) => f.id === Number(id));
                  sortedItems.push(itemWithValues || initialItems[id]);
                }
                e.items = sortedItems;
              });
      
              newUnitsInfo = newUnitsInfo.map((e) => ({
                ...e,
                items: e.items.map((f) => ({
                  ...f,
                  percentage: Math.trunc((f.value * 100) / e.total),
                })),
              }));
      
              const newUnitsInfoMap = new Map(newUnitsInfo.map((e) => [e.id, e]));
              const newUnitsInfoSorted: UnitStatisticsHome[] = [];
              let wasAddedSomeParking = false;
      
              sortedUnitTypes.forEach(({ id }) => {
                if (parkingTypeId.has(id) && !wasAddedSomeParking && newUnitsInfoMap.has('parkingId')) {
                  newUnitsInfoSorted.push(newUnitsInfoMap.get('parkingId'));
                  wasAddedSomeParking = true;
                } else if (newUnitsInfoMap.has(id)) {
                  newUnitsInfoSorted.push(newUnitsInfoMap.get(id));
                }
              });
     
              newUnitsInfo = newUnitsInfoSorted;
            }
      
            return newUnitsInfo;
          },
        },
        loaded: {
          unitsInfo: {
            $set: true,
          },
        },
      });
    }

    case HomeActionTypes.SET_LANGUAGE: {
      return update(state, {
        locale: {
          $set: action.payload.language,
        },
      });
    }

    case HomeActionTypes.CLEAN_STATE: {
      return initialState;
    }

    case HomeActionTypes.SET_PUBLICATIONS: {
      return update(state, {
        publications: { $set: action.payload },
        loaded: { publications: { $set: true } },
      });
    }

    case HomeActionTypes.SET_PUBLICATION_IMAGE: {
      const { id, src } = action.payload;
      const index = state.publications.findIndex((e) => e.id === id);
      return update(state, {
        publications: {
          [index]: {
            imagenSrc: { $set: src },
          },
        },
      });
    }

    case HomeActionTypes.SET_LOGBOOKS: {
      const { selectedProperty } = state;

      return update(state, {
        logbooks: {
          $apply: () => {
            const newLogbooks: ChartData[] = [];
            if (action.payload?.length) {
              let total = 0;
              action.payload.forEach(({ cantidad }) => {
                total += Number(cantidad);
              });
              action.payload.forEach(({ cantidad, id, nombre, color }) => {
                if (cantidad) {
                  newLogbooks.push({
                    name: nombre,
                    value: Number(cantidad),
                    color,
                    tooltipText: `${Math.trunc(
                      (Number(cantidad) * 100) / total
                    )}% ${nombre} = ${cantidad} registros`,
                    information: {
                      filter: {
                        typeId: id,
                        ...(selectedProperty !== 'allProperties'
                          ? { propiedadId: selectedProperty }
                          : {}),
                      },
                    },
                  });
                }
              });
            }

            return newLogbooks;
          },
        },
        loaded: { logbooks: { $set: true } },
      });
    }

    default:
      return state;
  }
};
