import { CargoClasificacion } from './cargoClasificacion.model';
import {Concepto, EstadoCuenta, EstadoCuentaEstado, CuentaRecaudadora, UnidadTipo} from './model.index';
import { Unidad } from './unidad.model';

export class EstadoCuentaDetalle {

    private _id: string;
    private _conceptoId: number;
    private _cargoClasificacionId?: number;
    private _monto: number;
    private _estadoCuentaId: string;
    private _fechaPago: string;
    private _estadoCuentaEstadoId: number;
    private _cuentaRecaudadoraId: string;
    private _nombre: string;
    private _unidadId: string;
    private _isFacturableInt: boolean;
    private _descuentoRecargoId: string;
    private _estadoCuentaDetallePadre: string;
    private _diasAtraso: number;
    private _cuentaCorrienteId: string;
    private _divisaCobroId: string;
    private _divisaConversionId: number;
    private _divisaConversionValor: number;
    private _divisaId: string;
    private _divisaConversionFecha: string;
    private _montoCobro: number;


    private _concepto: Concepto;
    private _estadoCuenta: EstadoCuenta;
    private _estadoCuentaEstado: EstadoCuentaEstado;
    private _cuentaRecaudadora: CuentaRecaudadora;
    private _unidad: Unidad;
    private _unidadTipo: UnidadTipo;
    private _cargoClasificacion?: CargoClasificacion;


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get conceptoId(): number {
        return this._conceptoId;
    }
    public set conceptoId(value: number) {
        this._conceptoId = value;
    }
    public get cargoClasificacionId(): number {
        return this._cargoClasificacionId;
    }
    public set cargoClasificacionId(value: number) {
        this._cargoClasificacionId = value;
    }
    public get monto(): number {
        return this._monto;
    }
    public set monto(value: number) {
        this._monto = value;
    }
    public get estadoCuentaId(): string {
        return this._estadoCuentaId;
    }
    public set estadoCuentaId(value: string) {
        this._estadoCuentaId = value;
    }
    public get fechaPago(): string {
        return this._fechaPago;
    }
    public set fechaPago(value: string) {
        this._fechaPago = value;
    }
    public get estadoCuentaEstadoId(): number {
        return this._estadoCuentaEstadoId;
    }
    public set estadoCuentaEstadoId(value: number) {
        this._estadoCuentaEstadoId = value;
    }
    public get cuentaRecaudadoraId(): string {
        return this._cuentaRecaudadoraId;
    }
    public set cuentaRecaudadoraId(value: string) {
        this._cuentaRecaudadoraId = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get unidadId(): string {
        return this._unidadId;
    }
    public set unidadId(value: string) {
        this._unidadId = value;
    }
    public get isFacturableInt(): boolean {
        return this._isFacturableInt;
    }
    public set isFacturableInt(value: boolean) {
        this._isFacturableInt = value;
    }
    public get descuentoRecargoId(): string {
        return this._descuentoRecargoId;
    }
    public set descuentoRecargoId(value: string) {
        this._descuentoRecargoId = value;
    }
    public get estadoCuentaDetallePadre(): string {
        return this._estadoCuentaDetallePadre;
    }
    public set estadoCuentaDetallePadre(value: string) {
        this._estadoCuentaDetallePadre = value;
    }
    public get diasAtraso(): number {
        return this._diasAtraso;
    }
    public set diasAtraso(value: number) {
        this._diasAtraso = value;
    }
    get cuentaCorrienteId(): string {
        return this._cuentaCorrienteId;
    }
    set cuentaCorrienteId(value: string) {
        this._cuentaCorrienteId = value;
    }
    get divisaCobroId(): string {
        return this._divisaCobroId;
    }

    set divisaCobroId(value: string) {
        this._divisaCobroId = value;
    }

    get divisaConversionId(): number {
        return this._divisaConversionId;
    }

    set divisaConversionId(value: number) {
        this._divisaConversionId = value;
    }

    get divisaConversionValor(): number {
        return this._divisaConversionValor;
    }

    set divisaConversionValor(value: number) {
        this._divisaConversionValor = value;
    }

    get divisaId(): string {
        return this._divisaId;
    }

    set divisaId(value: string) {
        this._divisaId = value;
    }

    get divisaConversionFecha(): string {
        return this._divisaConversionFecha;
    }

    set divisaConversionFecha(value: string) {
        this._divisaConversionFecha = value;
    }

    get montoCobro(): number {
        return this._montoCobro;
    }

    set montoCobro(value: number) {
        this._montoCobro = value;
    }

    public get concepto(): Concepto {
        return this._concepto;
    }
    public set concepto(value: Concepto) {
        this._concepto = value;
    }
    public get estadoCuenta(): EstadoCuenta {
        return this._estadoCuenta;
    }
    public set estadoCuenta(value: EstadoCuenta) {
        this._estadoCuenta = value;
    }
    public get estadoCuentaEstado(): EstadoCuentaEstado {
        return this._estadoCuentaEstado;
    }
    public set estadoCuentaEstado(value: EstadoCuentaEstado) {
        this._estadoCuentaEstado = value;
    }
    public get cuentaRecaudadora(): CuentaRecaudadora {
        return this._cuentaRecaudadora;
    }
    public set cuentaRecaudadora(value: CuentaRecaudadora) {
        this._cuentaRecaudadora = value;
    }
    public get unidad(): Unidad {
        return this._unidad;
    }
    public set unidad(value: Unidad) {
        this._unidad = value;
    }
    get unidadTipo(): UnidadTipo {
        return this._unidadTipo;
    }
    set unidadTipo(value: UnidadTipo) {
        this._unidadTipo = value;
    }
    public get cargoClasificacion(): CargoClasificacion {
        return this._cargoClasificacion;
    }
    public set cargoClasificacion(cargoClasificacion: CargoClasificacion) {
        this._cargoClasificacion = cargoClasificacion;
    }
}
