<!-- Default dropleft button -->
<div class="btn-group drop{{arrow}}" *ngIf="selectedLanguage">
    <button type="button" class="btn text-{{color}} dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <img src="assets/img/iconos/flags/{{selectedLanguage.key}}.png">&nbsp;{{selectedLanguage.title | translate}}
    </button>
    <div class="dropdown-menu">
        <a href="javascript: void(0)" class="dropdown-item" *ngFor="let language of languages"
            (click)="cambiarIdioma(language.key)">
            <img src="assets/img/iconos/flags/{{language.key}}.png">&nbsp;{{language.title | translate}}
        </a>
    </div>
</div>