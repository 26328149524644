import { ConceptoAdapter } from './concepto.adapter';
import { ContactoAdapter } from 'src/app/adapter/contacto.adapter';
import { EstadoCuentaEstadoAdapter } from './estado-cuenta-estado.adapter';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { EstadoCuentaDetalle, EstadoCuenta, Contrato } from 'src/app/models/model.index';
import { ContratoAdapter } from './contrato.adapter';
import { CuentaRecaudadoraAdapter } from './cuenta-recaudadora.adapter';
import { UnidadAdapter } from './unidad.adapter';
import { CargoClasificacionAdapter } from './cargo-clasificacion.adapter';
import {UnidadTipoAdapter} from './unidad-tipo.adapter';
import {ReservaAdapter} from "./reserva.adapter";

@Injectable({
  providedIn: 'root'
})

export class EstadoCuentaDetalleAdapter implements Adapter<EstadoCuentaDetalle> {

  constructor(
    public estadoCuentaEstadoAdapter: EstadoCuentaEstadoAdapter,
    public contratoAdapter: ContratoAdapter,
    public contactoAdapter: ContactoAdapter,
    public conceptoAdapter: ConceptoAdapter,
    public cuentaRecaudadoraAdapter: CuentaRecaudadoraAdapter,
    public unidadAdapter: UnidadAdapter,
    public cargoClasificacionAdapter: CargoClasificacionAdapter,
    public unidadTipoAdapter: UnidadTipoAdapter,
    public reservaAdapter: ReservaAdapter
  ) {}

  adapt(item: any): EstadoCuentaDetalle {
    const estadoCuentaDetalle = new EstadoCuentaDetalle();
    estadoCuentaDetalle.id = item.attributes.id;
    estadoCuentaDetalle.conceptoId = item.attributes.concepto_id;
    estadoCuentaDetalle.cargoClasificacionId = item.attributes.cargo_clasificacion_id;
    estadoCuentaDetalle.monto = parseFloat(this.getNumberWithoutExtraZeros(item.attributes.monto));
    estadoCuentaDetalle.estadoCuentaId = item.attributes.estado_cuenta_id;
    estadoCuentaDetalle.estadoCuentaEstadoId = +item.attributes.estado_cuenta_estado_id;
    estadoCuentaDetalle.fechaPago = item.attributes.fecha_pago;
    estadoCuentaDetalle.cuentaRecaudadoraId = item.attributes.cuenta_corriente_id;
    estadoCuentaDetalle.nombre = item.attributes.nombre;
    estadoCuentaDetalle.unidadId = item.attributes.unidad_id;
    estadoCuentaDetalle.isFacturableInt = item.attributes.is_facturable_int;
    estadoCuentaDetalle.descuentoRecargoId = item.attributes.descuento_recargo_id;
    estadoCuentaDetalle.estadoCuentaDetallePadre = item.attributes.estado_cuenta_detalle_padre;
    estadoCuentaDetalle.diasAtraso = item.attributes.dias_atraso;
    estadoCuentaDetalle.divisaId = item.attributes.divisa_id;
    estadoCuentaDetalle.divisaCobroId = item.attributes.divisa_cobro_id;
    estadoCuentaDetalle.montoCobro = parseFloat(this.getNumberWithoutExtraZeros(item.attributes.monto_cobro));
    estadoCuentaDetalle.divisaConversionValor = item.attributes.divisa_conversion_valor;
    estadoCuentaDetalle.divisaConversionId = item.attributes.divisa_conversion_id;
    estadoCuentaDetalle.divisaConversionFecha = item.attributes.divisa_conversion_fecha ? item.attributes.divisa_conversion_fecha : null;

    /* Includes*/
    if (item.included) {
      item.included.filter((valor) => {

        switch (valor.type) {
          case 'EstadoCuenta':
            estadoCuentaDetalle.estadoCuenta = this.fakeEstadoCuentaAdapter(valor);
            break;
          case 'EstadoCuentaEstado':
            estadoCuentaDetalle.estadoCuentaEstado = this.estadoCuentaEstadoAdapter.adapt(valor);
            break;
          case 'Contrato':
            estadoCuentaDetalle.estadoCuenta.contrato = this.contratoAdapter.adapt(valor);
            break;
          case 'Reserva':
            estadoCuentaDetalle.estadoCuenta.reserva = this.reservaAdapter.adapt(valor);
            break;
          case 'Contacto':
            if (!estadoCuentaDetalle.estadoCuenta.contrato) {
              estadoCuentaDetalle.estadoCuenta.contrato = new Contrato();
            }
            // estadoCuentaDetalle.estadoCuenta.contrato.contacto = this.contactoAdapter.adapt(valor);
            break;
          case 'Concepto':
            estadoCuentaDetalle.concepto = this.conceptoAdapter.adapt(valor);
            break;
          case 'CuentaRecaudadora':
            estadoCuentaDetalle.cuentaRecaudadora = this.cuentaRecaudadoraAdapter.adapt(valor);
            break;
          case 'Unidad':
            estadoCuentaDetalle.unidad = this.unidadAdapter.adapt(valor);
            break;
          case 'UnidadTipo':
            estadoCuentaDetalle.unidadTipo = this.unidadTipoAdapter.adapt(valor);
            break;
          case 'CargoClasificacion':
            estadoCuentaDetalle.cargoClasificacion = this.cargoClasificacionAdapter.adapt(valor);
            break;
        }
      });
    }
    return estadoCuentaDetalle;
  }

  getNumberWithoutExtraZeros(data: any) {
    if (data) {
      return Number(data).toString();
    }
  }


  fakeEstadoCuentaAdapter(item): EstadoCuenta {
    const estadoCuenta = new EstadoCuenta();
    estadoCuenta.id = item.attributes.id;
    estadoCuenta.contratoId = item.attributes.contrato_id;
    estadoCuenta.estadoCuentaEstadoId = +item.attributes.estado_cuenta_estado_id;
    estadoCuenta.fecha = item.attributes.fecha;
    estadoCuenta.fechaVencimiento = item.attributes.fecha_vencimiento;
    estadoCuenta.divisaId = item.attributes.divisa_id;
    estadoCuenta.montoPactado = item.attributes.monto_pactado;
    estadoCuenta.fechaPago = item.attributes.fecha_pago;
    estadoCuenta.fechaPeriodoInicio = item.attributes.fecha_periodo_inicio;
    estadoCuenta.fechaPeriodoFin = item.attributes.fecha_periodo_fin;
    estadoCuenta.isPagoConciliado = item.attributes.is_pago_conciliado;
    return estadoCuenta;
  }
}
