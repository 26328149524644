import { UntypedFormGroup, UntypedFormControl, NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Usuario } from './../models/usuario.model';
import { AuthService, ModalService } from '../services/service.index';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../store/models/app.model'
import { SetTokenRefresh } from '../store/actions/home.action';
import {GlobalsService} from "../services/globals.service";


@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public username: string;
  public usuario: Usuario;
  public clicked: boolean;
  public _remember: boolean;
  public error: boolean;
  private isMobileResolution: boolean;
  public formRemember: UntypedFormGroup;

  constructor(
    public router: Router,
    public authService: AuthService,
    private modalService: ModalService,
    private store: Store<AppState>,
    private globalsService: GlobalsService
  ) { }

  ngOnInit() {
    if (this.authService.isAuth()){
      this.iniciarPerfil();
    }else{
      this.clicked = false;
      this.checkResolution(window.innerWidth);
      if (this.authService.hasExpiredSetting()){
        this.modalService.notificacion('Su sesión ha expirado, por favor ingrese de nuevo', 'error');
        this.authService.clearExpiredSetting();
      }
      this.initForm();
    }
  }

  public get remember() {
      return this._remember;
  }
  public set remember(value: boolean) {
      this._remember = value;
  }

  initForm(){
    this.formRemember = new UntypedFormGroup({
      remember: new UntypedFormControl(this.remember),
   });
  }

  ingresar(formLogin: NgForm) {
    this.clicked = true;
    if ( formLogin.invalid ) {
      this.clicked = false;
      return;
    }
    const form = formLogin.form.getRawValue();
    this.authService.login(form).subscribe(
      resp => {
        this.authService.cargarPerfil().subscribe(() => {
          this.iniciarPerfil();
        },
        error => {
          this.modalService.notificacion('No fue posible cargar la informacion del perfil', 'error');
          this.clicked = false;
          this.error = true;
          this.authService.logout();
          this.authService.clearExpiredSetting();
        });
      },
      error => {
        this.modalService.notificacion('No fue posible loguear al usuario', 'error');
        this.authService.clearExpiredSetting();
        this.clicked = false;
        this.error = true;
      }
    );
  }

  iniciarPerfil(){
    this.authService.clearExpiredSetting();
    this.store.dispatch(new SetTokenRefresh({
      token: this.authService.token,
      refresh_token: this.authService.refresh_token,
      api: environment.urlApi,
      token_data: this.authService.getTokenData(),
      in_background: true
    }));
    this.globalsService.cargarGlobals().then(() => this.router.navigate(['/home']));
  }

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }

  public checkResolution(innerWidth) {
    if (innerWidth <= 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  onResize(event: any) {
    this.checkResolution(event.target.innerWidth);
  }

}
