<section id="contrasena" (window:resize)="onResize($event)">
    <!-- CONTAINER UNO -->

    <div class=" container-form-l desktop-form" *ngIf="!getIsMobileResolution()">
        <div class="row m-1">
            <div class="col-lg-6 col-md-6 offset-md-1 logo-img ">
                <img class="logo-contrasena" src="/assets/img/logo_recovery.png" alt="">
            </div>

            <div class="col-lg-3 col-md-4 logo-img contrasena-form">

                <form ngNativeValidate #formOlvidoContrasena="ngForm" (ngSubmit)="enviar(formOlvidoContrasena)">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>{{ 'session.forget_question' | translate }}</h2>
                            <p>
                                {{ 'session.set_password' | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="input-mail">
                        <label class="contrasena-label">{{ 'session.email' | translate }}</label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="inputGroup-sizing"> <i
                                        class="fas fa-envelope"></i></span>
                            </div>
                            <input type="email" class="form-control input-text" placeholder="{{ 'session.email' | translate }}" [(ngModel)]="email" id="email" name="email" required>
                        </div>
                    </div>
                    <div class="btn-contrasena">
                        <button class="btn btn-secondary btn-lg btn-block" type="submit" [disabled]="clicked" [recaptchaForm]="formOlvidoContrasena" recaptcha>
                            <span *ngIf="!clicked">{{ 'session.next' | translate }}</span>
                            <mat-progress-spinner style="margin:0 auto;" class="spinner" color="primary" mode="indeterminate" value="50" diameter="25" *ngIf="clicked">
                            </mat-progress-spinner>
                        </button>
                    </div>

                    <label class="back-login" [routerLink]="['/login']">
                        {{ 'session.password_remembered' | translate }}
                    </label>
                    <hr/>
                    <div class="text-center">
                        {{ 'general.language' | translate }}:
                        &nbsp;&nbsp;
                        <app-lenguaje arrow="up" color="black"></app-lenguaje>
                    </div>
    
                </form>
            </div>
        </div>
    </div>

    <!-- Formulario Responsive Mobile  -->

    <div class="container-fluid container-form-l responsive-form" *ngIf="getIsMobileResolution()">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 logo-img ">
                <img class="logo-contrasena" src="/assets/img/logo.png" alt="">
            </div>

            <div class="col-lg-3 logo-img contrasena-form">

                <form ngNativeValidate #formOlvidoContrasena="ngForm" (ngSubmit)="enviar(formOlvidoContrasena)">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>{{ 'session.forget_question' | translate }}</h2>
                            <p>
                                {{ 'session.set_password' | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="input-mail">
                        <label class="contrasena-label">{{ 'session.email' | translate }}</label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="inputGroup-sizing"> <i
                                        class="fas fa-envelope"></i></span>
                            </div>
                            <input type="email" class="form-control input-text" placeholder="{{ 'session.email' | translate }}" [(ngModel)]="email" id="email" name="email" required>
                        </div>
                    </div>
                    <div class="btn-contrasena">
                        <button class="btn btn-secondary btn-lg btn-block" type="submit" [disabled]="clicked" [recaptchaForm]="formOlvidoContrasena" recaptcha>
                            <span *ngIf="!clicked">{{ 'session.next' | translate }}</span>
                            <mat-progress-spinner style="margin:0 auto;" class="spinner" color="primary" mode="indeterminate" value="50" diameter="25" *ngIf="clicked">
                            </mat-progress-spinner>
                        </button>
                    </div>
                    
                    <label class="back-login" [routerLink]="['/login']">
                        {{ 'session.password_remembered' | translate }}
                    </label>
                    <hr/>
                    <div class="text-center">
                        {{ 'general.language' | translate }}:
                        &nbsp;&nbsp;
                        <app-lenguaje arrow="up" color="black"></app-lenguaje>
                    </div>
    
                </form>

            </div>

            <img class="img-squares" src="/assets/img/squares.png" alt="">
            <img class="img-circle" src="/assets/img/inter-1.png" alt="">

        </div>

    </div>


</section>
