import { Component, OnInit } from '@angular/core';
import { Router  } from '@angular/router';
import { Store } from '@ngrx/store';

import { SidebarService } from 'src/app/services/service.index';
import { AppState } from 'src/app/store/models/app.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(
    public _sidebar: SidebarService,
    public router: Router,
    private store: Store<AppState>
  ) { }

  showOptions:string = ''
  hoverElement:string = ''

  ngOnInit() {
    this.loadOptions();
    this.store.select('home').subscribe(() => {
      this.loadOptions();
    });
  }

  loadOptions(){
    this._sidebar.loadOptions().forEach((e:any) => {
      if(e.options.find((f:any) => f.url === this.router.url)) {
        this.showOptions = e.titulo
      }
    })
  }

  changeHoverElement = (element:string) => this.hoverElement = element

  goToUrl = (url:string) => url && this.router.navigate([url]);

  getClass = (url:string) => url && this.router.url.includes(url) ? 'current-url' : ''

  getContainerClass = (title:string) => {
    const option:any = this._sidebar.menu.find((e:any) => e.titulo === title)
    return this.showOptions === title || option.options.find(e => e.url === this.router.url) ? 'current-option' : ''
  }

  onclickInOption = (option:string) => {
    const clickedOption:any = this._sidebar.menu.find((e:any) => e.titulo === option)
    if(clickedOption.options.length && clickedOption.titulo !== this.showOptions){
      this.showOptions = clickedOption.titulo
    } else {
      this.showOptions = ''
    }
    this.goToUrl(clickedOption.url)
  }
}
