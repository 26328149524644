export class Region {

    private _id: number;
    private _nombre: string;
    private _paisId: number;

    public get id(): number {
        return this._id;
    }
    public set id(value: number) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get paisId(): number {
        return this._paisId;
    }
    public set paisId(value: number) {
        this._paisId = value;
    }
}
