import { EstadoCivilAdapter } from './../../adapter/estado-civil.adapter';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EstadoCivil } from 'src/app/models/model.index';

@Injectable({
  providedIn: 'root'
})

export class EstadoCivilService {

  constructor(
    public http: HttpClient,
    public estadoCivilAdapter: EstadoCivilAdapter
  ) { }

  cargarEstadosCiviles(): Observable <EstadoCivil[]> {
    return this.http.get(environment.urlApi + 'estados_civiles')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(estadosCiviles => this.estadoCivilAdapter.adapt(estadosCiviles));
        })
      );
  }

}
