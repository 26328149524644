import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { Adjunto } from '../models/adjunto.model';

@Injectable({
    providedIn: 'root'
})

export class AdjuntoAdapter implements Adapter<Adjunto> {
    adapt(item: any): Adjunto {
        const adjunto = new Adjunto();
        adjunto.id = item.attributes.id;
        adjunto.nombre = item.attributes.nombre;
        adjunto.adjuntoTipoId = item.attributes.adjunto_tipo_id;
        adjunto.fileNameOriginal = item.attributes.file_name_original;
        adjunto.fileNameHash = item.attributes.file_name_hash;
        adjunto.fileSize = item.attributes.file_size;
        adjunto.isPublic = item.attributes.is_public;
        adjunto.isDeleted = item.attributes.is_deleted;
        return adjunto;
    }

}
