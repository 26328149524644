import {DateJson} from '../interfaces/negocio.interface';

export class ContratoAnexoUnidadConfiguracion {
  private _id: string;
  private _contratoAnexoUnidadId: string;
  private _reajusteCheck: boolean;
  private _reajustePeriodicidadId: number;
  private _reajustePeriodicidadValor: number;
  private _reajusteTipoId: number;
  private _reajusteMontoTipo: number;
  private _reajusteValor: number;
  private _reajusteFecha: DateJson;
  private _reajusteDivisa: string;


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get contratoAnexoUnidadId(): string {
    return this._contratoAnexoUnidadId;
  }

  set contratoAnexoUnidadId(value: string) {
    this._contratoAnexoUnidadId = value;
  }

  get reajusteCheck(): boolean {
    return this._reajusteCheck;
  }

  set reajusteCheck(value: boolean) {
    this._reajusteCheck = value;
  }

  get reajustePeriodicidadId(): number {
    return this._reajustePeriodicidadId;
  }

  set reajustePeriodicidadId(value: number) {
    this._reajustePeriodicidadId = value;
  }

  get reajustePeriodicidadValor(): number {
    return this._reajustePeriodicidadValor;
  }

  set reajustePeriodicidadValor(value: number) {
    this._reajustePeriodicidadValor = value;
  }

  get reajusteTipoId(): number {
    return this._reajusteTipoId;
  }

  set reajusteTipoId(value: number) {
    this._reajusteTipoId = value;
  }

  get reajusteMontoTipo(): number {
    return this._reajusteMontoTipo;
  }

  set reajusteMontoTipo(value: number) {
    this._reajusteMontoTipo = value;
  }

  get reajusteValor(): number {
    return this._reajusteValor;
  }

  set reajusteValor(value: number) {
    this._reajusteValor = value;
  }

  get reajusteFecha(): DateJson {
    return this._reajusteFecha;
  }

  set reajusteFecha(value: DateJson) {
    this._reajusteFecha = value;
  }

  get reajusteDivisa(): string {
    return this._reajusteDivisa;
  }

  set reajusteDivisa(value: string) {
    this._reajusteDivisa = value;
  }
}
