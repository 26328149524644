import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NumeroPipe } from 'src/app/pipes/numero.pipe';
import { MonedaPipe } from 'src/app/pipes/moneda.pipe';
import { NgControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[formatMonto]',
    providers: [NumeroPipe, MonedaPipe]
})

export class FormatMontoDirective implements OnInit {

    private element: HTMLInputElement;

    constructor(
        private elementRef: ElementRef,
        private numero: NumeroPipe,
        private moneda: MonedaPipe,
        private control: NgControl
    ) { }

    @HostListener('focusout') onFocusOut() {
        // Bind Emitter
        this.control.control.setValue(this.formated);
    }

    @HostListener('focus') onFocus() {
        this.element.value = this.unformated.toString();
    }

    ngOnInit() {
        this.element = this.elementRef.nativeElement;
        this.control.control.setValue(this.formated);
    }

    get formated() {
        return this.moneda.transform(this.unformated);
    }

    get unformated() {
        return this.numero.transform(this.element.value);
    }
}
