import { MedioLlegadaAdapter } from 'src/app/adapter/medio-llegada.adapter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MedioLlegada } from 'src/app/models/model.index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MedioLlegadaService {

  constructor(
    public http: HttpClient,
    public medioLlegadaAdapter: MedioLlegadaAdapter
  ) { }

  cargarMediosLlegada(): Observable<MedioLlegada[]> {
    return this.http.get(environment.urlApi + 'medio_llegada')
    .pipe(
      map( (resp: any) =>
        resp.data.map( medioLlegada => this.medioLlegadaAdapter.adapt(medioLlegada))
      )
    );
  }

}
