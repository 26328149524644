import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalGeneralComponent } from './modal-general.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        TranslateModule
    ],
    exports: [ ModalGeneralComponent ],
    declarations: [ ModalGeneralComponent ]
})
export class ModalGeneralModule { }
