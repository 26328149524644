import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { Ciudad } from './../models/ciudad.model';

@Injectable({
    providedIn: 'root'
})

export class CiudadAdapter implements Adapter<Ciudad> {
    adapt(item: any): Ciudad {
        const ciudad = new Ciudad();
        ciudad.id = item.attributes.id;
        ciudad.nombre = item.attributes.nombre;
        ciudad.regionId = item.attributes.region_id;
        return ciudad;
    }

}
