export class Pais {

    private _id: string;
    private _nombre: string;
    private _isoCode: string;
    private _phoneCode: string;
    private _cultureCodeId: string;
    private _region: string;
    private _nacionalidad: string;
    private _createdAt: string;
    private _createdBy: string;
    private _updatedAt: string;
    private _updatedBy: string;


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get isoCode(): string {
        return this._isoCode;
    }
    public set isoCode(value: string) {
        this._isoCode = value;
    }
    public get phoneCode(): string {
        return this._phoneCode;
    }
    public set phoneCode(value: string) {
        this._phoneCode = value;
    }
    public get cultureCodeId(): string {
        return this._cultureCodeId;
    }
    public set cultureCodeId(value: string) {
        this._cultureCodeId = value;
    }
    public get region(): string {
        return this._region;
    }
    public set region(value: string) {
        this._region = value;
    }
    public get nacionalidad(): string {
        return this._nacionalidad;
    }
    public set nacionalidad(value: string) {
        this._nacionalidad = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    public get updatedAt(): string {
        return this._updatedAt;
    }
    public set updatedAt(value: string) {
        this._updatedAt = value;
    }
    public get updatedBy(): string {
        return this._updatedBy;
    }
    public set updatedBy(value: string) {
        this._updatedBy = value;
    }
}

