<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" [routerLink]="['/']"><i class="fa fa-home"></i>&nbsp;
            {{ 'home.title' | translate }}
        </li>
        <ng-container *ngFor="let element of breadcrumb">
            <li [class.active]="element.active" class="breadcrumb-item" [routerLink]="element.route" [queryParams]="element.queryParams || ''">{{element.title | translate}}</li>
        </ng-container>
    </ol>
</nav>
