import { NgModule } from '@angular/core';
import { FormatMontoDirective } from './format-monto.directive';
import { FormatIdentificadorDirective } from './format-identificador.directive';
import { ValuesOrSpinnerDirective } from './value-or-spinner.directive';
import { GoogleAnalitycsDirective } from './google-analitycs.directive';
import { DndDirective } from './dnd.directive';

@NgModule({
    declarations: [
        FormatMontoDirective,
        FormatIdentificadorDirective,
        ValuesOrSpinnerDirective,
        GoogleAnalitycsDirective,
        DndDirective
    ],
    exports: [
        FormatMontoDirective,
        FormatIdentificadorDirective,
        ValuesOrSpinnerDirective,
        GoogleAnalitycsDirective,
        DndDirective
    ]
})
export class DirectiveModule { }
