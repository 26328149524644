<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 24 24'
  *ngIf="type === 'home'"
  [style]="customStyle"
  >
  <path d='M0 0h24v24H0z' fill='none' />
  <path
    d='M12.6 18.06a1.006 1.006 0 01-1.23 0l-6.15-4.78A.994.994 0 104 14.85l6.76 5.26a2.011 2.011 0 002.46 0l6.76-5.26a1 1 0 000-1.57l-.01-.01a.991.991 0 00-1.22 0l-6.15 4.79zm.63-3.02l6.76-5.26a1.007 1.007 0 000-1.58l-6.76-5.26a2.011 2.011 0 00-2.46 0L4.01 8.21a1.007 1.007 0 000 1.58l6.76 5.26a2 2 0 002.46-.01z'
    attr.fill="{{color}}"
  />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 24 24'
  *ngIf="type === 'propiedades'"
  [style]="customStyle"
  >
  <path
    attr.fill="{{color}}"
    d='M20 3h-8a1 1 0 00-1 1v1.61l.01.01 5 4.5a2.991 2.991 0 01.99 2.23V13h2v2h-2v2h2v2h-2v2h3a1 1 0 001-1V4a1 1 0 00-1-1zm-5 4h-2V5h2zm4 4h-2V9h2zm0-4h-2V5h2z'
  />
  <path
    attr.fill="{{color}}"
    d='M15 20v-7.65a1 1 0 00-.33-.74l-5-4.5A.961.961 0 009 6.85a1.008 1.008 0 00-.67.26l-5 4.5a.969.969 0 00-.33.74V20a1 1 0 001 1h2a1 1 0 001-1v-4h4v4a1 1 0 001 1h2a1 1 0 001-1z'
  />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 24 24'
  *ngIf="type === 'unidades'"
  [style]="customStyle"
  >
  <path
    d='M10 19v-5h4v5a1 1 0 001 1h3a1 1 0 001-1v-7h1.7a.5.5 0 00.33-.87L12.67 3.6a1.008 1.008 0 00-1.34 0l-8.36 7.53a.5.5 0 00.33.87H5v7a1 1 0 001 1h3a1 1 0 001-1z'
    attr.fill="{{color}}"
  />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 24 24'
  *ngIf="type === 'balances'"
  [style]="customStyle"
  >
  <path
    d='M12 2a10 10 0 1010 10A10 10 0 0012 2zm.88 15.76v.36A.881.881 0 0112 19a.881.881 0 01-.88-.88v-.42a4.01 4.01 0 01-2.69-2.1.862.862 0 01.45-1.18l.07-.03a.851.851 0 011.08.39 2.34 2.34 0 002.12 1.37c.93 0 1.98-.48 1.98-1.61 0-.96-.7-1.46-2.28-2.03-1.1-.39-3.35-1.03-3.35-3.31a3.078 3.078 0 012.62-2.96v-.36A.881.881 0 0112 5a.881.881 0 01.88.88v.37a3.389 3.389 0 012.16 1.3.85.85 0 01-.36 1.3.874.874 0 01-1.02-.28 1.9 1.9 0 00-1.6-.77c-.7 0-1.81.37-1.81 1.39 0 .95.86 1.31 2.64 1.9 2.4.83 3.01 2.05 3.01 3.45a3.315 3.315 0 01-3.02 3.22z'
    attr.fill="{{color}}"
  />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 24 24'
  *ngIf="type === 'clientes'"
  [style]="customStyle"
  >
  <path
    d='M12 12.75a10.611 10.611 0 014.24.9A2.984 2.984 0 0118 16.38V17a1 1 0 01-1 1H7a1 1 0 01-1-1v-.61a2.963 2.963 0 011.76-2.73 10.44 10.44 0 014.24-.91zM4 13a2 2 0 10-2-2 2.006 2.006 0 002 2zm1.13 1.1A6.983 6.983 0 004 14a6.95 6.95 0 00-2.78.58A2.011 2.011 0 000 16.43V17a1 1 0 001 1h3.5v-1.61a4.5 4.5 0 01.63-2.29zM20 13a2 2 0 10-2-2 2.006 2.006 0 002 2zm4 3.43a2.011 2.011 0 00-1.22-1.85 6.8 6.8 0 00-3.91-.48 4.5 4.5 0 01.63 2.29V18H23a1 1 0 001-1zM12 6a3 3 0 11-3 3 3 3 0 013-3z'
    attr.fill="{{color}}"
  />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 33 28.845'
  *ngIf="type === 'administracion'"
  [style]="customStyle"
  >
  <path
    attr.fill="{{color}}"
    d='M17.628 13.237V4.808h4.808v6.41h3.204V3.205a1.607 1.607 0 00-1.6-1.6h-6.41a1.607 1.607 0 00-1.6-1.6H4.808a1.607 1.607 0 00-1.6 1.6V25.64H1.6a1.603 1.603 0 000 3.205h13.239a11.2 11.2 0 012.788-15.609zm-6.41 1.186a1.6 1.6 0 111.6 1.6 1.607 1.607 0 01-1.6-1.6z'
  />
  <path
    attr.fill="{{color}}"
    d='M30.255 14.887l-9.423 9.423-3.012-3.013a1.6 1.6 0 00-2.26 2.26l4.151 4.151a1.6 1.6 0 002.26 0l10.56-10.561a1.6 1.6 0 000-2.26 1.615 1.615 0 00-2.276 0z'
  />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 25 24'
  *ngIf="type === 'checkestate'"
  [style]="customStyle"
  >
  <path
    attr.fill="{{color}}"
    d='M14 11.26V6h3v4h2V5a1 1 0 00-1-1h-4a1 1 0 00-1-1H6a1 1 0 00-1 1v15H4a1 1 0 000 2h8.26A6.987 6.987 0 0114 11.26zM10 12a1 1 0 111 1 1 1 0 01-1-1z'
  />
  <g>
    <path
      attr.fill="{{color}}"
      d='M22.374 11.44a.343.343 0 01.589-.242l1.933 1.933a.343.343 0 010 .492l-1.933 1.932a.346.346 0 01-.589-.249v-1.24h-2.078a.7.7 0 01-.693-.693.7.7 0 01.693-.693h2.078zm-4.85 1.244a.693.693 0 10.693.693.695.695 0 00-.693-.693zm-2.078 6.924h2.078a.7.7 0 00.693-.693.7.7 0 00-.693-.693h-2.078v-1.238a.343.343 0 00-.589-.242l-1.933 1.931a.343.343 0 000 .492l1.933 1.933a.346.346 0 00.589-.249zm4.85 0a.693.693 0 10-.693-.693.695.695 0 00.693.693z'
    />
  </g>
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 26 24'
  *ngIf="type === 'checkin'"
  [style]="customStyle"
  >
  <path
    d='M14 11.26V6h3v4h2V5a1 1 0 00-1-1h-4a1 1 0 00-1-1H6a1 1 0 00-1 1v15H4a1 1 0 000 2h8.26A6.987 6.987 0 0114 11.26zM10 12a1 1 0 111 1 1 1 0 01-1-1z'
    attr.fill="{{color}}"
  />
  <path
    d='M16.366 17.051h2.807a.938.938 0 00.936-.936.938.938 0 00-.936-.936h-2.807v-1.674a.463.463 0 00-.8-.327l-2.606 2.607a.463.463 0 000 .664l2.61 2.61a.468.468 0 00.8-.337zm6.549 0a.936.936 0 10-.936-.936.938.938 0 00.937.936z'
    attr.fill="{{color}}"
  />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 26 24'
  *ngIf="type === 'checkout'"
  [style]="customStyle"
  >
  <path
    d='M14 11.26V6h3v4h2V5a1 1 0 00-1-1h-4a1 1 0 00-1-1H6a1 1 0 00-1 1v15H4a1 1 0 000 2h8.26A6.987 6.987 0 0114 11.26zM10 12a1 1 0 111 1 1 1 0 01-1-1z'
    attr.fill="{{color}}"
  />
  <path
    d='M19.639 17.051h-2.812a.938.938 0 01-.936-.936.938.938 0 01.936-.936h2.812v-1.674a.463.463 0 01.8-.327l2.6 2.607a.463.463 0 010 .664l-2.61 2.61a.468.468 0 01-.8-.337zm-6.549 0a.936.936 0 11.936-.936.938.938 0 01-.941.936z'
    attr.fill="{{color}}"
  />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  attr.width="{{width}}"
  viewBox='0 0 85 82'
  *ngIf="type === 'checkSinData'"
  [style]="customStyle"
  >
  <path
    d='M49.247 38.427V20.151h10.424v13.9h6.953V16.677a3.485 3.485 0 00-3.475-3.475h-13.9a3.485 3.485 0 00-3.477-3.475H21.448a3.485 3.485 0 00-3.474 3.475v52.125h-3.475a3.475 3.475 0 000 6.95h28.7a24.278 24.278 0 016.048-33.85zm-13.9 2.571a3.475 3.475 0 113.477 3.477A3.485 3.485 0 0135.348 41z'
    attr.fill="{{color}}"
  />
  <path
    d='M63.524 62.704h-5.437a2.361 2.361 0 01-2.566 2.568 2.279 2.279 0 01-2.431-2.468c-.034-2.465 2.161-3.039 5.335-3.039h2.026l-.169-6.28h-5.065l-.168 2.535c-4.424.034-7.564 2.397-7.564 6.753 0 4.693 3.275 7.226 7.969 7.226 5.335.005 8.171-3.241 8.07-7.295zm-9.252-14.889a3.19 3.19 0 003.41 3.174 3.2 3.2 0 003.444-3.174 3.189 3.189 0 00-3.444-3.111 3.179 3.179 0 00-3.41 3.111zm11.176-1.52h5.436a2.361 2.361 0 012.566-2.566 2.279 2.279 0 012.432 2.465c.034 2.465-2.161 3.039-5.335 3.039h-2.026l.169 6.28h5.065l.169-2.532c4.423-.034 7.564-2.4 7.564-6.753 0-4.693-3.275-7.226-7.969-7.226-5.336.002-8.172 3.242-8.071 7.293zM74.7 61.186a3.19 3.19 0 00-3.41-3.174 3.2 3.2 0 00-3.444 3.174 3.189 3.189 0 003.444 3.106 3.179 3.179 0 003.41-3.106z'
    attr.fill="{{color}}"
  />
</svg>

<svg xmlns="http://www.w3.org/2000/svg"
     attr.width="{{width}}"
     viewBox="0 0 24 24"
     *ngIf="type === 'configuracion'"
     [style]="customStyle"
>
  <path
    d="M19.5,12c0-0.23-0.01-0.45-0.03-0.68l1.86-1.41c0.4-0.3,0.51-0.86,0.26-1.3l-1.87-3.23c-0.25-0.44-0.79-0.62-1.25-0.42 l-2.15,0.91c-0.37-0.26-0.76-0.49-1.17-0.68l-0.29-2.31C14.8,2.38,14.37,2,13.87,2h-3.73C9.63,2,9.2,2.38,9.14,2.88L8.85,5.19 c-0.41,0.19-0.8,0.42-1.17,0.68L5.53,4.96c-0.46-0.2-1-0.02-1.25,0.42L2.41,8.62c-0.25,0.44-0.14,0.99,0.26,1.3l1.86,1.41 C4.51,11.55,4.5,11.77,4.5,12s0.01,0.45,0.03,0.68l-1.86,1.41c-0.4,0.3-0.51,0.86-0.26,1.3l1.87,3.23c0.25,0.44,0.79,0.62,1.25,0.42 l2.15-0.91c0.37,0.26,0.76,0.49,1.17,0.68l0.29,2.31C9.2,21.62,9.63,22,10.13,22h3.73c0.5,0,0.93-0.38,0.99-0.88l0.29-2.31 c0.41-0.19,0.8-0.42,1.17-0.68l2.15,0.91c0.46,0.2,1,0.02,1.25-0.42l1.87-3.23c0.25-0.44,0.14-0.99-0.26-1.3l-1.86-1.41 C19.49,12.45,19.5,12.23,19.5,12z M12.04,15.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S13.97,15.5,12.04,15.5z"
    attr.fill="{{color}}"
  />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  viewBox='0 0 24 24'
  attr.width="{{width}}"
  attr.fill="{{color}}"
  *ngIf="type === 'comunidad'"
>
  <g>
    <path d='M20,2H4.01c-1.1,0-2,0.9-2,2L2,22l4-4h14c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M12,6c1.1,0,2,0.9,2,2s-0.9,2-2,2 s-2-0.9-2-2S10.9,6,12,6z M16,14H8v-0.57c0-0.81,0.48-1.53,1.22-1.85C10.07,11.21,11.01,11,12,11c0.99,0,1.93,0.21,2.78,0.58 C15.52,11.9,16,12.62,16,13.43V14z' />
  </g>
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  viewBox='0 0 24 24'
  attr.width="{{width}}"
  attr.fill="{{color}}"
  *ngIf="type === 'publicaciones'"
  >
  <g>
    <g>
      <path d='M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z' />
      <g>
        <path d='M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z' />
        <path d='M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z' />
        <path d='M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z' />
      </g>
    </g>
  </g>
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  viewBox="0 0 576 614"
  attr.width="{{width}}"
  attr.fill="{{color}}"
  *ngIf="type === 'residentes'"
>
  <path d='M336 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM192 128c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H102.4C90 384 80 375.4 80 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2z' />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  viewBox='0 0 576 512'
  attr.width="{{width}}"
  attr.fill="{{color}}"
  *ngIf="type === 'espaciosComunes'"
  >
  <path d='M96 32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32zM416 192.7C326.3 200.8 256 276.2 256 368C256 427.5 285.6 480.1 330.8 512H48C21.49 512 0 490.5 0 464V192H416V192.7zM288 368C288 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 288 447.5 288 368zM432 480C462.2 480 489.5 468.1 509.7 448.7C503.5 429.7 485.6 416 464.6 416H399.4C378.4 416 360.5 429.7 354.3 448.7C374.5 468.1 401.8 480 432 480V480zM432 384C458.5 384 480 362.5 480 336C480 309.5 458.5 288 432 288C405.5 288 384 309.5 384 336C384 362.5 405.5 384 432 384z' />
</svg>

<svg
  xmlns='http://www.w3.org/2000/svg'
  viewBox='0 0 541 619'
  attr.width="{{width}}"
  attr.fill="{{color}}"
  *ngIf="type === 'bitacora'"
  >
  <path d='M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z' />
</svg>

<svg 
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 600 600"
  attr.width="{{width}}"
  attr.fill="{{color}}"
  *ngIf="type === 'ayuda'"
>
  <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
</svg>

<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 672 768"
  attr.width="{{width}}"
  attr.fill="{{color}}"
  *ngIf="type === 'manuales'"
>
  <path d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z" />
</svg>

<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 640 512"
  attr.width="{{width}}"
  attr.fill="{{color}}"
  *ngIf="type === 'propietarios'"
>
  <path d="M335.5 4l288 160c15.4 8.6 21 28.1 12.4 43.5s-28.1 21-43.5 12.4L320 68.6 47.5 220c-15.4 8.6-34.9 3-43.5-12.4s-3-34.9 12.4-43.5L304.5 4c9.7-5.4 21.4-5.4 31.1 0zM320 160a40 40 0 1 1 0 80 40 40 0 1 1 0-80zM144 256a40 40 0 1 1 0 80 40 40 0 1 1 0-80zm312 40a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zM226.9 491.4L200 441.5V480c0 17.7-14.3 32-32 32H120c-17.7 0-32-14.3-32-32V441.5L61.1 491.4c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l37.9-70.3c15.3-28.5 45.1-46.3 77.5-46.3h19.5c16.3 0 31.9 4.5 45.4 12.6l33.6-62.3c15.3-28.5 45.1-46.3 77.5-46.3h19.5c32.4 0 62.1 17.8 77.5 46.3l33.6 62.3c13.5-8.1 29.1-12.6 45.4-12.6h19.5c32.4 0 62.1 17.8 77.5 46.3l37.9 70.3c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8L552 441.5V480c0 17.7-14.3 32-32 32H472c-17.7 0-32-14.3-32-32V441.5l-26.9 49.9c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l36.3-67.5c-1.7-1.7-3.2-3.6-4.3-5.8L376 345.5V400c0 17.7-14.3 32-32 32H296c-17.7 0-32-14.3-32-32V345.5l-26.9 49.9c-1.2 2.2-2.6 4.1-4.3 5.8l36.3 67.5c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8z"/>
</svg>