export class ContratoEstado {

    static readonly ANULADO = 0;
    static readonly CREADO = 100;
    static readonly PENDIENTE_FIRMA = 200;
    static readonly PENDIENTE_FIRMA_MANUAL = 201;
    static readonly ERROR_FIRMA = 210;
    static readonly FIRMADO = 290;
    static readonly FIRMADO_MANUAL = 291;
    static readonly ACTIVO = 300;
    static readonly FINALIZADO = 400;
    static readonly FINALIZADO_ANTICIPADAMENTE = 410;

    private _id: string;
    private _nombre: string;
    private _lang_resource: string;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get lang_resource(): string {
        return this._lang_resource;
    }
    public set lang_resource(value: string) {
        this._lang_resource = value;
    }
}
