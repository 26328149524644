import {Injectable} from "@angular/core";
import {Adapter} from "./adapter";
import {UnidadFirmante} from "../models/unidad-firmante.model";

@Injectable({
  providedIn: 'root'
})
export class UnidadFirmanteAdapter implements Adapter<UnidadFirmante> {
  constructor() {
  }

  adapt(item: any): UnidadFirmante {
    const unidadFirmante = new UnidadFirmante();
    unidadFirmante.id = item.attributes.id;
    unidadFirmante.nombre = item.attributes.nombre;
    unidadFirmante.email = item.attributes.email;
    unidadFirmante.identificador = item.attributes.identificador;
    unidadFirmante.isActive = item.attributes.is_active;
    unidadFirmante.unidadId = item.attributes.unidad_id;
    return unidadFirmante;
  }
}
