<div class="modal-detalle-check-calendario">
  <div class="header-container">
    <span>{{ data.modalHeader }}</span>
    <i class="fas fa-times pointer" (click)="onNoClick()"></i>
  </div>

  <table class="table table-hover tb-al">
    <thead class="header-listas">
      <tr>
        <th *ngFor="let headerName of tableHeader" scope="col" class="header-column">{{ headerName | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data.checks">
        <td class="bold-cell">{{ item.tipoNombre }}</td>
        <td class="bold-cell">{{ item.folio }}</td>
        <td class="bold-cell">{{ item.nombre }}</td>
        <td class="bold-cell">
          <div class="check-status check-type-{{item.estadoId}}">
            {{ item.estadoNombre }}
          </div>
        </td>
        <td class="bold-cell">{{ item.responsableNombre }}</td>
        <td>
          <div class="btn-group boton-acc-cont" role="group">
            <a id="btnGroupDrop1" type="button" class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-ellipsis-v float-right"></i>
            </a>
            <div class="dropdown-menu dropdown-opc  dropdown-menu-in-check" aria-labelledby="btnGroupDrop1">
              <a
                [routerLink]="['/checkin/ver', item.id]"
                class="dropdown-item"
                (click)="onNoClick()"
              >
                <i class="fas fa-eye"></i><span>{{ 'general.view_detail' | translate }}</span>
              </a>
              <a
                *ngIf="canUpdateCheckstate && (item.estadoId === 100 || item.estadoId === 120 || item.estadoId === 110)"
                class="dropdown-item"
                [routerLink]="['checkin/editar/']"
                [queryParams]="{ checkId: item.id, contractId: item.contratoId, type: item.tipoId === 100 ? 'Checkin' : 'Checkout' }"
                (click)="onNoClick()"
              >
                <i class="fas fa-edit"></i><span>{{ 'general.edit' | translate }}</span>
              </a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="footer-table">
    <span class="pointer" (click)="onNoClick()" style="font-weight: 500;">{{ 'general.exit' | translate }}</span>
  </div>
</div>
