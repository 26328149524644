import {UnidadEstado} from './unidad-estado.model';
import {UnidadTipo} from './unidad-tipo.model';
import {UnidadSubEstado} from './unidad-subestado.model';
import {Contrato} from './contrato.model';
import {UnidadDivisaMonto} from './unidad-divisa-monto.model';

export class UnidadRadiografia {
  private _id: string;
  private _nombre: string;
  private _piso: number;
  private _unidadTipoId: string;
  private _unidadEstadoId: number;
  private _subEstadoId: number;
  private _isAmoblado: boolean;
  private _isAfectoImpuesto: boolean;
  private _hasTerraza: boolean;
  private _orientacion: string;
  private _tipologia: string;
  private _tipologiaEspecial: string;
  private _divisa: string;
  private _unidadTipo: UnidadTipo;
  private _unidadEstado: UnidadEstado;
  private _unidadSubEstado: UnidadSubEstado;
  private _contrato: Contrato;
  private _precioArriendo: UnidadDivisaMonto | 0;
  private _precioGastoComun: UnidadDivisaMonto | 0;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get nombre(): string {
    return this._nombre;
  }

  set nombre(value: string) {
    this._nombre = value;
  }

  get piso(): number {
    return this._piso;
  }

  set piso(value: number) {
    this._piso = value;
  }

  get unidadTipoId(): string {
    return this._unidadTipoId;
  }

  set unidadTipoId(value: string) {
    this._unidadTipoId = value;
  }

  get unidadEstadoId(): number {
    return this._unidadEstadoId;
  }

  set unidadEstadoId(value: number) {
    this._unidadEstadoId = value;
  }

  get subEstadoId(): number {
    return this._subEstadoId;
  }

  set subEstadoId(value: number) {
    this._subEstadoId = value;
  }

  get isAmoblado(): boolean {
    return this._isAmoblado;
  }

  set isAmoblado(value: boolean) {
    this._isAmoblado = value;
  }

  get isAfectoImpuesto(): boolean {
    return this._isAfectoImpuesto;
  }

  set isAfectoImpuesto(value: boolean) {
    this._isAfectoImpuesto = value;
  }

  get hasTerraza(): boolean {
    return this._hasTerraza;
  }

  set hasTerraza(value: boolean) {
    this._hasTerraza = value;
  }

  get orientacion(): string {
    return this._orientacion;
  }

  set orientacion(value: string) {
    this._orientacion = value;
  }

  get tipologia(): string {
    return this._tipologia;
  }

  set tipologia(value: string) {
    this._tipologia = value;
  }

  get tipologiaEspecial(): string {
    return this._tipologiaEspecial;
  }

  set tipologiaEspecial(value: string) {
    this._tipologiaEspecial = value;
  }

  get unidadTipo(): UnidadTipo {
    return this._unidadTipo;
  }

  set unidadTipo(value: UnidadTipo) {
    this._unidadTipo = value;
  }

  get unidadEstado(): UnidadEstado {
    return this._unidadEstado;
  }

  set unidadEstado(value: UnidadEstado) {
    this._unidadEstado = value;
  }

  get unidadSubEstado(): UnidadSubEstado {
    return this._unidadSubEstado;
  }

  set unidadSubEstado(value: UnidadSubEstado) {
    this._unidadSubEstado = value;
  }

  get contrato(): Contrato {
    return this._contrato;
  }

  set contrato(value: Contrato) {
    this._contrato = value;
  }

  get divisa(): string {
    // return this._divisa;
    return '7f377688-5b8a-49a2-a0f7-6bedd68fa900';
  }

  set divisa(value: string) {
    this._divisa = value;
  }

  get precioArriendo(): UnidadDivisaMonto | 0 {
    return this._precioArriendo;
  }

  set precioArriendo(value: UnidadDivisaMonto | 0) {
    this._precioArriendo = value;
  }

  get precioGastoComun(): UnidadDivisaMonto | 0 {
    return this._precioGastoComun;
  }

  set precioGastoComun(value: UnidadDivisaMonto | 0) {
    this._precioGastoComun = value;
  }
}
