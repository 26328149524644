import {Injectable} from "@angular/core";
import {Adapter} from "./adapter";
import {UnidadAdapter} from "./unidad.adapter";
import {ReservaUnidad} from "../models/reserva-unidad.model";
import { UnidadTipoAdapter } from "./unidad-tipo.adapter";
import {UnidadEstadoAdapter} from "./unidad-estado.adapter";
import {UnidadSubEstadoAdapter} from "./unidad-subestado.adapter";
import {PropiedadAdapter} from "./propiedad.adapter";
import {UnidadDivisaMontoAdapter} from "./unidad-divisa-monto.adapter";

@Injectable({
  providedIn: 'root'
})
export class ReservaUnidadAdapter implements Adapter<ReservaUnidad> {

  constructor(
    private unidadAdapter: UnidadAdapter,
    private unidadTipoAdapter: UnidadTipoAdapter,
    private unidadEstadoAdapter: UnidadEstadoAdapter,
    private unidadSubEstadoAdapter: UnidadSubEstadoAdapter,
    private propiedadAdapter: PropiedadAdapter,
    private unidadDivisaMontoAdapter: UnidadDivisaMontoAdapter
  ) {}

  adapt(item: any): ReservaUnidad {
    const reservaUnidad = new ReservaUnidad();
    reservaUnidad.id = item.attributes.id;
    reservaUnidad.reservaId = item.attributes.reserva_id;
    reservaUnidad.unidadId = item.attributes.unidad_id;
    reservaUnidad.abonar = item.attributes.abonar;

    if (item.included) {
      item.included.forEach(include => {
        switch (include.type) {
          case 'Unidad':
            reservaUnidad.unidad = this.unidadAdapter.adapt(include);
            break;
          case 'UnidadTipo':
            reservaUnidad.unidad.unidadTipo = this.unidadTipoAdapter.adapt(include);
            break;
          case 'UnidadEstado':
            reservaUnidad.unidad.unidadEstado = this.unidadEstadoAdapter.adapt(include);
            break;
          case 'UnidadSubEstado':
            reservaUnidad.unidad.unidadSubEstado = this.unidadSubEstadoAdapter.adapt(include);
            break;
          case 'Propiedad':
            reservaUnidad.unidad.propiedad = this.propiedadAdapter.adapt(include);
            break;
        }
      });
    }

    if (item.relationship && item.relationship.precios && item.relationship.precios.data) {
      reservaUnidad.unidad.divisas = item.relationship.precios.data.map(precio => this.unidadDivisaMontoAdapter.adapt(precio));
    }

    return reservaUnidad;
  }
}
