import { Pipe, PipeTransform } from '@angular/core';
import { PerfilService } from '../services/auxiliar/perfil.service';

@Pipe({
  name: 'numeroConSeparadores'
})
export class NumeroConSeparadoresPipe implements PipeTransform {

  constructor(
    private perfil: PerfilService
  ){ }

  transform(number: string|number): string {
    return Number(number).toLocaleString(this.perfil.locale);
  }
}
