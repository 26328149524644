import {Adapter} from "./adapter";
import {NegocioFirmanteInterface} from "../interfaces/negocio.interface";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class NegocioFirmanteAdapter implements Adapter<NegocioFirmanteInterface> {
  constructor() {
  }

  adapt(item: any): NegocioFirmanteInterface {
    const firmante: NegocioFirmanteInterface = {
      nombre: item.attributes.nombre,
      email: item.attributes.email,
      identificador: item.attributes.identificador
    }
    return firmante;
  }
}
