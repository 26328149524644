import { Injectable } from '@angular/core';
import { Unidad } from 'src/app/models/unidad.model';

@Injectable({
  providedIn: 'root'
})
export class OrientacionService {

    public orientaciones: {id: string, nombre: string, lang_resource: string}[];

    constructor() {
        this.orientaciones = [
            { id: 'NORTE', nombre: 'NORTE', lang_resource: 'orientation.north'},
            { id: 'SUR', nombre: 'SUR', lang_resource: 'orientation.south'},
            { id: 'ORIENTE', nombre: 'ORIENTE', lang_resource: 'orientation.east'},
            { id: 'PONIENTE', nombre: 'PONIENTE', lang_resource: 'orientation.west'},
            { id: 'NORORIENTE', nombre: 'NORORIENTE', lang_resource: 'orientation.north_east'},
            { id: 'NORPONIENTE', nombre: 'NORPONIENTE', lang_resource: 'orientation.north_west'},
            { id: 'SURORIENTE', nombre: 'SURORIENTE', lang_resource: 'orientation.south_east'},
            { id: 'SURPONIENTE', nombre: 'SURPONIENTE', lang_resource: 'orientation.south_west'},
            { id: 'ORIENTE/PONIENTE', nombre: 'ORIENTE/PONIENTE', lang_resource: 'orientation.east_west'}
        ];
    }

    cargarOrientaciones() {
        return this.orientaciones;
    }

    traduccionOrientacion(unidad: Unidad){
        let orientacion = this.cargarOrientaciones().filter(item=>item.nombre===unidad.orientacion)[0];
        return orientacion?orientacion.lang_resource:'';
    }

}
