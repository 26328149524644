export class Empresa {

    private _id: string;
    private _nombre: string;
    private _codigo: string;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get codigo(): string {
        return this._codigo;
    }
    public set codigo(value: string) {
        this._codigo = value;
    }

    public toJSON() {
        return {
            id: this._id,
            nombre: this._nombre,
            codigo: this._codigo
        }
    }

}
