import { Contrato, Divisa, EstadoCuentaDetalle, EstadoCuentaEstado } from 'src/app/models/model.index';
import {Reserva} from "./reserva.model";

interface MontoTotalEstadoCuentaInterface {
    monto: number;
    divisaId: string;
}

export class EstadoCuenta {

    private _id: string;
    private _contratoId?: string;
    private _reservaId?: string;
    private _estadoCuentaEstadoId: number;
    private _fecha: string;
    private _fechaCreacion: string;
    private _fechaVencimiento: string;
    private _divisaId: string;
    private _montoPactado: number;
    private _fechaPago: string;
    private _fechaConciliacionPago: string;
    private _fechaPeriodoInicio: string;
    private _fechaPeriodoFin: string;
    private _folio: string;
    private _isPrimerPago: boolean;
    private _correoEnviado: boolean;
    private _divisaCobroId: string;
    private _isPagoConciliado: boolean;
    private _contrato: Contrato;
    private _reserva: Reserva;
    private _estadoCuentaEstado: EstadoCuentaEstado;
    private _divisa: Divisa;
    private _estadoCuentaDetalles: EstadoCuentaDetalle[];

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get contratoId(): string {
        return this._contratoId;
    }
    public set contratoId(value: string) {
        this._contratoId = value;
    }

    public get reservaId(): string {
        return this._reservaId;
    }

    public set reservaId(value: string) {
        this._reservaId = value;
    }

    public get estadoCuentaEstadoId(): number {
        return this._estadoCuentaEstadoId;
    }
    public set estadoCuentaEstadoId(value: number) {
        this._estadoCuentaEstadoId = value;
    }
    public get fecha(): string {
        return this._fecha;
    }
    public set fecha(value: string) {
        this._fecha = value;
    }
    public get fechaCreacion(): string {
        return this._fechaCreacion;
    }
    public set fechaCreacion(value: string) {
        this._fechaCreacion = value;
    }
    public get fechaVencimiento(): string {
        return this._fechaVencimiento;
    }
    public set fechaVencimiento(value: string) {
        this._fechaVencimiento = value;
    }
    public get divisaId(): string {
        return this._divisaId;
    }
    public set divisaId(value: string) {
        this._divisaId = value;
    }
    public get montoPactado(): number {
        return this._montoPactado;
    }
    public set montoPactado(value: number) {
        this._montoPactado = value;
    }
    public get fechaPago(): string {
        return this._fechaPago;
    }
    public set fechaPago(value: string) {
        this._fechaPago = value;
    }
    public get fechaConciliacionPago(): string {
        return this._fechaConciliacionPago;
    }
    public set fechaConciliacionPago(value: string) {
        this._fechaConciliacionPago = value;
    }
    public get fechaPeriodoInicio(): string {
        return this._fechaPeriodoInicio;
    }
    public set fechaPeriodoInicio(value: string) {
        this._fechaPeriodoInicio = value;
    }
    public get fechaPeriodoFin(): string {
        return this._fechaPeriodoFin;
    }
    public set fechaPeriodoFin(value: string) {
        this._fechaPeriodoFin = value;
    }
    public get folio(): string {
        return this._folio;
    }
    public set folio(value: string) {
        this._folio = value;
    }
    public get isPrimerPago(): boolean {
        return this._isPrimerPago;
    }
    public set isPrimerPago(value: boolean) {
        this._isPrimerPago = value;
    }
    public get correoEnviado(): boolean {
        return this._correoEnviado;
    }
    public set correoEnviado(value: boolean) {
        this._correoEnviado = value;
    }
    get divisaCobroId(): string {
        return this._divisaCobroId;
    }
    set divisaCobroId(value: string) {
        this._divisaCobroId = value;
    }

    public get contrato(): Contrato {
        return this._contrato;
    }
    public set contrato(value: Contrato) {
        this._contrato = value;
    }

    get reserva(): Reserva {
        return this._reserva;
    }

    set reserva(value: Reserva) {
        this._reserva = value;
    }

    public get estadoCuentaEstado(): EstadoCuentaEstado {
        return this._estadoCuentaEstado;
    }
    public set estadoCuentaEstado(value: EstadoCuentaEstado) {
        this._estadoCuentaEstado = value;
    }
    public get divisa(): Divisa {
        return this._divisa;
    }
    public set divisa(value: Divisa) {
        this._divisa = value;
    }
    public get estadoCuentaDetalles(): EstadoCuentaDetalle[] {
        return this._estadoCuentaDetalles;
    }
    public set estadoCuentaDetalles(value: EstadoCuentaDetalle[]) {
        this._estadoCuentaDetalles = value;
    }
    get isPagoConciliado(): boolean {
        return this._isPagoConciliado;
    }

    set isPagoConciliado(value: boolean) {
        this._isPagoConciliado = value;
    }

    public get estadoCuentaDetallesOrderByConcepto() {
      const order = this._estadoCuentaDetalles.sort((n1, n2) => n1.conceptoId - n2.conceptoId);
      return order;
    }

    public get estadoCuentaDetallesOrderByUnidad() {
        if (this.contrato) {
            this.contrato.contratoUnidadOrderByUnidadTipo.forEach(i => {
                this.estadoCuentaDetallesOrderByConcepto.sort((a, b) => {
                    if (a.unidadId === i.unidadId) {
                        return 1;
                    }
                    if (b.unidadId === i.unidadId) {
                        return -1;
                    }
                    return 0;
                });
            });
        }
        if (this.reserva) {
            this.reserva.reservaUnidadOrderByUnidadTipo.forEach(i => {
                this.estadoCuentaDetallesOrderByConcepto.sort((a, b) => {
                    if (a.unidadId === i.unidadId) {
                        return 1;
                    }
                    if (b.unidadId === i.unidadId) {
                        return -1;
                    }
                    return 0;
                });
            });
        }

      return this._estadoCuentaDetalles;
    }

    public get totalPago(): MontoTotalEstadoCuentaInterface[] {
        const totalPago: MontoTotalEstadoCuentaInterface[] = [] as MontoTotalEstadoCuentaInterface[];
        this.estadoCuentaDetalles.forEach(item =>  {
            const findByDivisa = totalPago.find(pago => pago.divisaId === item.divisaId);
            if (findByDivisa) {
                findByDivisa.monto = findByDivisa.monto + item.monto;
            } else {
                totalPago.push({monto: item.monto, divisaId: item.divisaId});
            }
        });
        return totalPago;
    }

    public get propiedad() {
        let propiedad = null;
        if (this.contrato) {
            propiedad = this.contrato.propiedad
        }
        if (this.reserva) {
            propiedad = this.reserva.propiedad
        }
        return propiedad
    }

    public get titular() {
        let titular = null;
        if (this.contrato) {
            titular = this.contrato.titular;
        }
        if (this.reserva) {
            titular = this.reserva.contacto;
        }
        return titular
    }

    public get relacionUnidades() {
        let unidades = [];
        if (this.contrato) {
            unidades = this.contrato.contratoUnidad
        }
        if (this.reserva) {
            unidades = this.reserva.unidades
        }
        return unidades
    }
}
