import { ContratoContactoAdapter } from './contrato-contacto.adapter';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContactoAdapter } from './contacto.adapter';
import { PropiedadAdapter } from './propiedad.adapter';
import { Contrato } from '../models/model.index';
import { ContratoEstadoAdapter } from './contrato-estado.adapter';
import { ContratoUnidadAdapter } from './contrato-unidad.adapter';
import { CustodiaDocumentoAdapter } from './custodia-documento.adapter';
import {ContratoConfiguracionAdapter} from './contrato-configuracion.adapter';
import { ContratoSubEstadoAdapter } from './contrato-subestado.adapter';
import {NegocioFirmanteAdapter} from "./negocio-firmante.adapter";
import {MascotaAdapter} from "./mascota.adapter";
import {VehiculoAdapter} from "./vehiculo.adapter";
import {ReservaAdapter} from "./reserva.adapter";

export const getContractResidents = (residentsFromApi: any) => {
    const contacto = residentsFromApi.included.find(item => item.type === 'Contacto');
    const {contacto_id, is_active, id, pop_home} = residentsFromApi.attributes
    const {
        apellido,
        email,
        fecha_nacimiento,
        genero_id,
        identificador,
        identificador_tipo_id,
        is_fumador,
        nombre,
        ocupacion,
        pais_id,
        telefono
    } = contacto.attributes
    return {
        birthday: fecha_nacimiento ? fecha_nacimiento.split(' ')[0] : null,
        contactId: contacto_id,
        email,
        firstName: nombre,
        genderId: genero_id || '',
        isActive: is_active,
        isSmoker: is_fumador || false,
        lastName: apellido,
        nationality: pais_id || '',
        occupation: ocupacion || '',
        phone: telefono || '',
        residentId: id,
        identifier: identificador || '',
        identifierType: identificador_tipo_id || null,
        hasAccessToPopHome: pop_home
    }
}

@Injectable({
    providedIn: 'root'
})
export class ContratoAdapter implements Adapter<Contrato> {

    constructor(
        public contactoAdapter: ContactoAdapter,
        public contratoEstadoAdapter: ContratoEstadoAdapter,
        public contratoSubEstadoAdapter: ContratoSubEstadoAdapter,
        public contratoUnidadAdapter: ContratoUnidadAdapter,
        public propiedadAdapter: PropiedadAdapter,
        public custodiaDocumentoAdapter: CustodiaDocumentoAdapter,
        public contratoContactoAdapter: ContratoContactoAdapter,
        public contratoConfiguracionAdapter: ContratoConfiguracionAdapter,
        public firmanteAdapter: NegocioFirmanteAdapter,
        public mascotaAdapter: MascotaAdapter,
        public vehiculoAdapter: VehiculoAdapter,
        public reservaAdapter: ReservaAdapter
    ) {}

    adapt(item: any): Contrato {
        const contrato = new Contrato();
        contrato.id = item.attributes.id;
        contrato.folio = item.attributes.folio;
        contrato.ejecutivoId = item.attributes.ejecutivo_id;
        contrato.estadoId = item.attributes.estado_id;
        contrato.fecha = item.attributes.fecha;
        contrato.fechaInicio = item.attributes.fecha_inicio;
        contrato.fechaFin = item.attributes.fecha_fin;
        contrato.fechaRealTermino = item.attributes.fecha_real_fin_contrato;
        contrato.fechaFinAnticipada = item.attributes.fecha_fin_anticipada;
        contrato.contratoConfiguracionId = item.attributes.contrato_configuracion_id;
        contrato.contratoTemplateId = item.attributes.contrato_template_id;
        contrato.observacion = item.attributes.observacion;
        contrato.tocDocumentId = item.attributes.toc_document_id;
        contrato.documentoAdjuntoId = item.attributes.documento_adjunto_id;
        contrato.documentoBaseAdjuntoId = item.attributes.documento_base_adjunto_id;
        contrato.cotizacionid = item.attributes.cotizacion_id;
        contrato.isCreatePrimerCobro = item.attributes.is_create_primer_cobro;
        contrato.propiedadId = item.attributes.propiedad_id;
        contrato.createdAt = item.attributes.created_at;
        contrato.createdBy = item.attributes.created_by;
        contrato.esHistorico = item.attributes.es_historico;
        contrato.folioNumerico = item.attributes.folio_numerico;
        contrato.interesPorDia = item.attributes.interes_por_dia;
        contrato.renovacionCantidad = item.attributes.renovacion_cantidad;
        contrato.subEstadoId = item.attributes.sub_estado_id;
        contrato.reajusteFecha = item.attributes.reajuste_fecha;

        /* Includes */
        if (item.included) {
            item.included.filter((valor) => {
                switch (valor.type) {
                    case 'ContratoEstado':
                        contrato.contratoEstado = this.contratoEstadoAdapter.adapt(valor);
                        break;
                    case 'ContratoSubEstado':
                        contrato.contratoSubEstado = this.contratoSubEstadoAdapter.adapt(valor);
                        break;
                    case 'Propiedad':
                        contrato.propiedad = this.propiedadAdapter.adapt(valor);
                        break;
                    case 'Contacto':
                        if (valor.attributes.id === contrato.ejecutivoId) {
                            contrato.ejecutivo = this.contactoAdapter.adapt(valor);
                        }
                        break;
                    case 'ContratoConfiguracion':
                        contrato.contratoConfiguracion = this.contratoConfiguracionAdapter.adapt(valor);
                        break;
                }
            });
        }

        /* Relationship */

        if (item.relationship && item.relationship.contratoContacto) {
            contrato.contratoContacto = item.relationship.contratoContacto.data.map(data => this.contratoContactoAdapter.adapt(data));
            contrato.titular = contrato.contratoContacto.find(c => c.isTitular === true).contacto;
        }
        if (item.relationship && item.relationship.contratoUnidad) {
            contrato.contratoUnidad = item.relationship.contratoUnidad.data.map(contratoUnidad =>
                this.contratoUnidadAdapter.adapt(contratoUnidad)
            );
        }
        if (item.relationship && item.relationship.custodiaDocumento ) {
            contrato.custodiaDocumento = item.relationship.custodiaDocumento.data.map(custodiaDocumento =>
                this.custodiaDocumentoAdapter.adapt(custodiaDocumento)
            );
        }
        if (item.relationship && item.relationship.residentes) {
            contrato.residents = item.relationship.residentes.data.map(residente => getContractResidents(residente));
        } else {
            contrato.residents = []
        }
        if (item.relationship && item.relationship.mascotas) {
            contrato.pets = item.relationship.mascotas.data.map(mascota => this.mascotaAdapter.adapt(mascota));
        } else {
            contrato.pets = []
        }
        if (item.relationship && item.relationship.vehiculos) {
            contrato.vehicles = item.relationship.vehiculos.data.map(vehiculo => this.vehiculoAdapter.adapt(vehiculo));
        } else {
            contrato.vehicles = []
        }
        if (item.relationship && item.relationship.firmantes) {
            contrato.firmantes = item.relationship.firmantes.data.map(firmante => this.firmanteAdapter.adapt(firmante));
        } else {
            contrato.firmantes = [];
        }
        if (item.relationship && item.relationship.reservas) {
            contrato.reservas = item.relationship.reservas.data.map(reserva => this.reservaAdapter.adapt(reserva));
        } else {
            contrato.reservas = [];
        }
        return contrato;
    }
  }
