import { ContratoAdapter } from 'src/app/adapter/contrato.adapter';
import { DivisaAdapter } from './divisa.adapter';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { EstadoCuenta } from 'src/app/models/model.index';
import { EstadoCuentaDetalleAdapter } from './estado-cuenta-detalle.adapter';
import { EstadoCuentaEstadoAdapter } from './estado-cuenta-estado.adapter';
import {ReservaAdapter} from "./reserva.adapter";

@Injectable({
  providedIn: 'root'
})

export class EstadoCuentaAdapter implements Adapter<EstadoCuenta> {

  constructor(
    public estadoCuentaDetalleAdapter: EstadoCuentaDetalleAdapter,
    public estadoCuentaEstadoAdapter: EstadoCuentaEstadoAdapter,
    public divisaAdapter: DivisaAdapter,
    public contratoAdapter: ContratoAdapter,
    public reservaAdapter: ReservaAdapter
  ) {}

  adapt(item: any): EstadoCuenta {
    const estadoCuenta = new EstadoCuenta();
    estadoCuenta.id = item.attributes.id;
    estadoCuenta.folio = item.attributes.folio;
    estadoCuenta.contratoId = item.attributes.contrato_id;
    estadoCuenta.reservaId = item.attributes.reserva_id;
    estadoCuenta.estadoCuentaEstadoId = +item.attributes.estado_cuenta_estado_id;
    estadoCuenta.fecha = item.attributes.fecha;
    estadoCuenta.fechaCreacion = item.attributes.created_at;
    estadoCuenta.fechaVencimiento = item.attributes.fecha_vencimiento;
    estadoCuenta.divisaId = item.attributes.divisa_id;
    estadoCuenta.montoPactado = item.attributes.monto_pactado;
    estadoCuenta.fechaPago = item.attributes.fecha_pago;
    estadoCuenta.fechaConciliacionPago = item.attributes.fecha_conciliacion_pago;
    estadoCuenta.fechaPeriodoInicio = item.attributes.fecha_periodo_inicio;
    estadoCuenta.fechaPeriodoFin = item.attributes.fecha_periodo_fin;
    estadoCuenta.isPrimerPago = item.attributes.is_primero_pago;
    estadoCuenta.correoEnviado = item.attributes.correo_enviado;
    estadoCuenta.divisaCobroId = item.attributes.divisa_cobro_id;
    estadoCuenta.isPagoConciliado = item.attributes.is_pago_conciliado;

    /* Includes*/
    if (item.included) {
      item.included.forEach(include => {
        switch (include.type) {
          case 'Divisa':
            estadoCuenta.divisa = this.divisaAdapter.adapt(include);
            break;
          case 'EstadoCuentaEstado':
            estadoCuenta.estadoCuentaEstado = this.estadoCuentaEstadoAdapter.adapt(include);
            break;
          case 'Contrato':
            estadoCuenta.contrato = this.contratoAdapter.adapt(include);
            break;
          case 'Reserva':
            estadoCuenta.reserva = this.reservaAdapter.adapt(include);
            break;
          }
      });
    }

    /* Relationship */
    if (item.relationship && item.relationship.estadoCuentaDetalle) {
      estadoCuenta.estadoCuentaDetalles = item.relationship.estadoCuentaDetalle.data
      .map(estadoCuentaDetalle => this.estadoCuentaDetalleAdapter.adapt(estadoCuentaDetalle));
    }
    return estadoCuenta;
  }
}
