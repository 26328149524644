<!-- PAGINADOR -->
<div class="col-lg-12 box-paginador pb-4">
    <nav aria-label="Pagination" *ngIf="paginador.pageTotal > 1">
        <ul class="pagination justify-content-center">
            <li class="page-item" [ngClass]="{
                                'pointer': paginador.pageCurrent > 1,
                                'disabled': paginador.pageCurrent <= 1}">
                <a class="page-link" (click)="setPagina(paginador.pageCurrent - 1)" aria-label="Anterior" [ngStyle]="{'font-weight': paginador.pageCurrent > 1 ? '500' : 'normal' }">
                    <span aria-hidden="true">{{ 'paginate.prev' | translate }}</span>
                </a>
            </li>
            <li *ngFor="let page of pages;" class="page-item" [ngClass]="{
                                'pointer': page != paginador.pageCurrent,
                                'active': page == paginador.pageCurrent}">
                <a class="page-link" (click)="page != paginador.pageCurrent ? setPagina(page) : '' ">{{ page }}</a>
            </li>
            <li class="page-item" [ngClass]="{
                                'pointer': paginador.pageCurrent < paginador.pageTotal,
                                'disabled': paginador.pageCurrent == paginador.pageTotal}">
                <a class="page-link" (click)="setPagina(paginador.pageCurrent + 1)" aria-label="Siguiente" [ngStyle]="{'font-weight': paginador.pageCurrent < paginador.pageTotal ? '500' : 'normal' }">
                    <span aria-hidden="true">{{ 'paginate.next' | translate }}</span>
                </a>
            </li>
        </ul>
    </nav>
</div>
<!-- PAGINADOR -->
