import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {UnidadRadiografia} from '../models/unidad-radiografia.model';
import {UnidadTipoAdapter} from './unidad-tipo.adapter';
import {UnidadEstadoAdapter} from './unidad-estado.adapter';
import {UnidadSubEstadoAdapter} from './unidad-subestado.adapter';
import {ContratoAdapter} from './contrato.adapter';
import {UnidadEstado} from '../models/unidad-estado.model';
import {UnidadDivisaMontoAdapter} from './unidad-divisa-monto.adapter';

@Injectable({
  providedIn: 'root'
})
export class UnidadRadiografiaAdapter implements Adapter<UnidadRadiografia> {
  constructor(
    private unidadTipoAdapter: UnidadTipoAdapter,
    private unidadEstadoAdapter: UnidadEstadoAdapter,
    private unidadSubEstadoAdapter: UnidadSubEstadoAdapter,
    private contratoAdapter: ContratoAdapter,
    private unidadDivisaMontoAdapter: UnidadDivisaMontoAdapter
  ) {
  }
  adapt(item: any): UnidadRadiografia {
    const unidadRadiografia = new UnidadRadiografia();
    unidadRadiografia.id = item.attributes.id;
    unidadRadiografia.nombre = item.attributes.nombre;
    unidadRadiografia.piso = item.attributes.piso;
    unidadRadiografia.unidadTipoId = item.attributes.unidad_tipo_id;
    unidadRadiografia.unidadEstadoId = item.attributes.unidad_estado_id;
    unidadRadiografia.subEstadoId = item.attributes.sub_estado_id;
    unidadRadiografia.isAmoblado = item.attributes.is_amoblado;
    unidadRadiografia.isAfectoImpuesto = item.attributes.is_afecto_impuesto;
    unidadRadiografia.hasTerraza = item.attributes.has_terraza;
    unidadRadiografia.orientacion = item.attributes.orientacion ? item.attributes.orientacion : 'Sin información';
    unidadRadiografia.tipologia = item.attributes.tipologia ? item.attributes.tipologia : 'Sin información';
    unidadRadiografia.tipologiaEspecial = item.attributes.tipologia_especial ? '/ ' + item.attributes.tipologia_especial : '';

    /* INCLUDES */
    if (item.included) {
      item.included.forEach((include) => {
        switch (include.type) {
          case 'UnidadTipo':
            unidadRadiografia.unidadTipo = this.unidadTipoAdapter.adapt(include);
            break;
          case 'UnidadEstado':
            unidadRadiografia.unidadEstado = this.unidadEstadoAdapter.adapt(include);
            break;
          case 'UnidadSubEstado':
            unidadRadiografia.unidadSubEstado = this.unidadSubEstadoAdapter.adapt(include);
            break;
          case 'Contrato':
            unidadRadiografia.contrato = this.contratoAdapter.adapt(include);
            break;
        }
      })
    }

    unidadRadiografia.precioArriendo = 0;
    unidadRadiografia.precioGastoComun = 0;
    if (item.relationship?.precios?.data?.length > 0) {
      const precioTipoId = (String(unidadRadiografia.unidadEstado.id) === String(UnidadEstado.ARRENDADO.id)) ? 2 : 1;
      const precios = item.relationship.precios?.data?.filter(data => data.attributes.tipo_id === precioTipoId);
      unidadRadiografia.precioArriendo = precios.find(data => data.attributes.concepto_id === 1)
        ? this.unidadDivisaMontoAdapter.adapt(precios.find(data => data.attributes.concepto_id === 1)) : 0;
      unidadRadiografia.precioGastoComun = precios.find(data => data.attributes.concepto_id === 2)
        ? this.unidadDivisaMontoAdapter.adapt(precios.find(data => data.attributes.concepto_id === 2)) : 0;
    }
    return unidadRadiografia;
  }
}
