import {Component, Input, OnInit} from '@angular/core';
import { RoutingStateService } from 'src/app/services/shared/routing-state.service';
import {BreadcrumbInterface} from '../../interfaces/breadcrumb.interface';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css']
})
export class PageTitleComponent implements OnInit {
  public previousRoute: string;
  public titulo: string;
  @Input() breadcrumb: BreadcrumbInterface[];
  @Input() showPreviousButton: boolean = true
  @Input() showTitle: boolean = true
  constructor(
    private routingState: RoutingStateService,
  ) { }

  ngOnInit(): void {
    this.previousRoute = this.routingState.getPreviousUrl();
    const thisPage = this.breadcrumb.find(breadcrumb => breadcrumb.active === true);
    this.titulo = thisPage.title;
  }

}
