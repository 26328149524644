import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../services/shared/loader.service';

interface UrlWithoutGlobalSpinner {
    url: RegExp
    method: string
}
const urlsWithoutGlobalSpinner: UrlWithoutGlobalSpinner[] = [
    { url: new RegExp(`${environment.urlApi}usuario?`), method: 'GET' },
    { url:  new RegExp(`${environment.urlApi}usuario/create`), method: 'POST' },
    { url:  new RegExp(`${environment.urlApi}usuario/.*?/update`), method: 'PUT' },
    { url: new RegExp(`${environment.urlApi}usuario/.*?/roles`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}usuario/.*?/roles`), method: 'PATCH' },
    { url: new RegExp(`${environment.urlApi}carga-masiva?`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}carga-masiva`), method: 'POST' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/entregables`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/lecturas`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/equipamientos`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/costos`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/entregables`), method: 'POST' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/lecturas`), method: 'POST' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/equipamientos`), method: 'POST' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/costos`), method: 'POST' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/entregables`), method: 'PUT' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/lecturas`), method: 'PUT' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/equipamientos`), method: 'PUT' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/costos`), method: 'PUT' },
    { url: new RegExp(`${environment.urlApi}hitos`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}conectores`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}notificaciones?`), method: 'GET' },,
    { url: new RegExp(`${environment.urlApi}notificaciones/.*?`), method: 'PUT' },
    { url: new RegExp(`${environment.urlApi}notificaciones`), method: 'POST' },
    { url: new RegExp(`${environment.urlApi}propiedad/.*?/configuracion/cotizacion-nota?`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/concepto-clasificacion?`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}propiedad/.*?/configuracion/cotizacion-nota`), method: 'POST' },
    { url: new RegExp(`${environment.urlApi}propiedad/.*?/configuracion/cotizacion-nota/.*?`), method: 'PUT' },
    { url: new RegExp(`${environment.urlApi}propiedad/.*?/configuracion/descuento-recargo-configuracion`), method: 'POST' },
    { url: new RegExp(`${environment.urlApi}propiedad/.*?/configuracion/descuento-recargo-configuracion/.*?`), method: 'PUT' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/concepto-clasificacion`), method: 'POST' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/concepto-clasificacion/.*?`), method: 'PUT' },
    { url: new RegExp(`${environment.urlApi}propiedades/.*?/configuracion/concepto-clasificacion/.*?`), method: 'DELETE' },
    { url: new RegExp(`${environment.urlApi}modulo`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}usuario/activacionInactivacionMasiva`), method: 'PATCH' },
    { url: new RegExp(`${environment.urlApi}usuario/.*?/asignar-permisos`), method: 'PATCH' },
    { url: new RegExp(`${environment.urlApi}liquidacion-propietario/preview?`), method: 'GET' },
    { url: new RegExp(`${environment.urlApi}liquidacion-propietario?`), method: 'GET' },
]

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor(public loaderService: LoaderService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isUrlWithoutGlobalSpinner: boolean = urlsWithoutGlobalSpinner.some((e:UrlWithoutGlobalSpinner) => e.method === req.method && e.url.test(req.url))

        if (isUrlWithoutGlobalSpinner) {
            return next.handle(req)
        } else {
            this.loaderService.show();
            return next.handle(req).pipe(
                finalize(() => this.loaderService.hide())
            );
        }
    }
}
