import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Statistics } from '../models/statistics.model';

@Injectable({
  providedIn: 'root'
})

export class StatisticsAdapter implements Adapter<Statistics> {

  adapt(item: any): Statistics {
    const statistics = new Statistics();
    statistics.stats = item.stats;
    return statistics;
  }
}
