import {Globals} from '../models/globals.model';
import update from 'immutability-helper';
import {GlobalsActions, GlobalsActionTypes} from '../actions/globals.action';

const initialState: Globals = {
  divisas: [],
  identificadorTipos: [],
  cargoClasificaciones: [],
  conceptos: [],
  unidadEstados: [],
  unidadTipos: [],
  periodicidadTipos: [
    {id: 1, lang_resource: 'peridicity.days'},
    {id: 2, lang_resource: 'peridicity.months'},
    {id: 3, lang_resource: 'peridicity.years'}
  ],
  montoTipos: [
    {id: 1, lang_resource: 'expense_type.fixed_amount'},
    {id: 2, lang_resource: 'expense_type.percent_amount'}
  ],
  contratoContactoTipos: [],
  reajusteTipos: []
};

export const GlobalsReducer = (state: Globals = initialState, action: GlobalsActions) => {
  switch (action.type) {
    case GlobalsActionTypes.SET_DIVISAS: {
      return update(state, {divisas: {$set: action.payload}});
    }
    case GlobalsActionTypes.SET_IDENTIFICADOR_TIPOS: {
      return update(state, {identificadorTipos: {$set: action.payload}});
    }
    case GlobalsActionTypes.SET_CARGOS_CLASIFICACIONES: {
      return update(state, {cargoClasificaciones: {$set: action.payload}});
    }
    case GlobalsActionTypes.SET_CONCEPTOS: {
      return update(state, {conceptos: {$set: action.payload}});
    }
    case GlobalsActionTypes.SET_UNIDAD_ESTADOS: {
      return update(state, {unidadEstados: {$set: action.payload}});
    }
    case GlobalsActionTypes.SET_UNIDAD_TIPOS: {
      return update(state, {unidadTipos: {$set: action.payload}});
    }
    case GlobalsActionTypes.SET_CONTRATO_CONTACTO_TIPOS: {
      return update(state, {contratoContactoTipos: {$set: action.payload}});
    }
    case GlobalsActionTypes.SET_ALL: {
      const payload: Globals = action.payload;
      return update(state, {
        divisas: {$set: payload.divisas},
        identificadorTipos: {$set: payload.identificadorTipos},
        cargoClasificaciones: {$set: payload.cargoClasificaciones},
        conceptos: {$set: payload.conceptos},
        unidadEstados: {$set: payload.unidadEstados},
        unidadTipos: {$set: payload.unidadTipos},
        periodicidadTipos: {$set: state.periodicidadTipos},
        montoTipos: {$set: state.montoTipos},
        contratoContactoTipos: {$set: state.contratoContactoTipos}
      })
    }
  }
}
