import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AuthService,
  AuthGuard,
  SidebarService,
  SharedService,
  LoaderService,
  UsuarioService,
  ContactoService,
  UnidadTipoService,
  UnidadService,
  PropiedadService,
  CotizacionService,
  UnidadEstadoService,
  PerfilService,
  ContratoService,
  MedioLlegadaService,
  ModalService,
  TipologiaService,
  OrientacionService,
  TipologiaEspecialService,
  DataShareService
 } from './service.index';
import { RoutingStateService } from './shared/routing-state.service';
import { ContratoAnexoService } from './contrato-anexo/contrato-anexo.service';
import { AbilityService } from './ability/ability.service';


@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    AbilityService,
    AuthService,
    AuthGuard,
    SidebarService,
    SharedService,
    ModalService,
    LoaderService,
    UsuarioService,
    ContactoService,
    UnidadTipoService,
    UnidadService,
    PropiedadService,
    CotizacionService,
    UnidadEstadoService,
    PerfilService,
    ContratoService,
    MedioLlegadaService,
    TipologiaService,
    OrientacionService,
    TipologiaEspecialService,
    DataShareService,
    RoutingStateService,
    ContratoAnexoService
  ],
  declarations: []
})
export class ServiceModule { }
