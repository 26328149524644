import { ContactoAdapter } from 'src/app/adapter/contacto.adapter';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Empresa } from '../models/empresa.model';

@Injectable({
    providedIn: 'root'
})

export class EmpresaAdapter implements Adapter<Empresa> {

    constructor(
        public contactoAdapter: ContactoAdapter
    ) {}

    adapt(item: any): Empresa {

        const empresa = new Empresa();
        empresa.id = item.attributes.id;
        empresa.nombre = item.attributes.nombre;
        empresa.codigo = item.attributes.codigo;
        return empresa;
    }
  }
