import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Contacto } from 'src/app/models/model.index';
import { AbilityService } from 'src/app/services/ability/ability.service';

@Component({
  selector: 'app-contacto-detalle',
  templateUrl: './contacto-detalle.component.html',
  styleUrls: ['./contacto-detalle.component.css']
})
export class ContactoDetalleComponent implements OnInit {
  public showMore = false;
  @Input() contacto: Contacto;

  @Output() isEditarActive: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public ability: AbilityService
  ) { }

  ngOnInit() {
  }

  showEditar() {
    this.isEditarActive.emit(true);
  }

  get textVerMas() {
    if (this.showMore) {
      return 'Ver menos';
    } else {
      return 'Ver más';
    }
  }
  
  get canUpdateContacto(){
    return this.ability.hasAccion('ContactoUpdate');
  }

}
