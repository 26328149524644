import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FechaPipe} from '../../pipes/fecha.pipe';
import {Moment} from 'moment';
import {Utils} from '../utils';
import {ContratoUtils} from '../../pages/contratos/shared/contrato-utils';
import {NegocioInterface, DateJson} from '../../interfaces/negocio.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-selector-fechas',
  templateUrl: './selector-fechas.component.html',
  styleUrls: ['./selector-fechas.component.css'],
  providers: [FechaPipe]
})
export class SelectorFechasComponent implements OnInit {
  public fechaCotizacion: any;
  public fechaAnexo: string;
  public rangoFechas: {startDate: Moment, endDate: Moment}
  public showTexto: boolean;
  public inEdition = true;
  public types: string[] = ['Contrato', 'Cotizacion', 'Anexo'];
  public textInfoEdition = 'Al editar las fechas de inicio y término de contrato, <strong>valide o modifique</strong> el período de inicio de los cargos y descuentos.</p>';

  @Input() negocioData: NegocioInterface;
  @Input() type: string;
  @Input() isEdition = false;
  @Output() updateInEditionEmitter = new EventEmitter();
  @Output() seleccionarFechasEmitter = new EventEmitter();
  constructor(
    public toastr: ToastrService
  ) { }

  ngOnInit(): void {
    if (this.types.includes(this.type)) {
      this.initFormGroup();
      if (this.isEdition) {
        this.inEdition = false;
      }
    }
  }

  initFormGroup() {
    if (this.type === 'Cotizacion' && this.negocioData?.fecha) {
      this.fechaCotizacion = new Date(Utils.dateObjectToString(this.negocioData.fecha));
    }
    const fechaInicio = this.negocioData?.fechaInicio ? this.negocioData.fechaInicio : null;
    const fechaFin = this.negocioData?.fechaFin ? this.negocioData.fechaFin : null;
    if (fechaInicio && fechaFin) {
      this.inEdition = false;
    }
  }

  setFechas() {
    if (this.rangoFechas) {
      this.negocioData.fechaInicio = Utils.jsonDate(this.rangoFechas.startDate);
      this.negocioData.fechaFin = Utils.jsonDate(this.rangoFechas.endDate);
      this.disableFechas();
    } else {
      this.toastr.error('Seleccione el rango de fechas');
    }
  }

  disableFechas() {
    this.showTexto = false;
    this.inEdition = false;
    this.updateInEditionEmitter.emit(false);
  }

  enableFechas() {
    this.showTexto = true;
    this.inEdition = true;
  }

  setRangoFechas(rangoFechas: { startDate: Moment, endDate: Moment }) {
    if (rangoFechas) {
      this.rangoFechas = rangoFechas;
    }
  }

  monthDiff(fechaInicio, fechaFin) {
    if (fechaInicio && fechaFin) {
      return ContratoUtils.monthDiff(fechaInicio, fechaFin);
    } else {
      return '...';
    }
  }

  get periodo() {
    return this.monthDiff(this.negocioData.fechaInicio, this.negocioData.fechaFin);
  }

  dateAsString(date: DateJson) {
    return moment(Utils.dateObjectToString(date)).format('DD-MM-YYYY');
  }

  get textInfoDates() {
    if (this.negocioData.fechaInicio && this.negocioData.fechaFin) {
      const conector = this.type === 'Contrato' ? 'es' : 'será';
      return `La fecha de inicio del contrato ${conector} <b>${this.dateAsString(this.negocioData.fechaInicio)} </b> y la fecha de fin será <b>${this.dateAsString(this.negocioData.fechaFin)}</b> teniendo una duración de <b>${this.monthDiff(this.negocioData.fechaInicio, this.negocioData.fechaFin)} mes(es) </b>`;
    } else {
      return '...';
    }

  }

}
