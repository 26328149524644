import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { EstadoCivil } from '../models/model.index';

@Injectable({
    providedIn: 'root'
  })
  export class EstadoCivilAdapter implements Adapter<EstadoCivil> {
    adapt(item: any): EstadoCivil {
        const estadoCivil = new EstadoCivil();
        estadoCivil.id = item.attributes.id;
        estadoCivil.nombre = item.attributes.nombre;
        estadoCivil.lang_resource = item.attributes.lang_resource;
        return estadoCivil;
    }
  }
