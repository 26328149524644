import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { IdentificadorTipo } from '../models/model.index';

@Injectable({
  providedIn: 'root'
})

export class IdentificadorTipoAdapter implements Adapter<IdentificadorTipo> {
  adapt(item: any): IdentificadorTipo {
    const identificadorTipo = new IdentificadorTipo();
    identificadorTipo.id = item.attributes.id;
    identificadorTipo.nombre = item.attributes.nombre;
    identificadorTipo.paisId = item.attributes.pais_id;
    return identificadorTipo;
  }
}
