import {Unidad} from "./unidad.model";

export class ReservaUnidad {
  private _id: string;
  private _reservaId: string;
  private _unidadId: string;
  private _abonar: boolean;

  private _unidad: Unidad;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get reservaId(): string {
    return this._reservaId;
  }

  set reservaId(value: string) {
    this._reservaId = value;
  }

  get unidadId(): string {
    return this._unidadId;
  }

  set unidadId(value: string) {
    this._unidadId = value;
  }

  get abonar(): boolean {
    return this._abonar;
  }

  set abonar(value: boolean) {
    this._abonar = value;
  }

  get unidad(): Unidad {
    return this._unidad;
  }

  set unidad(value: Unidad) {
    this._unidad = value;
  }
}
