import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Contacto } from 'src/app/models/contacto.model';
import { ModalCotizacionComponent } from './../../pages/cotizaciones/modal-cotizacion/modal-cotizacion.component';
import { ModalRegistroPagoCuentasComponent } from 'src/app/pages/balances/modal-registro-pago-cuentas/modal-registro-pago-cuentas.component';
import { ModalReversoComponent } from 'src/app/pages/balances/modal-reverso/modal-reverso.component';
import { ModalContactoComponent } from '../../pages/clientes/shared/modal-contacto/modal-contacto.component';
import {ToastrService} from "ngx-toastr";
import { ModalExpiracionComponent } from 'src/app/pages/modal/modal-expiracion/modal-expiracion.component';
import { ModalGeneralComponent, ModalActionInterface } from 'src/app/shared/modal-general/modal-general.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private crearContactoModal: any;
  private generarCotizacionModal: any;
  private ingresarPagoModal: any;
  private creaReverso: any;
  private crearCalendarioModal: any;
  public contacto: Contacto;
  public cargoDescuento: any;
  public cotizacion: any;
  public pago: any;
  public notificacionModal: any;

  constructor(public dialog: MatDialog, private toastr: ToastrService) {
    this.contacto = new Contacto();
  }

  abrirModalGeneral(width:number,height:number,titulo?:string,cuerpo?:string,actions?:ModalActionInterface[]){
    this.dialog.open(ModalGeneralComponent, {
      width: width+'px',
      height: height+'px',
      data: {
        titulo: titulo,
        cuerpo: cuerpo,
        actions: actions
      }
    });
  }

  advertirExpiracion(onRefresh: Function, onCancel: Function): void {
    this.crearContactoModal = this.dialog.open(ModalExpiracionComponent, {
      width: '500px',
      height: '180px',
      data: {
        onRefresh: onRefresh,
        onCancel: onCancel
      }
    });
  }

  crearContacto(): void {
    const contacto = new Contacto();
    this.crearContactoModal = this.dialog.open(ModalContactoComponent, {
      width: '900px',
      data: {contacto, isNew: true}
    });
    this.crearContactoModal.afterClosed().subscribe(result => {
      console.log(result);
      this.contacto = result;
    });
  }

  cerrarModalCrearContacto(): void {
    this.crearContactoModal.close();
  }

  generarCotizacion(data: any): void {
    this.generarCotizacionModal = this.dialog.open(ModalCotizacionComponent, {
      width: '600px',
      data
    });
    this.generarCotizacionModal.afterClosed().subscribe(result => {
      console.log(result);
      this.cotizacion = result;
    });
  }

  notificacion(data: string, type?: string): void {
    switch (type) {
      case 'success':
        this.toastr.success(data);
        break
      case 'error':
        this.toastr.error(data);
        break;
      case 'warning':
        this.toastr.warning(data);
        break
      default:
        this.toastr.info(data);
        break;
    }
  }

  registrarPagoDeCuentas(): void {
    this.ingresarPagoModal = this.dialog.open(
      ModalRegistroPagoCuentasComponent,
      {
        width: '1120px'
      }
    );
    this.ingresarPagoModal.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  crearReverso(contacto, pago): void {
    this.creaReverso = this.dialog.open(
      ModalReversoComponent,
      { data: {contacto, pago} }
    );
    this.creaReverso.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
}
