import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  public defaultTitle = 'PopEstate';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) { }

  public async getTitle() {
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.getChild(this.activatedRoute);
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return '';
        })
      ).subscribe((title: string) => {
        const titleString = title ? `${this.defaultTitle} - ${title}` : this.defaultTitle;
        this.titleService.setTitle(titleString);
      });
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

}
