import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContactoEvaluacionEstado } from '../models/contacto-evaluacion-estado.model';

@Injectable({
    providedIn: 'root'
  })
  export class ContactoEvaluacionEstadoAdapter implements Adapter<ContactoEvaluacionEstado> {
    adapt(item: any): ContactoEvaluacionEstado {
        const contactoEvaluacionEstado = new ContactoEvaluacionEstado();
        contactoEvaluacionEstado.id = item.attributes.id;
        contactoEvaluacionEstado.nombre = item.attributes.nombre;
        return contactoEvaluacionEstado;
    }
  }
