import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContactoEvaluacionDetalle } from './../models/contacto-evaluacion-detalle.model';
import { AdjuntoAdapter } from './adjunto.adapter';

@Injectable({
    providedIn: 'root'
})

export class ContactoEvaluacionDetalleAdapter implements Adapter<ContactoEvaluacionDetalle> {

    constructor(
        public adjuntoAdapter: AdjuntoAdapter
    ) {}

    adapt(item: any): ContactoEvaluacionDetalle {
        const contactoEvaluacionDetalle = new ContactoEvaluacionDetalle();
        contactoEvaluacionDetalle.id = item.id;
        contactoEvaluacionDetalle.contactoEvaluacionId = item.attributes.contacto_evaluacion_id;
        contactoEvaluacionDetalle.nombre = item.attributes.nombre;
        contactoEvaluacionDetalle.createdAt = item.attributes.created_at;
        contactoEvaluacionDetalle.createdBy = item.attributes.created_by;
        contactoEvaluacionDetalle.isValido = item.attributes.is_valido;
        contactoEvaluacionDetalle.observacion = item.attributes.observacion;
        /* Relationship */
        if (item.relationship && item.relationship.adjuntos && item.relationship.adjuntos.data) {
            contactoEvaluacionDetalle.adjuntos = item.relationship.adjuntos.data
            .map(adjunto =>
                this.adjuntoAdapter.adapt(adjunto)
            );
        }
        return contactoEvaluacionDetalle;
    }
  }
