import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ModalDetalleCheckCalendario } from './modal-detalle-check-calendario.component';
import { AbilityModule } from '@casl/angular';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        RouterModule,
        TranslateModule,
        AbilityModule
    ],
    exports: [ ModalDetalleCheckCalendario, RouterModule],
    declarations: [ ModalDetalleCheckCalendario ]
})

export class ModalDetalleCheckCalendarioModule { }
