import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Paginador } from '../models/paginador.model';

@Injectable({
    providedIn: 'root'
  })

export class PaginadorAdapter implements Adapter<Paginador> {

    adapt(item: any): Paginador {
        const paginador = new Paginador();
        paginador.itemCurrentStart = item.pager.item_current_start;
        paginador.itemCurrentEnd = item.pager.item_current_end;
        paginador.itemTotal = item.pager.item_total;
        paginador.pageCurrent = item.pager.page_current;
        paginador.pageTotal = item.pager.page_total;
        return paginador;
    }
}
