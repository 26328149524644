import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Contacto } from 'src/app/models/model.index';

@Component({
  selector: 'app-modal-contacto',
  templateUrl: './modal-contacto.component.html',
  styleUrls: ['./modal-contacto.component.css']
})

export class ModalContactoComponent implements OnInit {

  public contacto: Contacto;
  public scrollContainer: any;

  @ViewChild('scrollframe') scrollframe: ElementRef;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalContactoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {contacto: Contacto},
    public toastr: ToastrService
  ) { }

  ngOnInit() {
    this.contacto = this.data.contacto;
  }

  activeEditar() {
    this.dialogRef.close({ editContact: true });
  }

  scrollToBottom() {
    setTimeout(() => {
      this.scrollContainer = this.scrollframe.nativeElement;
      this.scrollContainer.scroll({
        top: this.scrollContainer.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    });
  }

}
