<div mat-dialog-title class="modal-title">
    <h3 >{{data.titulo}}
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" mat-dialog-close>
            <span aria-hidden="true">&times;</span>
        </button>
    </h3>
</div>
<div mat-dialog-content class="my-4 text-center">

    <p [innerHTML]="data.contenido"></p>

    <div class="mt-3" *ngIf="data.previewPrimerCobro">
        <iframe class="e2e-iframe-trusted-src" width="100%" height="350" [src]="data.previewPrimerCobro" type="application/pdf"></iframe>
    </div>

    <div class="text-center" *ngIf="data.check">
        <mat-checkbox [(ngModel)]="data.check.value">{{ data.check.text }}</mat-checkbox>
    </div>


</div>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close class="boton-anterior">Cancelar</button>
    <button mat-button [mat-dialog-close]="true" class="boton-agp" cdkFocusInitial>Aceptar</button>
</mat-dialog-actions>
