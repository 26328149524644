import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { PropiedadConfiguracionCargoClasificacion } from '../models/propiedad-configuracioncargo-clasificacion.model';

@Injectable({
    providedIn: 'root'
})

export class PropiedadConfiguracionCargoClasificacionAdapter implements Adapter<PropiedadConfiguracionCargoClasificacion> {

    adapt(item: any): PropiedadConfiguracionCargoClasificacion {
        const propiedadConfiguracionCargoClasificacion = new PropiedadConfiguracionCargoClasificacion();
        propiedadConfiguracionCargoClasificacion.id = item.attributes.id;
        propiedadConfiguracionCargoClasificacion.conceptoId = item.attributes.concepto_id;
        propiedadConfiguracionCargoClasificacion.cargoClasificacionId = item.attributes.cargo_clasificacion_id;
        propiedadConfiguracionCargoClasificacion.isFacturableInt = item.attributes.is_facturable_int;
        propiedadConfiguracionCargoClasificacion.propietarioLiquidable = item.attributes.propietario_liquidable;
        propiedadConfiguracionCargoClasificacion.propietarioComisionable = item.attributes.propietario_comisionable;
        return propiedadConfiguracionCargoClasificacion;
    }

    simpleAdapt(item: {id, concepto_id, cargo_clasificacion_id, is_facturable_int}): PropiedadConfiguracionCargoClasificacion {
        const propiedadConfiguracionCargoClasificacion = new PropiedadConfiguracionCargoClasificacion();
        propiedadConfiguracionCargoClasificacion.id = item.id;
        propiedadConfiguracionCargoClasificacion.conceptoId = item.concepto_id;
        propiedadConfiguracionCargoClasificacion.cargoClasificacionId = item.cargo_clasificacion_id;
        propiedadConfiguracionCargoClasificacion.isFacturableInt = item.is_facturable_int;
        propiedadConfiguracionCargoClasificacion.propietarioLiquidable = false;
        propiedadConfiguracionCargoClasificacion.propietarioComisionable = false;
        return propiedadConfiguracionCargoClasificacion;
    }
}
