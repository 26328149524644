import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContratoUnidadDetalle } from './../models/model.index';

@Injectable({
    providedIn: 'root'
  })
  export class ContratoUnidadDetalleAdapter implements Adapter<ContratoUnidadDetalle> {
    adapt(item: any): ContratoUnidadDetalle {
        const contratoUnidadDetalle = new ContratoUnidadDetalle();
        contratoUnidadDetalle.id = item.attributes.id;
        contratoUnidadDetalle.contratoUnidadId = item.attributes.contrato_unidad_id;
        contratoUnidadDetalle.nombre = item.attributes.nombre;
        contratoUnidadDetalle.conceptoId = item.attributes.concepto_id;
        contratoUnidadDetalle.cargoClasificacionId = item.attributes.cargo_clasificacion_id;
        contratoUnidadDetalle.montoTipoId = item.attributes.monto_tipo_id;
        contratoUnidadDetalle.monto = +item.attributes.monto;
        contratoUnidadDetalle.descuentoRecargoConfiguracionId = item.attributes.descuento_recargo_configuracion_id;
        contratoUnidadDetalle.divisaId = item.attributes.divisa_id;
        contratoUnidadDetalle.observacion = item.attributes.observacion;
        contratoUnidadDetalle.isAfectoImpuesto = item.attributes.is_afecto_impuesto;
        contratoUnidadDetalle.descuentoRecargoConceptoAplicaId = item.attributes.descuento_recargo_concepto_aplica_id;
        contratoUnidadDetalle.cicloCantidad = item.attributes.ciclo_cantidad;
        contratoUnidadDetalle.cicloOpcion = item.attributes.ciclo_opcion;
        contratoUnidadDetalle.cicloFecha = item.attributes.ciclo_fecha;
        contratoUnidadDetalle.cuentaRecaudadoraId = item.attributes.cuenta_recaudadora_id;
        contratoUnidadDetalle.fechaInicio = item.attributes.fecha_inicio;
        contratoUnidadDetalle.fechaTermino = item.attributes.fecha_termino;
        contratoUnidadDetalle.montoTotal = +item.attributes.monto_total;
        contratoUnidadDetalle.isFacturableInt = item.attributes.is_facturable_int;
        contratoUnidadDetalle.createdAt = item.attributes.created_at;
        contratoUnidadDetalle.createdBy = item.attributes.created_by;

        if (item.meta) {
            const {divisaId, monto} = item.meta;
            contratoUnidadDetalle.meta = {conceptoId: item.attributes.concepto_id, divisaId, monto };
        }
        return contratoUnidadDetalle;
    }
  }
