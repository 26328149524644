import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TipologiaService {

    public tipologias: {id: string, nombre: string}[];

    constructor() {
        this.tipologias = [
            { id: 'ESTUDIO', nombre: 'ESTUDIO'},
            { id: '1D1B', nombre: '1D1B'},
            { id: '1D2B', nombre: '1D2B'},
            { id: '2D1B', nombre: '2D1B'},
            { id: '2D2B', nombre: '2D2B'},
            { id: '2D3B', nombre: '2D3B'},
            { id: '3D1B', nombre: '3D1B'},
            { id: '3D2B', nombre: '3D2B'},
            { id: '3D3B', nombre: '3D3B'}
        ];
    }

    cargarTipologias() {
        return this.tipologias;
    }

}
