<div class="modal-nc">
    <mat-dialog-content>
        <div>
            <h6>Ha seleccionado dos (2) Unidades.</h6>
            <h6>Usted puede:</h6>
            <p class="text-modal">Realizar una única cotización con todas las unidades seleccionadas o bien realizar cotizaciones individuales por cada unidad seleccionada</p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mb-1">
        <button type="button" [mat-dialog-close]="data.cotizacionUnica" [class]="'btn boton-agp mx-5'">Cotización Única</button>
        <button type="button" [mat-dialog-close]="data.cotizacionIndial" [class]="'btn boton-agp'">Cotizaciones Individuales</button>
    </mat-dialog-actions>
</div>