import { UntypedFormControl } from '@angular/forms';
import { Component, ViewChild, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-google-places',
    templateUrl: './google-places.component.html',
})

export class GooglePlacesComponent implements OnInit, OnChanges {

  @Input() adressType: string;
  @Input() paisCodigo: string;
  @Input() label: string;
  @Input() disabled: boolean
  @Input() required: boolean
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput = new UntypedFormControl();
  queryWait: boolean;
  place: any;
  adressEmit: any = {};
  adressFormated: string;

  public mapsLoaded: Observable<boolean>;
  public isAutoCompleteInit: boolean;
  public autocomplete: any;

  constructor(
    public translateService: TranslateService,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    if (!window.google) {
      this.initMapsLoad();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.paisCodigo?.firstChange) {
      this.getPlaceAutocomplete();
    }

    if (typeof changes.disabled?.currentValue === 'boolean') {
      if(changes.disabled.currentValue) {
        this.autocompleteInput.disable();
      } else {
        this.autocompleteInput.enable();
      }
    }
  }

  getLabelToShow = () =>
    this.label || this.translateService.instant('contact.find_address') 

  initMapsLoad() {
    this.mapsLoaded = this.httpClient
    .jsonp(
      `https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.googleKey}`,
      'callback'
    )
    .pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  initAutocomplete() {
    if (!this.isAutoCompleteInit) {
      this.getPlaceAutocomplete();
      this.isAutoCompleteInit = true;
    }
  }

  getPlaceAutocomplete() {
    const country = this.paisCodigo ? this.paisCodigo : 'CL';
    this.autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        componentRestrictions: { country },
        types: [this.adressType]
      }
    );
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.autocompleteInput.setValue('');
      this.place = this.autocomplete.getPlace();
      this.invokeEvent();
    });
  }

  invokeEvent() {
    this.prepareObject();
    this.setAddress.emit(this.adressEmit);
  }

  prepareObject() {
    const direccion = this.streetNumber ? this.street + ' ' + this.streetNumber : this.street;
    this.adressEmit = {
      pais: this.country,
      region: this.state,
      ciudad: this.city || this.district,
      comuna: this.comuna,
      direccion,
      latitud: this.place.geometry.location.lat(),
      longitud: this.place.geometry.location.lng(),
      direccionFormated: this.place.formatted_address,
      zone: this.zone
    };
  }

  getAddrComponent(componentTemplate) {
    let result;
    for (let i = 0; i < this.place.address_components.length; i++) {
      const addressType = this.place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = this.place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  get streetNumber(): string {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' };
    const streetNumber = this.getAddrComponent(COMPONENT_TEMPLATE);
    return streetNumber;
  }

  get street(): string {
    const COMPONENT_TEMPLATE = { route: 'long_name' };
    const street = this.getAddrComponent(COMPONENT_TEMPLATE);
    return street;
  }

  get city(): string {
    let COMPONENT_TEMPLATE: any = { locality: 'long_name' };
    if (this.state === 'Región Metropolitana') {
      COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' };
    } else if (this.country === 'Chile') {
      COMPONENT_TEMPLATE = { administrative_area_level_3: 'short_name' };
    }
    const city = this.getAddrComponent(COMPONENT_TEMPLATE);
    return city;
  }

  get state(): string {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'long_name' };
    const state = this.getAddrComponent(COMPONENT_TEMPLATE);
    return state === 'Bogotá' ? 'Cundinamarca' : state;
  }

  get district(): string {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'long_name' };
    const state = this.getAddrComponent(COMPONENT_TEMPLATE);
    return state;
  }

  get countryShort(): string {
    const COMPONENT_TEMPLATE = { country: 'short_name' };
    const countryShort = this.getAddrComponent(COMPONENT_TEMPLATE);
    return countryShort;
  }

  get country(): string {
    const COMPONENT_TEMPLATE = { country: 'long_name' };
    const country = this.getAddrComponent(COMPONENT_TEMPLATE);
    return country;
  }

  get comuna(): string {
    const COMPONENT_TEMPLATE = this.country === 'Colombia' ? { sublocality_level_1: 'short_name' } : { administrative_area_level_3: 'short_name' };
    const comuna = this.getAddrComponent(COMPONENT_TEMPLATE);
    return comuna;
  }

  get zone(): string {
    const COMPONENT_TEMPLATE = { neighborhood: 'short_name' };
    const zone = this.getAddrComponent(COMPONENT_TEMPLATE);
    return zone;
  }

  get isLoaded(): boolean {
    if (window.google) {
      return true;
    }
    return false;
  }

}
