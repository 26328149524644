import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContratoAnexoTipo } from '../models/contrato-anexo-tipo.model';

@Injectable({
    providedIn: 'root'
  })
  export class ContratoAnexoTipoAdapter implements Adapter<ContratoAnexoTipo> {
    adapt(item: any): ContratoAnexoTipo {
        const contratoAnexoTipo = new ContratoAnexoTipo();
        contratoAnexoTipo.id = item.attributes.id;
        contratoAnexoTipo.isActive = item.attributes.is_active;
        contratoAnexoTipo.nombre = item.attributes.nombre;
        return contratoAnexoTipo;
    }
  }
