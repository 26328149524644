export class Paginador {

    private _itemCurrentStart: number;
    private _itemCurrentEnd: number;
    private _itemTotal: number;
    private _pageCurrent: number;
    private _pageTotal: number;

    public get itemCurrentStart(): number {
        return this._itemCurrentStart;
    }
    public set itemCurrentStart(value: number) {
        this._itemCurrentStart = value;
    }
    public get itemCurrentEnd(): number {
        return this._itemCurrentEnd;
    }
    public set itemCurrentEnd(value: number) {
        this._itemCurrentEnd = value;
    }
    public get itemTotal(): number {
        return this._itemTotal;
    }
    public set itemTotal(value: number) {
        this._itemTotal = value;
    }
    public get pageCurrent(): number {
        return this._pageCurrent;
    }
    public set pageCurrent(value: number) {
        this._pageCurrent = value;
    }
    public get pageTotal(): number {
        return this._pageTotal;
    }
    public set pageTotal(value: number) {
        this._pageTotal = value;
    }

}
