import { Divisa } from './divisa.model';
import { Periodicidad } from './periodicidad.model';
import { MontoTipo } from './monto-tipo.model';
import {PropiedadConfiguracionCargoClasificacion} from './propiedad-configuracioncargo-clasificacion.model';
import {BaseModel} from './base.model';
import { PropiedadConfiguracionContabilidad } from './propiedad-configuracion-contabilidad.model';

export class PropiedadConfiguracionComercial {
    private _configuracionId: string;
    private _reajusteMontoTipoId: number;
    private _reajusteMonto: number;
    private _reajusteMontoDivisaId: string;
    private _reajustePeriodicidadId: number;
    private _reajustePeriodicidadValor: number;
    private _reajusteTipoId: number;
    private _retrasoMultaMonto: number;
    private _retrasoMultaMontoTipoId: number;
    private _retrasoCobroMultaLiqEnCurso: boolean;
    private _retrasoInteresMonto: number;
    private _retrasoInteresMontoTipoId: number;
    private _retrasoInteresPorDia: boolean;
    private _retrasoInteresNoAplicar: boolean;
    private _retrasoInteresPeriodicidadId: number;
    private _retrasoInteresPeriodicidadValor: number;
    private _retrasoInteresTipoId: number;
    private _contratoDiaGeneracion: number;
    private _contratoDiaCobro: number;
    private _contratoDiaCorteArriendo: number;
    private _contratoDiaCorteGastoComun: number;
    private _contratoDiaCorteServicios: number;
    private _contratoDiaPago: number;
    private _contratoAvisoTerminoDias: number;
    private _contratoFolioInicio: number;
    private _contratoDiaConversionDivisa: number;
    private _contratoEnvioManualEstadoCuenta: boolean;
    private _propiedadHasFirmaElectronica: boolean;
    private _contratoCobroPeriodicidadId: number;
    private _liquidacionCreacionEstadoId: number;
    private _divisaConversion: number;
    private _isRenovacionAutomatica?: boolean; // TODO
    private _cotizacionVigencia: number;
    private _reservaDivisaId: string;
    private _reservaMonto: number;

    private _cobroPeriodicidad: {id: number, nombre: string, formato: string}[];
    private _reajusteMontoTipo: MontoTipo;
    private _reajustePeriodicidad: Periodicidad;
    private _reajusteMontoDivisa: Divisa;
    private _retrasoMultaMontoTipo: MontoTipo;
    private _retrasoMultaMontoDivisa: Divisa;
    private _retrasoInteresMontoTipo: MontoTipo;
    private _retrasoInteresMontoDivisa: Divisa;
    private _retrasoInteresPeriodicidad: Periodicidad;
    private _retrasoInteresTipo: BaseModel;
    private _contabilidad: PropiedadConfiguracionContabilidad;
    private _reservaDivisa: Divisa;
    private _cargoClasificaciones: PropiedadConfiguracionCargoClasificacion[];
    private _divisasCargo: Divisa[];
    private _divisasCobro: Divisa[];
    private _reajusteTipos: BaseModel[];


    get configuracionId(): string {
        return this._configuracionId;
    }

    set configuracionId(value: string) {
        this._configuracionId = value;
    }

    get reajusteMontoTipoId(): number {
        return this._reajusteMontoTipoId;
    }

    set reajusteMontoTipoId(value: number) {
        this._reajusteMontoTipoId = value;
    }

    get reajusteMonto(): number {
        return this._reajusteMonto;
    }

    set reajusteMonto(value: number) {
        this._reajusteMonto = value;
    }

    get reajusteMontoDivisaId(): string {
        return this._reajusteMontoDivisaId;
    }

    set reajusteMontoDivisaId(value: string) {
        this._reajusteMontoDivisaId = value;
    }

    get reajustePeriodicidadId(): number {
        return this._reajustePeriodicidadId;
    }

    set reajustePeriodicidadId(value: number) {
        this._reajustePeriodicidadId = value;
    }

    get reajustePeriodicidadValor(): number {
        return this._reajustePeriodicidadValor;
    }

    set reajustePeriodicidadValor(value: number) {
        this._reajustePeriodicidadValor = value;
    }

    get reajusteTipoId(): number {
        return this._reajusteTipoId;
    }

    set reajusteTipoId(value: number) {
        this._reajusteTipoId = value;
    }

    get retrasoMultaMonto(): number {
        return this._retrasoMultaMonto;
    }

    set retrasoMultaMonto(value: number) {
        this._retrasoMultaMonto = value;
    }

    get retrasoMultaMontoTipoId(): number {
        return this._retrasoMultaMontoTipoId;
    }

    set retrasoMultaMontoTipoId(value: number) {
        this._retrasoMultaMontoTipoId = value;
    }

    get retrasoCobroMultaLiqEnCurso(): boolean {
        return this._retrasoCobroMultaLiqEnCurso;
    }

    set retrasoCobroMultaLiqEnCurso(value: boolean) {
        this._retrasoCobroMultaLiqEnCurso = value;
    }
    get retrasoInteresMonto(): number {
        return this._retrasoInteresMonto;
    }

    set retrasoInteresMonto(value: number) {
        this._retrasoInteresMonto = value;
    }

    get retrasoInteresMontoTipoId(): number {
        return this._retrasoInteresMontoTipoId;
    }

    set retrasoInteresMontoTipoId(value: number) {
        this._retrasoInteresMontoTipoId = value;
    }

    get retrasoInteresPorDia(): boolean {
        return this._retrasoInteresPorDia;
    }

    set retrasoInteresPorDia(value: boolean) {
        this._retrasoInteresPorDia = value;
    }

    get retrasoInteresNoAplicar(): boolean {
        return this._retrasoInteresNoAplicar;
    }

    set retrasoInteresNoAplicar(value: boolean) {
        this._retrasoInteresNoAplicar = value;
    }

    get retrasoInteresPeriodicidadId(): number {
        return this._retrasoInteresPeriodicidadId;
    }

    set retrasoInteresPeriodicidadId(value: number) {
        this._retrasoInteresPeriodicidadId = value;
    }

    get retrasoInteresTipoId(): number {
        return this._retrasoInteresTipoId;
    }

    set retrasoInteresTipoId(value: number) {
        this._retrasoInteresTipoId = value;
    }

    get retrasoInteresPeriodicidadValor(): number {
        return this._retrasoInteresPeriodicidadValor;
    }

    set retrasoInteresPeriodicidadValor(value: number) {
        this._retrasoInteresPeriodicidadValor = value;
    }

    get contratoDiaCobro(): number {
        return this._contratoDiaCobro;
    }

    set contratoDiaCobro(value: number) {
        this._contratoDiaCobro = value;
    }

    get contratoDiaGeneracion(): number {
        return this._contratoDiaGeneracion;
    }

    set contratoDiaGeneracion(value: number) {
        this._contratoDiaGeneracion = value;
    }

    get contratoDiaCorteArriendo(): number {
        return this._contratoDiaCorteArriendo;
    }

    set contratoDiaCorteArriendo(value: number) {
        this._contratoDiaCorteArriendo = value;
    }

    get contratoDiaCorteGastoComun(): number {
        return this._contratoDiaCorteGastoComun;
    }

    set contratoDiaCorteGastoComun(value: number) {
        this._contratoDiaCorteGastoComun = value;
    }

    get contratoDiaCorteServicios(): number {
        return this._contratoDiaCorteServicios;
    }

    set contratoDiaCorteServicios(value: number) {
        this._contratoDiaCorteServicios = value;
    }

    get contratoDiaPago(): number {
        return this._contratoDiaPago;
    }

    set contratoDiaPago(value: number) {
        this._contratoDiaPago = value;
    }

    get contratoAvisoTerminoDias(): number {
        return this._contratoAvisoTerminoDias;
    }

    set contratoAvisoTerminoDias(value: number) {
        this._contratoAvisoTerminoDias = value;
    }

    get contratoFolioInicio(): number {
        return this._contratoFolioInicio;
    }

    set contratoFolioInicio(value: number) {
        this._contratoFolioInicio = value;
    }

    get contratoDiaConversionDivisa(): number {
        return this._contratoDiaConversionDivisa;
    }

    set contratoDiaConversionDivisa(value: number) {
        this._contratoDiaConversionDivisa = value;
    }

    get contratoEnvioManualEstadoCuenta(): boolean {
        return this._contratoEnvioManualEstadoCuenta;
    }

    set contratoEnvioManualEstadoCuenta(value: boolean) {
        this._contratoEnvioManualEstadoCuenta = value;
    }

    get propiedadHasFirmaElectronica(): boolean {
        return this._propiedadHasFirmaElectronica;
    }

    set propiedadHasFirmaElectronica(value: boolean) {
        this._propiedadHasFirmaElectronica = value;
    }

    get contratoCobroPeriodicidadId(): number {
        return this._contratoCobroPeriodicidadId;
    }

    set contratoCobroPeriodicidadId(value: number) {
        this._contratoCobroPeriodicidadId = value;
    }

    get liquidacionCreacionEstadoId(): number {
        return this._liquidacionCreacionEstadoId;
    }

    set liquidacionCreacionEstadoId(value: number) {
        this._liquidacionCreacionEstadoId = value;
    }

    get cobroPeriodicidad(): { id: number; nombre: string; formato: string }[] {
        return this._cobroPeriodicidad;
    }

    set cobroPeriodicidad(value: { id: number; nombre: string; formato: string }[]) {
        this._cobroPeriodicidad = value;
    }

    get divisaConversion(): number {
        return this._divisaConversion;
    }

    set divisaConversion(value: number) {
        this._divisaConversion = value;
    }

    get isRenovacionAutomatica(): boolean {
        return this._isRenovacionAutomatica;
    }

    set isRenovacionAutomatica(value: boolean) {
        this._isRenovacionAutomatica = value;
    }

    get cotizacionVigencia(): number {
        return this._cotizacionVigencia;
    }

    set cotizacionVigencia(value: number) {
        this._cotizacionVigencia = value;
    }

    get reservaDivisaId(): string {
        return this._reservaDivisaId;
    }

    set reservaDivisaId(value: string) {
        this._reservaDivisaId = value;
    }

    get reservaMonto(): number {
        return this._reservaMonto;
    }

    set reservaMonto(value: number) {
        this._reservaMonto = value;
    }

    get reajusteMontoTipo(): MontoTipo {
        return this._reajusteMontoTipo;
    }

    set reajusteMontoTipo(value: MontoTipo) {
        this._reajusteMontoTipo = value;
    }

    get reajustePeriodicidad(): Periodicidad {
        return this._reajustePeriodicidad;
    }

    set reajustePeriodicidad(value: Periodicidad) {
        this._reajustePeriodicidad = value;
    }

    get reajusteMontoDivisa(): Divisa {
        return this._reajusteMontoDivisa;
    }

    set reajusteMontoDivisa(value: Divisa) {
        this._reajusteMontoDivisa = value;
    }

    get retrasoMultaMontoTipo(): MontoTipo {
        return this._retrasoMultaMontoTipo;
    }

    set retrasoMultaMontoTipo(value: MontoTipo) {
        this._retrasoMultaMontoTipo = value;
    }

    get retrasoMultaMontoDivisa(): Divisa {
        return this._retrasoMultaMontoDivisa;
    }

    set retrasoMultaMontoDivisa(value: Divisa) {
        this._retrasoMultaMontoDivisa = value;
    }

    get retrasoInteresMontoTipo(): MontoTipo {
        return this._retrasoInteresMontoTipo;
    }

    set retrasoInteresMontoTipo(value: MontoTipo) {
        this._retrasoInteresMontoTipo = value;
    }

    get retrasoInteresMontoDivisa(): Divisa {
        return this._retrasoInteresMontoDivisa;
    }

    set retrasoInteresMontoDivisa(value: Divisa) {
        this._retrasoInteresMontoDivisa = value;
    }

    get retrasoInteresPeriodicidad(): Periodicidad {
        return this._retrasoInteresPeriodicidad;
    }

    set retrasoInteresPeriodicidad(value: Periodicidad) {
        this._retrasoInteresPeriodicidad = value;
    }

    get retrasoInteresTipo(): BaseModel {
        return this._retrasoInteresTipo;
    }

    set retrasoInteresTipo(value: BaseModel) {
        this._retrasoInteresTipo = value;
    }

    get contabilidad(): PropiedadConfiguracionContabilidad {
        return this._contabilidad;
    }

    set contabilidad(value: PropiedadConfiguracionContabilidad) {
        this._contabilidad = value;
    }

    get reservaDivisa(): Divisa {
        return this._reservaDivisa;
    }

    set reservaDivisa(value: Divisa) {
        this._reservaDivisa = value;
    }

    get cargoClasificaciones(): PropiedadConfiguracionCargoClasificacion[] {
        return this._cargoClasificaciones;
    }

    set cargoClasificaciones(value: PropiedadConfiguracionCargoClasificacion[]) {
        this._cargoClasificaciones = value;
    }

    get divisasCargo(): Divisa[] {
        return this._divisasCargo;
    }

    set divisasCargo(value: Divisa[]) {
        this._divisasCargo = value;
    }

    get divisasCobro(): Divisa[] {
        return this._divisasCobro;
    }

    set divisasCobro(value: Divisa[]) {
        this._divisasCobro = value;
    }

    get reajusteTipos(): BaseModel[] {
        return this._reajusteTipos;
    }

    set reajusteTipos(value: BaseModel[]) {
        this._reajusteTipos = value;
    }
}
