import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {CotizacionNota} from '../models/cotizacion-nota.model';

@Injectable({
  providedIn: 'root'
})
export class CotizacionNotaAdapter implements Adapter<CotizacionNota> {
  adapt(item: any): CotizacionNota {
    const cotizacionNota = new CotizacionNota();
    cotizacionNota.id = item.attributes.id;
    cotizacionNota.propiedadId = item.attributes.propiedad_id;
    cotizacionNota.nombre = item.attributes.nombre;
    cotizacionNota.texto = item.attributes.texto;
    cotizacionNota.isActivoInterno = item.attributes.is_activo_interno;
    cotizacionNota.isActivoWeb = item.attributes.is_activo_web;
    cotizacionNota.orden = item.attributes.orden;
    return cotizacionNota;
  }
}
