import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Divisa } from '../../models/divisa.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DivisaAdapter } from 'src/app/adapter/divisa.adapter';

@Injectable({
  providedIn: 'root'
})
export class DivisaService {

  constructor(
    public http: HttpClient,
    public divisaAdapter: DivisaAdapter
  ) { }

  cargarDivisas(): Observable <Divisa[]> {
    return this.http.get(environment.urlApi + 'divisas')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(divisas => this.divisaAdapter.adapt(divisas));
        })
      );
  }

  cargarValorDivisa(fecha: string, from: string, to: string) {
    return this.http.get(`${environment.urlApi}divisas/${fecha}/${from}/${to}`).pipe(
      map((resp: any) => {
        return resp.data.attributes;
      })
    );
  }
}
