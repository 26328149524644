import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ProbabilidadArriendo } from './../models/probabilidad-arriendo.model';

@Injectable({
    providedIn: 'root'
})

export class ProbabilidadArriendoAdapter implements Adapter<ProbabilidadArriendo> {
    adapt(item: any): ProbabilidadArriendo {
        const probabilidadArriendo = new ProbabilidadArriendo();
        probabilidadArriendo.id = item.attributes.id;
        probabilidadArriendo.nombre = item.attributes.nombre;
        return probabilidadArriendo;
    }
}
