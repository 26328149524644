import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {ContratoAnexoUnidadDetalle} from '../models/contrato-anexo-unidad-detalle.model';
import {ContratoAnexoUnidadAdapter} from './contrato-anexo-unidad.adapter';
import {ContratoAnexoUnidad} from '../models/contrato-anexo-unidad.model';

@Injectable({
  providedIn: 'root'
})
export class ContratoAnexoUnidadDetalleAdapter implements Adapter<ContratoAnexoUnidadDetalle> {
  constructor() {
  }
  adapt(item: any): ContratoAnexoUnidadDetalle {
    const contratoAnexoUnidadDetalle = new ContratoAnexoUnidadDetalle();
    contratoAnexoUnidadDetalle.id = item.attributes.id;
    contratoAnexoUnidadDetalle.cargoClasificacionId = item.attributes.cargo_clasificacion_id;
    contratoAnexoUnidadDetalle.cicloCantidad = item.attributes.ciclo_cantidad;
    contratoAnexoUnidadDetalle.cicloFecha = item.attributes.ciclo_fecha;
    contratoAnexoUnidadDetalle.cicloOpcion = item.attributes.ciclo_opcion;
    contratoAnexoUnidadDetalle.conceptoId = item.attributes.concepto_id;
    contratoAnexoUnidadDetalle.contratoAnexoUnidad = new ContratoAnexoUnidad();
    contratoAnexoUnidadDetalle.contratoAnexoUnidad.id = item.attributes.contrato_anexo_unidad.id;
    contratoAnexoUnidadDetalle.contratoAnexoUnidad.contratoAnexoId = item.attributes.contrato_anexo_unidad.contrato_anexo_id;
    contratoAnexoUnidadDetalle.contratoAnexoUnidad.unidadId = item.attributes.contrato_anexo_unidad.unidad_id;
    contratoAnexoUnidadDetalle.contratoAnexoUnidadId = item.attributes.contrato_anexo_unidad_id;
    contratoAnexoUnidadDetalle.cuentaRecaudadoraId = item.attributes.cuenta_recaudadora_id;
    contratoAnexoUnidadDetalle.divisaId = item.attributes.divisa_id;
    contratoAnexoUnidadDetalle.fechaInicio = item.attributes.fecha_inicio;
    contratoAnexoUnidadDetalle.fechaTermino = item.attributes.fecha_termino;
    contratoAnexoUnidadDetalle.isAfectoImpuesto = item.attributes.is_afecto_impuesto;
    contratoAnexoUnidadDetalle.isFacturableInt = item.attributes.is_facturable_int;
    contratoAnexoUnidadDetalle.monto = item.attributes.monto;
    contratoAnexoUnidadDetalle.montoTipoId = item.attributes.monto_tipo_id;
    contratoAnexoUnidadDetalle.montoTotal = item.attributes.monto_total;
    contratoAnexoUnidadDetalle.nombre = item.attributes.nombre;
    return contratoAnexoUnidadDetalle;
  }
}
