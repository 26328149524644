import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {MontoTipo} from '../models/monto-tipo.model';

@Injectable({
    providedIn: 'root'
})

export class MontoTipoAdapter implements Adapter<MontoTipo> {
    adapt(item: any): MontoTipo {
        const montoTipo = new MontoTipo();
        montoTipo.id = item.attributes.id;
        montoTipo.nombre = item.attributes.nombre;
        montoTipo.simbolo = item.attributes.simbolo;
        return montoTipo;
    }
}
