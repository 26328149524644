import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {ContratoAnexoUnidadConfiguracion} from '../models/contrato-anexo-unidad-configuracion.model';
import {Utils} from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class ContratoAnexoUnidadConfiguracionAdapter implements Adapter<ContratoAnexoUnidadConfiguracion> {
  adapt(item: any): ContratoAnexoUnidadConfiguracion {
    const contratoAnexoUnidadConfiguracion = new ContratoAnexoUnidadConfiguracion();
    contratoAnexoUnidadConfiguracion.id = item.attributes.id;
    contratoAnexoUnidadConfiguracion.contratoAnexoUnidadId = item.attributes.contrato_anexo_unidad_id;
    contratoAnexoUnidadConfiguracion.reajusteCheck = item.attributes.reajuste_check;
    contratoAnexoUnidadConfiguracion.reajustePeriodicidadId = item.attributes.reajuste_periodicidad_id;
    contratoAnexoUnidadConfiguracion.reajustePeriodicidadValor = item.attributes.reajuste_periodicidad_valor;
    contratoAnexoUnidadConfiguracion.reajusteTipoId = item.attributes.reajuste_tipo_id;
    contratoAnexoUnidadConfiguracion.reajusteMontoTipo = item.attributes.reajuste_monto_tipo_id;
    contratoAnexoUnidadConfiguracion.reajusteValor = item.attributes.reajuste_valor;
    contratoAnexoUnidadConfiguracion.reajusteFecha = Utils.jsonDate(item.attributes.reajuste_fecha);
    return contratoAnexoUnidadConfiguracion;
  }
}
