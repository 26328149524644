import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Moment} from 'moment';
import {NegocioInterface, NegocioUnidadInterface} from '../../interfaces/negocio.interface';
import {Utils} from '../utils';
import {ToastrService} from 'ngx-toastr';
import {ConfirmacionesComponent} from '../confirmaciones/confirmaciones.component';
import {MatDialog} from "@angular/material/dialog";
import { ContratoUtils } from 'src/app/pages/contratos/shared/contrato-utils';
import { TranslateService } from '@ngx-translate/core';
import update from 'immutability-helper'
@Component({
  selector: 'app-selector-rango-fechas',
  templateUrl: './selector-rango-fechas.component.html',
  styleUrls: ['./selector-rango-fechas.component.css']
})
export class SelectorRangoFechasComponent implements OnInit {


  public formGroup: UntypedFormGroup;
  public datepicker: UntypedFormControl = new UntypedFormControl('');
  public maxDate: Date = new Date();
  @Input() negocioData: NegocioInterface
  @Input() type: string;
  @Input() rangoFechas: {startDate: Moment, endDate: Moment};
  @Output() setRangoEmitter = new EventEmitter()
  @Output() closeSidebarEmitter = new EventEmitter()
  constructor(
    public toastr: ToastrService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.rangoFechas) {
      this.datepicker.setValue(this.rangoFechas);
    }
  }

  initFormGroup() {
    const {fecha, fechaInicio, fechaFin} = this.negocioData
    const momentDate = fecha ? moment([fecha.year, fecha.month - 1, fecha.day]) : null;
    const momentDateInicio = fechaInicio ? moment([fechaInicio.year, fechaInicio.month - 1, fechaInicio.day]) : null;
    const momentDateFin = fechaFin ? moment([fechaFin.year, fechaFin.month - 1, fechaFin.day]) : null;
    const fechaForm = momentDate ? new Date(momentDate.toISOString()) : new Date();
    const fechaInicioForm = momentDateInicio ? new Date(momentDateInicio.toISOString()) : null;
    const fechaFinForm = momentDateFin ? new Date(momentDateFin.toISOString()) : null;
    this.formGroup = new UntypedFormGroup({
      fecha: new UntypedFormControl(fechaForm),
      fechaInicio: new UntypedFormControl(fechaInicioForm, Validators.required),
      fechaFin: new UntypedFormControl(fechaFinForm, Validators.required)
    })
  }

  get minFechaInicio() {
    const {fecha} = this.formGroup.value
    if (this.type === 'Cotizacion') {
      return fecha;
    } else {
      return null;
    }
  }

  get minFechaFin() {
    const {fechaInicio} = this.formGroup.value;
    return fechaInicio ? fechaInicio : null;
  }

  hasUnidades() {
    const unidades = this.negocioData.negocioUnidades.length;
    return unidades > 0;
  }

  save(updateCurrentUnits = false) {
    if (!this.hasUnidades() || updateCurrentUnits) {
      const {fecha, fechaInicio, fechaFin} = this.formGroup.controls;
      if (fecha.value && fechaInicio.value && fechaFin.value) {
        this.negocioData.fecha = Utils.jsonDate(fecha.value);
        this.negocioData.fechaInicio = Utils.jsonDate(fechaInicio.value);
        this.negocioData.fechaFin = Utils.jsonDate(fechaFin.value);
        if (updateCurrentUnits) {
          const cicloCantidad = ContratoUtils.monthDiff(this.negocioData.fechaInicio, this.negocioData.fechaFin);
          const cicloFecha = this.negocioData.fechaInicio
          this.negocioData.negocioUnidades = update(this.negocioData.negocioUnidades, {
            $apply: x =>
              x.map(e => ({
                ...e,
                configuracion: ContratoUtils.generateUnidadConfiguracion([{ conceptoId: 1 }] as any, e.configuracion.id),
                negocioUnidadDetalles: e.negocioUnidadDetalles
                  .filter(f => f.conceptoId === 1 || f.conceptoId === 2)
                  .map(f => ({ ...f, cicloCantidad, cicloFecha }))
              }))
          })
        }
        this.closeSidebarEmitter.emit();
      } else {
        this.toastr.error('Debe ingresar todas las fechas solicitadas');
      }
    } else {
      this.openModalConfirmacion();
    }
  }

  openModalConfirmacion() {
    const modalConfirmacion = this.dialog.open(
      ConfirmacionesComponent,
      {
        data: {
          titulo: this.translate.instant('contract.change-dates-warning-title'),
          contenido: this.translate.instant('contract.change-dates-warning-body')
        },
        width: '55vw'
      }
    );
    modalConfirmacion.afterClosed().subscribe(result => {
      if (result) {
        this.save(true);
      }
    });
  }

  get hasFechaInicio() {
    const { fechaInicio } = this.formGroup.controls
    return !!fechaInicio.value;
  }

  get disableButton() {
    const {fecha, fechaInicio, fechaFin} = this.formGroup.controls;
    return !fecha.value || !fechaInicio.value || !fechaFin.value;
  }

  setFechaFinByOption(option: string) {
    const { fechaInicio } = this.formGroup.controls;
    let fechaFin = moment();
    switch (option) {
      case 'mensual':
        fechaFin = moment(fechaInicio.value).add(1, 'month')
        break;
      case 'trimestral':
        fechaFin = moment(fechaInicio.value).add(3, 'months')
        break;
      case 'semestral':
        fechaFin = moment(fechaInicio.value).add(6, 'months')
        break;
      case 'anual':
        fechaFin = moment(fechaInicio.value).add(1, 'year')
        break;
    }
    this.formGroup.patchValue({fechaFin: new Date(fechaFin.toISOString())});
  }
}
