import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalService } from 'src/app//services/service.index';
import { AbilityService } from 'src/app/services/ability/ability.service';

@Component({
  selector: 'app-menu-cliente',
  templateUrl: './menu-cliente.component.html',
  styles: []
})
export class MenuClienteComponent implements OnInit {
  @Output() createContact = new EventEmitter()

  constructor(
    public _modalService: ModalService,
    public ability: AbilityService
  ) { }

  ngOnInit() {
  }

  get canCreateContacto(){
    return this.ability.hasAccion('ContactoCreate');
  }

  get canCreateEvaluacion(){
    return this.ability.hasAccion('ContactoEvaluacionCreate');
  }

  get canCreateContrato(){
    return this.ability.hasAccion('ContratoCreate');
  }

  get canCreateCotizacion(){
    return this.ability.hasAccion('CotizacionCreate');
  }

  get canCreateReserva(){
    return this.ability.hasAccion('ReservaCreate');
  }
}
