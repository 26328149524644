import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginador } from 'src/app/models/paginador.model';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import {Observable, throwError} from 'rxjs';
import { Utils } from 'src/app/shared/utils';
import {catchError, map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContratoAnexo } from 'src/app/models/contrato-anexo.model';
import { ContratoAnexoAdapter } from 'src/app/adapter/contrato-anexo.adapter';

@Injectable({
  providedIn: 'root'
})

export class ContratoAnexoService {

  public paginador: Paginador;
  public parametros: string;
  public cotizacion: any;

  constructor(
    public http: HttpClient,
    public contratoAnexoAdapter: ContratoAnexoAdapter,
    public paginadorAdapter: PaginadorAdapter,
  ) { }

  paginadorContrato() {
    return this.paginador;
  }

  cargarAnexos(page, filtro?): Observable <ContratoAnexo[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'anexo-contrato?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(contratos => this.contratoAnexoAdapter.adapt(contratos));
        })
      );
  }

  downloadFile = (filter?) => {
    const filterToApply = Utils.generarParametrosBusqueda('contrato_anexo_filter', filter)
    return this.http.get(`${environment.urlApi}anexo-contrato/export?${filterToApply}`, {responseType: 'blob'});
  }

  cargarAnexo(contratoAnexoId: string): Observable <ContratoAnexo> {
    return this.http.get(environment.urlApi + 'anexo-contrato/' + contratoAnexoId)
      .pipe(
        map( (resp: any) => this.contratoAnexoAdapter.adapt(resp.data) )
      );
  }

  crearAnexo(contratoId, contratoAnexo) {
    return this.http.post(environment.urlApi + 'contrato/' + contratoId + '/anexo/create', contratoAnexo);
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = '';
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('contrato_anexo_filter', filtro);
  }

  descargarDocumentoFirmado(contratoAnexoId: string): Observable<any> {
    return this.http.put(`${environment.urlApi}anexo-contrato/${contratoAnexoId}/descargar-documento-firmado`, '')
    .pipe(
      map((resp: any) => this.contratoAnexoAdapter.adapt(resp.data))
    );
  }

  adjuntosDownload(contratoAnexoId: string, adjuntoId: string): Observable<any> {
    return this.http.get(`${environment.urlApi}anexo-contrato/${contratoAnexoId}/adjuntos/${adjuntoId}/download`, {responseType: 'arraybuffer'})
    .pipe(
      map( (res: any) => {
        return new Blob([res], {type: 'application/pdf'});
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  adjuntos(contratoAnexoId: string, adjuntoId: string): Observable<any> {
    return this.http.get(`${environment.urlApi}anexo-contrato/${contratoAnexoId}/adjuntos/${adjuntoId}`, {responseType: 'arraybuffer'})
    .pipe(
      map( (res: any) => {
        return new Blob([res], {type: 'application/pdf'});
      })
    );
  }

  uploadDocumentoBase(contratoAnexoId: string, adjunto: any) {
    return this.http.put(environment.urlApi + 'anexo-contrato/' + contratoAnexoId + '/upload/documento', {adjunto});
  }

  uploadDocumentoFirmado(contratoAnexoId: string, adjunto: any) {
    return this.http.put(environment.urlApi + 'anexo-contrato/' + contratoAnexoId + '/upload/documento-firmado', {adjunto});
  }

  updateEstado(contratoAnexoId, updateData) {
    return this.http.patch(environment.urlApi + 'anexo-contrato/' + contratoAnexoId, updateData);
  }

  activarContratoAnexo(contratoAnexoId) {
    return this.http.get(environment.urlApi + 'anexo-contrato/' + contratoAnexoId + '/activar');
  }

  updateEstadoFirma(contratoAnexoId, updateData) {
    return this.http.patch(environment.urlApi + 'anexo-contrato/' + contratoAnexoId + '/firma', updateData);
  }

  firmar(contratoAnexoId: string) {
    return this.http.post(environment.urlApi + 'anexo-contrato/' + contratoAnexoId + '/firmar', '')
        .pipe(
            map( (resp: any) => this.contratoAnexoAdapter.adapt(resp.data) )
        );
  }

  estadoFirma(contratoAnexoId: string) {
    return this.http.get(environment.urlApi + 'anexo-contrato/' + contratoAnexoId + '/estado-firma');
  }

  enviarRecordatorioFirma(contratoAnexoId: string) {
    return this.http.post(environment.urlApi + 'anexo-contrato/' + contratoAnexoId + '/enviar-recordatorio', '');
  }

  validateAnexo(anexo, contratoId) {
    return this.http.post(environment.urlApi + 'contrato/' + contratoId + '/anexo/validate', anexo);
  }

  pdfPreview(anexo): Observable<any> {
    return this.http.post(environment.urlApi + 'anexo-contrato/pdf-preview', anexo, {responseType: 'arraybuffer'})
      .pipe(
        map( (res: any) => {
          return new Blob([res], {type: 'application/pdf'});
        })
      );
  }

  loadStates = (): Observable<any> => {
    return this.http.get(`${environment.urlApi}contrato-anexo-estado`)
    .pipe(
      map( (res: any) => {
        return res.data.map(e => ({ id: e.attributes.id, name: e.attributes.nombre }))
      })
    );
  }

  loadSignStates = (): Observable<any> => {
    return this.http.get(`${environment.urlApi}estados-firma`)
    .pipe(
      map( (res: any) => {
        return res.data.map(e => ({ id: e.attributes.id, name: e.attributes.nombre }))
      })
    );
  }
  cambiarFirmante(contratoAnexoId, updateData) {
    return this.http.put(`${environment.urlApi}anexo-contrato/${contratoAnexoId}/cambiar-firmante`, updateData);
  }

}
