import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Paginador, EstadoCuentaDetalle } from 'src/app/models/model.index';
import { Observable } from 'rxjs';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { Utils } from 'src/app/shared/utils';
import { EstadoCuentaDetalleAdapter } from 'src/app/adapter/estado-cuenta-detalle.adapter';

@Injectable({
  providedIn: 'root'
})
export class EstadoCuentaDetalleService {

  public orientaciones: {id: string, nombre: string}[];
  public paginador: Paginador;
  public parametros: any;

  constructor(
    public http: HttpClient,
    public paginadorAdapter: PaginadorAdapter,
    public estadoCuentaDetalleAdapter: EstadoCuentaDetalleAdapter
  ) {}

  paginadorEstadoCuentaDetalle() {
    return this.paginador;
  }

  cargarEstadoCuentaDetalles(page, filtro?): Observable <EstadoCuentaDetalle[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
        this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'estado_cuenta_detalle?' + this.parametros)
      .pipe(
        map( (resp: any) => {
            this.paginador = this.paginadorAdapter.adapt(resp.meta);
            return resp.data.map(estadoCuentaDetalle => this.estadoCuentaDetalleAdapter.adapt(estadoCuentaDetalle)
            );
        })
      );
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = Utils.generarParametrosBusqueda('estado_cuenta_detalle_filter', filtro);
  }
}
