import { PaisAdapter } from './pais.adapter';
import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { Banco } from './../models/banco.model';

@Injectable({
    providedIn: 'root'
})

export class BancoAdapter implements Adapter<Banco> {

    constructor(
        public paisAdapter: PaisAdapter
    ) {}

    adapt(item: any): Banco {
        const banco = new Banco();
        banco.id = item.attributes.id;
        banco.nombre = item.attributes.nombre;
        banco.paisId = item.attributes.pais_id;

        if (item.pais) {
            banco.pais = this.paisAdapter.adapt(item.pais);
        }

        return banco;
    }

}
