import {
    Ciudad,
    EstadoCivil,
    IdentificadorTipo,
    MedioLlegada,
    Pais,
    ProbabilidadArriendo,
    User
} from 'src/app/models/model.index';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { ComentariosPop } from '../shared/comentarios/comentarios-pop.model';
import {PropietarioContactoInterface} from "../interfaces/propietario-contacto.interface";

export class Contacto {

    private _id: string;
    private _razonSocial: string;
    private _giro: string;
    private _nombre: string;
    private _apellido: string;
    private _email: string;
    private _telefono: string;
    private _identificadorTipoId: number;
    private _identificador: string;
    private _direccion: string;
    private _direccion2: string;
    private _paisId: string;
    private _ciudadId: string;
    private _ciudadText: string;
    private _region: string;
    private _comuna: string;
    private _zipCode: string;
    private _medioLlegadaId: string;
    private _probabilidadArriendoId: number;
    private _paisIdNacionalidad: string;
    private _estadoCivilId: number;
    private _contactoTipoId: number;
    private _profesion: string;
    private _createdAt: string;
    private _createdBy: string;
    private _updatedAt: string;
    private _updatedBy: string;
    private _pais: Pais;
    private _ciudad: Ciudad;
    private _medioLlegada: MedioLlegada;
    private _probabilidadArriendo: ProbabilidadArriendo;
    private _nacionalidad: Pais;
    private _estadoCivil: EstadoCivil;
    private _identificadorTipo: IdentificadorTipo;
    private _esFumador: boolean
    private _fechaNacimiento: Date
    private _generoId: number
    private _ocupacion: string;
    private _accesoAPopHome: boolean;
    private _comentarios: ComentariosPop[];
    private _propietarioContactoId: string;
    private _propietarioContacto: PropietarioContactoInterface;
    private _cuentaBanco: {
        titular: string
        identificador: string
        banco: string
        tipoCuenta: string
        numero: string
        cuentaId: string
    }
    private _tieneMascota: boolean;
    private _rentaLiquida: number;
    private _usuarioCreador: User;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get razonSocial(): string {
        return this._razonSocial;
    }
    public set razonSocial(value: string) {
        this._razonSocial = value;
    }
    public get giro(): string {
        return this._giro;
    }
    public set giro(value: string) {
        this._giro = value;
    }
    public get nombre(): string {
        return new TitleCasePipe().transform(this._nombre);
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get apellido(): string {
        return new TitleCasePipe().transform(this._apellido);
    }
    public set apellido(value: string) {
        this._apellido = value;
    }
    public get email(): string {
        return this._email;
    }
    public set email(value: string) {
        this._email = value;
    }
    public get telefono(): string {
        return this._telefono;
    }
    public set telefono(value: string) {
        this._telefono = value;
    }
    public get identificadorTipoId(): number {
        return this._identificadorTipoId;
    }
    public set identificadorTipoId(value: number) {
        this._identificadorTipoId = value;
    }
    public get identificador(): string {
        return this._identificador;
    }
    public set identificador(value: string) {
        this._identificador = value;
    }
    public get direccion(): string {
        return this._direccion;
    }
    public set direccion(value: string) {
        this._direccion = value;
    }
    public get direccion2(): string {
        return this._direccion2;
    }
    public set direccion2(value: string) {
        this._direccion2 = value;
    }
    public get paisId(): string {
        return this._paisId;
    }
    public set paisId(value: string) {
        this._paisId = value;
    }
    public get ciudadId(): string {
        return this._ciudadId;
    }
    public set ciudadId(value: string) {
        this._ciudadId = value;
    }
    public get ciudadText(): string {
        return this._ciudadText;
    }
    public set ciudadText(value: string) {
        this._ciudadText = value;
    }
    public get region(): string {
        return this._region;
    }
    public set region(value: string) {
        this._region = value;
    }
    public get comuna(): string {
        return this._comuna;
    }
    public set comuna(value: string) {
        this._comuna = value;
    }
    public get zipCode(): string {
        return this._zipCode;
    }
    public set zipCode(value: string) {
        this._zipCode = value;
    }
    public get medioLlegadaId(): string {
        return this._medioLlegadaId;
    }
    public set medioLlegadaId(value: string) {
        this._medioLlegadaId = value;
    }
    public get probabilidadArriendoId(): number {
        return this._probabilidadArriendoId;
    }
    public set probabilidadArriendoId(value: number) {
        this._probabilidadArriendoId = value;
    }
    public get paisIdNacionalidad(): string {
        return this._paisIdNacionalidad;
    }
    public set paisIdNacionalidad(value: string) {
        this._paisIdNacionalidad = value;
    }
    public get estadoCivilId(): number {
        return this._estadoCivilId;
    }
    public set estadoCivilId(value: number) {
        this._estadoCivilId = value;
    }
    public get profesion(): string {
        return this._profesion;
    }
    public set profesion(value: string) {
        this._profesion = value;
    }
    public get contactoTipoId(): number {
        return this._contactoTipoId;
    }
    public set contactoTipoId(value: number) {
        this._contactoTipoId = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    public get updatedAt(): string {
        return this._updatedAt;
    }
    public set updatedAt(value: string) {
        this._updatedAt = value;
    }
    public get updatedBy(): string {
        return this._updatedBy;
    }
    public set updatedBy(value: string) {
        this._updatedBy = value;
    }


    public get pais(): Pais {
        return this._pais;
    }
    public set pais(value: Pais) {
        this._pais = value;
    }
    public get ciudad(): Ciudad {
        return this._ciudad;
    }
    public set ciudad(value: Ciudad) {
        this._ciudad = value;
    }
    public get medioLlegada(): MedioLlegada {
        return this._medioLlegada;
    }
    public set medioLlegada(value: MedioLlegada) {
        this._medioLlegada = value;
    }
    public get probabilidadArriendo(): ProbabilidadArriendo {
        return this._probabilidadArriendo;
    }
    public set probabilidadArriendo(value: ProbabilidadArriendo) {
        this._probabilidadArriendo = value;
    }
    public get nacionalidad(): Pais {
        return this._nacionalidad;
    }
    public set nacionalidad(value: Pais) {
        this._nacionalidad = value;
    }
    public get estadoCivil(): EstadoCivil {
        return this._estadoCivil;
    }
    public set estadoCivil(value: EstadoCivil) {
        this._estadoCivil = value;
    }
    public get identificadorTipo(): IdentificadorTipo {
        return this._identificadorTipo;
    }
    public set identificadorTipo(value: IdentificadorTipo) {
        this._identificadorTipo = value;
    }

    public get nombreCompleto(): string {
        if (this.contactoTipoId === 1) {
            const nombre = this._nombre ? this._nombre : '';
            const apellido = this._apellido ? this._apellido : '';
            return new TitleCasePipe().transform(nombre + ' ' + apellido);
        } else {
            return this._razonSocial;
        }
    }

    public get inicialesNombre(): string {
        if (this.contactoTipoId === 1) {
            return new UpperCasePipe().transform(this._nombre.substr(0, 1) + '' + this._apellido.substr(0, 1));
        } else {
            return new UpperCasePipe().transform(this._razonSocial.substr(0, 1));
        }
    }

    public get esFumador() {
        return this._esFumador;
    }
    public set esFumador(value: boolean) {
        this._esFumador = value;
    }
    public get fechaNacimiento() {
        return this._fechaNacimiento;
    }
    public set fechaNacimiento(value: Date) {
        this._fechaNacimiento = value;
    }
    public get generoId() {
        return this._generoId;
    }
    public set generoId(value: number) {
        this._generoId = value;
    }
    public get ocupacion() {
        return this._ocupacion;
    }
    public set ocupacion(value: string) {
        this._ocupacion = value;
    }
    public get accesoAPopHome() {
        return this._accesoAPopHome;
    }
    public set accesoAPopHome(value: boolean) {
        this._accesoAPopHome = value;
    }
    public get comentarios() {
        return this._comentarios;
    }
    public set comentarios(value: ComentariosPop[]) {
        this._comentarios = value;
    }

    get propietarioContactoId(): string {
        return this._propietarioContactoId;
    }

    set propietarioContactoId(value: string) {
        this._propietarioContactoId = value;
    }

    get propietarioContacto(): PropietarioContactoInterface {
        return this._propietarioContacto;
    }

    set propietarioContacto(value: PropietarioContactoInterface) {
        this._propietarioContacto = value;
    }

    public get cuentaBanco() {
        return this._cuentaBanco;
    }
    public set cuentaBanco(value: {
        titular: string
        identificador: string
        banco: string
        tipoCuenta: string
        numero: string
        cuentaId: string
    }) {
        this._cuentaBanco = value;
    }

    get tieneMascota(): boolean {
        return this._tieneMascota;
    }

    set tieneMascota(value: boolean) {
        this._tieneMascota = value;
    }

    get rentaLiquida(): number {
        return this._rentaLiquida;
    }

    set rentaLiquida(value: number) {
        this._rentaLiquida = value;
    }

    get usuarioCreador(): User {
        return this._usuarioCreador;
    }

    set usuarioCreador(value: User) {
        this._usuarioCreador = value;
    }

    public toJson() {
        return {
            id: this._id,
            email: this._email,
            nombreCompleto: this.nombreCompleto
        };

    }
}

