import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { GoogleRecaptchaService } from 'src/app/services/google-recaptcha.service';
import { environment } from 'src/environments/environment';

// tslint:disable-next-line: ban-types
declare var grecaptcha: any;

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[recaptcha]'
})

export class GoogleRecaptchaDirective implements OnInit, OnDestroy{
  private isReady: boolean = false;

  @Input() recaptchaForm: NgForm;

  constructor(
    public googleRecaptchaService: GoogleRecaptchaService
  ) { }

  @HostListener('click', ['$event']) onClick(event: any) {
    event.preventDefault()
    event.stopPropagation()
    if(!this.isReady){
      grecaptcha.ready(() => {
        this.isReady = true;
        this.makeToken();    
      });
    }else{
      this.makeToken();   
    }
    
  }

  makeToken(){
    grecaptcha.execute(environment.googleRecaptchaSiteKey, { action: 'login' }).then((token) => {
      this.recaptchaForm.form.setControl('gToken', new UntypedFormControl(token))
      this.recaptchaForm.ngSubmit.emit()
    });
  }

  ngOnInit() {
    this.googleRecaptchaService.initRecaptcha()
  }

  ngOnDestroy(): void {
    this.googleRecaptchaService.hideRecaptcha()
  }

}
