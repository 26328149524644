import { Adjunto } from './adjunto.model';

export class ContactoEvaluacionDetalle {
    private _createdAt: string;
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    private _createdBy: string;
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    private _id: string;
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    private _contactoEvaluacionId: string;
    public get contactoEvaluacionId(): string {
        return this._contactoEvaluacionId;
    }
    public set contactoEvaluacionId(value: string) {
        this._contactoEvaluacionId = value;
    }
    private _nombre: string;
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    
    private _isValido: boolean;
    public get isValido(): boolean {
        return this._isValido;
    }
    public set isValido(value: boolean) {
        this._isValido = value;
    }
    private _adjuntos: Adjunto[];
    public get adjuntos(): Adjunto[] {
        return this._adjuntos;
    }
    public set adjuntos(value: Adjunto[]) {
        this._adjuntos = value;
    }
    private _observacion: string;
    public get observacion(): string {
        return this._observacion;
    }
    public set observacion(value: string) {
        this._observacion = value;
    }
}
