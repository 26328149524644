import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../services/globals.service';
import { AuthService } from '../services/service.index';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: ['']
})
export class PagesComponent implements OnInit {
  showApp: boolean = false
  constructor(
    public globalsService: GlobalsService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.checkStorage()
  }

  checkStorage = async () => {
    const allInformationInGlobal = await this.globalsService.getStorageGlobals() as any
    if (!allInformationInGlobal || Object.values(allInformationInGlobal).some((e: any[]) => e.length === 0) || !localStorage.getItem('identity')){
      this.authService.logout();
      this.authService.clearExpiredSetting()
    } else {
      this.showApp = true
    }
  }
}
