import { PropiedadAdapter } from 'src/app/adapter/propiedad.adapter';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Unidad } from '../models/unidad.model';
import { UnidadTipoAdapter } from './unidad-tipo.adapter';
import {UnidadPropietarioAdapter} from './unidad-propietario.adapter';
import {UnidadDivisaMontoAdapter} from './unidad-divisa-monto.adapter';
import {UnidadFirmanteAdapter} from './unidad-firmante.adapter';
import {UnidadContratoAdapter} from './unidad-contrato.adapter';
import {UnidadContratoAnexoAdapter} from './unidad-contrato-anexo.adapter';

const getNumbersWithoutExtraZeros = (number) => {
    if (number) {
      return Number(number)
    }

    return number
}

@Injectable({
    providedIn: 'root'
})
export class UnidadAdapter implements Adapter<Unidad> {
    static DEFAULT_DIVISA = "7f377688-5b8a-49a2-a0f7-6bedd68fa900"; // CLP

    constructor(
      public unidadTipoAdapter: UnidadTipoAdapter,
      public propiedadAdapter: PropiedadAdapter,
      public unidadContratoAdapter: UnidadContratoAdapter,
      public anexoUnidadAdapter: UnidadContratoAnexoAdapter,
      public unidadPropietarioAdapter: UnidadPropietarioAdapter,
      public unidadDivisaMontoAdapter: UnidadDivisaMontoAdapter,
      public unidadFirmanteAdapter: UnidadFirmanteAdapter
    ) {}

    adapt(item: any): Unidad {
        const unidad = new Unidad();
        unidad.id = item.attributes.id;
        unidad.nombre = item.attributes.nombre;
        unidad.rol = item.attributes.rol;
        unidad.tipologia = item.attributes.tipologia;
        unidad.tipologiaEspecial = item.attributes.tipologia_especial;
        unidad.orientacion = item.attributes.orientacion;
        unidad.piso = getNumbersWithoutExtraZeros(item.attributes.piso);
        unidad.pisoCantidad = item.attributes.piso_cantidad;
        unidad.dormitorioCantidad = getNumbersWithoutExtraZeros(item.attributes.dormitorio_cantidad);
        unidad.dormitorioServicioCantidad = getNumbersWithoutExtraZeros(item.attributes.dormitorio_servicio_cantidad);
        unidad.camasCantidad = getNumbersWithoutExtraZeros(item.attributes.camas_cantidad);
        unidad.serviciosHigenicosCantidad = getNumbersWithoutExtraZeros(item.attributes.servicios_higenicos_cantidad);
        unidad.serviciosHigenicosServicioCantidad = getNumbersWithoutExtraZeros(item.attributes.servicios_higenicos_servicio_cantidad);
        unidad.superficieMunicipio = getNumbersWithoutExtraZeros(item.attributes.superficie_municipio);
        unidad.superficieUtil = getNumbersWithoutExtraZeros(item.attributes.superficie_util);
        unidad.superficieTerraza = getNumbersWithoutExtraZeros(item.attributes.superficie_terraza);
        unidad.superficieTotal = getNumbersWithoutExtraZeros(item.attributes.superficie_total);
        unidad.fechaRecepcionMunicipio = item.attributes.fecha_recepcion_municipio;
        unidad.fechaCompromisoEntrega = item.attributes.fecha_compromiso_entrega;
        unidad.avaluoFiscal = getNumbersWithoutExtraZeros(item.attributes.avaluo_fiscal);
        unidad.isAmoblado = item.attributes.is_amoblado;
        unidad.isAfectoImpuesto = item.attributes.is_afecto_impuesto;
        unidad.prorrateo = getNumbersWithoutExtraZeros(item.attributes.prorrateo);
        unidad.precioLista = getNumbersWithoutExtraZeros(item.attributes.precio_lista);
        unidad.precioTerreno = getNumbersWithoutExtraZeros(item.attributes.precio_terreno);
        unidad.precioBase = getNumbersWithoutExtraZeros(item.attributes.precio_base);
        unidad.precioEvaluacion = getNumbersWithoutExtraZeros(item.attributes.precio_evaluacion);
        unidad.precioGastoComun = item.attributes.precio_gasto_comun ? getNumbersWithoutExtraZeros(item.attributes.precio_gasto_comun) : 0;
        unidad.descripcion = item.attributes.descripcion;
        unidad.codigoErp = item.attributes.codigo_erp;
        unidad.createdAt = item.attributes.created_at;
        unidad.createdBy = item.attributes.created_by;
        unidad.updatedAt = item.attributes.updated_at;
        unidad.updatedBy = item.attributes.updated_by;
        unidad.propiedadId = item.attributes.propiedad_id;
        unidad.unidadTipoId = item.attributes.unidad_tipo_id;
        unidad.unidadEstadoId = item.attributes.unidad_estado_id;
        unidad.unidadCobroId = item.attributes.unidad_cobro_id;
        unidad.modelo = item.attributes.modelo;
        unidad.superficiePonderada = getNumbersWithoutExtraZeros(item.attributes.superficie_ponderada)
        unidad.centroCostos = item.attributes.centro_costos
        unidad.grupo = item.attributes.grupo
        unidad.superficieTerreno = getNumbersWithoutExtraZeros(item.attributes.superficie_terreno)
        unidad.garajes = item.attributes.garajes;
        unidad.aceptaMascotas = item.attributes.acepta_mascota;
        unidad.mostrarEnCotizadorWeb = item.attributes.mostrar_en_cotizador_web

        /* Includes*/
        if (item.included) {
            item.included.filter((valor) => {
                if (valor.type === 'Propiedad') {
                    unidad.propiedad = this.propiedadAdapter.adapt(valor);
                } else if (valor.type === 'UnidadTipo') {
                    unidad.unidadTipo = this.unidadTipoAdapter.adapt(valor);
                } else if (valor.type === 'UnidadCobro') {
                    unidad.unidadCobro = valor.attributes;
                } else if (valor.type === 'UnidadEstado') {
                    unidad.unidadEstado = valor.attributes;
                } else if (valor.type === 'UnidadSubEstado') {
                    unidad.unidadSubEstado = valor.attributes;
                }
            });
        }

        /* Relationship */
        if (item.relationship && item.relationship.imagenes) {
            unidad.imagenes = item.relationship.imagenes.data;
        }
        if (item.relationship?.unidadAdjunto?.data) {
            unidad.adjuntos = item.relationship.unidadAdjunto.data.map(e => ({
                ...e.attributes,
                ...(e.included?.length ? { ...e.included[0].attributes } : {}),
                editarId: e.id
            }));
        }
        if (item.relationship && item.relationship.propietarios && item.relationship.propietarios.data) {
            unidad.propietarios = item.relationship.propietarios.data.map(unidadPropietario => this.unidadPropietarioAdapter.adapt(unidadPropietario));
        }
        if (item.relationship && item.relationship.firmantes && item.relationship.firmantes.data) {
            unidad.firmantes = item.relationship.firmantes.data.map(unidadFirmante => this.unidadFirmanteAdapter.adapt(unidadFirmante));
        }
        if (item.relationship && item.relationship.precios && item.relationship.precios.data) {
            unidad.divisas = item.relationship.precios.data.map(precio => this.unidadDivisaMontoAdapter.adapt(precio));
        }

        // Validamos que existan divisas para arriendo
        if(unidad.divisas.filter((divisa)=>divisa.conceptoId==1 && divisa.tipoId==1).length===0){
            unidad.divisas.push(this.unidadDivisaMontoAdapter.adapt({
                attributes: {
                    "id": null,
                    "unidad_id": unidad.id,
                    "tipo_id": 1,
                    "divisa_id": UnidadAdapter.DEFAULT_DIVISA,
                    "concepto_id": 1,
                    "monto": "0"
                }
            }));
        }

        // Validamos que existan divisas para gasto comun
        if(unidad.divisas.filter((divisa)=>divisa.conceptoId==2 && divisa.tipoId==1).length===0){
            unidad.divisas.push(this.unidadDivisaMontoAdapter.adapt({
                attributes: {
                    "id": null,
                    "unidad_id": unidad.id,
                    "tipo_id": 1,
                    "divisa_id": UnidadAdapter.DEFAULT_DIVISA,
                    "concepto_id": 2,
                    "monto": "0"
                }
            }));
        }

        if (item.relationship?.portales?.data) {
            unidad.portales = item.relationship.portales.data.map(e => ({
                titulo: e.attributes.titulo,
                descripcion: e.attributes.descripcion,
                publicado: e.attributes.vinculado,
                imagenPrincipalPropiedad: e.attributes.imagen_principal_propiedad,
                imagenPrincipalUnidad: e.attributes.imagen_principal_unidad,
                imagenesNoPrincipalesPropiedad: e.attributes.imagenes_no_principales_propiedad,
                imagenesNoPrincipalesUnidad: e.attributes.imagenes_no_principales_unidad,
                portal: e.attributes.portal,
                link: e.attributes.link,
                estadoId: e.attributes.status
            }))
        } else {
            unidad.portales = []
        }
        
        if (item.relationship && item.relationship.contratosValidos && item.relationship.contratosValidos.data) {
            unidad.contratosValidos = item.relationship.contratosValidos.data
                .map(unidadContrato => this.unidadContratoAdapter.adapt(unidadContrato));
        }
        if (item.relationship && item.relationship.anexosValidos && item.relationship.anexosValidos.data) {
            unidad.anexosValidos = item.relationship.anexosValidos.data
                .map(anexoUnidad => this.anexoUnidadAdapter.adapt(anexoUnidad));
        }

        if (item.relationship?.cuentasServicio?.data) {
          unidad.cuentasServicio = item.relationship?.cuentasServicio.data.map(
            (e) => {
                return {
                    comentario: e.attributes.comentario,
                    empresaNombre: e.included.filter(e => e.type === 'EmpresaServicio')[0]?.attributes.nombre,
                    empresaServicioId: e.attributes.empresa_servicio_id,
                    empresaTipo: e.included.filter(e => e.type === 'EmpresaServicio')[0]?.attributes.tipo,
                    id: e.id,
                    numeroCliente: e.attributes.numero_cliente
                };
            }
          );
        } else {
          unidad.cuentasServicio = []
        }


        return unidad;
    }
}
