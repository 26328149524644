import { ContactoAdapter } from './../../adapter/contacto.adapter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contacto } from 'src/app/models/contacto.model';
import { Observable } from 'rxjs';
import { Utils } from 'src/app/shared/utils';
import { Paginador } from 'src/app/models/paginador.model';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {ContactoPayloadInterface} from "../../interfaces/contacto-payload.interface";

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  public pagina: string;
  public contacto: Contacto;
  public filterAttributes: any;
  public parametros: any;
  public paginador: Paginador;

  constructor(
    public http: HttpClient,
    public utils: Utils,
    public paginadorAdapter: PaginadorAdapter,
    public contactoAdapter: ContactoAdapter
  ) {
    this.filterAttributes = [
      'id', 'medioLlegada', 'estadoCivil', 'ciudad', 'pais', '_pais', 'nacionalidad', '_nacionalidad', 'createdAt', 'createdBy',
      'updatedAt', 'updatedBy', 'probabilidadArriendo'
    ];
  }

  cargarContactosPorCotizaciones(page, filtro?): Observable <Contacto[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'contactos/cotizacion?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(contactos => this.contactoAdapter.adapt(contactos));
        })
      );
  }

  cargarContactosPorContratos(page, filtro?): Observable <Contacto[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'contactos/contrato?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(contactos => this.contactoAdapter.adapt(contactos));
        })
      );
  }

  cargarContactos(page?, filtro?): Observable <Contacto[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'contactos?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(contactos => this.contactoAdapter.adapt(contactos));
        })
      );
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = '';
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('contacto_filter', filtro);
  }

  crearContacto(contacto: Contacto): Observable <any> {
    this.contacto = contacto;
    return this.http.post(environment.urlApi + 'contactos', this.formatJson());
  }

  cargarContacto(id: string): Observable <any> {
    return this.http.get(environment.urlApi + 'contactos/' + id)
    .pipe(
      map( (resp: any) => this.contactoAdapter.adapt(resp.data))
    );
  }

  actualizarContacto(contacto: Contacto): Observable <any> {
    this.contacto = contacto;
    return this.http.put(environment.urlApi + 'contactos/' + contacto.id, this.formatJson());
  }

  actualizarPropietario(payload: ContactoPayloadInterface): Observable<any> {
    return this.http.put(environment.urlApi + 'contactos/' + payload.id, payload)
  }

  formatJson() {
    return Utils.toJson(this.contacto, this.filterAttributes);
  }

  paginadorContacto() {
    return this.paginador;
  }

  exportar(filtro) {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'contactos/export?' + this.parametros, {responseType: 'blob'});
  }

  searchContactos(param): Observable <Contacto[]> {
    if (!param) {
      return;
    }
    return this.http.get(environment.urlApi + 'contactos/search?query=' + param)
      .pipe(
        map( (resp: any) => {
          return resp.data.map(contactos => this.contactoAdapter.adapt(contactos));
        })
      );
  }
}
