<div class="file-list" *ngIf="files">
    <div class="single-file" *ngFor="let file of files; let i = index">
        <i class="fa fa-file-upload"></i>
        <div class="info">
            <h4 class="name">{{file?.name}}</h4>
            <p class="size">
                {{ formatBytes(file?.size) }}
            </p>
        </div>
        <a class="pointer" (click)="deleteFile(i)"><i class="fa fa-trash"></i></a>
    </div>
</div>
<div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)">
    <div class="d-flex justify-content-center align-items-center">
        <img class="logo-upload" src="assets/img/iconos/icono-file-upload.svg">
        <div class="d-inline-block">
            <label>Arrastre y suelte sus archivos aquí</label>
            <label>o</label>
            <label class="font-weight-bolder pointer" for="fileDropRef">Explore sus archivos</label>
            <label class="conditions-text">Tamaño máximo por archivo: 20 MB</label>
        </div>
    </div>
</div>
