import {Injectable} from "@angular/core";
import {Divisa} from "../models/divisa.model";
import {CargoClasificacion} from "../models/cargoClasificacion.model";
import {Concepto} from "../models/concepto.model";
import {Globals} from "../store/models/globals.model";
import {
  SetAll,
  SetCargosClasificaciones,
  SetConceptos,
  SetContratoContactoTipos,
  SetDivisas, SetIdentificadorTipos, SetReajusteTipos, SetUnidadEstados, SetUnidadTipos
} from "../store/actions/globals.action";
import {NegocioService} from "./negocio/negocio.service";
import {LocalService} from "./local.service";
import {select, Store} from "@ngrx/store";
import {AppState} from "../store/models/app.model";
import {ContratoContactoTipo} from "../models/contrato-contacto-tipo.model";
import {take} from "rxjs/operators";
import {IdentificadorTipo} from "../models/identificador-tipo.model";
import {UnidadEstado} from "../models/unidad-estado.model";
import {UnidadTipo} from "../models/unidad-tipo.model";
import {BaseModel} from "../models/base.model";

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  public periodicidadTipos = [
    {id: 1, lang_resource: 'peridicity.days'},
    {id: 2, lang_resource: 'peridicity.months'},
    {id: 3, lang_resource: 'peridicity.years'}
  ];
  public montoTipos = [
    {id: 1, lang_resource: 'expense_type.fixed_amount'},
    {id: 2, lang_resource: 'expense_type.percent_amount'}
  ];
  constructor(
    private localService: LocalService,
    private store: Store<AppState>,
    private negocioService: NegocioService
  ) {
    this.initGlobals();
  }

  initGlobals() {
    const globalsLocalStorage = {...this.getStorageGlobals()};
    const globalsStore = {...this.getGlobals()};
    if (!globalsStore && globalsLocalStorage) {
      this.store.dispatch(new SetAll(globalsLocalStorage));
    } else if (!globalsStore && !globalsLocalStorage) {
      this.cargarGlobals();
    }
  }

  async cargarGlobals() {
    const payload: Globals = {
      divisas: await this.negocioService.cargarDivisas(),
      cargoClasificaciones: await this.negocioService.cargarClasificaciones(),
      conceptos: await this.negocioService.cargarConceptos(),
      identificadorTipos: await this.negocioService.cargarIdentificadorTipos(),
      unidadEstados: await this.negocioService.cargarUnidadEstados(),
      unidadTipos: await this.negocioService.cargarUnidadTipos(),
      periodicidadTipos: this.periodicidadTipos,
      montoTipos: this.montoTipos,
      contratoContactoTipos: await this.negocioService.cargarContactoTipos(),
      reajusteTipos: await this.negocioService.cargarReajusteTipos()
    };
    this.localService.setJsonValue('globals', JSON.stringify(payload));
    this.store.dispatch(new SetAll(payload));
  }

  getStorageGlobals(): Globals {
    return JSON.parse(this.localService.getJsonValue('globals'));
  }

  async getGlobals(): Promise<Globals> {
    let globalsResp: Globals = {} as Globals;
    await this.store.pipe(select(state => state.globals), take(1)).subscribe((resp) =>  {
      if (resp) {
        globalsResp = resp;
      }
    })
    return globalsResp;
  }

  setGlobalsFromStorage() {
    const payload: Globals = this.getStorageGlobals();
    this.store.dispatch(new SetAll(payload));
  }

  updateLocalStorage() {
    this.store.pipe(select(state => state.globals), take(1)).subscribe(globals =>  {
      if (globals) {
        this.localService.setJsonValue('globals', JSON.stringify(globals));
      }
    })
  }

  async existInStore(): Promise<boolean> {
    let exist = false;
    await this.store.pipe(select(state => state.globals), take(1)).subscribe((globals) => {
      if (globals) {
        exist = true;
      }
    })
    return exist;
  }

  getDivisas = async (): Promise<Divisa[]> => {
    let divisas: Divisa[] = [];
    const globals: Globals = await this.getGlobals();
    if (globals && globals.divisas?.length > 0) {
      divisas = globals.divisas;
    } else if (this.getStorageGlobals().divisas?.length > 0) {
      divisas = this.getStorageGlobals().divisas;
      this.store.dispatch(new SetDivisas(divisas));
    } else {
      divisas = await this.negocioService.cargarDivisas();
      this.store.dispatch(new SetDivisas(divisas));
    }
    return divisas;
  }

  getCargosClasificaciones = async (): Promise<CargoClasificacion[]> => {
    let cargoClasificaciones: CargoClasificacion[] = [];
    const globals: Globals = await this.getGlobals();
    if (globals && globals.cargoClasificaciones?.length > 0) {
      cargoClasificaciones = globals.cargoClasificaciones;
    } else if (this.getStorageGlobals().divisas?.length > 0) {
      cargoClasificaciones = this.getStorageGlobals().cargoClasificaciones;
      this.store.dispatch(new SetCargosClasificaciones(cargoClasificaciones));
    } else {
      cargoClasificaciones = await this.negocioService.cargarClasificaciones();
      this.store.dispatch(new SetCargosClasificaciones(cargoClasificaciones));
    }
    return cargoClasificaciones;
  }

  getConceptos = async (): Promise<Concepto[]> => {
    let conceptos: Concepto[] = [];
    const globals: Globals = await this.getGlobals();
    if (globals && globals.conceptos?.length > 0) {
      conceptos = globals.conceptos;
    } else if (this.getStorageGlobals().conceptos?.length > 0) {
      conceptos = this.getStorageGlobals().conceptos;
      this.store.dispatch(new SetConceptos(conceptos));
    } else {
      conceptos = await this.negocioService.cargarConceptos();
      this.store.dispatch(new SetConceptos(conceptos));
    }
    return conceptos;
  }

  getContratoContactoTipos = async (): Promise<ContratoContactoTipo[]> => {
    let contratoContactoTipos: ContratoContactoTipo[] = [];
    const globals: Globals = await this.getGlobals();
    if (globals && globals.contratoContactoTipos?.length > 0) {
      contratoContactoTipos = globals.contratoContactoTipos;
    } else if (this.getStorageGlobals().contratoContactoTipos?.length > 0) {
      contratoContactoTipos = this.getStorageGlobals().contratoContactoTipos;
      this.store.dispatch(new SetContratoContactoTipos(contratoContactoTipos));
    } else {
      contratoContactoTipos = await this.negocioService.cargarContactoTipos();
      this.store.dispatch(new SetContratoContactoTipos(contratoContactoTipos));
    }
    return contratoContactoTipos;
  }

  getIdentificadorTipos = async (): Promise<IdentificadorTipo[]> => {
    let identificadorTipos: IdentificadorTipo[] = [];
    const globals: Globals = await this.getGlobals();
    if (globals && globals.identificadorTipos?.length > 0) {
      identificadorTipos = globals.identificadorTipos;
    } else if (this.getStorageGlobals().identificadorTipos?.length > 0) {
      identificadorTipos = this.getStorageGlobals().identificadorTipos;
      this.store.dispatch(new SetIdentificadorTipos(identificadorTipos));
    } else {
      identificadorTipos = await this.negocioService.cargarIdentificadorTipos();
      this.store.dispatch(new SetIdentificadorTipos(identificadorTipos));
    }
    return identificadorTipos;
  }

  getUnidadEstados = async (): Promise<UnidadEstado[]> => {
    let unidadEstados: UnidadEstado[] = [];
    const globals: Globals = await this.getGlobals();
    if (globals && globals.unidadEstados?.length > 0) {
      unidadEstados = globals.unidadEstados
    } else if (this.getStorageGlobals().unidadEstados?.length > 0) {
      unidadEstados = this.getStorageGlobals().unidadEstados;
      this.store.dispatch(new SetUnidadEstados(unidadEstados));
    } else {
      unidadEstados = await this.negocioService.cargarUnidadEstados();
      this.store.dispatch(new SetUnidadEstados(unidadEstados));
    }
    return unidadEstados;
  }

  getUnidadTipos = async (): Promise<UnidadTipo[]> => {
    let unidadTipos: UnidadTipo[] = [];
    const globals: Globals = await this.getGlobals();
    if (globals && globals.unidadTipos?.length > 0) {
      unidadTipos = globals.unidadTipos
    } else if (this.getStorageGlobals().unidadTipos?.length > 0) {
      unidadTipos = this.getStorageGlobals().unidadTipos;
      this.store.dispatch(new SetUnidadTipos(unidadTipos));
    } else {
      unidadTipos = await this.negocioService.cargarUnidadTipos();
      this.store.dispatch(new SetUnidadTipos(unidadTipos));
    }
    return unidadTipos;
  }

  getPeriodicidadTipos = async (): Promise<any[]> => {
    return this.periodicidadTipos;
  }

  getMontoTipos = async (): Promise<any[]> => {
    return this.montoTipos;
  }

  getReajusteTipos = async (): Promise<BaseModel[]> => {
    let reajusteTipos: BaseModel[] = [];
    const globals: Globals = await this.getGlobals();
    if (globals && globals.reajusteTipos?.length > 0) {
      reajusteTipos = globals.reajusteTipos;
    } else if (this.getStorageGlobals().reajusteTipos?.length > 0) {
      reajusteTipos = this.getStorageGlobals().reajusteTipos;
      this.store.dispatch(new SetReajusteTipos(reajusteTipos))
    } else {
      reajusteTipos = await this.negocioService.cargarReajusteTipos();
      this.store.dispatch(new SetReajusteTipos(reajusteTipos));
    }
    return reajusteTipos;
  }
}
