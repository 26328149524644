import { Adapter } from './adapter';
import { ContactoAdapter } from './contacto.adapter';
import { PropiedadAdapter } from './propiedad.adapter';
import { UnidadAdapter } from './unidad.adapter';
import { ContratoAdapter } from './contrato.adapter';
import { TransaccionConceptoAdapter } from './transaccion-concepto.adapter';
import { Injectable } from '@angular/core';
import { Transaccion } from '../models/transaccion.model';
import { TransaccionTipoAdapter } from './transaccion-tipo.adapter';
import { MedioPagoAdapter } from './medio-pago.adapter';
import { EstadoCuentaDetalleAdapter } from './estado-cuenta-detalle.adapter';
import { ConceptoAdapter } from './concepto.adapter';
import { CargoClasificacionAdapter } from './cargo-clasificacion.adapter';
import {ContratoContactoAdapter} from "./contrato-contacto.adapter";

@Injectable({
    providedIn: 'root'
})

export class TransaccionAdapter implements Adapter<Transaccion> {

    constructor(
        public contactoAdapter: ContactoAdapter,
        public propiedadAdapter: PropiedadAdapter,
        public unidadAdapter: UnidadAdapter,
        public contratoAdapter: ContratoAdapter,
        public transaccionConceptoAdapter: TransaccionConceptoAdapter,
        public transaccionTipoAdapter: TransaccionTipoAdapter,
        public medioPagoAdapter: MedioPagoAdapter,
        public estadoCuentaDetalleAdapter: EstadoCuentaDetalleAdapter,
        public conceptoAdapter: ConceptoAdapter,
        public cargoClasificacionAdapter: CargoClasificacionAdapter,
        public contratoContactoAdapter: ContratoContactoAdapter
    ) {}

    adapt(item: any): Transaccion {
        const transaccion = new Transaccion();
        transaccion.id = item.attributes.id;
        transaccion.contactoId = item.attributes.contacto_id;
        transaccion.contratoId = item.attributes.contrato_id;
        transaccion.estadoCuentaDetalleConceptoId = item.attributes.estado_cuenta_detalle_concepto_id;
        transaccion.estadoCuentaDetalleCargoClasificacionId = item.attributes.estado_cuenta_detalle_cargo_clasificacion_id;
        transaccion.estadoCuentaDetalleIsFacturableInt = item.attributes.estado_cuenta_detalle_is_facturable_int;
        transaccion.estadoCuentaDetalleId = item.attributes.estado_cuenta_detalle_id;
        transaccion.estadoCuentaPagoId = item.attributes.estado_cuenta_pago_id;
        transaccion.fecha = item.attributes.fecha;
        transaccion.medioPagoId = item.attributes.medio_pago_id;
        transaccion.monto = item.attributes.monto;
        transaccion.divisaId = item.attributes.divisa_id;
        transaccion.propiedadId = item.attributes.propiedad_id;
        transaccion.transaccionConceptoId = item.attributes.transaccion_concepto_id;
        transaccion.transaccionTipoId = item.attributes.transaccion_tipo_id;
        transaccion.unidadId = item.attributes.unidad_id;

        /* Includes*/
        if (item.included) {
            item.included.filter((valor) => {
                switch(valor.type) {
                    case 'Propiedad':
                        transaccion.propiedad = this.propiedadAdapter.adapt(valor);
                        break;
                    case 'Unidad':
                        transaccion.unidad = this.unidadAdapter.adapt(valor);
                        break;
                    case 'Contrato':
                        transaccion.contrato = this.contratoAdapter.adapt(valor);
                        break;
                    case 'TransaccionConcepto':
                        transaccion.transaccionConcepto = this.transaccionConceptoAdapter.adapt(valor);
                        break;
                    case 'TransaccionTipo':
                        transaccion.transaccionTipo = this.transaccionTipoAdapter.adapt(valor);
                        break;
                    case 'MedioPago':
                        transaccion.medioPago = this.medioPagoAdapter.adapt(valor);
                        break;
                    case 'EstadoCuentaDetalle':
                        transaccion.estadoCuentaDetalle = this.estadoCuentaDetalleAdapter.adapt(valor);
                        break;
                    case 'Concepto':
                        /* TODO Posible Error! */
                        transaccion.estadoCuentaDetalle.concepto = this.conceptoAdapter.adapt(valor);
                        break;
                    case 'CargoClasificacion':
                        /* TODO Posible Error! */
                        transaccion.estadoCuentaDetalle.cargoClasificacion = this.cargoClasificacionAdapter.adapt(valor);
                        break;
                    case 'Egreso':
                        transaccion.egresoId = valor.id
                        transaccion.egresoClasificacion = valor.attributes.clasificacion
                        break;
                    case 'EgresoAccion':
                        transaccion.egresoAccionNombre = valor.attributes.nombre
                        break;
                    case 'EgresoTipo':
                        transaccion.egresoTipoNombre = valor.attributes.lang_resource
                        break;
                }
            });
        }
        if (item.relationship && item.relationship.contratoContacto) {
            const negocioContacto = item.relationship.contratoContacto.data.map(data => this.contratoContactoAdapter.adapt(data));
            transaccion.contacto = negocioContacto.find(c => c.isTitular === true).contacto;
        }

        return transaccion;
    }
}
