import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BaseAdapter} from "../../adapter/base.adapter";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {BaseModel} from "../../models/base.model";

@Injectable({
  providedIn: 'root'
})
export class ReajusteTipoService {
  constructor(
    public http: HttpClient,
    public baseAdapter: BaseAdapter
  ) {
  }

  cargarReajusteTipos(): Observable<BaseModel[]> {
    return this.http.get(`${environment.urlApi}contrato-reajuste-tipos`)
      .pipe(
        map((resp: any) => {
          return resp.data.map(item => this.baseAdapter.adapt(item))
        })
      )
  }
}
