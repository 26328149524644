import {Injectable} from "@angular/core";
import {Adapter} from "./adapter";
import {Vehicle} from "../models/vehicle.model";

@Injectable({
  providedIn: 'root'
})
export class VehiculoAdapter implements Adapter<Vehicle>{
  adapt(item: any): Vehicle {
    return {
      isActive: item.attributes.is_active,
      model: item.attributes.modelo,
      observation: item.attributes.observacion,
      plate: item.attributes.placa,
      vehicleId: item.attributes.id,
      vehicleType: Number(item.attributes.vehiculo_tipo_id),
      vehicleTypeName: item.attributes.vehiculo_tipo.lang_resource,
      color: item.attributes.color,
      brand: item.attributes.marca
    }
  }
}
