import { IdentificadorTipoAdapter } from 'src/app/adapter/identificador-tipo.adapter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IdentificadorTipo } from 'src/app/models/identificador-tipo.model';

@Injectable({
  providedIn: 'root'
})
export class IdentificadorTipoService {

  constructor(
    public http: HttpClient,
    public identificadorTipoAdapter: IdentificadorTipoAdapter
  ) { }

  cargarTiposIdentificacion(): Observable<IdentificadorTipo[]> {
    return this.http.get(environment.urlApi + 'identificador_tipo')
    .pipe(
      map( (resp: any) =>
        resp.data.map( identificadorTipo => this.identificadorTipoAdapter.adapt(identificadorTipo))
      )
    );
  }

}
