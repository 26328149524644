import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.css']
})
export class TableLoaderComponent implements OnInit {
  @Input() rows = 10;
  @Input() cols = 5;

  constructor() { }

  ngOnInit(): void {
  }

  get rowsList(): number[] {
    return new Array(this.rows);
  }

  get colsList(): number[] {
    return new Array(this.cols);
  }

}
