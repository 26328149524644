import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { EstadoCuentaEstado } from '../models/model.index';

@Injectable({
  providedIn: 'root'
})

export class EstadoCuentaEstadoAdapter implements Adapter<EstadoCuentaEstado> {
  adapt(item: any): EstadoCuentaEstado {
    const estadoCuentaEstado = new EstadoCuentaEstado();
    estadoCuentaEstado.id = item.attributes.id;
    estadoCuentaEstado.nombre = item.attributes.nombre;
    estadoCuentaEstado.lang_resource = item.attributes.lang_resource;
    return estadoCuentaEstado;
  }
}
