import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContratoContactoTipoAdapter } from 'src/app/adapter/contrato-contacto-tipo.adapter';
import { ContratoContactoTipo } from 'src/app/models/model.index';

@Injectable({
  providedIn: 'root'
})
export class ContratoContactoTiposService {

  constructor(
    public http: HttpClient,
    public contratoContactoTipoAdapter: ContratoContactoTipoAdapter
  ) { }

  cargarTipos(): Observable <ContratoContactoTipo[]> {
    return this.http.get(environment.urlApi + 'contrato_contacto_tipos')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(tipos => this.contratoContactoTipoAdapter.adapt(tipos));
        })
      );
  }

}
