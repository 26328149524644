import {Adapter} from './adapter';
import {UnidadDivisaMonto} from '../models/unidad-divisa-monto.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnidadDivisaMontoAdapter implements Adapter<UnidadDivisaMonto> {

  adapt(item: any): UnidadDivisaMonto {
    const unidadDivisaMonto = new UnidadDivisaMonto();
    unidadDivisaMonto.id = item.attributes.id;
    unidadDivisaMonto.conceptoId = item.attributes.concepto_id;
    unidadDivisaMonto.divisaId = item.attributes.divisa_id;
    unidadDivisaMonto.monto = Number(item.attributes.monto);
    unidadDivisaMonto.tipoId = item.attributes.tipo_id;
    unidadDivisaMonto.unidadId = item.attributes.unidad_id;
    return unidadDivisaMonto;
  }
}
