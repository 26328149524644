import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { EstadoPagoEstado } from '../models/estado-pago-estado.model';

@Injectable({
  providedIn: 'root'
})

export class EstadoPagoEstadoAdapter implements Adapter<EstadoPagoEstado> {
  adapt(item: any): EstadoPagoEstado {
    const estadoCuentaEstado = new EstadoPagoEstado();
    estadoCuentaEstado.id = item.attributes.id;
    estadoCuentaEstado.nombre = item.attributes.nombre;
    return estadoCuentaEstado;
  }
}
