import {
    Banco,
    MedioPago,
    EstadoCuenta,
    EstadoCuentaDetalle,
    Contacto,
    Contrato,
    CuentaRecaudadora,
    EstadoPagoEstado
} from './model.index';
import { Adjunto } from './adjunto.model';
import { Reverso } from './reverso.model';
import {PasarelaPago} from './pasarela-pago.model';


export interface EjecutivoInterface {
    id: string;
}

export interface ContactoInterface {
    apellido: string;
    contactoTipoId: number;
    email: string;
    id: string;
    nombre: string;
}

export class EstadoCuentaPago {

    private _id: string;
    private _fecha: string;
    private _monto: number;
    private _medioPagoId: number;
    private _pasarelaPagoId: string;
    private _observacion: string;
    private _estadoCuentaId: string;
    private _cuentaRecaudadoraId: string;
    private _numero: string;
    private _estadoPagoEstadoId: number;
    private _bancoId: number;
    private _ejecutivoId: string;
    private _divisaId: string;
    private _fechaConciliacion: string;
    private _facturaDocumentoTipo: string;
    private _facturaFolio: string;
    private _facturaFecha: string;
    private _facturaPdfUrl: string;
    private _ejecutivo: EjecutivoInterface;
    private _medioPago: MedioPago;
    private _pasarelaPago: PasarelaPago;
    private _estadoCuenta: EstadoCuenta;
    private _estadoCuentaDetalles: EstadoCuentaDetalle[];
    private _contacto: Contacto;
    private _contrato: Contrato;
    private _cuentaRecaudadora: CuentaRecaudadora;
    private _estadoPagoEstado: EstadoPagoEstado;
    private _banco: Banco;
    private _adjuntos: Adjunto[];
    private _reversos: Reverso[];


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get fecha(): string {
        return this._fecha;
    }
    public set fecha(value: string) {
        this._fecha = value;
    }
    public get monto(): number {
        return this._monto;
    }
    public set monto(value: number) {
        this._monto = value;
    }
    public get medioPagoId(): number {
        return this._medioPagoId;
    }
    public set medioPagoId(value: number) {
        this._medioPagoId = value;
    }
    get pasarelaPagoId(): string {
        return this._pasarelaPagoId;
    }

    set pasarelaPagoId(value: string) {
        this._pasarelaPagoId = value;
    }

    public get observacion(): string {
        return this._observacion;
    }
    public set observacion(value: string) {
        this._observacion = value;
    }
    public get estadoCuentaId(): string {
        return this._estadoCuentaId;
    }
    public set estadoCuentaId(value: string) {
        this._estadoCuentaId = value;
    }
    public get cuentaRecaudadoraId(): string {
        return this._cuentaRecaudadoraId;
    }
    public set cuentaRecaudadoraId(value: string) {
        this._cuentaRecaudadoraId = value;
    }

    get ejecutivoId(): string {
        return this._ejecutivoId;
    }

    set ejecutivoId(value: string) {
        this._ejecutivoId = value;
    }

    get divisaId(): string {
        return this._divisaId;
    }

    set divisaId(value: string) {
        this._divisaId = value;
    }

    public get numero(): string {
        return this._numero;
    }
    public set numero(value: string) {
        this._numero = value;
    }
    public get estadoPagoEstadoId(): number {
        return this._estadoPagoEstadoId;
    }
    public set estadoPagoEstadoId(value: number) {
        this._estadoPagoEstadoId = value;
    }
    public get bancoId(): number {
        return this._bancoId;
    }
    public set bancoId(value: number) {
        this._bancoId = value;
    }
    public get fechaConciliacion(): string {
        return this._fechaConciliacion;
    }
    public set fechaConciliacion(value: string) {
        this._fechaConciliacion = value;
    }
    public get facturaDocumentoTipo(): string {
        return this._facturaDocumentoTipo;
    }
    public set facturaDocumentoTipo(value: string) {
        this._facturaDocumentoTipo = value;
    }
    public get facturaFolio(): string {
        return this._facturaFolio;
    }
    public set facturaFolio(value: string) {
        this._facturaFolio = value;
    }
    public get facturaFecha(): string {
        return this._facturaFecha;
    }
    public set facturaFecha(value: string) {
        this._facturaFecha = value;
    }
    public get facturaPdfUrl(): string {
        return this._facturaPdfUrl;
    }
    public set facturaPdfUrl(value: string) {
        this._facturaPdfUrl = value
    }
    public get ejecutivo(): EjecutivoInterface {
        return this._ejecutivo;
    }
    public set ejecutivo(value: EjecutivoInterface) {
        this._ejecutivo = value;
    }
    public get medioPago(): MedioPago {
        return this._medioPago;
    }
    public set medioPago(value: MedioPago) {
        this._medioPago = value;
    }

    get pasarelaPago(): PasarelaPago {
        return this._pasarelaPago;
    }

    set pasarelaPago(value: PasarelaPago) {
        this._pasarelaPago = value;
    }

    public get estadoCuenta(): EstadoCuenta {
        return this._estadoCuenta;
    }
    public set estadoCuenta(value: EstadoCuenta) {
        this._estadoCuenta = value;
    }
    public get estadoCuentaDetalles(): EstadoCuentaDetalle[] {
        return this._estadoCuentaDetalles;
    }
    public set estadoCuentaDetalles(value: EstadoCuentaDetalle[]) {
        this._estadoCuentaDetalles = value;
    }
    public get contacto(): Contacto {
        return this._contacto;
    }
    public set contacto(value: Contacto) {
        this._contacto = value;
    }
    public get contrato(): Contrato {
        return this._contrato;
    }
    public set contrato(value: Contrato) {
        this._contrato = value;
    }
    public get cuentaRecaudadora(): CuentaRecaudadora {
        return this._cuentaRecaudadora;
    }
    public set cuentaRecaudadora(value: CuentaRecaudadora) {
        this._cuentaRecaudadora = value;
    }
    public get estadoPagoEstado(): EstadoPagoEstado {
        return this._estadoPagoEstado;
    }
    public set estadoPagoEstado(value: EstadoPagoEstado) {
        this._estadoPagoEstado = value;
    }
    public get banco(): Banco {
        return this._banco;
    }
    public set banco(value: Banco) {
        this._banco = value;
    }
    public get adjuntos(): Adjunto[] {
        return this._adjuntos;
    }
    public set adjuntos(value: Adjunto[]) {
        this._adjuntos = value;
    }
    public get reversos(): Reverso[] {
        return this._reversos;
    }
    public set reversos(value: Reverso[]) {
        this._reversos = value;
    }

}
