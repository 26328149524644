import { PaisAdapter } from './../../adapter/pais.adapter';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pais } from 'src/app/models/model.index';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaisService {

  constructor(
    public http: HttpClient,
    public paisAdapter: PaisAdapter
  ) { }

  cargarPaises(): Observable <Pais[]> {
    return this.http.get(environment.urlApi + 'paises')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(paises => this.paisAdapter.adapt(paises));
        })
      );
  }

}
