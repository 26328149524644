import { Pipe, PipeTransform } from '@angular/core';
import { RutPipe } from './rut.pipe';

@Pipe({
  name: 'identificador'
})
export class IdentificadorPipe implements PipeTransform {

  constructor(private rut: RutPipe) { }

  transform(val: string, identificadorTipo: number): string {
    if (!val) {
        return '';
    }
    switch (identificadorTipo) {
      case 1:
        return this.rut.transform(val);
      default:
        return val;
    }
  }
}
