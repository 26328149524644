import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Utils} from '../../shared/utils';
import {map} from 'rxjs/operators';
import {UserAdapter} from '../../adapter/user.adapter';
import {Observable} from 'rxjs';
import {Usuario} from '../../models/usuario.model';
import { ModuleWithRoles, User, UserConfiguration, UserPermission } from '../../models/user.model';
import { Paginador } from 'src/app/models/model.index';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  public parametros: any;
  public paginador: Paginador;
  private _ejecutivos: User[];

  constructor(
      public http: HttpClient,
      public userAdapter: UserAdapter,
      public paginadorAdapter: PaginadorAdapter,
  ) { }

  get ejecutivos(): User[] {
    return this._ejecutivos;
  }

  cargarEjecutivos(filtro?: any): Observable<User[]> {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'ejecutivo?' + this.parametros)
        .pipe(
            map((resp: any) => {
              this._ejecutivos = resp.data.map(user => this.userAdapter.adapt(user));
              return resp.data.map(user => this.userAdapter.adapt(user));
            })
        );
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = '';
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('user_filter', filtro);
  }

  getUserForConfigurations = (filter?: Object, page?: number): Observable<UserConfiguration[]> => {
    let filterToApply = ''

    if (page && page > 0) {
      filterToApply += '&page=' + page;
    }

    if (filter) {
      filterToApply += Utils.generarParametrosBusqueda('user_filter', filter)
    }

    return this.http.get(`${environment.urlApi}usuario?${filterToApply}`)
    .pipe(
      map((resp: any) => {
        this.paginador = this.paginadorAdapter.adapt(resp.meta);
        return resp.data.map(user => {
          const contactInformation = user.included.find(e => e.type === 'Contacto')
          const userInformation: UserConfiguration = {
            contactId: contactInformation?.id || '',
            id: user.id,
            firstName: contactInformation?.attributes?.nombre || '',
            lastName: contactInformation?.attributes?.apellido || '',
            email: contactInformation?.attributes.email || '',
            phone: contactInformation?.attributes.telefono || '',
            enabled: user.attributes.is_enabled,
            lastAccess: user.attributes.last_login || '-'
          }

          return userInformation
        })
      })
    );
  }

  getPagination = () => {
    return this.paginador;
  }

  addUser = (user:Object): Observable <any> => {
    return this.http.post(environment.urlApi + 'usuario/create', user);
  }

  updateUser = (user:any): Observable <any> => {
    return this.http.put(`${environment.urlApi}usuario/${user.id}/update`, user);
  }

  getModuleWithRoles = (): Observable<ModuleWithRoles[]> => {
    return this.http.get(`${environment.urlApi}modulo`).pipe(
      map((resp: any) => {
        return resp.data
          .filter((e:any) => e.relationship?.roles?.data?.length)
          .map((e:any) => ({
            id: e.id,
            langResource: e.attributes.lang_resource,
            roles: e.relationship.roles.data.map((f:any) => ({
              description: f.attributes.descripcion || '',
              id: f.id,
              isAdmin: f.attributes.is_admin,
              isChecked: false,
              langResource: f.attributes.lang_resource,
            })),
          }));
      })
    );
  };

  getUserRoles = (userId: string): Observable<any> => {
    return this.http.get(`${environment.urlApi}usuario/${userId}/roles`).pipe(
      map((resp: any) => {
        const contact = resp.data?.included?.find(e => e.type === 'Contacto')
        let fullName = ''
        if (contact) {
          fullName = `${contact.attributes?.nombre || ''} ${contact.attributes?.apellido || ''}`
        }
        return {
          fullName,
          roles: resp.data.relationship.roles.map(e => e.id)
        }  
      })
    );
  };

  setUserRoles = (roles:number[], userId: string): Observable<any> => {
    return this.http.patch(`${environment.urlApi}usuario/${userId}/roles`, { roles });
  }

  enableMassiveUser = (userIds: string[], value: boolean): Observable<any> => {
    return this.http.patch(`${environment.urlApi}usuario/activacionInactivacionMasiva`, { usuarios: userIds, habilitado: value });
  }

  getUserForPermissions = (): Observable<UserPermission[]> => {
    return this.http.get(`${environment.urlApi}usuarios`)
    .pipe(
      map((resp: any) => {
        return resp.data
        .map(user => (
          {
            id: user.attributes.id,
            email: user.attributes.email || '',
            firstName: user.included[0].attributes.nombre,
            lastName: user.included[0].attributes.apellido
          }
        ))
        .sort((a,b) => `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)
      })
    );
  }
}
