import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Pais } from '../models/pais.model';

@Injectable({
    providedIn: 'root'
})

export class PaisAdapter implements Adapter<Pais> {

    adapt(item: any): Pais {
        const pais = new Pais();
        pais.createdAt = item.attributes.created_at;
        pais.createdBy = item.attributes.created_by;
        pais.updatedAt = item.attributes.updated_at;
        pais.updatedBy = item.attributes.updated_by;
        pais.id = item.attributes.id;
        pais.nombre = item.attributes.nombre;
        pais.isoCode = item.attributes.iso_code;
        pais.phoneCode = item.attributes.phone_code;
        pais.cultureCodeId = item.attributes.culture_code_id;
        pais.region = item.attributes.region;
        pais.nacionalidad = item.attributes.nacionalidad;
        return pais;
    }
}
