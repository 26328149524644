import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContratoEstado } from '../models/model.index';

@Injectable({
    providedIn: 'root'
  })
  export class ContratoEstadoAdapter implements Adapter<ContratoEstado> {
    adapt(item: any): ContratoEstado {
        const contratoEstado = new ContratoEstado();
        contratoEstado.id = item.attributes.id;
        contratoEstado.nombre = item.attributes.nombre;
        contratoEstado.lang_resource = item.attributes.lang_resource;
        return contratoEstado;
    }
  }
