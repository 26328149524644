import { ModalService } from 'src/app/services/service.index';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { NgForm } from '@angular/forms';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-contrasena',
  templateUrl: './contrasena.component.html',
  styleUrls: ['./contrasena.component.css']
})
export class ContrasenaComponent implements OnInit {

  public email: string;
  public clicked: boolean;
  private isMobileResolution: boolean;
  public script: any;

  constructor(
    public router: Router,
    public authService: AuthService,
    public modalService: ModalService
  ) { }

  ngOnInit() {
    this.clicked = false;
    this.checkResolution(window.innerWidth);
  }

  olvidoContraseña(payload: any) {
    this.authService.olvidoContraseña(payload).subscribe((resp: any) => {
      if (resp.data) {
        this.modalService.notificacion('Se ha enviado el correo electrónico!','success');
        this.clicked = false;
      } else {
        resp.errors.forEach(error => {
          this.modalService.notificacion(error.detail, 'error');
        });
        this.clicked = false;
      }
    });
  }

  enviar(form: NgForm) {
    this.clicked = true;
    if ( form.invalid ) {
      this.clicked = false;
      return;
    }
    if (form.value.email ) {
      const payload = form.form.getRawValue();
      this.olvidoContraseña(payload);
    }
  }

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }

  public checkResolution(innerWidth) {
    if (innerWidth <= 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  onResize(event: any) {
    this.checkResolution(event.target.innerWidth);
  }

}
