import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import { MonedaPipe } from './moneda.pipe';
import { FechaPipe } from './fecha.pipe';
import { NumeroPipe } from './numero.pipe';
import { IdentificadorPipe } from './identificador.pipe';
import { RutPipe } from './rut.pipe';
import { DivisaPipe } from './divisa.pipe';
import { NumeroConSeparadoresPipe } from './numeroConSeparadores.pipe';

@NgModule({
  declarations: [
    ImagenPipe,
    MonedaPipe,
    FechaPipe,
    NumeroPipe,
    IdentificadorPipe,
    DivisaPipe,
    NumeroConSeparadoresPipe
  ],
  exports: [
    ImagenPipe,
    MonedaPipe,
    FechaPipe,
    NumeroPipe,
    IdentificadorPipe,
    DivisaPipe,
    NumeroConSeparadoresPipe
  ],
  providers: [ RutPipe, DivisaPipe, NumeroPipe, NumeroConSeparadoresPipe ]
})
export class PipesModule { }
