import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { CustodiaDocumento } from '../models/model.index';

@Injectable({
    providedIn: 'root'
})

export class CustodiaDocumentoAdapter implements Adapter<CustodiaDocumento> {

    adapt(item: any): CustodiaDocumento {
        const custodiaDocumento = new CustodiaDocumento();
        custodiaDocumento.createdAt = item.attributes.created_at;
        custodiaDocumento.createdBy = item.attributes.created_by;
        custodiaDocumento.id = item.attributes.id;
        custodiaDocumento.custodiaDocumentoTipoId = item.attributes.custodia_documento_tipo_id;
        custodiaDocumento.custodiaDocumentoEstadoId = item.attributes.custodia_documento_estado_id;
        custodiaDocumento.fechaCustodia = item.attributes.fecha_custodia;
        custodiaDocumento.folio = item.attributes.folio;
        custodiaDocumento.banco = item.attributes.banco;
        custodiaDocumento.monto = item.attributes.monto;
        custodiaDocumento.isDepositableSolamente = item.attributes.is_depositable_solamente;
        custodiaDocumento.isCobrableAlPortador = item.attributes.is_cobrable_al_portador;
        custodiaDocumento.giradoA = item.attributes.girado_a;
        return custodiaDocumento;
    }
}
