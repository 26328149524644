import {Injectable} from '@angular/core';
import {Paginador} from '../../models/paginador.model';
import {HttpClient} from '@angular/common/http';
import {CotizacionNotaAdapter} from '../../adapter/cotizacion-nota.adapter';
import {environment} from '../../../environments/environment';
import {Utils} from '../../shared/utils';
import {map} from 'rxjs/operators';
import {PaginadorAdapter} from '../../adapter/paginador.adapter';
import {DescuentoRecargoConfiguracionAdapter} from '../../adapter/descuento-recargo-configuracion.adapter';
import {PropiedadConfiguracionCargoClasificacionAdapter} from '../../adapter/propiedad-configuracion-cargo-clasificacion.adapter';
import {Observable} from 'rxjs';
import {CuentaRecaudadora} from '../../models/cuenta-recaudadora.model';
import {CuentaRecaudadoraAdapter} from '../../adapter/cuenta-recaudadora.adapter';
import {ContratoTemplateAdapter} from '../../adapter/contrato-template.adapter';
import {PropiedadFirmanteAdapter} from '../../adapter/propiedad-firmante.adapter';

@Injectable({
  providedIn: 'root'
})
export class PropiedadConfiguracionService {
  public paginador: Paginador;
  public paginadorDescuentoRecargo: Paginador;
  public parametros: any;
  constructor(
    public http: HttpClient,
    public paginadorAdapter: PaginadorAdapter,
    public cotizacionNotaAdapter: CotizacionNotaAdapter,
    public descuentoRecargoConfiguracionAdapter: DescuentoRecargoConfiguracionAdapter,
    public propiedadCargoClasificacionAdapter: PropiedadConfiguracionCargoClasificacionAdapter,
    public cuentaRecaudadoraAdapter: CuentaRecaudadoraAdapter,
    public contratoTemplateAdapter: ContratoTemplateAdapter,
    private propiedadFirmanteAdapter: PropiedadFirmanteAdapter
  ) {}

  generarParametrosBusqueda(filtroNombre, filtro)  {
    this.parametros = '';
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda(filtroNombre, filtro);
  }
  cargarNotasCotizacion(propiedadId: string, page, filtro?: any) {
    this.generarParametrosBusqueda('cotizacion_nota_filter', filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    this.parametros += '&limit=1000';
    return this.http.get(`${environment.urlApi}propiedades/${propiedadId}/configuracion/cotizacion-nota?${this.parametros}`).pipe(
      map((resp: any) => {
        this.paginador = this.paginadorAdapter.adapt(resp.meta);
        return resp.data.map(cotizacionNota => this.cotizacionNotaAdapter.adapt(cotizacionNota));
      })
    );
  }

  cargarNotaCotizacion(propiedadId: string, id: string) {
    return this.http.get(`${environment.urlApi}propiedades/${propiedadId}/configuracion/cotizacion-nota/${id}`).pipe(
      map((resp: any) => this.cotizacionNotaAdapter.adapt(resp.data))
    );
  }

  crearNotaCotizacion(propiedadId: string, payload: any) {
    return this.http.post(`${environment.urlApi}propiedades/${propiedadId}/configuracion/cotizacion-nota`, payload);
  }

  editarNotaCotizacion(propiedadId: string, cotizacionNotaId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/cotizacion-nota/${cotizacionNotaId}`, payload);
  }

  /* Descuento Recargo Configuracion */
  cargarDescuentoRecargoConfiguraciones(propiedadId: string, page, filtro?) {
    this.generarParametrosBusqueda('descuento_recargo_configuracion_filter', filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(`${environment.urlApi}propiedades/${propiedadId}/configuracion/descuento-recargo-configuracion?${this.parametros}`)
      .pipe(
        map( (resp: any) => {
          this.paginadorDescuentoRecargo = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(item => this.descuentoRecargoConfiguracionAdapter.adapt(item));
        })
      );
  }
  crearDescuentoRecargoConfiguracion(propiedadId: string, payload: any) {
    return this.http.post(`${environment.urlApi}propiedades/${propiedadId}/configuracion/descuento-recargo-configuracion`, payload)
  }
  editarDescuentoRecargoConfiguracion(propiedadId: string, descuentoRecargoId: string, payload: any) {
    return this.http
      .put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/descuento-recargo-configuracion/${descuentoRecargoId}`, payload);
  }
  getPaginadorDescuentoRecargo() {
    return this.paginadorDescuentoRecargo;
  }

  /* Clasificacion Conceptos */
  cargarConfiguracionConceptoClasificacion(propiedadId: string, page,  filtro?: any) {
    this.generarParametrosBusqueda('concepto_clasificacion_filter', filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    this.parametros += '&limit=1000';
    return this.http.get(`${environment.urlApi}propiedades/${propiedadId}/configuracion/concepto-clasificacion?${this.parametros}`)
      .pipe(
        map((resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(conceptoClasificacion => this.propiedadCargoClasificacionAdapter.adapt(conceptoClasificacion));
        })
      )
  }
  crearConfiguracionConceptoClasificacion(propiedadId: string, payload: any) {
    return this.http.post(`${environment.urlApi}propiedades/${propiedadId}/configuracion/concepto-clasificacion`, payload);
  }
  editarConfiguracionConceptoClasificacion(propiedadId: string, conceptoClasificacionId: string, payload: any) {
    return this.http
      .put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/concepto-clasificacion/${conceptoClasificacionId}`, payload);
  }
  eliminarConfiguracionConceptoClasificacion(propiedadId: string, conceptoClasificacionId: number) {
    return this.http.delete(`${environment.urlApi}propiedades/${propiedadId}/configuracion/concepto-clasificacion/${conceptoClasificacionId}`);
  }

  /* Cuentas Recaudadoras */
  cargarCuentaRecaudadoras(propiedadId: string, filtro?): Observable <CuentaRecaudadora[]> {
    return this.http.get(`${environment.urlApi}propiedades/${propiedadId}/configuracion/cuenta-recaudadora?limit=1000`)
      .pipe(
        map( (resp: any) => resp.data.map(cuentas => this.cuentaRecaudadoraAdapter.adapt(cuentas)))
      );
  }
  crearCuentaRecaudadora(propiedadId: string, payload: any) {
    return this.http.post(`${environment.urlApi}propiedades/${propiedadId}/configuracion/cuenta-recaudadora`, payload);
  }
  editarCuentaRecaudadora(propiedadId: string, cuentaRecaudadoraId: string, payload: any) {
    return this.http
      .put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/cuenta-recaudadora/${cuentaRecaudadoraId}`, payload);
  }
  cargarTemplates(propiedadId: string) {
    return this.http.get(`${environment.urlApi}propiedades/${propiedadId}/configuracion/contrato-template`).pipe(
      map((resp: any) => resp.data.map(template => this.contratoTemplateAdapter.adapt(template)))
    );
  }
  crearTemplate(propiedadId: string, payload: any) {
    return this.http.post(`${environment.urlApi}propiedades/${propiedadId}/configuracion/contrato-template`, payload);
  }
  editarTemplate(propiedadId: string, templateId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/contrato-template/${templateId}`, payload);
  }
  cargarFirmantes(propiedadId: string) {
    return this.http.get(`${environment.urlApi}propiedades/${propiedadId}/configuracion/firmantes`).pipe(
      map((resp: any) => resp.data.relationship.PropiedadConfiguracionFirmantes?.data?.map(firmante =>
      this.propiedadFirmanteAdapter.adapt(firmante)))
    );
  }
  crearFirmante(propiedadId: string, payload: any) {
    return this.http.post(`${environment.urlApi}propiedades/${propiedadId}/configuracion/firmantes`, payload);
  }
  editarFirmante(propiedadId: string, firmanteId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/firmantes/${firmanteId}`, payload);
  }
  eliminarFirmante(propiedadId: string, firmanteId: string) {
    return this.http.delete(`${environment.urlApi}propiedades/${propiedadId}/configuracion/firmantes/${firmanteId}`);
  }
  actualizarDivisaCargo(propiedadId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/divisa-cargos`, payload);
  }
  actualizarDivisaCobro(propiedadId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/divisa-cobro`, payload);
  }
  /* Multas */
  actualizarMulta(propiedadId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/multa`, payload);
  }
  /* Intereses */
  actualizarInteres(propiedadId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/interes`, payload);
  }
  /* Reajustes */
  actualizarReajuste(propiedadId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/reajuste`, payload);
  }

  actualizarReajusteTipos(propiedadId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/reajuste-tipos`, payload);
  }

  actualizarParametrosComerciales(propiedadId: string, payload: any) {
    return this.http.put(`${environment.urlApi}propiedades/${propiedadId}/configuracion/comercial`, payload);
  }

}
