import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {ContactoAdapter} from './contacto.adapter';
import {UnidadPropietario} from '../models/unidad-propietario.model'
import {MontoTipoAdapter} from './monto-tipo.adapter';
import {DivisaAdapter} from "./divisa.adapter";

@Injectable({
  providedIn: 'root'
})

export class UnidadPropietarioAdapter implements Adapter<UnidadPropietario> {

  constructor(
    public contactoAdapter: ContactoAdapter,
    private montoTipoAdapter: MontoTipoAdapter,
    private divisaAdapter: DivisaAdapter
  ) {}

  adapt(item: any): UnidadPropietario {
    const unidadPropietario = new UnidadPropietario();
    unidadPropietario.id = item.attributes.id;
    unidadPropietario.contactoId = item.attributes.contacto_id;
    unidadPropietario.unidadId = item.attributes.unidad_id;
    unidadPropietario.porcentaje = item.attributes.porcentaje;
    unidadPropietario.activo = item.attributes.activo;
    unidadPropietario.comisionTipoId = item.attributes.comision_tipo_id;
    unidadPropietario.comisionDivisaId = item.attributes.comision_divisa_id;
    unidadPropietario.comisionValor = item.attributes.comision_valor;
    unidadPropietario.comisionValorImpuesto = item.attributes.comision_valor_impuesto;
    unidadPropietario.divisaId = item.attributes.divisa_id;

    /* Includes*/
    if (item.included) {
      item.included.filter((valor) => {
        switch (valor.type) {
          case 'Contacto':
            unidadPropietario.contacto = this.contactoAdapter.adapt(valor);
            break;
          case 'MontoTipo':
            unidadPropietario.montoTipo = this.montoTipoAdapter.adapt(valor);
            break;
          case 'Divisa':
            unidadPropietario.divisa = this.divisaAdapter.adapt(valor);
            break;
        }
      });
    }

    return unidadPropietario;
  }
}
