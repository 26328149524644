import { MedioPago } from './../../models/medio-pago.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MedioPagoAdapter } from 'src/app/adapter/medio-pago.adapter';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedioPagoService {

  constructor(
    public http: HttpClient,
    public medioPagoAdapter: MedioPagoAdapter
  ) { }

  cargarMedioPagos(): Observable <MedioPago[]> {
    return this.http.get(environment.urlApi + 'medio_pagos')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(medioPagos => this.medioPagoAdapter.adapt(medioPagos));
        })
      );
  }

}
