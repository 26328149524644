import {Action} from '@ngrx/store';
import {Divisa} from '../../models/divisa.model';
import {IdentificadorTipo} from '../../models/identificador-tipo.model';
import {CargoClasificacion} from '../../models/cargoClasificacion.model';
import {Concepto} from '../../models/concepto.model';
import {UnidadEstado} from '../../models/unidad-estado.model';
import {UnidadTipo} from '../../models/unidad-tipo.model';
import {Globals} from "../models/globals.model";
import {LocalService} from "../../services/local.service";
import {ContratoContactoTipo} from "../../models/contrato-contacto-tipo.model";
import {BaseModel} from "../../models/base.model";

export enum GlobalsActionTypes {
  SET_DIVISAS = 'SET_DIVISAS',
  SET_IDENTIFICADOR_TIPOS = 'SET_IDENTIFICADORES_TIPO',
  SET_CARGOS_CLASIFICACIONES = 'SET_CARGOS_CLASIFICACIONES',
  SET_CONCEPTOS = 'SET_CONCEPTOS',
  SET_UNIDAD_ESTADOS = 'SET_UNIDAD_ESTADOS',
  SET_UNIDAD_TIPOS = 'SET_UNIDAD_TIPOS',
  SET_ALL = 'SET_ALL',
  SET_PERIODICIDAD_TIPOS = 'SET_PERIODICIDAD_TIPOS',
  SET_MONTO_TIPOS = 'SET_MONTO_TIPOS',
  SET_CONTRATO_CONTACTO_TIPOS = 'SET_CONTRATO_CONTACTO_TIPOS',
  SET_REAJUSTE_TIPOS = 'SET_REAJUSTE_TIPOS',
}

export class SetDivisas implements Action {
  readonly type = GlobalsActionTypes.SET_DIVISAS;
  constructor(public payload: Divisa[]) {}
}

export class SetIdentificadorTipos implements Action {
  readonly type = GlobalsActionTypes.SET_IDENTIFICADOR_TIPOS;
  constructor(public payload: IdentificadorTipo[]) {}
}

export class SetCargosClasificaciones implements Action {
  readonly type = GlobalsActionTypes.SET_CARGOS_CLASIFICACIONES;
  constructor(public payload: CargoClasificacion[]) {}
}

export class SetConceptos implements Action {
  readonly type = GlobalsActionTypes.SET_CONCEPTOS;
  constructor(public payload: Concepto[]) {}
}

export class SetUnidadEstados implements Action {
  readonly type = GlobalsActionTypes.SET_UNIDAD_ESTADOS;
  constructor(public payload: UnidadEstado[]) {}
}

export class SetUnidadTipos implements Action {
  readonly type = GlobalsActionTypes.SET_UNIDAD_TIPOS;
  constructor(public payload: UnidadTipo[]) {}
}

export class SetContratoContactoTipos implements Action {
  readonly type = GlobalsActionTypes.SET_CONTRATO_CONTACTO_TIPOS;
  constructor(public payload: ContratoContactoTipo[]) {}
}

export class SetReajusteTipos implements Action {
  readonly type = GlobalsActionTypes.SET_REAJUSTE_TIPOS;
  constructor(public payload: BaseModel[]) {}
}

export class SetAll implements Action {
  readonly type = GlobalsActionTypes.SET_ALL;
  constructor(public payload: Globals) {}
}

export type GlobalsActions =
  SetDivisas |
  SetIdentificadorTipos |
  SetCargosClasificaciones |
  SetConceptos |
  SetUnidadEstados |
  SetUnidadTipos |
  SetContratoContactoTipos |
  SetReajusteTipos |
  SetAll
