import {AppModel} from './app.model';

export class UnidadTipo extends AppModel{
    public static readonly CASA = {id: '055d7215-3028-47dc-a5b0-6a212f0d6a6a', lang_resource: 'unit_type.home'};
    public static readonly DEPARTAMENTO = {id: '27567871-07bd-46f2-a1ca-030329eeb680', lang_resource: 'unit_type.flat'};
    public static readonly ESTACIONAMIENTO_DE_AUTO_COMERCIO = {id: '2bf8ad62-ffc4-4d72-8b9f-0b2fa14df3e9', lang_resource: 'unit_type.comercial_parking'};
    public static readonly PISO = {id: '5fbab18f-92d8-4911-aabb-71c68dd0a68e', lang_resource: 'unit_type.floor'};
    public static readonly COLIVING = {id: '60bd5cfa-347a-441a-a839-c42525d255b7', lang_resource: 'unit_type.coliving'};
    public static readonly AZOTEA = {id: '892e45d1-b16f-4e16-b29b-89f96348094a', lang_resource: 'unit_type.terrace'};
    public static readonly GALPON = {id: '8aca64f9-9977-4a43-9767-9c99005ed11a', lang_resource: 'unit_type.commercial_warehouse'};
    public static readonly ESTACIONAMIENTO_DE_AUTO_Y_BODEGA = {id: '8bf8b974-265e-41c1-ab67-a86c5d5ed085', lang_resource: 'unit_type.warehouse_parking'};
    public static readonly ESTACIONAMIENTO_DE_AUTO_PACK = {id: '934ece8e-ed18-45e4-9652-c0bdbfa5266c', lang_resource: 'unit_type.autopack_parking'};
    public static readonly ESTACIONAMIENTO_MOTO = {id: 'a6709c5a-3ef1-4508-9b19-fffdb25526e6', lang_resource: 'unit_type.motorcycle_parking'};
    public static readonly LOCAL_COMERCIAL = {id: 'ab5df79b-2272-47ee-8b3d-587c2f1afd5d', lang_resource: 'unit_type.shop'};
    public static readonly ESTACIONAMIENTO_DE_AUTO = {id: 'cc732cbb-fbc2-4b51-8438-699ad2c4ebb2', lang_resource: 'unit_type.parking'};
    public static readonly BODEGA = {id: 'd3cebfbb-1acf-4ddd-83b9-1e1de73256cc', lang_resource: 'unit_type.warehouse'};
    public static readonly OFICINA = {id: 'e1e92ba2-6d77-4204-85db-fe3246225f62', lang_resource: 'unit_type.office'};
    public static readonly TERRENO = {id: 'e6296cff-f04c-4c55-b570-277dc08e5c44', lang_resource: 'unit_type.ground'};
    public static readonly ESTACIONAMIENTO_DISCAPACITADO = {id: 'f6e6ab9c-2741-46d5-8ad5-3f0fa5a2f590', lang_resource: 'unit_type.handicapped_parking'};

    private _id: string;
    private _nombre: string;
    private _isActive: string;
    private _orden: number;
    private _lang_resource: string;


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get isActive(): string {
        return this._isActive;
    }
    public set isActive(value: string) {
        this._isActive = value;
    }
    public get orden(): number {
        return this._orden;
    }
    public set orden(value: number) {
        this._orden = value;
    }
    public get lang_resource(): string {
        return this._lang_resource;
    }
    public set lang_resource(value: string) {
        this._lang_resource = value;
    }
}
