import { EstadoCivilAdapter } from './estado-civil.adapter';
import { Adapter } from './adapter';
import {inject, Injectable} from '@angular/core';
import { Contacto } from '../models/contacto.model';
import { PaisAdapter } from './pais.adapter';
import { CiudadAdapter } from './ciudad.adapter';
import { MedioLlegadaAdapter } from './medio-llegada.adapter';
import { ProbabilidadArriendoAdapter } from './probabilidad-arriendo';
import { IdentificadorTipoAdapter } from './identificador-tipo.adapter';
import * as moment from 'moment';
import {PropietarioContactoInterface} from "../interfaces/propietario-contacto.interface";
import {User} from "../models/user.model";

@Injectable({
  providedIn: 'root'
})

export class ContactoAdapter implements Adapter<Contacto> {
  constructor(
    public paisAdapter: PaisAdapter,
    public ciudadAdapter: CiudadAdapter,
    public medioLlegadaAdapter: MedioLlegadaAdapter,
    public probabilidadArriendoAdapter: ProbabilidadArriendoAdapter,
    public estadoCivilAdapter: EstadoCivilAdapter,
    public identificadorTipoAdapter: IdentificadorTipoAdapter,
  ) {
  }

  adapt(item: any): Contacto {
    const cliente = new Contacto();
    cliente.createdAt = item.attributes.created_at;
    cliente.createdBy = item.attributes.created_by;
    cliente.updatedAt = item.attributes.updated_at;
    cliente.updatedBy = item.attributes.updated_by;
    cliente.id = item.attributes.id;
    cliente.razonSocial = item.attributes.razon_social;
    cliente.giro = item.attributes.giro;
    cliente.nombre = item.attributes.nombre;
    cliente.apellido = item.attributes.apellido;
    cliente.email = item.attributes.email;
    cliente.telefono = item.attributes.telefono;
    cliente.identificador = item.attributes.identificador;
    cliente.identificadorTipoId = item.attributes.identificador_tipo_id;
    cliente.direccion = item.attributes.direccion;
    cliente.direccion2 = item.attributes.direccion2;
    cliente.paisId = item.attributes.pais_id ? item.attributes.pais_id : '';
    cliente.paisIdNacionalidad = item.attributes.pais_id_nacionalidad ? item.attributes.pais_id_nacionalidad : '';
    cliente.ciudadId = item.attributes.ciudad_id ? item.attributes.ciudad_id : '';
    cliente.ciudadText = item.attributes.ciudad_text;
    cliente.region = item.attributes.region;
    cliente.comuna = item.attributes.comuna;
    cliente.zipCode = item.attributes.zip_code;
    cliente.medioLlegadaId = item.attributes.medio_llegada_id ? item.attributes.medio_llegada_id : '';
    cliente.probabilidadArriendoId = item.attributes.probabilidad_arriendo_id ? item.attributes.probabilidad_arriendo_id : '';
    cliente.estadoCivilId = item.attributes.estado_civil_id ? item.attributes.estado_civil_id : '';
    cliente.profesion = item.attributes.profesion;
    cliente.contactoTipoId = item.attributes.contacto_tipo_id;
    cliente.esFumador = item.attributes.is_fumador || false
    cliente.fechaNacimiento = item.attributes.fecha_nacimiento ? item.attributes.fecha_nacimiento.split(' ')[0] : null
    cliente.generoId = item.attributes.genero_id || null
    cliente.ocupacion = item.attributes.ocupacion || ''
    cliente.comentarios = []
    cliente.propietarioContactoId = item.attributes.propietario_contacto_id;
    cliente.tieneMascota = item.attributes.tiene_mascota || false;
    cliente.rentaLiquida = item.attributes.renta_liquida || null;

    /* Includes*/
    const paises = [];
    if (item.included) {
      item.included.filter((valor) => {
        if (valor.type === 'Pais') {
          paises.push(this.paisAdapter.adapt(valor));
          const findPais = paises.find(pais => pais.id === cliente.paisId);
          cliente.pais = findPais;
          const findNacionalidad = paises.find(pais => pais.id === cliente.paisIdNacionalidad);
          cliente.nacionalidad = findNacionalidad;
        } else if (valor.type === 'Ciudad') {
          cliente.ciudad = this.ciudadAdapter.adapt(valor);
        } else if (valor.type === 'MedioLlegada') {
          cliente.medioLlegada = this.medioLlegadaAdapter.adapt(valor);
        } else if (valor.type === 'ProbabilidadArriendo') {
          cliente.probabilidadArriendo = this.probabilidadArriendoAdapter.adapt(valor);
        } else if (valor.type === 'EstadoCivil') {
          cliente.estadoCivil = this.estadoCivilAdapter.adapt(valor);
        } else if (valor.type === 'IdentificadorTipo') {
          cliente.identificadorTipo = this.identificadorTipoAdapter.adapt(valor);
        } else if (valor.type === 'UsuarioCreador') {
          cliente.usuarioCreador = this.adaptUsuarioCreador(valor);
        } else if (valor.type === 'CuentaBanco') {
          cliente.cuentaBanco = {
            titular: valor.attributes.titular,
            identificador: valor.attributes.identificador,
            banco: valor.attributes.banco,
            tipoCuenta: valor.attributes.tipo_cuenta,
            numero: valor.attributes.numero,
            cuentaId: valor.attributes.id
          }
        }
      });
    }

    if (Array.isArray(item.data)) {
      item.data.forEach(e => {
        cliente.accesoAPopHome = e.attributes?.popHome
      })
    }

    if (item.relationship?.comentarios?.data) {
      cliente.comentarios = item.relationship.comentarios.data.map(e => {
        const attachedFiles = []
        let comment = ''
        let subject = ''
        let createdDate = ''
        let firstName = ''
        let lastName = ''
        let id = ''

        e.included.forEach(f => {
          if (f.type === 'Contacto') {
            firstName = f.attributes.nombre || ''
            lastName = f.attributes.apellido || ''
          } else if (f.type === 'Comentario') {
            comment = f.attributes.comentario
            subject = f.attributes.clasificacion
            createdDate = moment(f.attributes.fecha).format('DD/MM/YYYY')
            id = f.attributes.id
          }
        })


        e.relationship?.adjuntos?.data.forEach(f => {
          attachedFiles.push({
            name: f.attributes.nombre,
            id: f.id,
            size: (f.attributes.file_size/1000).toFixed(2)
          })
        })

        return {
          id,
          comment,
          subject,
          creatorName: `${firstName} ${lastName}`,
          creatorInitials: `${firstName[0] || ''}${lastName[0] || ''}`,
          createdDate,
          attachedFiles
        }
      })
    }

    if (item.relationship?.propietario_contacto) {
      cliente.propietarioContacto = this.adapterPropietarioContacto(item.relationship?.propietario_contacto);
    }
    return cliente;
  }

  adapterPropietarioContacto(data: any): PropietarioContactoInterface {
    const contacto = data.included.find(include => include.type === 'Contacto');
    const {nombre, apellido} = contacto.attributes;
    return {
      userId: data.attributes.id,
      contactoId: data.attributes.contacto_id,
      email: data.attributes.email,
      nombre,
      apellido,
      nombreCompleto: `${nombre} ${apellido}`
    }
  }

  adaptUsuarioCreador(data: any): User {
    const usuarioCreador = new User();
    usuarioCreador.id = data.attributes.id;
    usuarioCreador.email = data.attributes.email;
    usuarioCreador.contactoId = data.attributes.contacto_id;
    if (data.included) {
      data.included.filter((valor) => {
        if (valor.type === 'Contacto') {
          usuarioCreador.contacto = this.adapt(valor);
        }
      });
    }
    return usuarioCreador;
  }
}
