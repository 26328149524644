import { CiudadAdapter } from './../../adapter/ciudad.adapter';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Ciudad } from 'src/app/models/model.index';

@Injectable({
  providedIn: 'root'
})
export class CiudadService {

  constructor(
    public http: HttpClient,
    public ciudadAdapter: CiudadAdapter
  ) { }

  cargarCiudades(): Observable <Ciudad[]> {
    return this.http.get(environment.urlApi + 'ciudades')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(ciudades => this.ciudadAdapter.adapt(ciudades));
        })
      );
  }

}
