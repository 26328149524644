import {Injectable} from "@angular/core";
import {Adapter} from "./adapter";
import {DivisaAdapter} from "./divisa.adapter";
import {CotizacionConfiguracion} from "../models/cotizacion-configuracion.model";

@Injectable({
    providedIn: 'root'
})

export class CotizacionConfiguracionAdapter implements Adapter<CotizacionConfiguracion> {
    constructor(
        public divisaAdapter: DivisaAdapter
    ) {}
    adapt(item: any): CotizacionConfiguracion {
        const cotizacionConfiguracion = new CotizacionConfiguracion();
        cotizacionConfiguracion.cotizacionId = item.attributes.cotizacion_id;
        cotizacionConfiguracion.divisaId = item.attributes.divisa_id;
        cotizacionConfiguracion.divisaCobroId = item.attributes.divisa_cobro_id;
        cotizacionConfiguracion.divisaConversionId = item.attributes.divisa_conversion_id;

        if (item.included) {
            item.included.filter((valor) => {
                switch (valor.type) {
                    case 'Divisa':
                        cotizacionConfiguracion.divisa = this.divisaAdapter.adapt(valor);
                        break;
                    case 'divisaCobro':
                        cotizacionConfiguracion.divisaCobro = this.divisaAdapter.adapt(valor);
                        break;
                }
            });
        }
        return cotizacionConfiguracion;
    }
}
