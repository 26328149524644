import { Directive, HostListener, Input, OnInit } from '@angular/core';
// tslint:disable-next-line: ban-types
declare var gtag: Function;

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[trackOnClick]'
})

export class GoogleAnalitycsDirective implements OnInit {

  @Input() trackCategory: string;
  @Input() trackLabel: string;

  constructor( ) { }

  @HostListener('click') onClick() {
    if (!this.trackCategory || !this.trackLabel) {
      return;
    }
    gtag('event', 'click', {
      event_category: this.trackCategory,
      event_label: this.trackLabel
    });
  }

  ngOnInit() { }

}
