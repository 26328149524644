import { Pais } from './pais.model';
import { PropiedadTipo } from './propiedad-tipo.model';
import {PropiedadConfiguracionComercial} from './propiedad-configuracion-comercial.model';
import { Ciudad } from './ciudad.model';
export interface MetaStats {
  unidadEstadoId: number;
  nombre: string;
  cantidad: number;
}

export class Propiedad {

  private _createdAt: string;
  private _createdBy: string;
  private _id: string;
  private _nombre: string;
  private _direccion: string;
  private _direccion_nivel1: string;
  private _direccion_nivel2: string;
  private _direccion_nivel3: string;
  private _direccion_nivel4: string;
  private _direccion_nivel5: string;
  private _direccion_nivel6: string;
  private _paisId: string;
  private _region: string;
  private _ciudadNombre: string;
  private _ciudadId: string;
  private _comuna: string;
  private _zipCode: string;
  private _propiedadTipoId: string;
  private _descripcion: string;
  private _fechaConstruccion: string;
  private _fechaEntrega: string;
  private _fechaRecepcionMunicipio: string;
  private _pisoCantidad: string;
  private _subterraeoCantidad: string;
  private _web: string;
  private _superficieTerreno: string;
  private _superficieConstruida: string;
  private _inversionTotal: string;
  private _codigo: string;
  private _estrato: number;
  private _latitud: number;
  private _longitud: number;
  private _wasiIdEmpresa: number;

  /* Includes */
  private _pais: Pais;
  private _ciudad: Ciudad;
  private _propiedadTipo: PropiedadTipo;
  private _configuracion: PropiedadConfiguracionComercial;


  /* Relationship */
  private _imagenes: [];
  private _adjuntos: any[];

  /* Meta */
  private _stats: MetaStats[];

  public get createdAt(): string {
    return this._createdAt;
  }

  public set createdAt(value: string) {
    this._createdAt = value;
  }

  public get createdBy(): string {
    return this._createdBy;
  }

  public set createdBy(value: string) {
    this._createdBy = value;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get nombre(): string {
    return this._nombre;
  }

  public set nombre(value: string) {
    this._nombre = value;
  }

  public get direccion(): string {
    return this._direccion;
  }

  public set direccion(value: string) {
    this._direccion = value;
  }


  public get direccion_nivel1(): string {
    return this._direccion_nivel1;
  }

  public set direccion_nivel1(value: string) {
    this._direccion_nivel1 = value;
  }

  public get direccion_nivel2(): string {
    return this._direccion_nivel2;
  }

  public set direccion_nivel2(value: string) {
    this._direccion_nivel2 = value;
  }

  public get direccion_nivel3(): string {
    return this._direccion_nivel3;
  }

  public set direccion_nivel3(value: string) {
    this._direccion_nivel3 = value;
  }

  public get direccion_nivel4(): string {
    return this._direccion_nivel4;
  }

  public set direccion_nivel4(value: string) {
    this._direccion_nivel4 = value;
  }

  public get direccion_nivel5(): string {
    return this._direccion_nivel5;
  }

  public set direccion_nivel5(value: string) {
    this._direccion_nivel5 = value;
  }

  public get direccion_nivel6(): string {
    return this._direccion_nivel6;
  }

  public set direccion_nivel6(value: string) {
    this._direccion_nivel6 = value;
  }

  public get paisId(): string {
    return this._paisId;
  }

  public set paisId(value: string) {
    this._paisId = value;
  }

  public get region(): string {
    return this._region;
  }

  public set region(value: string) {
    this._region = value;
  }

  public get ciudadNombre(): string {
    return this._ciudadNombre;
  }

  public set ciudadNombre(value: string) {
    this._ciudadNombre = value;
  }

  public get ciudadId(): string {
    return this._ciudadId;
  }

  public set ciudadId(value: string) {
    this._ciudadId = value;
  }

  public get comuna(): string {
    return this._comuna;
  }

  public set comuna(value: string) {
    this._comuna = value;
  }

  public get zipCode(): string {
    return this._zipCode;
  }

  public set zipCode(value: string) {
    this._zipCode = value;
  }

  public get propiedadTipoId(): string {
    return this._propiedadTipoId;
  }

  public set propiedadTipoId(value: string) {
    this._propiedadTipoId = value;
  }

  public get descripcion(): string {
    return this._descripcion;
  }

  public set descripcion(value: string) {
    this._descripcion = value;
  }

  public get fechaConstruccion(): string {
    return this._fechaConstruccion;
  }

  public set fechaConstruccion(value: string) {
    this._fechaConstruccion = value;
  }

  public get fechaEntrega(): string {
    return this._fechaEntrega;
  }

  public set fechaEntrega(value: string) {
    this._fechaEntrega = value;
  }

  public get fechaRecepcionMunicipio(): string {
    return this._fechaRecepcionMunicipio;
  }

  public set fechaRecepcionMunicipio(value: string) {
    this._fechaRecepcionMunicipio = value;
  }

  public get pisoCantidad(): string {
    return this._pisoCantidad;
  }

  public set pisoCantidad(value: string) {
    this._pisoCantidad = value;
  }

  public get subterraeoCantidad(): string {
    return this._subterraeoCantidad;
  }

  public set subterraeoCantidad(value: string) {
    this._subterraeoCantidad = value;
  }

  public get web(): string {
    return this._web;
  }

  public set web(value: string) {
    this._web = value;
  }

  public get superficieTerreno(): string {
    return this._superficieTerreno;
  }

  public set superficieTerreno(value: string) {
    this._superficieTerreno = value;
  }

  public get superficieConstruida(): string {
    return this._superficieConstruida;
  }

  public set superficieConstruida(value: string) {
    this._superficieConstruida = value;
  }

  public get inversionTotal(): string {
    return this._inversionTotal;
  }

  public set inversionTotal(value: string) {
    this._inversionTotal = value;
  }

  public get codigo(): string {
    return this._codigo;
  }

  public set codigo(value: string) {
    this._codigo = value;
  }

  public get pais(): Pais {
    return this._pais;
  }

  public set pais(value: Pais) {
    this._pais = value;
  }

  public get ciudad(): Ciudad {
    return this._ciudad;
  }

  public set ciudad(value: Ciudad) {
    this._ciudad = value;
  }

  public get propiedadTipo(): PropiedadTipo {
    return this._propiedadTipo;
  }

  public set propiedadTipo(value: PropiedadTipo) {
    this._propiedadTipo = value;
  }

  get configuracion(): PropiedadConfiguracionComercial {
    return this._configuracion;
  }

  set configuracion(value: PropiedadConfiguracionComercial) {
    this._configuracion = value;
  }

  public get imagenes(): [] {
    return this._imagenes;
  }

  public set imagenes(value: []) {
    this._imagenes = value;
  }

  public get adjuntos(): any[] {
    return this._adjuntos;
  }

  public set adjuntos(value: any[]) {
    this._adjuntos = value;
  }

  get stats(): MetaStats[] {
    return this._stats;
  }

  set stats(value: MetaStats[]) {
    this._stats = value;
  }

  public get estrato(): number {
    return this._estrato;
  }

  public set estrato(value: number) {
    this._estrato = value;
  }

  public get latitud(): number {
    return this._latitud;
  }

  public set latitud(value: number) {
    this._latitud = value;
  }

  public get longitud(): number {
    return this._longitud;
  }

  public set longitud(value: number) {
    this._longitud = value;
  }

  public get wasiIdEmpresa(): number {
    return this._wasiIdEmpresa;
  }

  public set wasiIdEmpresa(value: number) {
    this._wasiIdEmpresa = value;
  }
}


