import { EstadoCuentaEstadoAdapter } from './estado-cuenta-estado.adapter';
import { ConceptoAdapter } from './concepto.adapter';
import { ContratoAdapter } from 'src/app/adapter/contrato.adapter';
import { ContactoAdapter } from 'src/app/adapter/contacto.adapter';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { EstadoCuentaPago } from 'src/app/models/model.index';
import { EstadoCuentaDetalleAdapter } from './estado-cuenta-detalle.adapter';
import { MedioPagoAdapter } from './medio-pago.adapter';
import { EstadoCuentaAdapter } from './estado-cuenta.adapter';
import { CuentaRecaudadoraAdapter } from './cuenta-recaudadora.adapter';
import { EstadoPagoEstadoAdapter } from './estado-pago-estado.adapter';
import { BancoAdapter } from './banco.adapter';
import { EjecutivoInterface } from '../models/estado-cuenta-pago.model';
import { EjecutivoInterface as EjecutivoInterfaceReverso} from '../models/reverso.model';
import { AdjuntoAdapter } from './adjunto.adapter';
import { ReversoAdapter } from './reveso.adapter';
import { MotivoReversoAdapter } from './motivo-reverso.adapter';
import {PasarelaPagoAdapter} from './pasarela-pago.adapter';

@Injectable({
  providedIn: 'root'
})

export class EstadoCuentaPagoAdapter implements Adapter<EstadoCuentaPago> {

  constructor(
    public estadoCuentaDetalleAdapter: EstadoCuentaDetalleAdapter,
    public medioPagoAdapter: MedioPagoAdapter,
    public pasarelaPagoAdapter: PasarelaPagoAdapter,
    public estadoCuentaAdapter: EstadoCuentaAdapter,
    public contactoAdapter: ContactoAdapter,
    public contratoAdapter: ContratoAdapter,
    public conceptoAdapter: ConceptoAdapter,
    public estadoCuentaEstadoAdapter: EstadoCuentaEstadoAdapter,
    public cuentaRecaudadoraAdapter: CuentaRecaudadoraAdapter,
    public estadoPagoEstadoAdapter: EstadoPagoEstadoAdapter,
    public bancoAdapter: BancoAdapter,
    public adjuntoAdapter: AdjuntoAdapter,
    public reversoAdapter: ReversoAdapter,
    public motivoReversoAdapter: MotivoReversoAdapter
  ) {}

  adapt(item: any): EstadoCuentaPago {
    const estadoCuentaPago = new EstadoCuentaPago();
    estadoCuentaPago.id = item.attributes.id;
    estadoCuentaPago.fecha = item.attributes.fecha;
    estadoCuentaPago.fechaConciliacion = item.attributes.fecha_conciliacion;
    estadoCuentaPago.facturaDocumentoTipo = item.attributes.factura_documento_tipo;
    estadoCuentaPago.facturaFolio = item.attributes.factura_folio;
    estadoCuentaPago.facturaFecha = item.attributes.factura_fecha;
    estadoCuentaPago.facturaPdfUrl = item.attributes.factura_pdf_url;
    estadoCuentaPago.monto = item.attributes.monto;
    estadoCuentaPago.medioPagoId = item.attributes.medio_pago_id;
    estadoCuentaPago.observacion = item.attributes.observacion;
    estadoCuentaPago.estadoCuentaId = item.attributes.estado_cuenta_id;
    estadoCuentaPago.numero = item.attributes.numero;
    estadoCuentaPago.estadoPagoEstadoId = item.attributes.estado_pago_estado_id;
    estadoCuentaPago.bancoId = item.attributes.banco_id;
    estadoCuentaPago.divisaId = item.attributes.divisa_id;
    estadoCuentaPago.divisaId = item.attributes.divisa_id;
    estadoCuentaPago.pasarelaPagoId = item.attributes.pasarelaPagoId;

    /* Includes*/
    if (item.included) {
      item.included.filter((valor) => {
        if (valor.type === 'MedioPago') {
          estadoCuentaPago.medioPago = this.medioPagoAdapter.adapt(valor);
        } else if (valor.type === 'PasarelaPago') {
          estadoCuentaPago.pasarelaPago = this.pasarelaPagoAdapter.adapt(valor);
        } else if (valor.type === 'EstadoCuenta') {
          estadoCuentaPago.estadoCuenta = this.estadoCuentaAdapter.adapt(valor);
        } else if (valor.type === 'Contacto') {
          estadoCuentaPago.contacto = this.contactoAdapter.adapt(valor);
        } else if (valor.type === 'Contrato') {
          estadoCuentaPago.contrato = this.contratoAdapter.adapt(valor);
        } else if (valor.type === 'EstadoPagoEstado') {
          estadoCuentaPago.estadoPagoEstado = this.estadoPagoEstadoAdapter.adapt(valor);
        } else if (valor.type === 'Banco') {
          estadoCuentaPago.banco = this.bancoAdapter.adapt(valor);
        } else if (valor.type === 'Ejecutivo') {
          estadoCuentaPago.ejecutivo = {} as EjecutivoInterface;
          estadoCuentaPago.ejecutivo.id = valor.attributes.contacto_id;
        }
      });
    }

    /* Relationship */
    if (item.relationship && item.relationship.estadoCuentaDetalle) {
      estadoCuentaPago.estadoCuentaDetalles = item.relationship.estadoCuentaDetalle.data
        .map(estadoCuentaDetalle => this.estadoCuentaDetalleAdapter.adapt(estadoCuentaDetalle) );
    }
    if (item.relationship && item.relationship.adjunto && item.relationship.adjunto.data) {
      estadoCuentaPago.adjuntos = item.relationship.adjunto.data.map(data =>
        this.adjuntoAdapter.adapt(data)
      );
    }
    if (item.relationship && item.relationship.reverso && item.relationship.reverso.data) {
      estadoCuentaPago.reversos = item.relationship.reverso.data.map(data =>
        this.reversoAdapter.adapt(data)
      );
    }

    return estadoCuentaPago;
  }
}
