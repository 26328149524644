import { CargoClasificacion } from './cargoClasificacion.model';
import { Concepto, ContratoUnidad } from './model.index';

export class ContratoUnidadDetalle {

    private _id: string;
    private _contratoUnidadId: string;
    private _nombre: string;
    private _conceptoId: number;
    private _cargoClasificacionId?: number;
    private _montoTipoId: number;
    private _monto: number;
    private _descuentoRecargoConfiguracionId: string;
    private _divisaId: string;
    private _observacion: string;
    private _isAfectoImpuesto: boolean;
    private _descuentoRecargoConceptoAplicaId: number;
    private _cicloCantidad: number;
    private _cicloFecha: string;
    private _cicloOpcion: boolean;
    private _cuentaRecaudadoraId: string;
    private _fechaInicio: string;
    private _fechaTermino: string;
    private _montoTotal: number;
    private _isFacturableInt: boolean;
    private _createdAt: string;
    private _createdBy: string;

    private _concepto: Concepto;
    private _contratoUnidad: ContratoUnidad;
    private _cargoClasificacion?: CargoClasificacion;

    private _meta: {conceptoId: number, divisaId: string, monto: number};

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get contratoUnidadId(): string {
        return this._contratoUnidadId;
    }
    public set contratoUnidadId(value: string) {
        this._contratoUnidadId = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get conceptoId(): number {
        return this._conceptoId;
    }
    public set conceptoId(value: number) {
        this._conceptoId = value;
    }
    public get cargoClasificacionId(): number {
        return this._cargoClasificacionId;
    }
    public set cargoClasificacionId(value: number) {
        this._cargoClasificacionId = value;
    }
    public get montoTipoId(): number {
        return this._montoTipoId;
    }
    public set montoTipoId(value: number) {
        this._montoTipoId = value;
    }
    public get monto(): number {
        return this._monto;
    }
    public set monto(value: number) {
        this._monto = value;
    }
    public get descuentoRecargoConfiguracionId(): string {
        return this._descuentoRecargoConfiguracionId;
    }
    public set descuentoRecargoConfiguracionId(value: string) {
        this._descuentoRecargoConfiguracionId = value;
    }
    get divisaId(): string {
        return this._divisaId;
    }
    set divisaId(value: string) {
        this._divisaId = value;
    }
    public get observacion(): string {
        return this._observacion;
    }
    public set observacion(value: string) {
        this._observacion = value;
    }
    public get isAfectoImpuesto(): boolean {
        return this._isAfectoImpuesto;
    }
    public set isAfectoImpuesto(value: boolean) {
        this._isAfectoImpuesto = value;
    }
    public get descuentoRecargoConceptoAplicaId(): number {
        return this._descuentoRecargoConceptoAplicaId;
    }
    public set descuentoRecargoConceptoAplicaId(value: number) {
        this._descuentoRecargoConceptoAplicaId = value;
    }
    public get cicloCantidad(): number {
        return this._cicloCantidad;
    }
    public set cicloCantidad(value: number) {
        this._cicloCantidad = value;
    }
    public get cicloFecha(): string {
        return this._cicloFecha;
    }
    public set cicloFecha(value: string) {
        this._cicloFecha = value;
    }
    public get cicloOpcion(): boolean {
        return this._cicloOpcion;
    }
    public set cicloOpcion(value: boolean) {
        this._cicloOpcion = value;
    }
    public get cuentaRecaudadoraId(): string {
        return this._cuentaRecaudadoraId;
    }
    public set cuentaRecaudadoraId(value: string) {
        this._cuentaRecaudadoraId = value;
    }
    public get fechaInicio(): string {
        return this._fechaInicio;
    }
    public set fechaInicio(value: string) {
        this._fechaInicio = value;
    }
    public get fechaTermino(): string {
        return this._fechaTermino;
    }
    public set fechaTermino(value: string) {
        this._fechaTermino = value;
    }
    public get montoTotal(): number {
        return this._montoTotal;
    }
    public set montoTotal(value: number) {
        this._montoTotal = value;
    }
    public get isFacturableInt(): boolean {
        return this._isFacturableInt;
    }
    public set isFacturableInt(value: boolean) {
        this._isFacturableInt = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }

    public get concepto(): Concepto {
        return this._concepto;
    }
    public set concepto(value: Concepto) {
        this._concepto = value;
    }
    public get contratoUnidad(): ContratoUnidad {
        return this._contratoUnidad;
    }
    public set contratoUnidad(value: ContratoUnidad) {
        this._contratoUnidad = value;
    }
    public get cargoClasificacion(): CargoClasificacion {
        return this._cargoClasificacion;
    }
    public set cargoClasificacion(cargoClasificacion: CargoClasificacion) {
        this._cargoClasificacion = cargoClasificacion;
    }

    get meta(): { conceptoId: number; divisaId: string; monto: number } {
        return this._meta;
    }

    set meta(value: { conceptoId: number; divisaId: string; monto: number }) {
        this._meta = value;
    }
}
