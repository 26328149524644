import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { MedioLlegada } from './../models/medio-llegada.model';

@Injectable({
    providedIn: 'root'
})

export class MedioLlegadaAdapter implements Adapter<MedioLlegada> {
    adapt(item: any): MedioLlegada {
        const medioLlegada = new MedioLlegada();
        medioLlegada.id = item.attributes.id;
        medioLlegada.nombre = item.attributes.nombre;
        medioLlegada.lang_resource = item.attributes.lang_resource;
        return medioLlegada;
    }
}
