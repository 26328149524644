import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContratoAnexoEstado } from '../models/contrato-anexo-estado.model';

@Injectable({
    providedIn: 'root'
  })
  export class ContratoAnexoEstadoAdapter implements Adapter<ContratoAnexoEstado> {
    adapt(item: any): ContratoAnexoEstado {
        const contratoAnexoEstado = new ContratoAnexoEstado();
        contratoAnexoEstado.id = item.attributes.id;
        contratoAnexoEstado.nombre = item.attributes.nombre;
        return contratoAnexoEstado;
    }
  }
