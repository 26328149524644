<div class="table-responsive">
    <table class="table tabla-pop">
        <thead class="header-listas">
            <tr>
                <th *ngFor="let row of rowsList">
                    <ngx-skeleton-loader [theme]="{background: '#7884a6', height: '15px'}" [count]="1" appearance="line"></ngx-skeleton-loader>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let col of colsList">
                <td *ngFor="let row of rowsList">
                    <ngx-skeleton-loader [count]="1" appearance="line"></ngx-skeleton-loader>
                </td>
            </tr>
        </tbody>
    </table>
</div>
