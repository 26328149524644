import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalContactoComponent } from './modal-contacto.component';
import { ContactoDetalleModule } from '../contacto-detalle/contacto-detalle.module';
import { TranslateModule } from '@ngx-translate/core';
import { AbilityModule } from '@casl/angular';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        ContactoDetalleModule,
        TranslateModule,
        AbilityModule
    ],
    exports: [ ModalContactoComponent],
    declarations: [ ModalContactoComponent ]
})

export class ModalContactoModule { }
