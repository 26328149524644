export class ReservaEstado {
  private _id: number;
  private _nombre: string;
  private _langResource: string;


  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get nombre(): string {
    return this._nombre;
  }

  set nombre(value: string) {
    this._nombre = value;
  }

  get langResource(): string {
    return this._langResource;
  }

  set langResource(value: string) {
    this._langResource = value;
  }
}
