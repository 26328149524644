import { Propiedad, Contacto } from 'src/app/models/model.index';
import { Unidad } from './unidad.model';
import { Contrato } from './contrato.model';
import { MedioPago } from './medio-pago.model';
import { EstadoCuentaPago } from './estado-cuenta-pago.model';
import { CuentaRecaudadora } from './cuenta-recaudadora.model';
import { EstadoCuentaDetalle } from './estado-cuenta-detalle.model';
import { Concepto } from './concepto.model';
import { TransaccionConcepto } from './transaccion-concepto.model';
import { TransaccionTipo } from './transaccion-tipo.model';
import { CargoClasificacion } from './cargoClasificacion.model';

export class Transaccion {

    private _id: string;
    private _contactoId: string;
    private _propiedadId: string;
    private _unidadId: string;
    private _contratoId: string;
    private _transaccionConceptoId: number;
    private _transaccionTipoId: number;
    private _medioPagoId: number;
    private _monto: number;
    private _divisaId: string;
    private _pasarelaPagoId: number;
    private _estadoCuentaPagoId: string;
    private _estadoCuentaDetalleId: string;
    private _estadoCuentaDetalleConceptoId: number;
    private _estadoCuentaDetalleCargoClasificacionId?: number;
    private _estadoCuentaDetalleIsFacturableInt?: boolean;
    private _reversoId: string;
    private _cuentaRecaudadoraIngresoId: string;
    private _cuentaRecaudadoraEgresoId: string;
    private _egresoId: string;
    private _fecha: string;
    private _ejecutivoId: string;

    private _contacto: Contacto;
    private _propiedad: Propiedad;
    private _unidad: Unidad;
    private _contrato: Contrato;
    private _transaccionConcepto: TransaccionConcepto;
    private _transaccionTipo: TransaccionTipo;
    private _medioPago: MedioPago;
    private _estadoCuentaPago: EstadoCuentaPago;
    private _cuentaRecaudadoraIngreso: CuentaRecaudadora;
    private _cuentaRecaudadoraEgreso: CuentaRecaudadora;
    private _estadoCuentaDetalle: EstadoCuentaDetalle;
    private _estadoCuentaDetalleConcepto: Concepto;
    private _ejecutivo: Contacto;
    private _cargoClasificacion?: CargoClasificacion;
    private _egresoTipoNombre: string;
    private _egresoAccionNombre: string
    private _egresoClasificacion: string

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get contactoId(): string {
        return this._contactoId;
    }
    public set contactoId(value: string) {
        this._contactoId = value;
    }
    public get propiedadId(): string {
        return this._propiedadId;
    }
    public set propiedadId(value: string) {
        this._propiedadId = value;
    }
    public get unidadId(): string {
        return this._unidadId;
    }
    public set unidadId(value: string) {
        this._unidadId = value;
    }
    public get contratoId(): string {
        return this._contratoId;
    }
    public set contratoId(value: string) {
        this._contratoId = value;
    }
    public get transaccionConceptoId(): number {
        return this._transaccionConceptoId;
    }
    public set transaccionConceptoId(value: number) {
        this._transaccionConceptoId = value;
    }
    public get transaccionTipoId(): number {
        return this._transaccionTipoId;
    }
    public set transaccionTipoId(value: number) {
        this._transaccionTipoId = value;
    }
    public get medioPagoId(): number {
        return this._medioPagoId;
    }
    public set medioPagoId(value: number) {
        this._medioPagoId = value;
    }
    public get monto(): number {
        return this._monto;
    }
    public set monto(value: number) {
        this._monto = value;
    }
    get divisaId(): string {
        return this._divisaId;
    }
    set divisaId(value: string) {
        this._divisaId = value;
    }
    public get pasarelaPagoId(): number {
        return this._pasarelaPagoId;
    }
    public set pasarelaPagoId(value: number) {
        this._pasarelaPagoId = value;
    }
    public get estadoCuentaPagoId(): string {
        return this._estadoCuentaPagoId;
    }
    public set estadoCuentaPagoId(value: string) {
        this._estadoCuentaPagoId = value;
    }
    public get estadoCuentaDetalleId(): string {
        return this._estadoCuentaDetalleId;
    }
    public set estadoCuentaDetalleId(value: string) {
        this._estadoCuentaDetalleId = value;
    }
    public get estadoCuentaDetalleConceptoId(): number {
        return this._estadoCuentaDetalleConceptoId;
    }
    public set estadoCuentaDetalleConceptoId(value: number) {
        this._estadoCuentaDetalleConceptoId = value;
    }
    public get estadoCuentaDetalleCargoClasificacionId(): number {
        return this._estadoCuentaDetalleCargoClasificacionId;
    }
    public set estadoCuentaDetalleCargoClasificacionId(estadoCuentaDetalleCargoClasificacionId: number) {
        this._estadoCuentaDetalleCargoClasificacionId = estadoCuentaDetalleCargoClasificacionId;
    }
    public get estadoCuentaDetalleIsFacturableInt(): boolean {
        return this._estadoCuentaDetalleIsFacturableInt;
    }
    public set estadoCuentaDetalleIsFacturableInt(estadoCuentaDetalleIsFacturableInt: boolean) {
        this._estadoCuentaDetalleIsFacturableInt = estadoCuentaDetalleIsFacturableInt;
    }
    public get reversoId(): string {
        return this._reversoId;
    }
    public set reversoId(value: string) {
        this._reversoId = value;
    }
    public get cuentaRecaudadoraIngresoId(): string {
        return this._cuentaRecaudadoraIngresoId;
    }
    public set cuentaRecaudadoraIngresoId(value: string) {
        this._cuentaRecaudadoraIngresoId = value;
    }
    public get cuentaRecaudadoraEgresoId(): string {
        return this._cuentaRecaudadoraEgresoId;
    }
    public set cuentaRecaudadoraEgresoId(value: string) {
        this._cuentaRecaudadoraEgresoId = value;
    }
    public get egresoId(): string {
        return this._egresoId;
    }
    public set egresoId(value: string) {
        this._egresoId = value;
    }
    public get fecha(): string {
        return this._fecha;
    }
    public set fecha(value: string) {
        this._fecha = value;
    }
    public get ejecutivoId(): string {
        return this._ejecutivoId;
    }
    public set ejecutivoId(value: string) {
        this._ejecutivoId = value;
    }


    public get contacto(): Contacto {
        return this._contacto;
    }
    public set contacto(value: Contacto) {
        this._contacto = value;
    }
    public get propiedad(): Propiedad {
        return this._propiedad;
    }
    public set propiedad(value: Propiedad) {
        this._propiedad = value;
    }
    public get unidad(): Unidad {
        return this._unidad;
    }
    public set unidad(value: Unidad) {
        this._unidad = value;
    }
    public get contrato(): Contrato {
        return this._contrato;
    }
    public set contrato(value: Contrato) {
        this._contrato = value;
    }
    public get transaccionConcepto(): TransaccionConcepto {
        return this._transaccionConcepto;
    }
    public set transaccionConcepto(value: TransaccionConcepto) {
        this._transaccionConcepto = value;
    }
    public get transaccionTipo(): TransaccionTipo {
        return this._transaccionTipo;
    }
    public set transaccionTipo(value: TransaccionTipo) {
        this._transaccionTipo = value;
    }
    public get medioPago(): MedioPago {
        return this._medioPago;
    }
    public set medioPago(value: MedioPago) {
        this._medioPago = value;
    }
    public get estadoCuentaPago(): EstadoCuentaPago {
        return this._estadoCuentaPago;
    }
    public set estadoCuentaPago(value: EstadoCuentaPago) {
        this._estadoCuentaPago = value;
    }
    public get cuentaRecaudadoraIngreso(): CuentaRecaudadora {
        return this._cuentaRecaudadoraIngreso;
    }
    public set cuentaRecaudadoraIngreso(value: CuentaRecaudadora) {
        this._cuentaRecaudadoraIngreso = value;
    }
    public get cuentaRecaudadoraEgreso(): CuentaRecaudadora {
        return this._cuentaRecaudadoraEgreso;
    }
    public set cuentaRecaudadoraEgreso(value: CuentaRecaudadora) {
        this._cuentaRecaudadoraEgreso = value;
    }
    public get estadoCuentaDetalle(): EstadoCuentaDetalle {
        return this._estadoCuentaDetalle;
    }
    public set estadoCuentaDetalle(value: EstadoCuentaDetalle) {
        this._estadoCuentaDetalle = value;
    }
    public get estadoCuentaDetalleConcepto(): Concepto {
        return this._estadoCuentaDetalleConcepto;
    }
    public set estadoCuentaDetalleConcepto(value: Concepto) {
        this._estadoCuentaDetalleConcepto = value;
    }
    public get ejecutivo(): Contacto {
        return this._ejecutivo;
    }
    public set ejecutivo(value: Contacto) {
        this._ejecutivo = value;
    }
    public get cargoClasificacion(): CargoClasificacion {
        return this._cargoClasificacion;
    }
    public set cargoClasificacion(cargoClasificacion: CargoClasificacion) {
        this._cargoClasificacion = cargoClasificacion;
    }
    public get egresoTipoNombre(): string {
        return this._egresoTipoNombre;
    }
    public set egresoTipoNombre(value: string) {
        this._egresoTipoNombre = value;
    }
    public get egresoAccionNombre(): string {
        return this._egresoAccionNombre;
    }
    public set egresoAccionNombre(value: string) {
        this._egresoAccionNombre = value;
    }
    public get egresoClasificacion(): string {
        return this._egresoClasificacion;
    }
    public set egresoClasificacion(value: string) {
        this._egresoClasificacion = value;
    }
}
