import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { UnidadTipo } from './../models/unidad-tipo.model';

@Injectable({
    providedIn: 'root'
  })
  export class UnidadTipoAdapter implements Adapter<UnidadTipo> {
    adapt(item: any): UnidadTipo {
        const unidadTipo = new UnidadTipo();
        unidadTipo.id = item.attributes.id;
        unidadTipo.nombre = item.attributes.nombre;
        unidadTipo.isActive = item.attributes.is_active;
        unidadTipo.orden = item.attributes.orden;
        unidadTipo.lang_resource = item.attributes.lang_resource;
        return unidadTipo;
    }
  }
