import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {BaseModel} from '../models/base.model';

@Injectable({
  providedIn: 'root'
})
export class BaseAdapter implements Adapter<BaseModel> {
  adapt(item: any): BaseModel {
    const baseModel = new BaseModel();
    baseModel.id = item.attributes.id;
    baseModel.nombre = item.attributes.nombre;
    return baseModel;
  }
}
