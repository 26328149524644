<div class="pop-drawer-md">
    <div class="drawer-title">
        <h2>Fechas del Negocio</h2>
        <hr>
    </div>
    <div class="w-100">
        <form [formGroup]="formGroup">
            <mat-form-field class="date-picker w-100" appearance="outline" *ngIf="type === 'Cotizacion'">
                <mat-label>Fecha</mat-label>
                <input matInput formControlName="fecha" [matDatepicker]="fecha" required autocomplete="off" [max]="maxDate"/>
                <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                <mat-datepicker #fecha></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="date-picker w-100" appearance="outline">
                <mat-label>Fecha Inicio</mat-label>
                <input matInput formControlName="fechaInicio" [matDatepicker]="fechaInicio" required autocomplete="off" [min]="minFechaInicio"/>
                <mat-datepicker-toggle matSuffix [for]="fechaInicio"></mat-datepicker-toggle>
                <mat-datepicker #fechaInicio></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="date-picker w-100" appearance="outline">
                <mat-label>Fecha Fin</mat-label>
                <input matInput formControlName="fechaFin" [matDatepicker]="fechaFin" required autocomplete="off" [min]="minFechaFin"/>
                <mat-datepicker-toggle matSuffix [for]="fechaFin"></mat-datepicker-toggle>
                <mat-datepicker #fechaFin></mat-datepicker>
                <mat-hint *ngIf="hasFechaInicio" class="w-100">
                    <div class="d-flex justify-content-between">
                        <button mat-stroked-button color="primary" class="btn-form" (click)="setFechaFinByOption('mensual')">Mensual</button>
                        <button mat-stroked-button color="primary" class="btn-form" (click)="setFechaFinByOption('trimestral')">Trimestral</button>
                        <button mat-stroked-button color="primary" class="btn-form" (click)="setFechaFinByOption('semestral')">Semestral</button>
                        <button mat-stroked-button color="primary" class="btn-form" (click)="setFechaFinByOption('anual')">Anual</button>
                    </div>
                </mat-hint>
            </mat-form-field>
        </form>
    </div>
    <div class="sidebar-action-buttons">
        <button class="pop-btn-cancel pop-btn-md" type="button"  (click)="closeSidebarEmitter.emit()">Cancelar</button>
        <button [disabled]="disableButton" class="pop-btn-primary pop-btn-md" type="button" (click)="save()">Guardar</button>
    </div>
</div>
