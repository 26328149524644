import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Banco } from 'src/app/models/model.index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BancoAdapter } from 'src/app/adapter/banco.adapter';

@Injectable({
  providedIn: 'root'
})
export class BancoService {

  constructor(
    public http: HttpClient,
    public bancoAdapter: BancoAdapter
  ) { }

  cargarBancos(): Observable <Banco[]> {
    return this.http.get(environment.urlApi + 'bancos')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(bancos => this.bancoAdapter.adapt(bancos));
        })
      );
  }

}
