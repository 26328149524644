import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagenService {

  constructor(
    public http: HttpClient
  ) { }

  getImagen(img: string, tipo: string, id: string): Observable<Blob> {
    const url = environment.urlApi + tipo + '/' + id + '/' + 'imagenes/' + img + '/download';
    return this.http.get(url, { responseType: 'blob' });
  }
}

