import { Injectable } from '@angular/core';
import { ContactoAdapter } from './contacto.adapter';
import { ContratoEstadoAdapter } from './contrato-estado.adapter';
import { ContratoSubEstadoAdapter } from './contrato-subestado.adapter';
import { PropiedadAdapter } from './propiedad.adapter';
import { ContratoConfiguracionAdapter } from './contrato-configuracion.adapter';
import { Adapter } from './adapter';
import { Contrato } from '../models/contrato.model';


@Injectable({
    providedIn: 'root'
  })
  export class UnidadContratoAdapter implements Adapter<Contrato> {

    constructor(
        public contactoAdapter: ContactoAdapter,
        public contratoEstadoAdapter: ContratoEstadoAdapter,
        public contratoSubEstadoAdapter: ContratoSubEstadoAdapter,
        public propiedadAdapter: PropiedadAdapter,
        public contratoConfiguracionAdapter: ContratoConfiguracionAdapter
    ) {}

    adapt(item: any): Contrato {
        const contrato = new Contrato();
        contrato.id = item.attributes.id;
        contrato.folio = item.attributes.folio;
        contrato.ejecutivoId = item.attributes.ejecutivo_id;
        contrato.estadoId = item.attributes.estado_id;
        contrato.fecha = item.attributes.fecha;
        contrato.fechaInicio = item.attributes.fecha_inicio;
        contrato.fechaFin = item.attributes.fecha_fin;
        contrato.fechaRealTermino = item.attributes.fecha_real_fin_contrato;
        contrato.fechaFinAnticipada = item.attributes.fecha_fin_anticipada;
        contrato.contratoConfiguracionId = item.attributes.contrato_configuracion_id;
        contrato.contratoTemplateId = item.attributes.contrato_template_id;
        contrato.observacion = item.attributes.observacion;
        contrato.tocDocumentId = item.attributes.toc_document_id;
        contrato.documentoAdjuntoId = item.attributes.documento_adjunto_id;
        contrato.documentoBaseAdjuntoId = item.attributes.documento_base_adjunto_id;
        contrato.cotizacionid = item.attributes.cotizacion_id;
        contrato.isCreatePrimerCobro = item.attributes.is_create_primer_cobro;
        contrato.propiedadId = item.attributes.propiedad_id;
        contrato.createdAt = item.attributes.created_at;
        contrato.createdBy = item.attributes.created_by;
        contrato.esHistorico = item.attributes.es_historico;
        contrato.folioNumerico = item.attributes.folio_numerico;
        contrato.interesPorDia = item.attributes.interes_por_dia;
        contrato.renovacionCantidad = item.attributes.renovacion_cantidad;
        contrato.subEstadoId = item.attributes.sub_estado_id;
        contrato.reajusteFecha = item.attributes.reajuste_fecha;

        /* Includes */
        if (item.included) {
            item.included.filter((valor) => {
                switch (valor.type) {
                    case 'ContratoEstado':
                        contrato.contratoEstado = this.contratoEstadoAdapter.adapt(valor);
                        break;
                    case 'ContratoSubEstado':
                        contrato.contratoSubEstado = this.contratoSubEstadoAdapter.adapt(valor);
                        break;
                    case 'Propiedad':
                        contrato.propiedad = this.propiedadAdapter.adapt(valor);
                        break;
                    case 'Contacto':
                        if (valor.attributes.id === contrato.ejecutivoId) {
                            contrato.ejecutivo = this.contactoAdapter.adapt(valor);
                        }
                        break;
                    case 'ContratoConfiguracion':
                        contrato.contratoConfiguracion = this.contratoConfiguracionAdapter.adapt(valor);
                        break;
                }
            });
        }
        return contrato;
    }
  }
