export class ContratoSubEstado {

    private _id: string;
    private _nombre: string;
    private _lang_resource: string;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get langResource(): string {
        return this._lang_resource;
    }
    public set langResource(value: string) {
        this._lang_resource = value;
    }
}
