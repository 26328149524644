export class MontoTipo {
    private _id: number;
    private _nombre: string;
    private _simbolo: string;


    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get nombre(): string {
        return this._nombre;
    }

    set nombre(value: string) {
        this._nombre = value;
    }

    get simbolo(): string {
        return this._simbolo;
    }

    set simbolo(value: string) {
        this._simbolo = value;
    }
}
