import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AbilityService } from 'src/app/services/ability/ability.service';

@Component({
  selector: 'app-modal-detalle-check-calendario',
  templateUrl: './modal-detalle-check-calendario.component.html',
  styleUrls: ['./modal-detalle-check-calendario.component.css']
})
export class ModalDetalleCheckCalendario implements OnInit {
  tableHeader:string[] = ['checkstate.type', 'checkstate.folio', 'checkstate.unit', 'general.status', 'checkstate.responsable', 'general.actions']

  constructor(
    public dialogRef: MatDialogRef<ModalDetalleCheckCalendario>,
    public toastr: ToastrService,
    private ability: AbilityService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get canUpdateCheckstate(){
    return this.ability.hasAccion('CheckUnidadUpdate');
  }
}
