import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {Periodicidad} from '../models/periodicidad.model';

@Injectable({
    providedIn: 'root'
})
export class PeriodicidadAdapter implements Adapter<Periodicidad> {
    adapt(item: any): Periodicidad {
        const periodicidad = new Periodicidad();
        periodicidad.id = item.attributes.id;
        periodicidad.nombre = item.attributes.nombre;
        periodicidad.formato = item.attributes.formato;
        periodicidad.descripcion = item.attributes.descripcion;
        return periodicidad;
    }
}
