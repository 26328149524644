import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Paginador } from 'src/app/models/model.index';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import {
  ActualizarEstado,
  Adjunto,
  CheckFiltro,
  CheckInfo,
  ComentarioDetalleCheck,
  ComentarioGeneralCheck,
  ComentarioInternoCheck,
  CommentObject,
  ElementoLista,
  NewCost
} from '../../models/checkestate.model'
import { CalendarEvent } from '../../models/calendario.model'
import { Utils } from 'src/app/shared/utils';
import { map } from 'rxjs/operators';

moment.locale('es-CL')

/**
 * Obtener los elementos para el componente que muestra la lista con los distintos check
 * @param {Object[]} resp - Respuesta de la api
 * @returns {Object[]} - Elementos para el componente que muestra la lista con los distintos check
 */
const obtenerElementosListaCheck = (resp: any[]) => {
  const elementosLista :ElementoLista[] = []

  resp.forEach((e:any) => {
    const infoAdicional:any = {}

    e.included.forEach((f:any) => {
      if(f.type){
        infoAdicional[f.type]= f
      }
    })

    const fecha = e.attributes.fecha_inicio.split(' ')
    const hora = fecha[1].split(':')

    elementosLista.push({
      contratoId: e.attributes.contrato_id,
      estado: infoAdicional.CheckEstado.attributes.nombre,
      estadoId: infoAdicional.CheckEstado.attributes.id,
      fecha: moment(fecha[0]).format('DD/MM/YYYY'),
      fechaCompleta: fecha[0],
      folio: e.attributes.folio,
      hora: `${hora[0]}:${hora[1]}`,
      id: e.attributes.id,
      nombreArrendatario: infoAdicional.Contacto.attributes.nombre,
      nombrePropiedad: infoAdicional.Propiedad.attributes.nombre,
      nombreResponsable: `${e.relationship.ejecutivo.included[0].attributes.nombre} ${e.relationship.ejecutivo.included[0].attributes.apellido}`,
      nombreUnidad: infoAdicional.Unidad.attributes.nombre,
      rut: infoAdicional.Contacto.attributes.identificador || '',
      identificadorTipoId: infoAdicional.Contacto.attributes.identificador_tipo_id,
      tipoCheck: infoAdicional.CheckTipo.attributes.nombre,
      tipoCheckId: infoAdicional.CheckTipo.attributes.id,
      unidadId: e.attributes.unidad_id
    })
  })

  return elementosLista
}

const obtenerDataCheck = (resp: any):CheckInfo => {
  const fecha = resp.attributes?.fecha_inicio || ''
  const fechaTermino = resp.attributes?.fecha_termino || ''

  const data:CheckInfo = {
    checkTipoId: resp.attributes.check_tipo_id,
    comentariosInternos: [],
    comentariosGenerales: {
      equipamiento: [],
      entregables: [],
      lecturas: [],
      costos: [],
      participantes: []
    },
    contactoCorreo: '',
    contactoId: '',
    contactoIdentificador: '',
    contactoIdentificadorId: 1,
    contactoNombre: '',
    contactoTelefono: '',
    contratoId: resp.attributes.contrato_id,
    costos: {
      Entregables: [],
      Extras: [],
      Lecturas: [],
      Equipamiento: [],
      total: 0
    },
    ejecutivoCorreo: '',
    ejecutivoId: resp.attributes.ejecutivo_id,
    ejecutivoNombre: '',
    ejecutivoTelefono: '',
    entregable: [],
    entregableEnEdicion: [],
    equipamiento: [],
    equipamientoEnEdicion: [],
    estadoId: null,
    estadoNombre: '',
    fecha: fecha ? moment(fecha).format('DD [de] MMMM YYYY [- A las] hh:mm A') : '',
    fechaTermino: fechaTermino ? moment(fechaTermino).format('DD [de] MMMM YYYY') : '',
    folio: resp.attributes?.folio,
    id: resp.attributes.id,
    imagenes: {
      Equipamiento: [],
      Entregables: [],
      Lecturas: []
    },
    lectura: [],
    lecturaEnEdicion: [],
    nombrePropiedad: '',
    nombreTipoUnidad: '',
    nombreUnidad: '',
    padreId: resp.attributes.parent_id || null,
    unidadId: resp.attributes.unidad_id,
    unidades: []
  }

  resp.included.forEach(included => {
    if (included.type === 'Propiedad') {
      data.nombrePropiedad = included.attributes.nombre
    }

    if (included.type === 'Unidad') {
      data.nombreUnidad = included.attributes.nombre
    }

    if (included.type === 'UnidadTipo') {
      data.nombreTipoUnidad = included.attributes.nombre
      data.langTipoUnidad  = included.attributes.lang_resource;
    }

    if (included.type === 'CheckEstado') {
      data.estadoId = included.attributes.id
      data.estadoNombre = included.attributes.nombre
    }

    if (included.type === 'Contacto') {
      data.contactoCorreo = included.attributes.email
      data.contactoId = included.attributes.id
      data.contactoIdentificador = included.attributes.identificador
      data.contactoIdentificadorId = included.attributes.identificador_tipo_id
      data.contactoNombre = included.attributes.nombre
      data.contactoTelefono = included.attributes.telefono
    }
  })

  resp.relationship.ejecutivo.included.forEach(included => {
    if (included.type === 'Contacto') {
      data.ejecutivoCorreo = included.attributes.email
      data.ejecutivoNombre = `${included.attributes.nombre} ${included.attributes.apellido} `
      data.ejecutivoTelefono = included.attributes.telefono
    }
  })

  resp.relationship.checkUnidadEquipamiento.data?.forEach(e => {
    data.equipamiento.push({
      concepto: e.attributes.nombre,
      evaluacionCheckin: resp.attributes.check_tipo_id === 200
        ? ''
        : e.attributes.valor === '0'
        ? ''
        : e.attributes.valor,
      observacion: e.attributes.observacion,
      evaluacionCheckout: resp.attributes.check_tipo_id === 200
        ? e.attributes.valor === '0'
          ? ''
          : e.attributes.valor
        : '',
      id: e.attributes.id,
      parentId: e.attributes.parent_id || ''
    })

    data.equipamientoEnEdicion.push({
      nombre: e.attributes.nombre,
      observacion: e.attributes.observacion || '',
      valor: e.attributes.valor,
      id: e.attributes.id,
    })
  })

  resp.relationship.checkUnidadEntregas.data?.forEach(e => {
    data.entregable.push({
      cantidadCheckin: resp.attributes.check_tipo_id === 100 ? e.attributes.valor : '',
      cantidadCheckout: resp.attributes.check_tipo_id === 200 ? e.attributes.valor : '',
      concepto: e.attributes.nombre,
      devolucion: e.attributes.tipo === "CON_DEVOLUCION" ? 'Si' : 'No',
      id: e.attributes.id,
      parentId: e.attributes.parent_id || ''
    })

    data.entregableEnEdicion.push({
      nombre: e.attributes.nombre,
      observacion: e.attributes.observacion || '',
      tipo: e.attributes.tipo,
      valor: e.attributes.valor,
      id: e.attributes.id,
    })
  })

  resp.relationship.checkUnidadLecturas.data?.forEach(e => {
    data.lectura.push({
      concepto: e.attributes.nombre,
      id: e.attributes.id,
      indicadorCheckin: resp.attributes.check_tipo_id === 100 ? e.attributes.valor : '',
      indicadorCheckout: resp.attributes.check_tipo_id === 200 ? e.attributes.valor : '',
      parentId: e.attributes.parent_id || ''
    })

    data.lecturaEnEdicion.push({
      nombre: e.attributes.nombre,
      observacion: e.attributes.observacion || '',
      valor: e.attributes.valor,
      id: e.attributes.id,
    })
  })

  const types = new Map(resp.relationship.contratoUnidad.included.filter(e => e.type === 'UnidadTipo').map(e => [e.id, e.attributes.lang_resource]))

  resp.relationship.contratoUnidad.included.forEach(e => {
    if (e.type === 'Unidad') {
      data.unidades.push({
        tipo: types.get(e.attributes.unidad_tipo_id),
        nombre: e.attributes.nombre
      })
    }
  })

  let comentariosInternos:ComentarioDetalleCheck[] = []

  resp.relationship.comentariosInternos?.data?.forEach(e => {
    const adjuntos:Adjunto[] = e.relationship?.adjuntos ?
      e.relationship.adjuntos.data.map(f => ({id: f.attributes.id, nombre: f.attributes.nombre})) :
      []

    const contact = e.included.find(e => e.type === 'Contacto')
    const comment = e.included.find(e => e.type === 'Comentario')

    comentariosInternos.push({
      adjuntos,
      asunto: comment.attributes.clasificacion,
      comentario: comment.attributes.comentario,
      comentarioId: comment.attributes.id,
      creador: `${contact.attributes.nombre} ${contact.attributes.apellido}`,
      creadorIniciales: `${contact.attributes.nombre[0]}${contact.attributes.apellido[0]}`,
      fechaCreacion: comment.attributes.fecha
    })
  })

  data.comentariosInternos = comentariosInternos
    .sort((a, b) => (a.fechaCreacion > b.fechaCreacion ? 1 : -1))
    .map(e => ({...e, fechaCreacion: moment(e.fechaCreacion).format('DD/MM/YYYY')}))

  resp.relationship.comentariosGenerales?.data?.forEach(e => {
    const contact = e.included.find(e => e.type === 'Contacto')
    const comment = e.included.find(e => e.type === 'Comentario')
    const subject:string = comment.attributes.clasificacion

    data.comentariosGenerales[subject].push({
      asunto: subject,
      comentario: comment.attributes.comentario,
      comentarioId: comment.attributes.id,
      creador: `${contact.attributes.nombre} ${contact.attributes.apellido}`,
      creadorIniciales: `${contact.attributes.nombre[0]}${contact.attributes.apellido[0]}`,
      fechaCreacion: comment.attributes.fecha
    })
  })

  for (const type in data.comentariosGenerales) {
    data.comentariosGenerales[type] = data.comentariosGenerales[type]
      .sort((a, b) => (a.fechaCreacion > b.fechaCreacion ? 1 : -1))
      .map(e => ({...e, fechaCreacion: moment(e.fechaCreacion).format('DD/MM/YYYY')}))
  }

  resp.relationship.imagenesAdjuntas?.data?.forEach(e => {
    const contact = e.included.find(f => f.type === 'Contacto')
    const attachedImages = e.relationship.adjuntos.data
    .map(f => ({
      comentario: f.attributes.descripcion || '',
      creador: `${contact.attributes.nombre} ${contact.attributes.apellido}`,
      creadorIniciales: `${contact.attributes.nombre[0]}${contact.attributes.apellido[0]}`,
      fechaCreacion: e.attributes.fecha,
      imagenId: f.attributes.id,
      imagenNombre: f.attributes.nombre,
      imagenSrc: '',
      unidadId: e.attributes.check_unidad_id
    }))
    .sort((a, b) => (a.fechaCreacion > b.fechaCreacion ? 1 : -1))
    .map(e => ({...e, fechaCreacion: moment(e.fechaCreacion).format('DD/MM/YYYY')}))

    if (e.attributes.check_unidad_tipo === 'Equipamiento') {
      data.imagenes.Equipamiento = attachedImages
    } else if (e.attributes.check_unidad_tipo === 'Entregables') {
      data.imagenes.Entregables = attachedImages
    } else {
      data.imagenes.Lecturas = attachedImages
    }
  })

  resp.relationship.checkUnidadParticipantes?.data?.forEach(e => {
    data.terceroInformacion = {
      correo: e.attributes.email,
      identificador: e.attributes.identificador,
      identificadorTipoId: 1,
      nombre: e.attributes.nombre,
      telefono: e.attributes.telefono
    }
  })

  resp.relationship.checkUnidadCostos?.data?.forEach(e => {
    data.costos[e.attributes.tipo_costo].push({
      concepto: e.attributes.nombre,
      cantidad: e.attributes.cantidad,
      id: e.attributes.id,
      total: Number(e.attributes.total)
    })
    data.costos.total += Number(e.attributes.total)
  })

  return data
}

const obtenerEventosCalendario = (resp: any):CalendarEvent[]  => {
  const events: CalendarEvent[] = []
  resp.data.forEach(e => {
    const checkTipo = e.included.find(e => e.type === 'CheckTipo')
    const unitInfo = e.included.find(e => e.type === 'Unidad')

    events.push({
      color: {
        // yellow
        primary: '#F7BB10',
        secondary: '#F7BB10'
      },
      end: new Date(e.attributes.fecha_termino),
      information: {
        estadoId: e.attributes.check_estado_id,
        id: e.attributes.id,
        tipoCheck: checkTipo.attributes.nombre,
        unidadId: e.attributes.unidad_id
      },
      start: new Date(e.attributes.fecha_inicio),
      title: `${checkTipo.attributes.nombre} - ${unitInfo.attributes.nombre}`
    })
  })


  return events
}

@Injectable({
  providedIn: 'root'
})
export class CheckestateService {

public paginador: Paginador;

  constructor(
    public http: HttpClient,
    public paginadorAdapter: PaginadorAdapter,
  ) { }

  cargarElementosLista(filter?: CheckFiltro, page?: number): Observable <any> {
    let filterToApply = ''

    if (page && page > 0) {
      filterToApply += '&page=' + page;
    }
    if (filter) {
      filterToApply += Utils.generarParametrosBusqueda('check_unidad_filter', filter)
    }
    return this.http.get(`${environment.urlApi}check-unidad?${filterToApply}`)
      .pipe(
        map((resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return obtenerElementosListaCheck(resp.data);
        })
      );
  }

  cargarTiposCheck = (): Observable <any> => {
    return this.http.get(environment.urlApi + 'check-tipo')
      .pipe(
        map((resp: any) => {
          return (resp.data.map(e => e.attributes))
        })
      );
  }

  cargarEstados = (): Observable <any> => {
    return this.http.get(environment.urlApi + 'check-estado')
      .pipe(
        map((resp: any) => {
          return (resp.data.map(e => e.attributes))
        })
      );
  }

  paginadorCheck = () => {
    return this.paginador;
  }

  crearCheckin = (checkinInfo:Object): Observable <any> => {
    return this.http.post(environment.urlApi + 'check-unidad/check-in', checkinInfo);
  }

  crearCheckout = (checkoutInfo:Object): Observable <any> => {
    return this.http.post(environment.urlApi + 'check-unidad/checko-out', checkoutInfo);
  }

  cargarInformacionCheck = (checkId:string): Observable <any> => {
    return this.http.get(environment.urlApi + `check-unidad/${checkId}/view`)
      .pipe(
        map((resp: any) => obtenerDataCheck(resp.data))
      );
  }

  actualizarEstadoCheck(updates:ActualizarEstado, id: string): Observable <any> {
    return this.http.patch(`${environment.urlApi}check-unidad/${id}`, updates);
  }

  actualizarCheck(updates:Object, id: string): Observable <any> {
    return this.http.put(`${environment.urlApi}check-unidad/${id}`, updates);
  }

  cargarImagenes = (attachedImages: Object, id:string): Observable <any>  => {
    return this.http.post(`${environment.urlApi}check-unidad/${id}/imagenes/carga`, attachedImages);
  }

  crearComentarioInterno = (comment: ComentarioInternoCheck, id: string): Observable <any>  => {
    return this.http.post(`${environment.urlApi}comentario/check-unidad/interno/${id}/create`, comment);
  }

  crearComentarioGeneral = (comment: ComentarioGeneralCheck, id: string): Observable <any>  => {
    return this.http.post(`${environment.urlApi}comentario/check-unidad/general/${id}/create`, comment);
  }

  downloadImage(checkId: string, attachId: string): Observable<any> {
    const URL = `${environment.urlApi}check-unidad/${checkId}/adjuntos/${attachId}/download`
    return this.http.get(URL, { responseType: 'blob' });
  }

  actualizarComentario = (comment: CommentObject, id: string): Observable <any>  => {
    return this.http.put(`${environment.urlApi}comentarios/${id}/update`, comment);
  }

  cargarCheckUnidades(unitIds: string[], filter?: CheckFiltro): Observable <any> {
    let filterToApply = ''

    unitIds.forEach((id: string) => {
      filterToApply += `&check_unidad_filter[unidadId][]=${id}`
    })

    if (filter) {
      filterToApply += Utils.generarParametrosBusqueda('check_unidad_filter', filter)
    }

    return this.http
      .get(`${environment.urlApi}check-unidad?${filterToApply}`)
      .pipe(
        map((resp: any) => {
          return resp
        })
      )
  }

  obtenerUltimoCheckoutId (checkId: string): Observable <any> {
    return this.http
      .get(`${environment.urlApi}check-unidad/${checkId}/historial`)
      .pipe(
        map((resp: any) => {
          let checkoutInfo = resp.data?.relationship?.CheckUnidadParent?.data?.reverse().find(e => e.attributes.check_tipo_id === 200) || null
          return checkoutInfo?.id || null
        })
      )
  }

  cargarTodosChecks (filter?: CheckFiltro): Observable <any> {
    let filterToApply = ''

    if (filter) {
      filterToApply += Utils.generarParametrosBusqueda('check_unidad_filter', filter)
    }
    return this.http
      .get(`${environment.urlApi}check-unidad/all-data?${filterToApply}`)
      .pipe(
        map((resp: any) => {
          return obtenerEventosCalendario(resp)
        })
      )
  }

  enviarCorreo = (emails:Object, id:string): Observable <any> => {
    return this.http.post(`${environment.urlApi}check-unidad/${id}/email`, emails);
  }

  descargarPDF = (id:string): Observable <any> => {
    return this.http.get(`${environment.urlApi}check-unidad/${id}/pdf`, {responseType: 'arraybuffer'})
    .pipe(
      map( (res: any) => {
        return new Blob([res], {type: 'application/pdf'});
      })
    )
  }

  crearCosto = (checkId: string,cost:NewCost): Observable <any> => {
    return this.http.post(`${environment.urlApi}check-unidad/${checkId}/costos`, cost);
  }

  export(filter: CheckFiltro): Observable <any> {
    let filterToApply = Utils.generarParametrosBusqueda('check_unidad_filter', filter)

    return this.http.get(`${environment.urlApi}check-unidad/export?${filterToApply}`, {responseType: 'blob'} )
  }

  obtenerEstadoEnWebApp (checkId: string): Observable <any> {
    return this.http
      .get(`${environment.urlApi}check-unidad/${checkId}/estado`)
      .pipe(
        map((resp: any) => resp)
      )
  }
}
