export class Adjunto {
    private _createdAt: string;
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    private _createdBy: string;
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    private _id: string;
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    private _nombre: string;
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    private _adjuntoTipoId: string;
    public get adjuntoTipoId(): string {
        return this._adjuntoTipoId;
    }
    public set adjuntoTipoId(value: string) {
        this._adjuntoTipoId = value;
    }
    private _fileNameOriginal: string;
    public get fileNameOriginal(): string {
        return this._fileNameOriginal;
    }
    public set fileNameOriginal(value: string) {
        this._fileNameOriginal = value;
    }
    private _fileNameHash: string;
    public get fileNameHash(): string {
        return this._fileNameHash;
    }
    public set fileNameHash(value: string) {
        this._fileNameHash = value;
    }
    private _fileSize: string;
    public get fileSize(): string {
        return this._fileSize;
    }
    public set fileSize(value: string) {
        this._fileSize = value;
    }
    private _isPublic: string;
    public get isPublic(): string {
        return this._isPublic;
    }
    public set isPublic(value: string) {
        this._isPublic = value;
    }
    private _isDeleted: string;
    public get isDeleted(): string {
        return this._isDeleted;
    }
    public set isDeleted(value: string) {
        this._isDeleted = value;
    }
}
