import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-tabs-list',
  templateUrl: './tabs-list.component.html',
  styleUrls: ['./tabs-list.component.css']
})
export class TabsListComponent implements OnInit {
  @Input() tabs: { id: number, name: string }[];
  @Input() selectedTab: number;
  @Output() onTabClick = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  changeTab(tab: number): void {
    this.onTabClick.emit(tab);
  }
}
