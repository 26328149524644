import {Pipe, PipeTransform} from '@angular/core';
import {PerfilService} from '../services/auxiliar/perfil.service';
import {NumeroPipe} from './numero.pipe';

@Pipe({
  name: 'divisa'
})
export class DivisaPipe implements PipeTransform {
  constructor(
    private perfil: PerfilService,
    private abbreviateNumber: NumeroPipe,
  ) {
  }

  transform(value: any, divisaId: string, abbreviate: boolean = false): any {
    const val = Number(value);
    let monto;
    if (!abbreviate) {
      monto = val.toLocaleString(this.perfil.locale);
    } else {
      monto = this.abbreviateNumber.abbreviateNumber(val, 2);
    }
    if (monto && monto !== 'NaN') {
      switch (divisaId) {
        // UF
        case '20562d2a-bed8-451f-a8f8-77f293a4880a':
          return monto + ' UF';
        // CLP
        case '7f377688-5b8a-49a2-a0f7-6bedd68fa900':
          return monto + ' CLP';
        // SOL
        case '0ea7be9c-2dcb-409e-9bbd-21a01c450cb7':
          return 'S/' + monto;
        default:
          return '$' + monto;
      }
    }
    return 0;
  }

  get randomNumber() {
    return Math.random();
  }
}
