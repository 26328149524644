import { UnidadTipo } from './unidad-tipo.model';
import { Propiedad } from './propiedad.model';
import { UnidadEstado } from './unidad-estado.model';
import { UnidadCobro } from './unidad-cobro.model';
import {UnidadPropietario} from './unidad-propietario.model';
import {UnidadDivisaMonto} from './unidad-divisa-monto.model';
import {UnidadFirmante} from "./unidad-firmante.model";
import { UnidadPortalModel } from './unidad-portal.model';
import {Contrato} from './contrato.model';
import {ContratoAnexo} from './contrato-anexo.model';
import { UnidadCuentaServicioModel } from './unidad-cuenta-servicio.model';
import {UnidadSubEstado} from "./unidad-subestado.model";

export class Unidad {

    private _id: string;
    private _nombre: string;
    private _rol: string;
    private _tipologia: string;
    private _tipologiaEspecial: string;
    private _orientacion: string;
    private _piso: string;
    private _pisoCantidad: number;
    private _dormitorioCantidad: number;
    private _dormitorioServicioCantidad: number;
    private _camasCantidad: number;
    private _serviciosHigenicosCantidad: number;
    private _serviciosHigenicosServicioCantidad: number;
    private _superficieMunicipio: number;
    private _superficieUtil: number;
    private _superficieTerraza: number;
    private _superficieTotal: number;
    private _superficieTerreno: string;
    private _fechaRecepcionMunicipio: string;
    private _fechaCompromisoEntrega: string;
    private _avaluoFiscal: number;
    private _isAmoblado: string;
    private _isAfectoImpuesto: string;
    private _prorrateo: string;
    private _precioLista: number;
    private _precioTerreno: number;
    private _precioBase: number;
    private _precioEvaluacion: number;
    private _precioGastoComun: number;
    private _descripcion: string;
    private _codigoErp: string;
    private _createdAt: string;
    private _createdBy: string;
    private _updatedAt: string;
    private _updatedBy: string;
    private _propiedadId: string;
    private _unidadTipoId: string;
    private _unidadCobroId: string;
    private _unidadEstadoId: number;
    private _modelo: string;
    private _contratosValidos: Contrato[];
    private _anexosValidos: ContratoAnexo[];
    private _divisas: UnidadDivisaMonto[] = [];
    private _superficiePonderada
    private _centroCostos
    private _grupo
    private _garajes;
    private _aceptaMascotas: boolean;
    private _mostrarEnCotizadorWeb: boolean;

    /* Includes */
    private _propiedad: Propiedad;
    public _unidadTipo: UnidadTipo;
    private _unidadCobro: UnidadCobro;
    private _unidadEstado: UnidadEstado;
    private _unidadSubEstado: UnidadSubEstado;

    /* Relationship */
    private _imagenes: [];
    private _propietarios: UnidadPropietario[];
    private _firmantes: UnidadFirmante[];
    private _adjuntos: any[];
    private _portales: UnidadPortalModel[]
    private _cuentasServicio: UnidadCuentaServicioModel[]


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get rol(): string {
        return this._rol;
    }
    public set rol(value: string) {
        this._rol = value;
    }
    public get tipologia(): string {
        return this._tipologia;
    }
    public set tipologia(value: string) {
        this._tipologia = value;
    }
    public get tipologiaEspecial(): string {
        return this._tipologiaEspecial;
    }
    public set tipologiaEspecial(value: string) {
        this._tipologiaEspecial = value;
    }
    public get orientacion(): string {
        return this._orientacion;
    }
    public set orientacion(value: string) {
        this._orientacion = value;
    }
    public get piso(): string {
        return this._piso;
    }
    public set piso(value: string) {
        this._piso = value;
    }
    public get pisoCantidad(): number {
        return this._pisoCantidad;
    }
    public set pisoCantidad(value: number) {
        this._pisoCantidad = value;
    }
    public get dormitorioCantidad(): number {
        return this._dormitorioCantidad;
    }
    public set dormitorioCantidad(value: number) {
        this._dormitorioCantidad = value;
    }
    public get dormitorioServicioCantidad(): number {
        return this._dormitorioServicioCantidad;
    }
    public set dormitorioServicioCantidad(value: number) {
        this._dormitorioServicioCantidad = value;
    }
    public get camasCantidad(): number {
        return this._camasCantidad;
    }
    public set camasCantidad(value: number) {
        this._camasCantidad = value;
    }
    public get serviciosHigenicosCantidad(): number {
        return this._serviciosHigenicosCantidad;
    }
    public set serviciosHigenicosCantidad(value: number) {
        this._serviciosHigenicosCantidad = value;
    }
    public get serviciosHigenicosServicioCantidad(): number {
        return this._serviciosHigenicosServicioCantidad;
    }
    public set serviciosHigenicosServicioCantidad(value: number) {
        this._serviciosHigenicosServicioCantidad = value;
    }
    public get superficieMunicipio(): number {
        return this._superficieMunicipio;
    }
    public set superficieMunicipio(value: number) {
        this._superficieMunicipio = value;
    }
    public get superficieUtil(): number {
        return this._superficieUtil;
    }
    public set superficieUtil(value: number) {
        this._superficieUtil = value;
    }
    public get superficieTerraza(): number {
        return this._superficieTerraza;
    }
    public set superficieTerraza(value: number) {
        this._superficieTerraza = value;
    }
    public get superficieTotal(): number {
        return this._superficieTotal;
    }
    public set superficieTotal(value: number) {
        this._superficieTotal = value;
    }
    public get superficieTerreno(): string {
        return this._superficieTerreno;
    }
    public set superficieTerreno(value: string) {
        this._superficieTerreno = value;
    }
    public get fechaRecepcionMunicipio(): string {
        return this._fechaRecepcionMunicipio;
    }
    public set fechaRecepcionMunicipio(value: string) {
        this._fechaRecepcionMunicipio = value;
    }
    public get fechaCompromisoEntrega(): string {
        return this._fechaCompromisoEntrega;
    }
    public set fechaCompromisoEntrega(value: string) {
        this._fechaCompromisoEntrega = value;
    }
    public get avaluoFiscal(): number {
        return this._avaluoFiscal;
    }
    public set avaluoFiscal(value: number) {
        this._avaluoFiscal = value;
    }
    public get isAmoblado(): string {
        return this._isAmoblado;
    }
    public set isAmoblado(value: string) {
        this._isAmoblado = value;
    }
    public get isAfectoImpuesto(): string {
        return this._isAfectoImpuesto;
    }
    public set isAfectoImpuesto(value: string) {
        this._isAfectoImpuesto = value;
    }
    public get prorrateo(): string {
        return this._prorrateo;
    }
    public set prorrateo(value: string) {
        this._prorrateo = value;
    }
    public get precioLista(): number {
        return this._precioLista;
    }
    public set precioLista(value: number) {
        this._precioLista = value;
    }
    public get precioTerreno(): number {
        return this._precioTerreno;
    }
    public set precioTerreno(value: number) {
        this._precioTerreno = value;
    }
    public get precioBase(): number {
        return this._precioBase;
    }
    public set precioBase(value: number) {
        this._precioBase = value;
    }
    public get precioEvaluacion(): number {
        return this._precioEvaluacion;
    }
    public set precioEvaluacion(value: number) {
        this._precioEvaluacion = value;
    }
    public get precioGastoComun(): number {
        return this._precioGastoComun;
    }
    public set precioGastoComun(value: number) {
        this._precioGastoComun = value;
    }
    public get descripcion(): string {
        return this._descripcion;
    }
    public set descripcion(value: string) {
        this._descripcion = value;
    }
    public get codigoErp(): string {
        return this._codigoErp;
    }
    public set codigoErp(value: string) {
        this._codigoErp = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    public get updatedAt(): string {
        return this._updatedAt;
    }
    public set updatedAt(value: string) {
        this._updatedAt = value;
    }
    public get updatedBy(): string {
        return this._updatedBy;
    }
    public set updatedBy(value: string) {
        this._updatedBy = value;
    }
    public get propiedadId(): string {
        return this._propiedadId;
    }
    public set propiedadId(value: string) {
        this._propiedadId = value;
    }
    public get unidadTipoId(): string {
        return this._unidadTipoId;
    }
    public set unidadTipoId(value: string) {
        this._unidadTipoId = value;
    }
    public get unidadCobroId(): string {
        return this._unidadCobroId;
    }
    public set unidadCobroId(value: string) {
        this._unidadCobroId = value;
    }
    public get unidadEstadoId(): number {
        return this._unidadEstadoId;
    }
    public set unidadEstadoId(value: number) {
        this._unidadEstadoId = value;
    }
    public get modelo(): string {
        return this._modelo;
    }
    public set modelo(value: string) {
        this._modelo = value;
    }

    public get divisas(): UnidadDivisaMonto[] {
        return this._divisas;
    }

    public set divisas(value: UnidadDivisaMonto[]) {
        this._divisas = value;
    }

    public get contratosValidos(): Contrato[] {
        return this._contratosValidos;
    }

    public set contratosValidos(value: Contrato[]) {
        this._contratosValidos = value;
    }

    public get anexosValidos(): ContratoAnexo[] {
        return this._anexosValidos;
    }

    public set anexosValidos(value: ContratoAnexo[]) {
        this._anexosValidos = value;
    }

    public get superficiePonderada(): string {
        return this._superficiePonderada;
    }
    public set superficiePonderada(value: string) {
        this._superficiePonderada = value;
    }
    public get totalMensual(): number {
        const precioLista = this._precioLista ? this._precioLista : 0;
        const gastoComun = this._precioGastoComun ? this._precioGastoComun : 0;
        return precioLista + gastoComun;
    }

    public get propiedad(): Propiedad {
        return this._propiedad;
    }
    public set propiedad(value: Propiedad) {
        this._propiedad = value;
    }
    public get unidadTipo(): UnidadTipo {
        return this._unidadTipo;
    }
    public set unidadTipo(value: UnidadTipo) {
        this._unidadTipo = value;
    }
    public get unidadCobro(): UnidadCobro {
        return this._unidadCobro;
    }
    public set unidadCobro(value: UnidadCobro) {
        this._unidadCobro = value;
    }
    public get unidadEstado(): UnidadEstado {
        return this._unidadEstado;
    }
    public set unidadEstado(value: UnidadEstado) {
        this._unidadEstado = value;
    }

    get unidadSubEstado(): UnidadSubEstado {
        return this._unidadSubEstado;
    }

    set unidadSubEstado(value: UnidadSubEstado) {
        this._unidadSubEstado = value;
    }

    public get imagenes(): [] {
        return this._imagenes;
    }
    public set imagenes(value: []) {
        this._imagenes = value;
    }
    public get adjuntos(): any[] {
        return this._adjuntos;
    }
    public set adjuntos(value: any[]) {
        this._adjuntos = value;
    }

    get propietarios(): UnidadPropietario[] {
        return this._propietarios?.sort((a, b) => {
            if (a.activo && !b.activo) {
                return -1;
            } else {
                return 1;
            }
        });
    }

    set propietarios(value: UnidadPropietario[]) {
        this._propietarios = value;
    }

    get firmantes(): UnidadFirmante[] {
        return this._firmantes;
    }

    set firmantes(value: UnidadFirmante[]) {
        this._firmantes = value;
    }

    public get centroCostos(): string {
        return this._centroCostos;
    }
    public set centroCostos(value: string) {
        this._centroCostos = value;
    }
    public get grupo(): string {
        return this._grupo;
    }
    public set grupo(value: string) {
        this._grupo = value;
    }

    public get garajes(): string {
        return this._garajes;
    }
    public set garajes(value: string) {
        this._garajes = value;
    }

    get aceptaMascotas(): boolean {
        return this._aceptaMascotas;
    }

    set aceptaMascotas(value: boolean) {
        this._aceptaMascotas = value;
    }

    public get portales(): UnidadPortalModel[] {
        return this._portales;
    }
    public set portales(value: UnidadPortalModel[]) {
        this._portales = value;
    }

    public get cuentasServicio(): UnidadCuentaServicioModel[] {
        return this._cuentasServicio;
    }
    public set cuentasServicio(value: UnidadCuentaServicioModel[]) {
        this._cuentasServicio = value;
    }

    public get precios() {
        const precios: {id, tipo, concepto, divisa, monto}[] = [];
        this.divisas.forEach(divisa => {
            precios.push({id: divisa.id, tipo: divisa.tipoId, concepto: divisa.conceptoId, divisa: divisa.divisaId, monto: divisa.monto});
        })
        return precios;
    }

    get mostrarEnCotizadorWeb(): boolean {
        return this._mostrarEnCotizadorWeb;
    }

    set mostrarEnCotizadorWeb(value: boolean) {
        this._mostrarEnCotizadorWeb = value;
    }
}
