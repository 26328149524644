import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImagenService } from '../services/imagen/imagen.service';
import { Propiedad, Unidad } from '../models/model.index';

@Pipe({
  name: 'imagen'
})

export class ImagenPipe implements PipeTransform {

  public tipo: string;

  constructor(
    public http: HttpClient,
    public imagenService: ImagenService
  ) {}

  async transform( entity: any ): Promise<string> {
    if (!entity.imagenes || entity.imagenes.length < 1) {
      return 'assets/img/placeholder.png';
    }
    this.getTipo(entity);
    if (!this.tipo || !entity.id) {
      return 'assets/img/placeholder.png';
    }
    const imagenId = entity.imagenes.find(e => e.attributes?.is_principal)?.id || entity.imagenes[0].id;
    try {
      const imageBlob = await this.imagenService.getImagen(imagenId, this.tipo, entity.id).toPromise();
      return this.renderImage(imageBlob);
    } catch (error) {
      return 'assets/img/placeholder.png';
    }
  }

  renderImage(imageBlob: Blob): Promise <string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    });
  }

  private getTipo(entity) {
    if (entity instanceof Propiedad) {
      this.tipo = 'propiedades';
    }
    if (entity instanceof Unidad) {
      this.tipo = 'unidades';
    }
  }

}

