import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// tslint:disable-next-line: ban-types
declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalitycsService {

  routerSubscription: Subscription;

  constructor(
    public router: Router
  ) { }

  gtag() {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag('config', environment.googleAnalitycsTrackingId, { page_path: event.urlAfterRedirects });
      });
  }

  eventSearchGtag(filtro: object, filterPrefix: string) {
    let isActive = false;
    const searchTerms: any = {};
    Object.entries(filtro).forEach(
      ([key, value]: [string, string]) => {
        const term = this.getValue(value);
        if (term) {
          searchTerms[filterPrefix + '__' + key] = value;
          isActive = true;
        }
      }
    );
    if (isActive) {
      gtag('event', 'search', searchTerms);
    }
  }

  private getValue(value) {
    switch (typeof value) {
      case 'string':
        if (value !== undefined && value !== '') {
          return value;
        }
        break;
      case 'object':
        if (Array.isArray(value) && value.length > 0) {
          return value;
        }
        if (value !== null && Object.keys(value).length > 0) {
          return value;
        }
        if (value instanceof Date) {
          return value;
        }
        break;
      default:
        return null;
    }
  }

}
