import {Adapter} from './adapter';
import {Injectable} from '@angular/core';
import {PropiedadConfiguracionComercial} from '../models/propiedad-configuracion-comercial.model';
import {DivisaAdapter} from './divisa.adapter';
import {MontoTipoAdapter} from './monto-tipo.adapter';
import {PeriodicidadAdapter} from './periodicidad.adapter';
import {PropiedadConfiguracionCargoClasificacionAdapter} from './propiedad-configuracion-cargo-clasificacion.adapter';
import {BaseAdapter} from './base.adapter';
import { PropiedadConfiguracionContabilidadAdapter } from './propiedad-configuracion-contabilidad.adapter';

@Injectable({
    providedIn: 'root'
})

export class PropiedadConfiguracionComercialAdapter implements Adapter<PropiedadConfiguracionComercial> {
    constructor(
        public divisaAdapter: DivisaAdapter,
        public montoTipoAdapter: MontoTipoAdapter,
        public periodicidadAdapter: PeriodicidadAdapter,
        public configuracionCargoClasificacionAdapter: PropiedadConfiguracionCargoClasificacionAdapter,
        public configuracionContabilidadAdapter: PropiedadConfiguracionContabilidadAdapter,
        public baseAdapter: BaseAdapter
    ) {}
    adapt(item: any): PropiedadConfiguracionComercial {
        const propiedadConfiguracionComercial = new PropiedadConfiguracionComercial();
        propiedadConfiguracionComercial.reajusteMonto = item.attributes.reajuste_monto;
        propiedadConfiguracionComercial.reajusteMontoDivisaId = item.attributes.reajuste_monto_divisa_id;
        propiedadConfiguracionComercial.reajusteMontoTipoId = item.attributes.reajuste_monto_tipo_id;
        propiedadConfiguracionComercial.reajustePeriodicidadId = item.attributes.reajuste_periodicidad_id;
        propiedadConfiguracionComercial.reajustePeriodicidadValor = item.attributes.reajuste_periodicidad_valor;
        propiedadConfiguracionComercial.reajusteTipoId = item.attributes.reajuste_tipo_id;
        propiedadConfiguracionComercial.retrasoMultaMonto = item.attributes.retraso_multa_monto;
        propiedadConfiguracionComercial.retrasoMultaMontoTipoId = item.attributes.retraso_multa_monto_tipo_id;
        propiedadConfiguracionComercial.retrasoInteresMonto = item.attributes.retraso_interes_monto;
        propiedadConfiguracionComercial.retrasoInteresMontoTipoId = item.attributes.retraso_interes_monto_tipo_id;
        propiedadConfiguracionComercial.retrasoInteresPorDia = item.attributes.retraso_interes_por_dia;
        propiedadConfiguracionComercial.retrasoInteresNoAplicar = item.attributes.retraso_interes_no_aplicar;
        propiedadConfiguracionComercial.retrasoInteresPeriodicidadId = item.attributes.retraso_interes_periodicidad_id;
        propiedadConfiguracionComercial.retrasoInteresPeriodicidadValor = item.attributes.retraso_interes_periodicidad_valor;
        propiedadConfiguracionComercial.retrasoInteresTipoId = item.attributes.retraso_interes_tipo_id;
        propiedadConfiguracionComercial.contratoDiaGeneracion = item.attributes.contrato_dia_generacion;
        propiedadConfiguracionComercial.contratoDiaCobro = item.attributes.contrato_dia_cobro;
        propiedadConfiguracionComercial.contratoDiaCorteArriendo = item.attributes.contrato_dia_corte_arriendo;
        propiedadConfiguracionComercial.contratoDiaCorteGastoComun = item.attributes.contrato_dia_corte_gasto_comun;
        propiedadConfiguracionComercial.contratoDiaCorteServicios = item.attributes.contrato_dia_corte_servicios;
        propiedadConfiguracionComercial.contratoDiaPago = item.attributes.contrato_dia_pago;
        propiedadConfiguracionComercial.contratoDiaConversionDivisa = item.attributes.contrato_dia_conversion_divisa;
        propiedadConfiguracionComercial.contratoAvisoTerminoDias = item.attributes.contrato_aviso_termino_dias;
        propiedadConfiguracionComercial.contratoFolioInicio = item.attributes.contrato_folio_inicio;
        propiedadConfiguracionComercial.contratoEnvioManualEstadoCuenta = false;
        propiedadConfiguracionComercial.propiedadHasFirmaElectronica = item.attributes.propiedad_has_firma_electronica;
        propiedadConfiguracionComercial.contratoCobroPeriodicidadId = item.attributes.contrato_cobro_periodicidad_id;
        propiedadConfiguracionComercial.liquidacionCreacionEstadoId = item.attributes.liquidacion_creacion_estado_id;
        propiedadConfiguracionComercial.cotizacionVigencia = item.attributes.cotizacion_vigencia;
        propiedadConfiguracionComercial.contratoEnvioManualEstadoCuenta = item.attributes.contrato_envio_manual_estado_cuenta;
        propiedadConfiguracionComercial.divisaConversion = item.attributes.divisa_conversion;
        propiedadConfiguracionComercial.reservaDivisaId = item.attributes.reserva_divisa_id;
        propiedadConfiguracionComercial.reservaMonto = item.attributes.reserva_monto;

        if (item.included) {
            item.included.filter((valor) => {
                switch (valor.type) {
                    case 'cobroPeriodicidad':
                        // TODO: REVISAR CON SG 1 o mas;
                        propiedadConfiguracionComercial.cobroPeriodicidad = valor.attributes;
                        break;
                    case 'reajusteMontoTipo':
                        propiedadConfiguracionComercial.reajusteMontoTipo = this.montoTipoAdapter.adapt(valor);
                        break;
                    case 'reajustePeriodicidad':
                        propiedadConfiguracionComercial.reajustePeriodicidad = this.periodicidadAdapter.adapt(valor);
                        break;
                    case 'reajusteMontoDivisa':
                        propiedadConfiguracionComercial.reajusteMontoDivisa = this.divisaAdapter.adapt(valor);
                        break;
                    case 'retrasoMultaMontoTipo':
                        propiedadConfiguracionComercial.retrasoMultaMontoTipo = this.montoTipoAdapter.adapt(valor);
                        break;
                    case 'retrasoMultaMontoDivisa':
                        propiedadConfiguracionComercial.retrasoMultaMontoDivisa = this.divisaAdapter.adapt(valor);
                        break;
                    case 'retrasoInteresMontoTipo':
                        propiedadConfiguracionComercial.retrasoInteresMontoTipo = this.montoTipoAdapter.adapt(valor);
                        break;
                    case 'retrasoInteresMontoDivisa':
                        propiedadConfiguracionComercial.retrasoInteresMontoDivisa = this.divisaAdapter.adapt(valor);
                        break;
                    case 'retrasoInteresPeriodicidad':
                        propiedadConfiguracionComercial.retrasoInteresPeriodicidad = this.periodicidadAdapter.adapt(valor);
                        break;
                    case 'retrasoInteresTipo':
                        propiedadConfiguracionComercial.retrasoInteresTipo = valor.attributes;
                        propiedadConfiguracionComercial.retrasoInteresTipoId = valor.attributes.id;
                        break;
                    case 'reservaDivisa':
                        propiedadConfiguracionComercial.reservaDivisa = this.divisaAdapter.adapt(valor);
                        break;
                }
            });
        }
        // RELATIONSHIP
        if (item.relationship) {
            const { cargoClasificacion, contabilidad, divisasCargo, divisasCobro, reajusteTipos } = item.relationship;
            if (cargoClasificacion) {
                propiedadConfiguracionComercial.cargoClasificaciones = cargoClasificacion.data?.map(cargo =>
                  this.configuracionCargoClasificacionAdapter.adapt(cargo));
            }
            if (contabilidad) {
                propiedadConfiguracionComercial.contabilidad = this.configuracionContabilidadAdapter.adapt(contabilidad.data?.[0]);
            }
            if (divisasCargo) {
                propiedadConfiguracionComercial.divisasCargo = divisasCargo.data?.map(divisaCargo => this.divisaAdapter.adapt(divisaCargo));
            }
            if (divisasCobro) {
                propiedadConfiguracionComercial.divisasCobro = divisasCobro.data?.map(divisaCobro => this.divisaAdapter.adapt(divisaCobro))
            }
            if (reajusteTipos) {
                propiedadConfiguracionComercial.reajusteTipos = reajusteTipos.data?.map(reajusteTipo => this.baseAdapter.adapt(reajusteTipo))
            }
        }
        return propiedadConfiguracionComercial;
    }
}
