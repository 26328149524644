import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { CargoClasificacion } from '../models/cargoClasificacion.model';

@Injectable({
    providedIn: 'root'
  })
  export class CargoClasificacionAdapter implements Adapter<CargoClasificacion> {
    adapt(item: any): CargoClasificacion {
        const cargoClasificacion = new CargoClasificacion();
        cargoClasificacion.id = item.attributes.id;
        cargoClasificacion.nombre = item.attributes.nombre;
        cargoClasificacion.orden = item.attributes.orden;
        cargoClasificacion.lang_resource = item.attributes.lang_resource;
        return cargoClasificacion;
    }
  }
