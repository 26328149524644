<h3 mat-dialog-title class="modal-title">
    {{ 'general.view' | translate }}
    {{ 'general.contact' | translate }}
</h3>
<mat-dialog-content #scrollframe>
    <app-contacto-detalle [contacto]="contacto" (isEditarActive)="activeEditar()"></app-contacto-detalle>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-between">
    <button mat-dialog-close [class]="'boton-anterior'">
        {{ 'general.cancel' | translate }}
    </button>
</mat-dialog-actions>
