import {AppModel} from "./app.model";

export class Divisa extends AppModel{

    private _id: string;
    private _nombre: string;
    private _codigo: string;
    private _cantidadDecimales: string;
    private _simboloMonetario: string;


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get codigo(): string {
        return this._codigo;
    }
    public set codigo(value: string) {
        this._codigo = value;
    }
    public get cantidadDecimales(): string {
        return this._cantidadDecimales;
    }
    public set cantidadDecimales(value: string) {
        this._cantidadDecimales = value;
    }
    public get simboloMonetario(): string {
        return this._simboloMonetario;
    }
    public set simboloMonetario(value: string) {
        this._simboloMonetario = value;
    }
}
