import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Divisa } from '../models/model.index';

@Injectable({
    providedIn: 'root'
  })
  export class DivisaAdapter implements Adapter<Divisa> {
    adapt(item: any): Divisa {
        const divisa = new Divisa();
        divisa.id = item.attributes.id;
        divisa.nombre = item.attributes.nombre;
        divisa.codigo = item.attributes.codigo;
        divisa.cantidadDecimales = item.attributes.cantidad_decimales;
        divisa.simboloMonetario = item.attributes.simbolo_monetario;
        return divisa;
    }
  }
