import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { PasarelaPago } from '../models/model.index';

@Injectable({
    providedIn: 'root'
})

export class PasarelaPagoAdapter implements Adapter<PasarelaPago> {
    adapt(item: any): PasarelaPago {
        const pasarelaPago = new PasarelaPago();
        pasarelaPago.id = item.attributes.id;
        pasarelaPago.nombre = item.attributes.nombre;
        return pasarelaPago;
    }
}
