import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paginador, EstadoCuentaPago } from 'src/app/models/model.index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { EstadoCuentaPagoAdapter } from 'src/app/adapter/estado-cuenta-pago.adapter';
import { Utils } from 'src/app/shared/utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EstadoCuentaPagoService {

  public paginador: Paginador;
  public parametros: string;
  public estadoCuentaPago: EstadoCuentaPago;

  constructor(
    public http: HttpClient,
    public paginadorAdapter: PaginadorAdapter,
    public estadoCuentaPagoAdapter: EstadoCuentaPagoAdapter
  ) { }

  paginadorEstadoCuentaPago() {
    return this.paginador;
  }

  cargarEstadoCuentaPagos(page, filtro?): Observable <EstadoCuentaPago[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'estado_cuenta_pago?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(estadoCuenta => this.estadoCuentaPagoAdapter.adapt(estadoCuenta));
        })
      );
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = Utils.generarParametrosBusqueda('estado_cuenta_pago_filter', filtro);
  }

  cargarEstadoCuentaPago(filtro): Observable <EstadoCuentaPago[]> {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'estado_cuenta_pago?' + this.parametros)
      .pipe(
        map( (resp: any) => resp.data.map(estadoCuentaPago => this.estadoCuentaPagoAdapter.adapt(estadoCuentaPago)) )
      );
  }

  crearEstadoCuentaPago(estadoCuentaPago): Observable <any> {
    return this.http.post(environment.urlApi + 'estado_cuenta_pago', estadoCuentaPago);
  }

  downloadFile(estadoCuentaPagoId: string, idAdjunto: string): Observable<any> {
    const URL = environment.urlApi + 'estado_cuenta_pago/' + estadoCuentaPagoId + '/adjuntos/' + idAdjunto + '/download';
    return this.http.get(URL, { responseType: 'blob' });
  }

}
