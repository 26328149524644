import { Propiedad } from './propiedad.model';

export class CuentaRecaudadora {

    private _id: string;
    private _nombre: string;
    private _banco: string;
    private _tipoCuenta: string;
    private _numero: string;
    private _titular: string;
    private _identificadorTipoId: number;
    private _identificador: string;
    private _email: string;
    private _telefono: string;
    private _propiedadId: string;

    private _conceptos: number[];
    private _identificadorTipo: any;
    private _propiedad: Propiedad;


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get banco(): string {
        return this._banco;
    }
    public set banco(value: string) {
        this._banco = value;
    }
    public get tipoCuenta(): string {
        return this._tipoCuenta;
    }
    public set tipoCuenta(value: string) {
        this._tipoCuenta = value;
    }
    public get numero(): string {
        return this._numero;
    }
    public set numero(value: string) {
        this._numero = value;
    }
    public get titular(): string {
        return this._titular;
    }
    public set titular(value: string) {
        this._titular = value;
    }
    public get identificadorTipoId(): number {
        return this._identificadorTipoId;
    }
    public set identificadorTipoId(value: number) {
        this._identificadorTipoId = value;
    }
    public get identificador(): string {
        return this._identificador;
    }
    public set identificador(value: string) {
        this._identificador = value;
    }
    public get email(): string {
        return this._email;
    }
    public set email(value: string) {
        this._email = value;
    }
    public get telefono(): string {
        return this._telefono;
    }
    public set telefono(value: string) {
        this._telefono = value;
    }
    public get propiedadId(): string {
        return this._propiedadId;
    }
    public set propiedadId(value: string) {
        this._propiedadId = value;
    }

    get conceptos(): number[] {
        return this._conceptos;
    }

    set conceptos(value: number[]) {
        this._conceptos = value;
    }

    public get identificadorTipo(): any {
        return this._identificadorTipo;
    }
    public set identificadorTipo(value: any) {
        this._identificadorTipo = value;
    }
    public get propiedad(): Propiedad {
        return this._propiedad;
    }
    public set propiedad(value: Propiedad) {
        this._propiedad = value;
    }
}
