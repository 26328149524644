import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Concepto } from '../models/concepto.model';

@Injectable({
    providedIn: 'root'
  })
  export class ConceptoAdapter implements Adapter<Concepto> {
    adapt(item: any): Concepto {
        const concepto = new Concepto();
        concepto.id = item.attributes.id;
        concepto.nombre = item.attributes.nombre;
        concepto.tipoId = item.attributes.tipo_id;
        concepto.lang_resource = item.attributes.lang_resource;
        return concepto;
    }
  }
