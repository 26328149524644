import { Injectable } from '@angular/core';
import { AbilityService } from '../ability/ability.service';

@Injectable()
export class SidebarService {
  menu: any = [];

  constructor(
    private ability: AbilityService
  ) {}

  loadOptions():any[]{
    this.menu = [
      {
        titulo: 'home.title',
        icono: 'home',
        url: '/home',
        isActive: true,
        options: []
      }
    ];
    if(this.ability.canViewPropiedades()){
      this.menu.push({
        titulo: 'menu.properties',
        icono: 'propiedades',
        url: '/propiedades',
        isActive: true,
        options: []
      });
    }

    if(this.ability.canViewUnidades()){
      this.menu.push({
        titulo: 'menu.units',
        icono: 'unidades',
        url: '/unidades',
        isActive: true,
        options: []
      });
    }

    this.menu.push({
      titulo: 'Servicios',
      icono: 'assets/img/icon-servicios.png',
      url: 'javascript: void(0)',
      isActive: false,
      options: []
    });

    if(this.ability.canViewClientes()){
      this.menu.push({
        titulo: 'menu.customers',
        icono: 'clientes',
        url: '/clientes',
        isActive: true,
        options: []
      });
    }

    if(this.ability.canViewBalance()){
      this.menu.push({
        titulo: 'menu.balances',
        icono: 'balances',
        url: '/balances',
        isActive: true,
        options: []
      });
    }

    if (this.ability.canViewOwners()) {
      this.menu.push({
        titulo: 'general.owners',
        icono: 'propietarios',
        url: '',
        isActive: true,
        options: [{
          titulo: 'general.settlements',
          icono: 'balances',
          url: '/propietarios/liquidaciones/previews',
          isActive: true
        }]
      })
    }

    if(this.ability.canViewPropiedades()){
      this.menu.push({
        titulo: 'menu.settings',
        icono: 'configuracion',
        url: '/configuraciones',
        isActive: false,
        options: []
      });
    }

    this.menu.push({
      titulo: 'Reportes',
      icono: 'assets/img/icon-reportes.png',
      url: 'javascript: void(0)',
      isActive: false,
      options: []
    });

    if(this.ability.canViewCheckstate()){
      this.menu.push({
        titulo: 'menu.managment',
        icono: 'administracion',
        url: '',
        isActive: true,
        options: [{
          titulo: 'menu.checkstate',
          icono: 'checkestate',
          url: '/checkestate',
          isActive: true
        }]
      });
    }

    if(this.ability.canViewBitacora() || this.ability.canViewPublicaciones() || this.ability.canViewResidentes() || this.ability.canViewEspaciosComunes()){
      let itemComunidad = {
        titulo: 'menu.comunity',
        icono: 'comunidad',
        url: '',
        isActive: true,
        options: []
      }

      if(this.ability.canViewBitacora()){
        itemComunidad.options.push({
          titulo: 'menu.logbook',
          icono: 'bitacora',
          url: '/bitacora',
          isActive: true
        })
      }

      if(this.ability.canViewPublicaciones()){
        itemComunidad.options.push({
          titulo: 'menu.publications',
          icono: 'publicaciones',
          url: '/publicaciones',
          isActive: true
        })
      }

      if(this.ability.canViewResidentes()){
        itemComunidad.options.push({
          titulo: 'contacts.residents',
          icono: 'residentes',
          url: '/residentes',
          isActive: true
        })
      }

      if(this.ability.canViewEspaciosComunes()){
        itemComunidad.options.push({
          titulo: 'menu.common_spaces',
          icono: 'espaciosComunes',
          url: '/espacios-comunes',
          isActive: true,
          options: []
        });
      }

      this.menu.push(itemComunidad);
    }

    return this.menu;
  }
}
