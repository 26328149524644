import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.css']
})
export class InfoTextComponent implements OnInit {
  @Input() text: string;
  @Input() enableEdition = false;
  @Output() editEmitter = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  edit() {
    this.editEmitter.emit()
  }

}
