import { MotivoReverso } from './motivo-reverso.model';
import { Contacto } from './contacto.model';

export interface EjecutivoInterface {
    id: string;
    contactoId: string;
}

export class Reverso {

    private _id: string;
    private _comentario: string;
    private _ejecutivoId: string;
    private _estadoCuentaPagoId: string;
    private _fecha: string;
    private _folio: string;
    private _folioNumerico: number;
    private _monto: number;
    private _motivoReversoId: number;

    private _contacto: Contacto;
    private _ejecutivo: EjecutivoInterface;
    private _motivoReverso: MotivoReverso;


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get comentario(): string {
        return this._comentario;
    }
    public set comentario(value: string) {
        this._comentario = value;
    }
    public get ejecutivoId(): string {
        return this._ejecutivoId;
    }
    public set ejecutivoId(value: string) {
        this._ejecutivoId = value;
    }
    public get estadoCuentaPagoId(): string {
        return this._estadoCuentaPagoId;
    }
    public set estadoCuentaPagoId(value: string) {
        this._estadoCuentaPagoId = value;
    }
    public get fecha(): string {
        return this._fecha;
    }
    public set fecha(value: string) {
        this._fecha = value;
    }
    public get folio(): string {
        return this._folio;
    }
    public set folio(value: string) {
        this._folio = value;
    }
    public get folioNumerico(): number {
        return this._folioNumerico;
    }
    public set folioNumerico(value: number) {
        this._folioNumerico = value;
    }
    public get monto(): number {
        return this._monto;
    }
    public set monto(value: number) {
        this._monto = value;
    }
    public get motivoReversoId(): number {
        return this._motivoReversoId;
    }
    public set motivoReversoId(value: number) {
        this._motivoReversoId = value;
    }


    public get contacto(): Contacto {
        return this._contacto;
    }
    public set contacto(value: Contacto) {
        this._contacto = value;
    }
    public get ejecutivo(): EjecutivoInterface {
        return this._ejecutivo;
    }
    public set ejecutivo(value: EjecutivoInterface) {
        this._ejecutivo = value;
    }
    public get motivoReverso(): MotivoReverso {
        return this._motivoReverso;
    }
    public set motivoReverso(value: MotivoReverso) {
        this._motivoReverso = value;
    }
}
