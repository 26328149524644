import {Injectable} from "@angular/core";
import {Adapter} from "./adapter";
import {ReservaEstado} from "../models/reserva-estado.model";

@Injectable({
  providedIn: 'root'
})
export class ReservaEstadoAdapter implements Adapter<ReservaEstado> {
  adapt(item: any): ReservaEstado {
    const reservaEstado = new ReservaEstado();
    reservaEstado.id = item.attributes.id;
    reservaEstado.nombre = item.attributes.nombre;
    reservaEstado.langResource = item.attributes.lang_resource;
    return reservaEstado;
  }
}
