import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContactoInterface, ContratoAnexo, EjecutivoInterface } from '../models/contrato-anexo.model';
import { ContratoAnexoEstadoAdapter } from './contrato-anexo-estado.adapter';
import { ContratoAnexoTipoAdapter } from './contrato-anexo-tipo.adapter';
import { ContactoAdapter } from './contacto.adapter';
import {ContratoAnexoContactoAdapter} from './contrato-anexo-contacto.adapter';
import {ContratoConfiguracionAdapter} from './contrato-configuracion.adapter';
import {ContratoAnexoUnidadDetalleAdapter} from './contrato-anexo-unidad-detalle.adapter';
import { CustodiaDocumentoAdapter } from './custodia-documento.adapter';
import {Contacto} from '../models/contacto.model';

@Injectable({
  providedIn: 'root'
})
export class UnidadContratoAnexoAdapter implements Adapter<ContratoAnexo> {

  constructor(
    public contratoAnexoEstadoAdapter: ContratoAnexoEstadoAdapter,
    public contratoAnexoTipoAdapter: ContratoAnexoTipoAdapter,
    public contactoAdapter: ContactoAdapter,
    public contratoAnexoUnidadDetalleAdapter: ContratoAnexoUnidadDetalleAdapter,
    public contratoAnexoContactoAdapter: ContratoAnexoContactoAdapter,
    public contratoConfiguracionAdapter: ContratoConfiguracionAdapter,
    public custodiaDocumentoAdapter: CustodiaDocumentoAdapter
  ) {}

  adapt(item: any): ContratoAnexo {
    const contratoAnexo = new ContratoAnexo();
    contratoAnexo.id = item.attributes.id;
    contratoAnexo.contratoBaseId = item.attributes.contrato_base_id;
    contratoAnexo.contratoId = item.attributes.contrato_id;
    contratoAnexo.documentoId = item.attributes.documento_id;
    contratoAnexo.documentoFirmadoId = item.attributes.documento_firmado_id;
    contratoAnexo.ejecutivoId = item.attributes.ejecutivo_id;
    contratoAnexo.esFirmable = item.attributes.es_firmable;
    contratoAnexo.estadoId = item.attributes.estado_id;
    contratoAnexo.estadoFirmaId = item.attributes.estado_firma_id;
    contratoAnexo.fecha = item.attributes.fecha;
    contratoAnexo.folio = item.attributes.folio;
    contratoAnexo.folioNumerico = item.attributes.folio_numerico;
    contratoAnexo.templateId = item.attributes.template_id;
    contratoAnexo.contratoFechaFin = item.attributes.contrato_fecha_fin;
    contratoAnexo.contratoReajusteFecha = item.attributes.contrato_reajuste_fecha;

    /* Includes ContratoAnexoTipoAdapter*/
    if (item.included) {
      item.included.filter((valor) => {
        switch (valor.type) {
          case 'ContratoAnexoEstado':
            contratoAnexo.contratoAnexoEstado = this.contratoAnexoEstadoAdapter.adapt(valor);
            break;
          case 'Ejecutivo':
            contratoAnexo.ejecutivo = {} as EjecutivoInterface;
            contratoAnexo.ejecutivo.id = valor.attributes.contacto_id;
            break;
          case 'Contacto':
            contratoAnexo.contacto = {} as ContactoInterface;
            contratoAnexo.contacto.nombre = valor.attributes.nombre;
            contratoAnexo.contacto.apellido = valor.attributes.apellido;
            contratoAnexo.contacto.email = valor.attributes.email;
            contratoAnexo.contacto.contactoTipoId = valor.attributes.contacto_tipo_id;
            break;
          case 'ContratoAnexoConfiguracion':
            contratoAnexo.contratoAnexoConfiguracion = this.contratoConfiguracionAdapter.adapt(valor)
            break;
          case 'EstadoFirma':
            contratoAnexo.estadoFirmaNombre = valor.attributes.nombre;

        }
      });
    }
    /* Relationship */
    if (item.relationship && item.relationship.contratoAnexoTipos) {
      contratoAnexo.contratoAnexoTipos = item.relationship.contratoAnexoTipos.data.map(data =>
        this.contratoAnexoTipoAdapter.adapt(data)
      );
    }

    if (item.relationship && item.relationship.contratoAnexoContacto) {
      contratoAnexo.contratoAnexoContacto = item.relationship.contratoAnexoContacto.data
        .map(data => this.contratoAnexoContactoAdapter.adapt(data))
    }
    if (item.relationship && item.relationship.custodiaDocumento ) {
      contratoAnexo.contratoAnexoCustodiaDocumentos = item.relationship.custodiaDocumento.data
      .map(custodiaDocumento =>
          this.custodiaDocumentoAdapter.adapt(custodiaDocumento)
      )
      .sort( (n1, n2) => n1.folio - n2.folio );
    }
    return contratoAnexo;
  }
}
