export class PropiedadConfiguracionCargoClasificacion {
    private _cargoClasificacionId: number;
    private _conceptoId: number;
    private _id: number;
    private _isFacturableInt: boolean;
    private _propietarioLiquidable: boolean;
    private _propietarioComisionable: boolean;

    public get cargoClasificacionId(): number {
        return this._cargoClasificacionId;
    }
    public set cargoClasificacionId(value: number) {
        this._cargoClasificacionId = value;
    }
    public get conceptoId(): number {
        return this._conceptoId;
    }
    public set conceptoId(value: number) {
        this._conceptoId = value;
    }
    public get id(): number {
        return this._id;
    }
    public set id(value: number) {
        this._id = value;
    }
    public get isFacturableInt(): boolean {
        return this._isFacturableInt;
    }
    public set isFacturableInt(value: boolean) {
        this._isFacturableInt = value;
    }

    get propietarioLiquidable(): boolean {
        return this._propietarioLiquidable;
    }

    set propietarioLiquidable(value: boolean) {
        this._propietarioLiquidable = value;
    }

    get propietarioComisionable(): boolean {
        return this._propietarioComisionable;
    }

    set propietarioComisionable(value: boolean) {
        this._propietarioComisionable = value;
    }
}
