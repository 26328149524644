export class PropiedadTipo {

    private _id: string;
    private _nombre: string;
    private _isActive: string;


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get isActive(): string {
        return this._isActive;
    }
    public set isActive(value: string) {
        this._isActive = value;
    }
}
