import update from 'immutability-helper'
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-agregar-comentario-interno',
  templateUrl: './modal-agregar-comentario-interno.component.html',
  styleUrls: ['./modal-agregar-comentario-interno.component.css'],
})
export class ModalAgregarComentarioInterno implements OnInit {
  form: UntypedFormGroup;
  adjuntos: any[] = []
  minDate = new Date()
  maxDate = new Date()

  constructor(
    public dialogRef: MatDialogRef<ModalAgregarComentarioInterno>,
    public toastr: ToastrService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      comment: new UntypedFormControl(),
      subject: new UntypedFormControl(),
      date: new UntypedFormControl()
   });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelect = (event) => {
    const file = event.addedFiles[0];
    this.readFile(file).then(fileContents => {
      const splitB64 = fileContents.toString().split(',');
      this.adjuntos.push({ fileAdjunto: splitB64[1], nombre: file.name, adjuntoTipoId: 1 });
    });
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      if (file.size > 100000000) {
        this.toastr.error(this.translate.instant('general.error_max_filesize',{ filename:file.name, maxSize: '100MB' }));
        return reject(null);
      }
      const reader = new FileReader();
      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };
      reader.onerror = e => {
        this.toastr.error('Error al subir archivo ' + file.name);
        return reject(null);
      };
      if (!file) {
        this.toastr.error('Error al subir archivo ' + file.name);
        return reject(null);
      }
      reader.readAsDataURL(file);
    });
  }

  submit = () => {
    if (this.form.valid) {
      this.dialogRef.close({
        adjuntos: this.adjuntos,
        comment: this.form.value.comment,
        date: this.form.value.date,
        subject: this.form.value.subject
      })
    }
  }

  onRemove = (index: number) => {
    this.adjuntos = update(this.adjuntos, {
      $splice: [[index, 1]]
    })
  }
}
