import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { CotizacionAdapter } from '../../adapter/cotizacion.adapter';
import { Paginador } from 'src/app/models/paginador.model';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CotizacionService {


  public paginador: Paginador;
  public parametros: any;
  public cotizacion: any;
  public cotizacionMeta: any;

  constructor(
    public http: HttpClient,
    public cotizacionAdapter: CotizacionAdapter,
    public paginadorAdapter: PaginadorAdapter,
  ) { }

  paginadorContizacion() {
    return this.paginador;
  }

  getCotizacionMeta() {
    return this.cotizacionMeta.primerPago;
  }

  cargarCotizaciones(page, filtro?): Observable <Cotizacion[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'cotizaciones?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(unidad => this.cotizacionAdapter.adapt(unidad));
        })
      );
  }

  cargarCotizacion(id: string): Observable <Cotizacion> {
    return this.http.get(environment.urlApi + 'cotizaciones/' + id)
      .pipe(
        map( (resp: any) => {
          this.cotizacionMeta  = resp.meta;
          return this.cotizacionAdapter.adapt(resp.data);
        } )
      );
  }

  generarParametrosBusqueda(filtro)  {
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('cotizacion_filter', filtro);
  }

  crearCotizacion(cotizacion): Observable <any> {
    return this.http.post(environment.urlApi + 'cotizaciones', cotizacion);
  }

  formatJson() {
    const filter = [];
    return Utils.toJson(this.cotizacion, filter);
  }

  downloadFile(id: string, fileName: string): Observable<any> {
    return this.http.get(environment.urlApi + 'cotizaciones/' + id + '/pdf', { responseType: 'blob' });
  }

  enviarCotizacion(id: string): Observable <any> {
    return this.http.get(environment.urlApi + 'cotizaciones/' + id + '/email');
  }

  exportar(filtro) {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'cotizaciones/export?' + this.parametros, {responseType: 'blob'});
  }

}
