import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { ContactoAdapter } from './contacto.adapter';
import { ContratoContacto } from '../models/contrato-contacto.model';
import {PaisAdapter} from './pais.adapter';
import {MedioLlegadaAdapter} from './medio-llegada.adapter';
import {IdentificadorTipoAdapter} from './identificador-tipo.adapter';
import {ContratoContactoTipoAdapter} from './contrato-contacto-tipo.adapter';


@Injectable({
    providedIn: 'root'
})

export class ContratoContactoAdapter implements Adapter<ContratoContacto> {

    constructor(
        public contactoAdapter: ContactoAdapter,
        public paisAdapter: PaisAdapter,
        public medioLlegadaAdapter: MedioLlegadaAdapter,
        public identificadorTipoAdapter: IdentificadorTipoAdapter,
        public contratoContactoTipoAdapter: ContratoContactoTipoAdapter
    ) {}

    adapt(item: any): ContratoContacto {
        const negocioContacto = new ContratoContacto();
        negocioContacto.id = item.attributes.id;
        negocioContacto.contactoId = item.attributes.contacto_id;
        negocioContacto.contratoContactoTipoId = item.attributes.contrato_contacto_tipo_id;
        negocioContacto.contratoId = item.attributes.contrato_id;
        negocioContacto.isFirmante = item.attributes.is_firmante;
        negocioContacto.isTitular = item.attributes.is_titular;
        if (item.included) {
            const contactoIncluded = item.included.find(included => included.type === 'Contacto');
            const paisIncluded = item.included.find(included => included.type === 'Pais')
            const medioLlegadaIncluded = item.included.find(included => included.type === 'MedioLlegada')
            const indentificadorTipoIncluded = item.included.find(included => included.type === 'IdentificadorTipo')
            const contratoContactoTipoIncluded = item.included.find(included => included.type === 'ContratoContactoTipo')
            if (contactoIncluded) {
                negocioContacto.contacto = this.contactoAdapter.adapt(contactoIncluded);
                if (paisIncluded) {
                    negocioContacto.contacto.pais = this.paisAdapter.adapt(paisIncluded)
                }
                if (medioLlegadaIncluded) {
                    negocioContacto.contacto.medioLlegada = this.medioLlegadaAdapter.adapt(medioLlegadaIncluded);
                }
                if (indentificadorTipoIncluded) {
                    negocioContacto.contacto.identificadorTipo = this.identificadorTipoAdapter.adapt(indentificadorTipoIncluded);
                }
            }
            if (contratoContactoTipoIncluded) {
                negocioContacto.contratoContactoTipo = this.contratoContactoTipoAdapter.adapt(contratoContactoTipoIncluded);
            }
        }
        return negocioContacto;
    }

  }
