import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';

interface MaterialRangeInterface {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

type Rango = {
  fechaDesde?: moment.Moment;
  fechaHasta?: moment.Moment;
};

type Rangos = {
  [k: string]: moment.Moment[];
};

@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.css']
})

export class DatepickerRangeComponent implements OnChanges {
  
  @Input() rango: Rango;
  @Input() rangos: Rangos = {};
  @Input() label = 'general.date_range';
  @Input() placeholder = 'general.select_date_range';
  @Output() rangoSeleccionado = new EventEmitter<Rango>();

  alwaysShowCalendars = true;
  locale: any = {
    applyLabel: 'aceptar',
    cancelLabel: 'cancelar',
    clearLabel: 'limpiar',
    customRangeLabel: 'Rango personalizado',
    format: 'DD-MM-YYYY',
    daysOfWeek: moment.weekdaysShort(),
    monthNames: moment.monthsShort()
  };
  datepicker: UntypedFormControl = new UntypedFormControl('');
  private oldDatepicker: string;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rango) {
      this.setDatepickerValue();
    }
  }

  seleccionarRango() {
    if (!this.isChanged || !this.selected) {
      return;
    }
    
    this.oldDatepicker = JSON.stringify(this.selected);
    const datepicker = this.selected;
    if (datepicker && datepicker.startDate && datepicker.endDate) {
      this.rangoSeleccionado.emit({
        fechaDesde: datepicker.startDate,
        fechaHasta: datepicker.endDate,
      });
    } else {
      this.rangoSeleccionado.emit({
        fechaDesde: null,
        fechaHasta: null,
      });
    }
  }

  get isChanged(): boolean {
    if (this.oldDatepicker !== JSON.stringify(this.selected) ) {
      return true;
    }
    return false;
  }

  get selected(): MaterialRangeInterface {
    return this.datepicker.value;
  }

  get hideRanges(): boolean {
    if (!this.rangos) {
      return true;
    }
    return Object.keys(this.rangos).length <= 0;
  }

  setDatepickerValue() {
    let range: MaterialRangeInterface = null;
    if (this.rango && (this.rango.fechaDesde || this.rango.fechaHasta)) {
      range = {startDate: this.rango.fechaDesde, endDate: this.rango.fechaHasta};
    }
    this.datepicker.setValue(range, {emitEvent: false});
  }

}
