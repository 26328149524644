import { Contacto } from 'src/app/models/contacto.model';
import { Empresa } from './empresa.model';

export class User {

    private _id: string;
    private _email: string;
    private _contactoId: string;
    private _empresaId: string;
    private _createdAt: string;
    private _createdBy: string;

    private _contacto: Contacto;
    private _empresa: Empresa;
    private _empresasAsociadas: Empresa[];

    private _roles: string[];
    private _acciones: string[];

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get contactoId(): string {
        return this._contactoId;
    }
    public set contactoId(value: string) {
        this._contactoId = value;
    }

    get empresaId(): string {
        return this._empresaId;
    }

    set empresaId(value: string) {
        this._empresaId = value;
    }

    public get email(): string {
        return this._email;
    }
    public set email(email: string) {
        this._email = email;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get roles(): string[] {
        return this._roles;
    }
    public set roles(roles: string[]) {
        this._roles = roles;
    }
    public get acciones(): string[] {
        return this._acciones;
    }
    public set acciones(acciones: string[]) {
        this._acciones = acciones;
    }

    public get contacto(): Contacto {
        return this._contacto;
    }
    public set contacto(value: Contacto) {
        this._contacto = value;
    }

    public get empresa(): Empresa {
        return this._empresa;
    }
    public set empresa(value: Empresa) {
        this._empresa = value;
    }
    public get empresasAsociadas(): Empresa[] {
        return this._empresasAsociadas;
    }
    public set empresasAsociadas(value: Empresa[]) {
        this._empresasAsociadas = value;
    }

    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }

    public toJSON() {
        return {
            id: this._id,
            email: this._email,
            contactoId: this._contactoId,
            contacto: this._contacto.toJson(),
            empresa: this._empresa.toJSON(),
            roles: this._roles,
            acciones: this._acciones,
            empresasAsociadas: this._empresasAsociadas.map(empresa => empresa.toJSON())
        };
    }

}

export interface UserConfiguration {
    contactId: string
    id: string
    firstName: string
    lastName: string
    email: string
    phone: string
    enabled: boolean
    lastAccess: string
}

export interface ModuleWithRoles {
    id: number
    langResource: string
    roles: {
        description: string
        id: number
        isAdmin: boolean
        isChecked: boolean
        langResource: string
    }[]
}

export interface UserPermission {
    id: string
    firstName: string
    lastName: string
    email: string
}
