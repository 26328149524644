import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Concepto } from 'src/app/models/model.index';
import { Utils } from 'src/app/shared/utils';
import { ConceptoAdapter } from 'src/app/adapter/concepto.adapter';

@Injectable({
  providedIn: 'root'
})
export class DescuentosRecargosConceptoAplicaService {

  public parametros: string;

  constructor(
    public http: HttpClient,
    public conceptoAdapter: ConceptoAdapter
  ) { }

  cargarConceptoAplica(filtro?): Observable <Concepto[]> {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'descuento_recargo_concepto_aplica?' + this.parametros)
      .pipe(
        map( (resp: any) => resp.data.map(cuentas => this.conceptoAdapter.adapt(cuentas)) )
      );
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = '';
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('descuento_recargo_concepto_aplica_filter', filtro);
  }

}
