import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UsuarioService } from '../services/service.index';
import { Usuario } from '../models/usuario.model';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styles: []
})
export class RegistroComponent implements OnInit {

  formRegistro: UntypedFormGroup;

  constructor(
    public _usuarioService: UsuarioService
  ) { }

  noCoincide(campo1: string, campo2: string) {
    return (gruop: UntypedFormGroup)  => {
      const pass1 = gruop.controls[campo1].value;
      const pass2 = gruop.controls[campo2].value;
      if (pass1 === pass2) {
        return null;
      }
      return {
        noCoincide: true,
      };
    };
  }

  ngOnInit() {
    this.formRegistro = new UntypedFormGroup({
      nombre: new UntypedFormControl( null, Validators.required ),
      apellido: new UntypedFormControl( null, Validators.required ),
      email: new UntypedFormControl( null, [Validators.required, Validators.email] ),
      tipoCuenta: new UntypedFormControl( null, Validators.required ),
      contrasena: new UntypedFormControl( null, Validators.required ),
      contrasena2: new UntypedFormControl( null, Validators.required )
    }, {
      validators: this.noCoincide( 'contrasena', 'contrasena2')
    });
  }

  registrarUsuario() {
    if ( this.formRegistro.invalid) {
      console.log(JSON.stringify(this.formRegistro.value));
      return;
    }
    const usuario = new Usuario();
    // this._usuarioService.crearUsuario(usuario);
  }

}
