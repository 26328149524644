import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PropiedadAdapter } from 'src/app/adapter/propiedad.adapter';
import { StatisticsAdapter } from 'src/app/adapter/statistics.adapter';
import { Statistics } from 'src/app/models/statistics.model';
import { Propiedad } from 'src/app/models/propiedad.model';
import { Paginador } from 'src/app/models/paginador.model';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { Utils } from 'src/app/shared/utils';
import { PropiedadConfiguracionCargoClasificacionAdapter } from 'src/app/adapter/propiedad-configuracion-cargo-clasificacion.adapter';
import { PropiedadConfiguracionCargoClasificacion } from 'src/app/models/propiedad-configuracioncargo-clasificacion.model';
import {PropiedadConfiguracionComercial} from '../../models/propiedad-configuracion-comercial.model';
import {PropiedadConfiguracionComercialAdapter} from '../../adapter/propiedad-configuracion-comercial.adapter';
import { UnidadRadiografia } from '../../models/unidad-radiografia.model';
import {UnidadRadiografiaAdapter} from '../../adapter/unidad-radiografia.adapter';
import {PropiedadFirmanteAdapter} from "../../adapter/propiedad-firmante.adapter";
import {LiquidacionPeriodoOrquestadorAdapter} from "../../adapter/liquidacion-periodo-orquestador.adapter";
import { PropiedadConfiguracionContabilidadAdapter } from 'src/app/adapter/propiedad-configuracion-contabilidad.adapter';
import { PropiedadConfiguracionContabilidad } from 'src/app/models/propiedad-configuracion-contabilidad.model';

@Injectable({
  providedIn: 'root'
})
export class PropiedadService {

  public propiedades: Propiedad[];
  public paginador: Paginador;
  public parametros: string;

  constructor(
    public http: HttpClient,
    private propiedadAdapter: PropiedadAdapter,
    private statisticsAdapter: StatisticsAdapter,
    private paginadorAdapter: PaginadorAdapter,
    private propiedadConfiguracionCargoClasificacionAdapter: PropiedadConfiguracionCargoClasificacionAdapter,
    private propiedadConfiguracionComercialAdapter: PropiedadConfiguracionComercialAdapter,
    private propiedadConfiguracionContabilidadAdapter: PropiedadConfiguracionContabilidadAdapter,
    private unidadRadiografiaAdapter: UnidadRadiografiaAdapter,
    private propiedadFirmanteAdapter: PropiedadFirmanteAdapter,
    private liquidacionPeriodoOrquestadorAdapter: LiquidacionPeriodoOrquestadorAdapter
  ) { }

  cargarPropiedad(propiedadId: string) {
    return this.http.get(environment.urlApi + 'propiedades/' + propiedadId)
      .pipe(
        map((resp: any) => this.propiedadAdapter.adapt(resp.data))
      )
  }

  cargarPropiedades(page?: number, filtro?: any ): Observable <any[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'propiedades?' + this.parametros)
      .pipe(
        map( (resp: any[any]) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta)
          return resp.data.map(item => this.propiedadAdapter.adapt(item))
        })
      );
  }

  cargarPropiedadesSinPaginado(): Observable <any[]> {
    return this.http.get(environment.urlApi + 'propiedades?limit=1000')
      .pipe(
        map( (resp: any[any]) =>
          resp.data.map(item => this.propiedadAdapter.adapt(item))
        )
      );
  }

  cargarPropiedadesDetalle(idPropiedad): Observable <Propiedad> {
    return this.http.get(environment.urlApi + 'propiedades/' + idPropiedad)
      .pipe(
        map( (resp: any[any]) => this.propiedadAdapter.adapt(resp.data) )
      );
  }

  statisticsUnidadesPorPropiedad(idPropiedad): Observable<Statistics> {
    return this.http.get(environment.urlApi + 'propiedades/' + idPropiedad + '/statistics')
      .pipe(
        map( (resp: any[any]) => this.statisticsAdapter.adapt(resp.meta) )
      );
  }

  cargarPropiedadesByUnidad(page?, filtro?, limit?): Observable <Propiedad[]> {
    let filter = null;
    if (filtro) {
      const { tipologia, ...rest } = filtro;
      filter = {
        ...rest,
        ...(tipologia
          ? {
              tipologia: tipologia
                .replaceAll("+", "")
                .replaceAll("-", "")
                .replaceAll(" ", "")
                .replaceAll(",", ".")
            }
          : {}),
      };
    }
    this.generarParametrosBusqueda(filter);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    if (limit) {
      this.parametros += '&limit=' + limit;
    }
    return this.http.get(environment.urlApi + 'propiedades_by_unidad?' + this.parametros)
      .pipe(
        map( (resp: any[any]) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(item => this.propiedadAdapter.adapt(item));
        })
      );
  }

  paginadorPropiedad() {
    return this.paginador;
  }

  generarParametrosBusqueda(filtro)  {
    if (!filtro) {
      this.parametros = '';
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('propiedad_filter', filtro);
  }

  exportar(filtro) {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'propiedades/export?' + this.parametros, {responseType: 'blob'});
  }

  cargarConfiguracionCargoClasificaciones(idPropiedad: string): Observable <PropiedadConfiguracionCargoClasificacion[]>  {
    return this.http.get(environment.urlApi + 'propiedades/' + idPropiedad + '/configuracion/cargo-clasificaciones')
      .pipe(
        map( (resp: any[any]) => {
            const data = resp.data.relationship.cargoClasicacion.data;
            if (!data) {
                return [];
            }
            return data.map(item => this.propiedadConfiguracionCargoClasificacionAdapter.adapt(item) );
        })
      );
  }

  cargarConfiguracionComercial(idPropiedad: string): Observable<PropiedadConfiguracionComercial> {
    return this.http.get(environment.urlApi + 'propiedades/' + idPropiedad + '/configuracion/comercial')
        .pipe(
            map( (resp: any[any]) => this.propiedadConfiguracionComercialAdapter.adapt(resp.data) )
        );
  }

  cargarConfiguracionContabilidad(idPropiedad: string): Observable<PropiedadConfiguracionContabilidad> {
    return this.http.get(environment.urlApi + 'propiedades/' + idPropiedad + '/configuracion/contabilidad')
      .pipe(
          map((resp: any) => this.propiedadConfiguracionContabilidadAdapter.adapt(resp.data))
      );
  }

  cargarIndicadoresEstados = (filter?) => {
    if (filter) {
      this.parametros = Utils.generarParametrosBusqueda('unidad_filter', filter);
    } else {
      this.parametros = ''
    }

    return this.http.get(`${environment.urlApi}propiedades/indicador/estados?${this.parametros}`)
    .pipe(
      map( (res: any) => {
        return res
      })
    )
  }

  cargarRadiografia(propiedadId: string): Observable<UnidadRadiografia[]> {
    return this.http.get(environment.urlApi + 'propiedades/' + propiedadId + '/unidades-radiografia')
      .pipe(
        map((resp: any) => {
          return resp.data.map(unidadRadiografia => this.unidadRadiografiaAdapter.adapt(unidadRadiografia));
        })
    );
  }

  cargarLiquidacionesPeriodos(propiedadId: string) {
    return this.http.get(`${environment.urlApi}propiedades/${propiedadId}/estado-cuenta-generador`).pipe(
      map((resp: any) => {
        return resp.data.map(item => this.liquidacionPeriodoOrquestadorAdapter.adapt(item));
      })
    )
  }

  crearLiquidacionPeriodo(propiedadId, payload) {
    return this.http.post(`${environment.urlApi}propiedades/${propiedadId}/estado-cuenta-generador`, payload);
  }
  
  getPropertyTypes(): Observable <any> {
    return this.http.get(environment.urlApi + 'propiedades_tipo')
      .pipe(
        map( (resp: any[any]) =>
          resp.data.map(e => ({
            id: e.id,
            name: e.attributes.nombre
          }))
        )
      );
  }

  createProperty = (newProperty: Object): Observable <any> =>  
    this.http.post(environment.urlApi + 'propiedades', newProperty);

  downloadImage = (imageId: string, propertyId: string) => {
    const URL = `${environment.urlApi}propiedades/${propertyId}/imagenes/${imageId}/download`
    return this.http.get(URL, { responseType: 'blob' });
  }

  downloadFile = (fileId: string, propertyId: string) => {
    const URL = `${environment.urlApi}propiedades/${propertyId}/adjuntos/${fileId}/download`
    return this.http.get(URL, { responseType: 'blob' });
  }

  updateProperty = (id: string, updates: any): Observable <any> => {
    return this.http.put(`${environment.urlApi}propiedades/${id}`, updates);
  }
}
