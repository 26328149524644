import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContratoUnidad } from './../models/model.index';
import {UnidadAdapter} from './unidad.adapter';
import {ContratoUnidadConfiguracionAdapter} from './contrato-unidad-configuracion.adapter';
import {ContratoUnidadDetalleAdapter} from './contrato-unidad-detalle.adapter';
import {UnidadTipoAdapter} from './unidad-tipo.adapter';

@Injectable({
    providedIn: 'root'
  })
  export class ContratoUnidadAdapter implements Adapter<ContratoUnidad> {
  constructor(
    private unidadAdapter: UnidadAdapter,
    private contratoUnidadConfiguracionAdapter: ContratoUnidadConfiguracionAdapter,
    private contratoUnidadDetalleAdapter: ContratoUnidadDetalleAdapter,
    private unidadTipoAdapter: UnidadTipoAdapter
  ) {}
    adapt(item: any): ContratoUnidad {
      const contratoUnidad = new ContratoUnidad();
      contratoUnidad.id = item.attributes.id;
      contratoUnidad.contratoId = item.attributes.contrato_id;
      contratoUnidad.unidadId = item.attributes.unidad_id;
      contratoUnidad.createdAt = item.attributes.created_at;
      contratoUnidad.createdBy = item.attributes.created_by;
      if (item.included) {
        const unidadIncluded = item.included.find(included => included.type === 'Unidad');
        const contratoUnidadConfigIncluded = item.included.find(included => included.type === 'ContratoUnidadConfiguracion');
        const unidadTipo = item.included.find(included => included.type === 'UnidadTipo');
        if (unidadIncluded) {
          contratoUnidad.unidad = this.unidadAdapter.adapt(unidadIncluded);
        }
        if (contratoUnidadConfigIncluded) {
          contratoUnidad.contratoUnidadConfiguracion = this.contratoUnidadConfiguracionAdapter.adapt(contratoUnidadConfigIncluded);
        }
        if (unidadTipo) {
          contratoUnidad.unidadTipo = this.unidadTipoAdapter.adapt(unidadTipo);
        }
      }
      if (item.relationship && item.relationship.contratoUnidadDetalle) {
        contratoUnidad.contratoUnidadDetalle = item.relationship.contratoUnidadDetalle.data.map(contratoUnidadDetalle =>
          this.contratoUnidadDetalleAdapter.adapt(contratoUnidadDetalle)
        );
      }
      return contratoUnidad;
    }
  }
