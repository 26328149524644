<div class="agregar-comentario-interno-container">
    <div class="header-container">
        <span>{{ data.modalHeader }}</span>
        <i class="fas fa-times" (click)="onNoClick()"></i>
    </div>

    <div class="form-container">
        <div [innerHTML]="data.modalText" [ngClass]="{'modal-text': data.modalText}"></div>
        <form [formGroup]="form">
            <mat-form-field appearance="outline" *ngIf="data.inputsToShow.has('subject')">
                <mat-label>{{ 'general.subject' | translate }}</mat-label>
                <input matInput type="text" formControlName="subject" placeholder="{{ 'general.write_here' | translate }}" [required]="data.requiredInputs.has('subject')" maxlength="255">
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="data.inputsToShow.has('date')" class="date-input">
                <mat-label>{{ 'general.date' | translate }}</mat-label>
                <input matInput [matDatepicker]="fecha" formControlName="date" [disabled]="true" [max]="maxDate" [required]="data.requiredInputs.has('date')">
                <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                <mat-datepicker #fecha disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width"  *ngIf="data.inputsToShow.has('comment')">
                <mat-label>{{ 'general.observation' | translate }}</mat-label>
                <textarea matInput placeholder="{{ 'general.add_observation' | translate }}" formControlName="comment" [required]="data.requiredInputs.has('comment')" maxlength="255"></textarea>
                <mat-hint
                    align="end"
                    [ngStyle]="{ color: form.value.comment?.length === 255 ? 'red' : 'inherit' }"
                >
                    {{ form.value.comment?.length || 0 }} / {{ 255 }}
                </mat-hint>
            </mat-form-field>

            <div class="row" *ngIf="data.inputsToShow.has('attachment')">
                <div class="col-12">
                    <ngx-dropzone #drop (change)="onSelect($event)" hidden></ngx-dropzone>
                    <button class="btn btn-file mb-4" (click)="drop.showFileSelector()">
                        <mat-icon>attach_file</mat-icon>{{ 'general.attach_file' | translate }}
                    </button>
                    <span class="optional">{{ 'general.optional' | translate }}</span>
                </div>
            </div>
            <div class="col-sm-12 mb-2" style="margin-bottom: 1.5rem !important;">
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let adjunto of adjuntos; index as index;">
                        <div class="d-flex justify-content-between">
                            <div class="p-2">
                                <i class="far fa-file mr-3"></i>{{ adjunto.nombre }}
                            </div>
                            <div class="p-2">
                                <i class="fas fa-trash-alt pointer mr-3" (click)="onRemove(index)" matTooltip="{{ 'general.delete' | translate }}"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="crear-comentario-footer mb-2 mt-2 text-right">
                <button class="btn boton-anterior" id="cancelarComentario" (click)="onNoClick()">{{ 'general.cancel' | translate }}</button>
                <button class="btn btn-siguiente" id="guardarComentario" (click)="submit()">{{ 'general.accept' | translate }}</button>
            </div>
        </form>
    </div>
</div>
