import {Adapter} from './adapter';
import {LiquidacionPeriodoOrquestador} from '../models/liquidacion-periodo-orquestador.model';
import {Injectable} from '@angular/core';
import {UserAdapter} from './user.adapter';
import {PropiedadAdapter} from './propiedad.adapter';
@Injectable({
  providedIn: 'root'
})
export class LiquidacionPeriodoOrquestadorAdapter implements Adapter<LiquidacionPeriodoOrquestador> {
  constructor(
    private userAdapter: UserAdapter,
    private propiedadAdapter: PropiedadAdapter
  ) {
  }
  adapt(item: any): LiquidacionPeriodoOrquestador {
    const liquidacionPeriodoOrquestador = new LiquidacionPeriodoOrquestador();
    liquidacionPeriodoOrquestador.id = item.attributes.id;
    liquidacionPeriodoOrquestador.propiedadId = item.attributes.propiedad_id;
    liquidacionPeriodoOrquestador.periodo = item.attributes.periodo;
    liquidacionPeriodoOrquestador.ejecutivoId = item.attributes.ejecutivo_id;
    liquidacionPeriodoOrquestador.estado = item.attributes.estado;

    if (item.included) {
      item.included.filter(include =>  {
        switch (include.type) {
          case 'Propiedad':
            liquidacionPeriodoOrquestador.propiedad = this.propiedadAdapter.adapt(include);
            break;
          case 'User':
            liquidacionPeriodoOrquestador.ejecutivo = this.userAdapter.adapt(include)
            break;
        }
      })
    }
    return liquidacionPeriodoOrquestador;
  }
}
