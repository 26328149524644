import { CotizacionEstado } from './cotizacion-estado.model';
import { CotizacionUnidad } from './cotizacion-unidad.model';
import { CotizacionUnidadDetalle } from './cotizacion-unidad-detalle.model';
import { Propiedad } from './propiedad.model';
import { CotizacionContacto } from './cotizacion-contacto.model';
import { Contacto } from './contacto.model';
import { CotizacionConfiguracion } from "./cotizacion-configuracion.model";

export class Cotizacion {

    private _id: string;
    private _folio: string;
    private _contactoId: string;
    private _ejecutivoId: string;
    private _estadoId: string;
    private _fecha: string;
    private _fechaVigencia: string;
    private _fechaInicioContrato: string;
    private _fechaFinContrato: string;
    private _diaPago: string;
    private _observacion: string;
    private _createdAt: string;
    private _createdBy: string;

    private _cotizacionContacto: CotizacionContacto[];
    private _cotizacionEstado: CotizacionEstado;
    private _propiedad: Propiedad;
    private _cotizacionConfiguracion: CotizacionConfiguracion

    private _cotizacionUnidad: CotizacionUnidad[];
    private _cotizacionUnidadDetalle: CotizacionUnidadDetalle[];

    private _titular: Contacto;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get folio(): string {
        return this._folio;
    }
    public set folio(value: string) {
        this._folio = value;
    }
    public get contactoId(): string {
        return this._contactoId;
    }
    public set contactoId(value: string) {
        this._contactoId = value;
    }
    public get ejecutivoId(): string {
        return this._ejecutivoId;
    }
    public set ejecutivoId(value: string) {
        this._ejecutivoId = value;
    }
    public get estadoId(): string {
        return this._estadoId;
    }
    public set estadoId(value: string) {
        this._estadoId = value;
    }
    public get fecha(): string {
        return this._fecha;
    }
    public set fecha(value: string) {
        this._fecha = value;
    }
    public get fechaVigencia(): string {
        return this._fechaVigencia;
    }
    public set fechaVigencia(value: string) {
        this._fechaVigencia = value;
    }
    public get fechaInicioContrato(): string {
        return this._fechaInicioContrato;
    }
    public set fechaInicioContrato(value: string) {
        this._fechaInicioContrato = value;
    }
    public get fechaFinContrato(): string {
        return this._fechaFinContrato;
    }
    public set fechaFinContrato(value: string) {
        this._fechaFinContrato = value;
    }
    public get diaPago(): string {
        return this._diaPago;
    }
    public set diaPago(value: string) {
        this._diaPago = value;
    }
    public get observacion(): string {
        return this._observacion;
    }
    public set observacion(value: string) {
        this._observacion = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }


    public get cotizacionContacto(): CotizacionContacto[] {
        return this._cotizacionContacto;
    }
    public set cotizacionContacto(value: CotizacionContacto[]) {
        this._cotizacionContacto = value;
    }
    public get cotizacionEstado(): CotizacionEstado {
        return this._cotizacionEstado;
    }
    public set cotizacionEstado(value: CotizacionEstado) {
        this._cotizacionEstado = value;
    }
    public get propiedad(): Propiedad {
        return this._propiedad;
    }
    public set propiedad(value: Propiedad) {
        this._propiedad = value;
    }
    get cotizacionConfiguracion(): CotizacionConfiguracion {
        return this._cotizacionConfiguracion;
    }
    set cotizacionConfiguracion(value: CotizacionConfiguracion) {
        this._cotizacionConfiguracion = value;
    }

    public get cotizacionUnidad(): CotizacionUnidad[] {
        return this._cotizacionUnidad;
    }
    public set cotizacionUnidad(value: CotizacionUnidad[]) {
        this._cotizacionUnidad = value;
    }
    public get cotizacionUnidadDetalle(): CotizacionUnidadDetalle[] {
        return this._cotizacionUnidadDetalle;
    }
    public set cotizacionUnidadDetalle(value: CotizacionUnidadDetalle[]) {
        this._cotizacionUnidadDetalle = value;
    }

    public get titular(): Contacto {
        return this._titular;
    }
    public set titular(value: Contacto) {
        this._titular = value;
    }
}

