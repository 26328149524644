<div class="pop-section row mt-4">
    <div class="col-sm-1">
        <div class="img-fluid text-center mt-2">
            <i style="color: #C8C8C8" class="fa fa-user-circle fa-3x"></i>
        </div>
    </div>
    <div class="col-sm-11 ">
        <div class="d-flex justify-content-between">
            <div>
                <label class="pop-label font-lg d-block pop-color-blue">{{contacto.nombre}} {{contacto.apellido}}</label>
                <div *ngIf="contacto.identificador || contacto.nacionalidad">
                    <label *ngIf="contacto.nacionalidad" class="font-weight-light d-inline pop-label font-md mr-3">{{contacto.nacionalidad.nacionalidad}}</label>
                    <label *ngIf="contacto.identificador && contacto.nacionalidad" class="font-weight-light d-inline pop-label font-md mr-3">|</label>
                    <label *ngIf="contacto.identificador" class="font-weight-light d-inline pop-label font-md">{{contacto.identificador | identificador:contacto.identificadorTipoId}}</label>
                </div>
            </div>
            <div class="align-self-center">
                <a class="pointer" *ngIf="canUpdateContacto" (click)="showEditar()"><i class="fa fa-edit"></i> Editar</a>
            </div>
        </div>
        <hr>
        <div class="d-flex justify-content-between">
            <div>
                <label *ngIf="contacto.email" class="font-weight-light d-inline pop-label font-md mr-3">{{contacto.email}}</label>
                <label *ngIf="contacto.email && contacto.telefono" class="font-weight-light d-inline pop-label font-md mr-3">|</label>
                <label *ngIf="contacto.telefono" class="font-weight-light d-inline pop-label font-md mr-3">{{contacto.telefono}}</label>
            </div>
            <div class="align-self-center">
                <a class="pointer" (click)="showMore = !showMore">{{textVerMas}}</a>
            </div>
        </div>
        <ng-container *ngIf="showMore">
            <hr>
            <div class="row">
                <div class="col-sm-3">
                    <label class="pop-label d-block font-weight-light">{{ 'contact.marital_status' | translate }}:</label>
                    <label class="pop-label d-block font-md font-weight-light">{{contacto.estadoCivil ? (contacto.estadoCivil.lang_resource | translate) : 'Sin información'}}</label>
                </div>
                <div class="col-sm-3">
                    <label class="pop-label d-block font-weight-light">{{ 'contact.profession' | translate }}:</label>
                    <label class="pop-label d-block font-md font-weight-light">{{contacto.profesion ? contacto.profesion : 'Sin información'}}</label>
                </div>
                <div class="col-sm-3">
                    <label class="pop-label d-block font-weight-light">{{ 'contact.arrival_way' | translate }}:</label>
                    <label class="pop-label d-block font-md font-weight-light">{{contacto.medioLlegada ? (contacto.medioLlegada.lang_resource | translate) : 'Sin información'}}</label>
                </div>
                <div class="col-sm-3">
                    <label class="pop-label d-block font-weight-light">{{ 'contact.income_date' | translate }}:</label>
                    <label class="pop-label d-block font-md font-weight-light">{{contacto.createdAt | date}}</label>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-sm-4">
                    <label class="pop-label d-block font-weight-light">{{ 'contact.address' | translate }}:</label>
                    <label class="pop-label d-block font-md font-weight-light">{{contacto.direccion ? contacto.direccion : 'Sin información'}}</label>
                </div>
                <div class="col-sm-4">
                    <label class="pop-label d-block font-weight-light">{{ 'contact.country' | translate }}:</label>
                    <label class="pop-label d-block font-md font-weight-light">{{contacto.pais ? contacto.pais.nombre : 'Sin información'}}</label>
                </div>
                <div class="col-sm-4">
                    <label class="pop-label d-block font-weight-light">{{ 'contact.zip_code' | translate }}:</label>
                    <label class="pop-label d-block font-md font-weight-light">{{contacto.zipCode ? contacto.zipCode : 'Sin información'}}</label>
                </div>
            </div>
        </ng-container>
    </div>
</div>
