export class CotizacionNota {
  private _id: string;
  private _propiedadId: string;
  private _nombre: string;
  private _texto: string;
  private _isActivoInterno: boolean;
  private _isActivoWeb: boolean;
  private _orden: number;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get propiedadId(): string {
    return this._propiedadId;
  }

  set propiedadId(value: string) {
    this._propiedadId = value;
  }

  get nombre(): string {
    return this._nombre;
  }

  set nombre(value: string) {
    this._nombre = value;
  }

  get texto(): string {
    return this._texto;
  }

  set texto(value: string) {
    this._texto = value;
  }

  get isActivoInterno(): boolean {
    return this._isActivoInterno;
  }

  set isActivoInterno(value: boolean) {
    this._isActivoInterno = value;
  }

  get isActivoWeb(): boolean {
    return this._isActivoWeb;
  }

  set isActivoWeb(value: boolean) {
    this._isActivoWeb = value;
  }

  get orden(): number {
    return this._orden;
  }

  set orden(value: number) {
    this._orden = value;
  }
}
