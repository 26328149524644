export class Statistics {

  private _stats: any[];

  public get stats(): any[] {
    return this._stats;
  }
  public set stats(value: any[]) {
    this._stats = value;
  }

}
