import { Adapter } from './adapter';
import { TransaccionTipo } from '../models/model.index';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class TransaccionTipoAdapter implements Adapter<TransaccionTipo> {

    adapt(item: any): TransaccionTipo {
        const transaccionTipo = new TransaccionTipo();
        transaccionTipo.id = item.attributes.id;
        transaccionTipo.nombre = item.attributes.nombre;
        return transaccionTipo;
    }

}
