import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormInfoDataInterface} from '../../interfaces/form-info-data.interface';

@Component({
  selector: 'app-form-info',
  templateUrl: './form-info.component.html',
  styleUrls: ['./form-info.component.css']
})
export class FormInfoComponent implements OnInit {
  @Input() formInfoData: FormInfoDataInterface[];
  @Input() id: string;
  @Output() inEdition = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  editForm() {
    if (this.id) {
      this.inEdition.emit(this.id);
    } else {
      this.inEdition.emit(true);
    }
  }

}
