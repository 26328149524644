export class PropiedadConfiguracionContabilidad {
    private _id: number;
    private _propiedadId: string;
    private _enabled: boolean;
    private _codigoCentroCostos: string;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get propiedadId(): string {
        return this._propiedadId;
    }

    set propiedadId(value: string) {
        this._propiedadId = value;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }

    get codigoCentroCostos() {
        return this._codigoCentroCostos;
    }

    set codigoCentroCostos(value: string) {
        this._codigoCentroCostos = value;
    }
}
