import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { EstadoCuentaAdapter } from '../../adapter/estado-cuenta.adapter';
import { EstadoCuentaPreviewAdapter } from 'src/app/adapter/estado-cuenta-preview.adapter';
import { Contrato, EstadoCuenta, Paginador } from 'src/app/models/model.index';
import { Observable } from 'rxjs';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { Utils } from 'src/app/shared/utils';
import {AccionesMasivasLiquidacionInterface} from '../../pages/balances/shared/interfaces/AccionesMasivasLiquidacionInterface';

@Injectable({
    providedIn: 'root'
})
export class EstadoCuentaService {

    public paginador: Paginador;
    public parametros: any;

    constructor(
        public http: HttpClient,
        public paginadorAdapter: PaginadorAdapter,
        public estadoCuentaAdapter: EstadoCuentaAdapter,
        public estadoCuentaPreviewAdapter: EstadoCuentaPreviewAdapter
    ) { }

    paginadorEstadoCuenta() {
        return this.paginador;
    }

    cargarEstadoCuentas(page, filtro?): Observable<EstadoCuenta[]> {
        if (filtro && filtro.estadoCuentaEstadoId) {
          const indexPorGenrerar = filtro.estadoCuentaEstadoId.findIndex(estado => estado === 999);
          if (indexPorGenrerar !== -1) {
            filtro.estadoCuentaEstadoId.splice(indexPorGenrerar, 1);
          }
        }
        this.generarParametrosBusqueda(filtro);
        if (page && page > 0) {
            this.parametros += '&page=' + page;
        }
        return this.http.get(environment.urlApi + 'estado_cuenta?' + this.parametros)
            .pipe(
                map((resp: any) => {
                    this.paginador = this.paginadorAdapter.adapt(resp.meta);
                    return resp.data.map(estadoCuenta => this.estadoCuentaAdapter.adapt(estadoCuenta));
                })
            );
    }

    cargarEstadoCuenta(filtro): Observable<EstadoCuenta> {
        this.generarParametrosBusqueda(filtro);
        return this.http.get(environment.urlApi + 'estado_cuenta?' + this.parametros)
            .pipe(
                map((resp: any) => {
                    return resp.data.map(estadoCuenta => this.estadoCuentaAdapter.adapt(estadoCuenta));
                })
            );
    }

    getById(id: string): Observable<EstadoCuenta> {
        return this.http.get(environment.urlApi + 'estado_cuenta/' + id)
          .pipe(
            map((resp: any) => this.estadoCuentaAdapter.adapt(resp.data))
          );
    }

    previewPrimerCobro(contratoId: string): Observable<any> {
        return this.http.post(environment.urlApi + 'estado_cuenta/preview', { contratoId, isPrimerPago: true }, { responseType: 'arraybuffer' })
            .pipe(
                map((res: any) => {
                    return new Blob([res], { type: 'application/pdf' });
                })
            );
    }

    crearPrimerCobro(contratoId: string, email: boolean): Observable<any> {
        return this.http.post(environment.urlApi + 'estado_cuenta/primer_cobro', { contratoId, email: email ? 1 : 0 });
    }

    generarParametrosBusqueda(filtro) {
        this.parametros = Utils.generarParametrosBusqueda('estado_cuenta_filter', filtro);
    }

    enviarLiquidacion(id: string): Observable<any> {
        return this.http.post(environment.urlApi + 'estado_cuenta/enviar_liquidaciones', { estadoCuentas: [id] });
    }

    enviarLiquidaciones(filtro): Observable<any> {
        this.generarParametrosBusqueda(filtro);
        return this.http.post(environment.urlApi + 'estado_cuenta/enviar-liquidaciones-masivo?' + this.parametros, {});
    }

    downloadFile(id: string): Observable<any> {
        return this.http.get(environment.urlApi + 'estado_cuenta/' + id + '/pdf', { responseType: 'blob' });
    }

    exportar(filtro) {
        this.generarParametrosBusqueda(filtro);
        return this.http.get(environment.urlApi + 'estado_cuenta/export?' + this.parametros, { responseType: 'blob' });
    }

    indicadores(filtro?): Observable<any> {
        if (filtro) {
            this.generarParametrosBusqueda(filtro);
        }
        return this.http.get(environment.urlApi + 'estado_cuenta/indicadores?' + this.parametros)
            .pipe(
                map((resp: any) => resp.meta.indicadores)
            );
    }

    actualizarFechaVencimiento(id: string, estadoCuenta) {
        estadoCuenta.fechaVencimiento = Utils.jsonDate(estadoCuenta.fechaVencimiento);
        return this.http.put(environment.urlApi + 'estado_cuenta/' + id, estadoCuenta);
    }

    actualizarEstado(estadoCuentaId: string, estadoCuentaEstado: number) {
        return this.http.patch(environment.urlApi + 'estado_cuenta/' + estadoCuentaId, {estadoCuentaEstado});
    }

    crearEstadoCuenta(estadoCuenta): Observable<any> {
        return this.http.post(environment.urlApi + 'estado_cuenta', estadoCuenta);
    }

    actualizarEstadosMasivos(data: AccionesMasivasLiquidacionInterface, filtro) {
        this.generarParametrosBusqueda(filtro);
        return this.http.patch(environment.urlApi + 'estado_cuenta/masivo-filter?' + this.parametros, data);
    }

    previewList(contrato: Contrato) {
        return this.http.get(environment.urlApi + 'estado_cuenta/preview-list/' + contrato.id)
            .pipe(
                map((resp: any) =>
                resp.meta.estadoCuentaPreviewList.map(estadoCuenta => this.estadoCuentaPreviewAdapter.adapt(estadoCuenta, contrato))
                ),
                map((previews: any) => previews.sort((a, b) => Date.parse(b.fecha) - Date.parse(a.fecha) ) )
            );
    }

    previewEstadoCuenta(contratoId: string, fecha: any): Observable<any> {
        return this.http.post(environment.urlApi + 'estado_cuenta/preview', { contratoId, isPrimerPago: false, fecha }, { responseType: 'arraybuffer' })
            .pipe(
                map((res: any) => new Blob([res], { type: 'application/pdf' }) )
            );
    }

    crearEstadoCuentaFutura(estadoCuenta): Observable<any> {
        return this.http.post(environment.urlApi + 'estado_cuenta/futuras', estadoCuenta)
    }

    borrarEstadoCuenta(estadoCuentaId: string): Observable<any> {
        return this.http.delete(environment.urlApi + 'estado_cuenta/' + estadoCuentaId)
    }

}
