<div class="modal-enviar-correo">
  <div class="header-container">
    <span>{{ data.modalHeader }}</span>
    <i class="fas fa-times pointer" (click)="onNoClick()"></i>
  </div>

  <div [innerHTML]="data.modalText" style="padding: 25px;"></div>
  <mat-form-field class="email-container" appearance="outline">
    <mat-label>{{ 'general.recipients' | translate }}</mat-label>
    <mat-chip-list #emailsList>
      <mat-chip
        *ngFor="let email of emails"
        (removed)="remove(email)"
      >
        {{ email }}
      <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input #emailsInput
        [matChipInputFor]="emailsList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
      >
    </mat-chip-list>
  </mat-form-field>
  <div class="required-input">{{ 'general.required_single' | translate }}*</div>

  <div class="crear-comentario-footer mb-2 mt-2 text-right">
    <button class="btn boton-anterior" id="cancelarComentario" (click)="onNoClick()">
      {{ 'general.cancel' | translate }}
    </button>
    <button class="btn btn-siguiente" id="guardarComentario" (click)="submit()" [disabled]="emails.length === 0">
      {{ 'general.send' | translate }}
    </button>
  </div>
</div>
