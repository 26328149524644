import {Injectable} from '@angular/core';
import {Unidad} from '../../../models/unidad.model';
import {Utils} from '../../../shared/utils';
import {PropiedadConfiguracionComercial} from '../../../models/propiedad-configuracion-comercial.model';
import {ContratoConfiguracion} from '../../../models/contrato-configuracion.model';
import {DescuentoRecargoConfiguracion} from '../../../models/descuento-recargo-configuracion.model';
import {DivisaPipe} from '../../../pipes/divisa.pipe';
import {
  ConfiguracionInterface,
  CustodiaDocumentoInterface,
  DateJson,
  NegocioCobrosRecurrentesInterface,
  NegocioContactoInterface,
  NegocioInterface, NegocioReservaInterface,
  NegocioUnidadConfiguracionInterface,
  NegocioUnidadDetalleInterface,
  NegocioUnidadInterface
} from '../../../interfaces/negocio.interface';
import {ContratoInterface, ContratoUnidadInterface} from './contrato-data.interface';
import {Contrato} from '../../../models/contrato.model';
import {ContratoContacto} from '../../../models/contrato-contacto.model';
import {CustodiaDocumento} from '../../../models/custodia-documento.model';
import {ContratoUnidad} from '../../../models/contrato-unidad.model';
import {ContratoUnidadConfiguracion} from '../../../models/contrato-unidad-configuracion.model';
import {ContratoUnidadDetalle} from '../../../models/contrato-unidad-detalle.model';
import {Contacto} from '../../../models/contacto.model';
import * as moment from 'moment';
import {Reserva} from "../../../models/reserva.model";

@Injectable({
  providedIn: 'root',
})
export class ContratoUtils {
  private static mensajeBase = 'Los valores de la unidad se reajustarán ';
  private static divisaPipe: DivisaPipe;
  private static periodicidadTipos = [
    {id: 1, lang_resource: 'Dia(s)'},
    {id: 2, lang_resource: 'Mes(es)'},
    {id: 3, lang_resource: 'Año(s)'}
  ];
  constructor(
    public divisaPipe: DivisaPipe
  ) {
    this.divisaPipe = divisaPipe;
  }

  static monthDiff(fechaInicio: DateJson, fechaFin: DateJson) {
    let months;
    months = (fechaFin.year - fechaInicio.year) * 12;
    months -= fechaInicio.month;
    months += fechaFin.month;
    return months;
  }

  static fechaSiguienteReajuste(fechaInicio: DateJson, periodicidadTipo: number, periodicidadValor: number) {
    const siguienteFecha = moment(Utils.dateObjectToString(fechaInicio))
    switch (periodicidadTipo) {
      case 1:
        siguienteFecha.add(periodicidadValor, 'day');
        break;
      case 2:
        siguienteFecha.add(periodicidadValor, 'month');
        break;
      case 3:
        siguienteFecha.add(periodicidadValor, 'year');
    }
    return siguienteFecha;
  }

  static cargosUnidad(unidad: Unidad, periodo: number, fechaInicio: DateJson): NegocioUnidadDetalleInterface[] {
    const defaultDivisa = '7f377688-5b8a-49a2-a0f7-6bedd68fa900' // Peso Chileno
    const primerPrecioArriendo = unidad.divisas?.find(item => item.conceptoId === 1 && item.tipoId === 1);
    const primerPrecioGGCC = unidad.divisas?.find(item => item.conceptoId === 2 && item.tipoId === 1);
    const montoArriendo = primerPrecioArriendo ? primerPrecioArriendo.monto : 0;
    const montoGGCC = primerPrecioGGCC ? primerPrecioGGCC.monto : 0;
    const divisaArriendo = primerPrecioArriendo ? primerPrecioArriendo.divisaId : defaultDivisa;
    const divisaGGCC = primerPrecioGGCC ? primerPrecioGGCC.divisaId : defaultDivisa;
    const cargoArriendo = this.generateCargoDescuentoArriendoGC('Arriendo', montoArriendo, periodo, divisaArriendo, fechaInicio);
    const cargoGastoComun = this.generateCargoDescuentoArriendoGC('Gasto Común', montoGGCC, periodo, divisaGGCC, fechaInicio);
    return [cargoArriendo, cargoGastoComun];
  }

  static generateCargoDescuentoArriendoGC(type: string, monto: number, periodo: number, divisaId: string, fechaInicio: DateJson) {
    const cargoDescuento: NegocioUnidadDetalleInterface = {
      divisaId,
      nombre: type,
      conceptoId: type === 'Arriendo' ? 1 : 2,
      montoTipoId: 1,
      cargoClasificacionId: type === 'Arriendo' ? 1 : 2,
      isFacturableInt: true,
      monto,
      cicloCantidad: periodo,
      cicloOpcion: false,
      cicloFecha: fechaInicio
    }
    return cargoDescuento;
  }

  // tslint:disable-next-line:max-line-length
  static generateUnidadConfiguracion(unidadDetalles: NegocioUnidadDetalleInterface[], configuracionId?: string): NegocioUnidadConfiguracionInterface {
    const uf = '20562d2a-bed8-451f-a8f8-77f293a4880a';
    const detalleArriendo = unidadDetalles.find(item => item.conceptoId === 1);
    const reajusteCheck = detalleArriendo.divisaId === uf ? 1 : 0;
    const reajusteTipo = detalleArriendo.divisaId === uf ? 2 : null;
    const id = configuracionId ? configuracionId : null;
    return {
      id,
      reajusteCheck,
      reajusteTipo,
      reajustePeriodicidad: null,
      reajustePeriodicidadValor: null,
      reajusteMontoTipo: null,
      reajusteValor: null,
      reajusteFecha: null,
      reajusteDivisa: null
    };
  }

  static defaultUnidadConfiguracion(): NegocioUnidadConfiguracionInterface {
    return {
      reajusteCheck: 0,
      reajusteTipo: null,
      reajustePeriodicidad: null,
      reajustePeriodicidadValor: null,
      reajusteMontoTipo: null,
      reajusteFecha: null,
      reajusteDivisa: null,
      reajusteValor: null
    }
  }

  static unidadConfiguracionToUf(unidadConfiguracion: NegocioUnidadConfiguracionInterface): NegocioUnidadConfiguracionInterface {
    return {
      id: unidadConfiguracion.id ? unidadConfiguracion.id : null,
      reajusteCheck: 1,
      reajusteTipo: 2,
      reajustePeriodicidad: null,
      reajustePeriodicidadValor: null,
      reajusteMontoTipo: null,
      reajusteValor: null,
      reajusteFecha: null,
      reajusteDivisa: null
    };
  }

  // tslint:disable-next-line:max-line-length
  static propiedadConfiguracionToContratoConfiguracionInterface(propiedadConfiguracion: PropiedadConfiguracionComercial): ConfiguracionInterface {

    return {
      divisaCobro: propiedadConfiguracion.divisasCobro ? propiedadConfiguracion.divisasCobro[0].id : '7f377688-5b8a-49a2-a0f7-6bedd68fa900',
      cobroPeriodicidad: propiedadConfiguracion.contratoCobroPeriodicidadId,
      retrasoMultaTipo: propiedadConfiguracion.retrasoMultaMontoTipoId,
      retrasoMultaMonto: propiedadConfiguracion.retrasoMultaMonto,
      retrasoMultaMontoDivisa: propiedadConfiguracion.retrasoMultaMontoDivisa?.id,
      retrasoCobroMultaLiqEnCurso: propiedadConfiguracion.retrasoCobroMultaLiqEnCurso,
      retrasoInteresMontoTipo: propiedadConfiguracion.retrasoInteresMontoTipoId,
      retrasoInteresMonto: propiedadConfiguracion.retrasoInteresMonto,
      retrasoInteresMontoDivisa: propiedadConfiguracion.retrasoInteresMontoDivisa?.id,
      retrasoInteresPeriodicidad: propiedadConfiguracion.retrasoInteresPeriodicidadId,
      retrasoInteresPeriodicidadValor: propiedadConfiguracion.retrasoInteresPeriodicidadValor,
      retrasoInteresTipoId: propiedadConfiguracion.retrasoInteresTipoId,
      diaGeneracion: propiedadConfiguracion.contratoDiaGeneracion,
      diaCobro: propiedadConfiguracion.contratoDiaCobro,
      liquidacionCreacionEstado: propiedadConfiguracion.liquidacionCreacionEstadoId,
      diaPago: propiedadConfiguracion.contratoDiaPago,
      diaCorteArriendo: propiedadConfiguracion.contratoDiaCorteArriendo,
      diaCorteGastoComun: propiedadConfiguracion.contratoDiaCorteGastoComun,
      diaCorteServicios: propiedadConfiguracion.contratoDiaCorteServicios,
      avisoTerminoDias: propiedadConfiguracion.contratoAvisoTerminoDias,
      interesPorDia: propiedadConfiguracion.retrasoInteresPorDia,
      isRenovacionAutomatica: propiedadConfiguracion.isRenovacionAutomatica ? propiedadConfiguracion.isRenovacionAutomatica : false,
      isGeneracionAutomatica: !propiedadConfiguracion.contratoEnvioManualEstadoCuenta,
      divisaConversion: propiedadConfiguracion.contratoDiaConversionDivisa || 0
    };
  }

  static descuentoRecargoToUnidadDetalleInterface(descuentoRecargo: DescuentoRecargoConfiguracion) {
    const unidadDetalle: NegocioUnidadDetalleInterface = {
      descuentoRecargoConfiguracion: descuentoRecargo.id,
      nombre: descuentoRecargo.nombre,
      conceptoId: descuentoRecargo.conceptoId,
      cargoClasificacionId: descuentoRecargo.cargoClasificacionId,
      montoTipoId: descuentoRecargo.montoTipoId,
      monto: descuentoRecargo.cicloOpcion ? descuentoRecargo.montoTotal : descuentoRecargo.monto,
      divisaId: descuentoRecargo.divisaId,
      descuentoRecargoConceptoAplicaId: descuentoRecargo.descuentoRecargoConceptoAplicaId,
      cicloOpcion: descuentoRecargo.cicloOpcion,
      cicloCantidad: descuentoRecargo.cicloCantidad,
      cicloFecha: descuentoRecargo.cicloFecha,
      isAfectoImpuesto: descuentoRecargo.isAfectoImpuesto,
      isFacturableInt: descuentoRecargo.isFacturableInt
    }
    return unidadDetalle;
  }

  static multaPorAtraso(configuracion: ConfiguracionInterface, divisaPipe: DivisaPipe) {
    const { retrasoMultaTipo, retrasoMultaMonto, retrasoMultaMontoDivisa} = configuracion
    if (retrasoMultaTipo) {
      switch (retrasoMultaTipo) {
        case 1:
          return divisaPipe.transform(retrasoMultaMonto, retrasoMultaMontoDivisa);
        case 2:
          return retrasoMultaMonto + '%';
      }
    } else {
      return 'No';
    }
  }

  static interesPorAtraso(configuracion: ConfiguracionInterface, divisaPipe: DivisaPipe) {
    const {retrasoInteresMontoTipo, retrasoInteresTipoId, retrasoInteresMonto, retrasoInteresMontoDivisa } = configuracion
    if (retrasoInteresMontoTipo) {
      switch (retrasoInteresMontoTipo) {
        case 1:
          return `Interés fijo de ${divisaPipe.transform(retrasoInteresMonto, retrasoInteresMontoDivisa)}`
        case 2:
          switch (retrasoInteresTipoId) {
            case 1:
              return 'Interés simple de ' + retrasoInteresMonto + '%';
            case 2:
              return 'Interés compuesto de ' + retrasoInteresMonto + '%';
            case 3:
              return `Interés variable de ${retrasoInteresMonto}%`;
          }
      }
    } else {
      return 'No';
    }
  }

  // tslint:disable-next-line:max-line-length
  static cobrosRecurrentesToConfiguracion(configuracion: ConfiguracionInterface, cobrosRecurrentes: NegocioCobrosRecurrentesInterface): ConfiguracionInterface {
    configuracion.isGeneracionAutomatica = cobrosRecurrentes.isGeneracionAutomatica;
    configuracion.diaGeneracion = cobrosRecurrentes.diaGeneracion;
    configuracion.diaPago = cobrosRecurrentes.diaPago;
    configuracion.liquidacionCreacionEstado = cobrosRecurrentes.liquidacionCreacionEstado;
    configuracion.retrasoMultaTipo = cobrosRecurrentes.retrasoMultaTipo;
    configuracion.retrasoMultaMonto = cobrosRecurrentes.retrasoMultaMonto;
    configuracion.retrasoMultaMontoDivisa = cobrosRecurrentes.retrasoMultaMontoDivisa;
    configuracion.retrasoCobroMultaLiqEnCurso = cobrosRecurrentes.retrasoCobroMultaLiqEnCurso;
    configuracion.retrasoInteresTipoId = cobrosRecurrentes.retrasoInteresTipoId;
    configuracion.retrasoInteresMontoTipo = cobrosRecurrentes.retrasoInteresMontoTipo;
    configuracion.retrasoInteresMonto = cobrosRecurrentes.retrasoInteresMonto;
    configuracion.retrasoInteresMontoDivisa = cobrosRecurrentes.retrasoInteresMontoDivisa;
    configuracion.retrasoInteresPeriodicidad = cobrosRecurrentes.retrasoInteresPeriodicidad;
    configuracion.retrasoInteresPeriodicidadValor = cobrosRecurrentes.retrasoInteresPeriodicidadValor;
    if (typeof configuracion.interesPorDia === 'boolean') configuracion.interesPorDia = !!cobrosRecurrentes.retrasoInteresMontoTipo
    return configuracion;
  }

  static contactoTipo(contratoContactoTipoId: number) {
    switch (contratoContactoTipoId) {
      case 1:
        return 'Titular';
      case 2:
        return 'Aval';
      case 3:
        return 'Representante';
      case 4:
        return 'Cotitular';
      default:
        return 'N/A';
    }
  }

  static negocioDataToContratoData(negocioData: NegocioInterface) {
    const contratoData: ContratoInterface = {
      id: negocioData.contratoId ? negocioData.contratoId : null,
      fecha: negocioData.fecha,
      fechaInicio: negocioData.fechaInicio,
      fechaFin: negocioData.fechaFin,
      contratoContactos: negocioData.negocioContactos,
      custodiaDocumentos: negocioData.custodiaDocumentos ? negocioData.custodiaDocumentos : [],
      contratoUnidades: this.negocioUnidadToContratoUnidad(negocioData.negocioUnidades),
      contratoTemplate: negocioData.contratoTemplate,
      estado: negocioData.estado,
      subEstado: negocioData.subEstado,
      ejecutivo: negocioData.ejecutivo,
      observacion: negocioData.observacion,
      configuracion: negocioData.configuracion,
      firmantes: negocioData.firmantes,
      documento: negocioData.documento ? negocioData.documento : null,
      documentoBaseOrigenId: negocioData.documentoBaseOrigenId ? negocioData.documentoBaseOrigenId : null,
      reservas: negocioData.reservas?.map(item => item.id)
    };
    return contratoData;
  }

  static negocioUnidadToContratoUnidad(negocioUnidades: NegocioUnidadInterface[]): ContratoUnidadInterface[] {
    const contratoUnidades: ContratoUnidadInterface[] = [];
    negocioUnidades.forEach(item => {
      const contratoUnidad: ContratoUnidadInterface = {
        id: item.id ? item.id : null,
        unidad: item.unidad,
        configuracion: this.unidadConfiguracionReajuste(item.configuracion),
        contratoUnidadDetalles: item.negocioUnidadDetalles
      }
      contratoUnidades.push(contratoUnidad);
    });
    return contratoUnidades;
  }

  static unidadConfiguracionReajuste(unidadConfiguracion: NegocioUnidadConfiguracionInterface): NegocioUnidadConfiguracionInterface {
    const configuracion: NegocioUnidadConfiguracionInterface = {...unidadConfiguracion};
    if (configuracion.reajusteCheck === 1) {
      configuracion.reajusteFecha = this.fechaReajusteToSave(
        configuracion.reajusteFecha,
        configuracion.reajustePeriodicidad,
        configuracion.reajustePeriodicidadValor
      );
    }
    return configuracion;
  }

  static contratoToNegocioData(contrato: Contrato): NegocioInterface {
    return {
      contratoId: contrato.id,
      fecha: Utils.jsonDate(moment(contrato.fecha)),
      fechaInicio: Utils.jsonDate(moment(contrato.fechaInicio)),
      fechaFin: Utils.jsonDate(moment(contrato.fechaFin)),
      fechaRealTermino: contrato.fechaRealTermino ? Utils.jsonDate(moment(contrato.fechaRealTermino)) : null,
      negocioContactos: this.contratoContactosToNegocioContactos(contrato.contratoContacto),
      custodiaDocumentos: this.custodiaDocumentosToInterface(contrato.custodiaDocumento),
      negocioUnidades: this.contratoUnidadToNegocioUnidades(contrato.contratoUnidad, contrato.fechaInicio),
      contratoTemplate: contrato.contratoTemplateId,
      estado: contrato.estadoId,
      subEstado: contrato.subEstadoId,
      ejecutivo: contrato.ejecutivoId,
      observacion: contrato.observacion,
      configuracion: this.contratoConfiguracionToNegocioConfiguracion(contrato.contratoConfiguracion),
      contactos: this.contactosContrato(contrato),
      unidades: this.unidadesContrato(contrato),
      propiedad: contrato.propiedad,
      firmantes: contrato.firmantes,
      reservas: this.contratoReservasToNegocioReservas(contrato.reservas),
    };
  }

  static contratoContactosToNegocioContactos(contratoContacto: ContratoContacto[]): NegocioContactoInterface[] {
    const negocioContactos: NegocioContactoInterface[] = [];
    contratoContacto.forEach(contacto => {
      const negocioContacto: NegocioContactoInterface = {
        id: contacto.id,
        contactoId: contacto.contactoId,
        contratoContactoTipoId: contacto.contratoContactoTipoId,
        isFirmante: contacto.isFirmante,
        isTitular: contacto.isTitular
      };
      negocioContactos.push(negocioContacto);
    })
    return negocioContactos;
  }

  static custodiaDocumentosToInterface(custodiaDocumentos: CustodiaDocumento[]): CustodiaDocumentoInterface[] {
    const custodiaDocumentosInterface: CustodiaDocumentoInterface[] = [];
    if (custodiaDocumentos && custodiaDocumentos.length > 0) {
      custodiaDocumentos.forEach(documento =>  {
        const documentoInterface: CustodiaDocumentoInterface = {
          id: documento.id,
          tipo: documento.custodiaDocumentoTipoId,
          estado: documento.custodiaDocumentoEstadoId,
          fechaCustodia: Utils.jsonDate(documento.fechaCustodia.substr(0, 10)),
          fechaDocumento: null,
          fechaDevolucion: null,
          folio: documento.folio,
          banco: documento.banco,
          monto: documento.monto,
          observacion: '',
          isDepositableSolamente: documento.isDepositableSolamente,
          isCobrableAlPortador: documento.isCobrableAlPortador,
          giradoA: documento.giradoA
        }
        custodiaDocumentosInterface.push(documentoInterface);
      });
    }
    return custodiaDocumentosInterface;
  }

  static contratoUnidadToNegocioUnidades(contratoUnidad: ContratoUnidad[], contratoFechaInicio: string): NegocioUnidadInterface[] {
    const negocioUnidades: NegocioUnidadInterface[] = [];
    contratoUnidad.forEach(unidad => {
      const configuracion =
        this.contratoUnidadConfiguracionToNegocioUnidadConfiguracion(unidad.contratoUnidadConfiguracion, contratoFechaInicio);
      const negocioUnidad: NegocioUnidadInterface = {
        id: unidad.id,
        unidad: unidad.unidadId,
        configuracion,
        negocioUnidadDetalles: this.contratoUnidadDetalleToNegocioUnidadDetalle(unidad.contratoUnidadDetalle)
      }
      negocioUnidades.push(negocioUnidad);
    });
    return negocioUnidades;
  }

  // tslint:disable-next-line:max-line-length
  static contratoUnidadConfiguracionToNegocioUnidadConfiguracion(unidadConfiguracion: ContratoUnidadConfiguracion, contratoFechaInicio: string): NegocioUnidadConfiguracionInterface {
    const fechaReajuste = unidadConfiguracion.reajusteFecha ? Utils.jsonDate(unidadConfiguracion.reajusteFecha) :
      Utils.jsonDate(contratoFechaInicio.substr(0, 10));
    const fechaSiguienteReajuste = this.fechaSiguienteReajuste(
      fechaReajuste,
      unidadConfiguracion.reajustePeriodicidadId,
      unidadConfiguracion.reajustePeriodicidadValor
    );
    return {
      id: unidadConfiguracion.id,
      reajusteCheck: unidadConfiguracion.reajusteCheck ? 1 : 0,
      reajusteTipo: unidadConfiguracion.reajusteTipoId,
      reajustePeriodicidad: unidadConfiguracion.reajustePeriodicidadId,
      reajustePeriodicidadValor: unidadConfiguracion.reajustePeriodicidadValor,
      reajusteMontoTipo: unidadConfiguracion.reajusteMontoTipo,
      reajusteValor: unidadConfiguracion.reajusteValor,
      reajusteFecha: unidadConfiguracion.reajusteCheck ? Utils.jsonDate(fechaSiguienteReajuste) : null,
      reajusteDivisa: unidadConfiguracion.reajusteDivisa,
      reajusteMonto: unidadConfiguracion.propiedadConfiguracion?.reajusteMonto,
      reajusteMontoDivisaId: unidadConfiguracion.propiedadConfiguracion?.reajusteMontoDivisaId,
      reajusteMontoTipoId: unidadConfiguracion.propiedadConfiguracion?.reajusteMontoTipoId
    }
  }

  static contratoUnidadDetalleToNegocioUnidadDetalle(contratoUnidadDetalles: ContratoUnidadDetalle[]): NegocioUnidadDetalleInterface[] {
    const negocioUnidadDetalles: NegocioUnidadDetalleInterface[] = [];
    contratoUnidadDetalles.forEach(unidadDetalle => {
      const negocioUnidadDetalle: NegocioUnidadDetalleInterface = {
        id: unidadDetalle.id,
        descuentoRecargoConfiguracion: unidadDetalle.descuentoRecargoConfiguracionId,
        nombre: unidadDetalle.nombre,
        conceptoId: unidadDetalle.conceptoId,
        cargoClasificacionId: unidadDetalle.cargoClasificacionId,
        montoTipoId: unidadDetalle.montoTipoId,
        monto: unidadDetalle.meta ? unidadDetalle.meta.monto : unidadDetalle.cicloOpcion ? unidadDetalle.montoTotal : unidadDetalle.monto,
        divisaId: unidadDetalle.meta ? unidadDetalle.meta.divisaId : unidadDetalle.divisaId,
        descuentoRecargoConceptoAplicaId: unidadDetalle.descuentoRecargoConceptoAplicaId,
        cicloOpcion: unidadDetalle.cicloOpcion,
        cicloCantidad: unidadDetalle.cicloCantidad,
        cicloFecha: Utils.jsonDate(unidadDetalle.cicloFecha.substr(0, 10)),
        cuentaRecaudadoraId: unidadDetalle.cuentaRecaudadoraId,
        isAfectoImpuesto: unidadDetalle.isAfectoImpuesto,
        observacion: unidadDetalle.observacion,
        isFacturableInt: unidadDetalle.isFacturableInt
      }
      negocioUnidadDetalles.push(negocioUnidadDetalle);
    })
    return negocioUnidadDetalles;
  }

  static contratoConfiguracionToNegocioConfiguracion(configuracion: ContratoConfiguracion): ConfiguracionInterface {
    return {
      id: configuracion.id,
      divisaCobro: configuracion.divisaCobroId,
      divisaConversion: configuracion.divisaConversionId,
      cobroPeriodicidad: configuracion.cobroPeriodicidadId,
      retrasoMultaTipo: configuracion.retrasoMultaMontoTipoId,
      retrasoMultaMonto: configuracion.retrasoMultaMonto,
      retrasoMultaMontoDivisa: configuracion.retrasoMultaMontoDivisa,
      retrasoCobroMultaLiqEnCurso: configuracion.retrasoCobroMultaLiqEnCurso,
      retrasoInteresMontoTipo: configuracion.retrasoInteresMontoTipoId,
      retrasoInteresMonto: configuracion.retrasoInteresMonto,
      retrasoInteresMontoDivisa: configuracion.retrasoInteresMontoDivisa,
      retrasoInteresPeriodicidad: configuracion.retrasoInteresPeriodicidad?.id,
      retrasoInteresPeriodicidadValor: configuracion.retrasoInteresPeriodicidadValor,
      retrasoInteresTipoId: configuracion.retrasoInteresTipoId,
      diaGeneracion: configuracion.diaGeneracion,
      diaCobro: configuracion.diaCobro,
      liquidacionCreacionEstado: configuracion.liquidacionCreacionEstadoId,
      diaPago: configuracion.diaPago,
      diaCorteArriendo: configuracion.diaCorteArriendo,
      diaCorteGastoComun: configuracion.diaCorteGastoComun,
      diaCorteServicios: configuracion.diaCorteServicios,
      avisoTerminoDias: configuracion.avisoTerminoDias ? configuracion.avisoTerminoDias : null,
      interesPorDia: configuracion.interesPorDia,
      isRenovacionAutomatica: configuracion.isRenovacionAutomatica,
      isGeneracionAutomatica: configuracion.isGeneracionAutomatica
    };
  }

  static unidadesContrato(contrato: Contrato): Unidad[] {
    const unidades: Unidad[] = [];
    contrato.contratoUnidad.forEach(item =>  {
      const unidad = item.unidad;
      unidad.propiedad = contrato.propiedad
      unidades.push(unidad);
    })
    return unidades;
  }

  static contactosContrato(contrato: Contrato): Contacto[] {
    const contactos: Contacto[] = [];
    contrato.contratoContacto.forEach(item =>  {
      contactos.push(item.contacto);
    })
    return contactos;
  }

  static montoPorcentajeCargoDescuento(montoTotal: number, negocioUnidadDetalle: NegocioUnidadDetalleInterface): number {
    const montoPorcentaje = negocioUnidadDetalle.monto;
    const isAfectoImpuesto = negocioUnidadDetalle.isAfectoImpuesto;
    let monto = (montoTotal * (montoPorcentaje / 100));
    if (isAfectoImpuesto) {
      monto = monto * 1.19;
    }
    return Number(monto.toFixed(2));
  }

  static fechaReajusteToSave(date: DateJson, periodicidadTipo: number, periodicidadCantidad: number) {
    const fechaReajuste = date ? Utils.dateObjectToString(date) : null;
    const dateMoment = moment(fechaReajuste);
    if (fechaReajuste && dateMoment && periodicidadTipo) {
      switch (periodicidadTipo) {
        case 1:
          dateMoment.subtract(periodicidadCantidad, 'day');
          break;
        case 2:
          dateMoment.subtract(periodicidadCantidad, 'month');
          break;
        case 3:
          dateMoment.subtract(periodicidadCantidad, 'year');
          break;
      }
      return Utils.jsonDate(dateMoment);
    } else {
      return null;
    }
  }

  static tipoReajuste(unidadConfiguracion: NegocioUnidadConfiguracionInterface, locale: string): string {
    if (unidadConfiguracion.reajusteCheck === 1) {
      switch (unidadConfiguracion.reajusteTipo) {
        case 1:
          return this.mensajeBase + this.montoReajuste(unidadConfiguracion, locale) + ' ' +
            this.periodicidadReajuste(unidadConfiguracion) + this.textFechaSiguienteReajuste(unidadConfiguracion);
        case 2:
          return this.mensajeBase + 'según el valor de la UF del primer día del mes.'
        case 3:
          return this.mensajeBase + 'según el valor de la UF del primer dia del mes, ' + this.periodicidadReajuste(unidadConfiguracion)
            + this.textFechaSiguienteReajuste(unidadConfiguracion);
        case 4:
          return this.mensajeBase + 'según el IPC acumulado, ' + this.periodicidadReajuste(unidadConfiguracion) +
            this.textFechaSiguienteReajuste(unidadConfiguracion);
      }
    } else {
      return 'Unidad sin reajuste'
    }
  }

  static montoReajuste(unidadConfiguracion: NegocioUnidadConfiguracionInterface, locale: string) {
    const { reajusteMontoTipo, reajusteValor, reajusteDivisa} = unidadConfiguracion;
    let monto;
    switch (reajusteMontoTipo) {
      case 1:
        monto = this.getMonto(reajusteValor, reajusteDivisa, locale);
        break;
      case 2:
        monto = reajusteValor + '%';
        break;
    }
    return 'en <b>' + monto + '</b>';
  }

  static periodicidadReajuste(unidadConfiguracion: NegocioUnidadConfiguracionInterface) {
    const { reajustePeriodicidad, reajustePeriodicidadValor} = unidadConfiguracion;
    const periodicidad = this.periodicidadTipos.find(item => item.id === reajustePeriodicidad);
    return 'cada <b>' + reajustePeriodicidadValor + ' ' + periodicidad.lang_resource + '</b>';
  }

  static textFechaSiguienteReajuste(unidadConfiguracion: NegocioUnidadConfiguracionInterface) {
    const { reajusteFecha } = unidadConfiguracion;
    const fechaSiguienteReajuste = Utils.dateObjectToString(reajusteFecha);
    return `. Y la fecha del siguiente reajuste será el <b>${Utils.momentString(fechaSiguienteReajuste, 'YYYY-MM-DD')}</b>`;
  }

  static getMonto(value: any, divisaId: string, locale: string): any {
    const val = Number(value);
    const monto = val.toLocaleString(locale);
    if (monto && monto !== 'NaN') {
      switch (divisaId) {
        // UF
        case '20562d2a-bed8-451f-a8f8-77f293a4880a':
          return monto + ' UF';
        // CLP
        case '7f377688-5b8a-49a2-a0f7-6bedd68fa900':
          return monto + ' CLP';
        // SOL
        case '0ea7be9c-2dcb-409e-9bbd-21a01c450cb7':
          return 'S/' + monto;
        default:
          return monto + ' CLP';
      }
    }
    return 0;
  }

  static contratoReservasToNegocioReservas(reservas: Reserva[]): NegocioReservaInterface[]
  {
    const reservasList: NegocioReservaInterface[] = [];
    reservas.forEach(reserva => {
      const reservaNegocio: NegocioReservaInterface = {
        id: reserva.id,
        folio: reserva.folio,
        fecha: Utils.jsonDate(reserva.fecha),
        divisaId: reserva.divisa_id,
        monto: reserva.monto
      };
      reservasList.push(reservaNegocio)
    })
    return reservasList;
  }
}
