import {ContratoAnexoTipo} from "./contrato-anexo-tipo.model";

export class ContratoTemplate {

    private _id: string;
    private _nombre: string;
    private _contenido: string;
    private _propiedadId: string;
    private _isActive: boolean;
    private _isAnexo: boolean;
    private _contratoAnexoTipoId: number;
    private _createdAt: string;
    private _createdBy: string;

    private _contratoAnexoTipo: ContratoAnexoTipo

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get contenido(): string {
        return this._contenido;
    }
    public set contenido(value: string) {
        this._contenido = value;
    }
    public get propiedadId(): string {
        return this._propiedadId;
    }
    public set propiedadId(value: string) {
        this._propiedadId = value;
    }

    get isActive(): boolean {
        return this._isActive;
    }

    set isActive(value: boolean) {
        this._isActive = value;
    }

    get isAnexo(): boolean {
        return this._isAnexo;
    }

    set isAnexo(value: boolean) {
        this._isAnexo = value;
    }

    get contratoAnexoTipoId(): number {
        return this._contratoAnexoTipoId;
    }

    set contratoAnexoTipoId(value: number) {
        this._contratoAnexoTipoId = value;
    }

    get contratoAnexoTipo(): ContratoAnexoTipo {
        return this._contratoAnexoTipo;
    }

    set contratoAnexoTipo(value: ContratoAnexoTipo) {
        this._contratoAnexoTipo = value;
    }

    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
}
