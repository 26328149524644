import { Injectable } from '@angular/core';
import { Paginador, Transaccion } from 'src/app/models/model.index';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/shared/utils';
import { TransaccionAdapter } from 'src/app/adapter/transaccion.adapter';
import { Egreso } from 'src/app/models/egreso.model';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class TransaccionService {

  public paginador: Paginador;
  public parametros: any;

  constructor(
    public http: HttpClient,
    public paginadorAdapter: PaginadorAdapter,
    public transaccionAdapter: TransaccionAdapter
  ) { }

  paginadorTransaccion() {
    return this.paginador;
  }

  cargarTransacciones(page, filtro?): Observable <Transaccion[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'transaccion?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(transaccion => this.transaccionAdapter.adapt(transaccion)
          );
        })
      );
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = Utils.generarParametrosBusqueda('transaccion_filter', filtro);
  }

  indicadores(filtro?): Observable <any> {
    if (filtro) {
        this.generarParametrosBusqueda(filtro);
    }
    return this.http.get(environment.urlApi + 'transaccion/indicadores?' + this.parametros)
    .pipe(
        map( (resp: any) => resp.meta.indicadores)
    );
  }

  cargarTiposEgresos = () => {
    return this.http.get(environment.urlApi + 'egreso-tipo')
      .pipe(
        map((resp: any) => {
          return resp.data
        })
      );
  }

  cargarAccionesEgresos = () => {
    return this.http.get(environment.urlApi + 'egreso-accion')
      .pipe(
        map((resp: any) => {
          return resp.data
        })
      );
  }

  crearEgreso = (egreso: Object) => {
    return this.http.post(environment.urlApi + 'egreso', egreso);
  }

  cargarEgreso = (id: string): Observable <Egreso> => {
    return this.http.get(environment.urlApi + 'egreso/' + id)
      .pipe(
        map((resp: any) => {
          const {
            attributes: {
              clasificacion,
              fecha,
              folio,
              divisa_id,
              monto,
              detalle,
              id
            },
            included,
            relationship
          } = resp.data
          const expense: Egreso = {
            folio,
            propertyName: '',
            unitName: '',
            unitTypeName: '',
            createdDate: moment(fecha.split('+')[0]).format('DD/MM/YYYY'),
            createdTime: moment(fecha.split('+')[0]).format('hh:mm a'),
            expenseTypeName: '',
            classification: clasificacion,
            divisaId: divisa_id,
            value: monto,
            actionType: '',
            details: detalle,
            attachedFiles: [],
            contacto: null,
            contrato: '',
            id
          }

          included.forEach(e => {
            switch (e.type) {
              case 'EgresoTipo':
                expense.expenseTypeName = e.attributes.nombre
                break;
              case 'Propiedad':
                expense.propertyName = e.attributes.nombre
                break;
              case 'Unidad':
                expense.unitName = e.attributes.nombre
                break;
              case 'EgresoAccion':
                expense.actionType = e.attributes.nombre
                break;
              case 'UnidadTipo':
                expense.unitTypeName = e.attributes.nombre
                break;
              case 'Contrato':
                expense.contrato = e.attributes.folio
                break;
            }
          })

          relationship.adjuntos?.data?.forEach(e => {
            expense.attachedFiles.push({
              id: e.id,
              name: e.attributes.nombre
            })
          })

          relationship.contratoContacto?.data?.forEach(e => {
            if (e.attributes.is_titular) {
              const contacto = e.included.find(included => included.type === 'Contacto');
              if (contacto) {
                expense.contacto = {
                  id: contacto.attributes.id,
                  name: contacto.attributes.nombre+' '+contacto.attributes.apellido
                }
              }
            }
          })

          return expense
        })
      );
  }

  descargarAdjuntoEgreso = (fileId: string, expenseId: string) => {
    const URL = `${environment.urlApi}egreso/${expenseId}/adjuntos/${fileId}/download`
    return this.http.get(URL, { responseType: 'blob' });
  }

  exportar(filtro) {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'transaccion/export?' + this.parametros, { responseType: 'blob' });
  }
}
