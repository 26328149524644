import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {

  transform(val: string, format?: string): string {
    if (!format) {
      format = 'DD-MM-YYYY';
    }
    if (val !== undefined && val !== null) {
      if (typeof(val) === 'object') {
        return moment(val).format(format);
      } else {
        return moment(val.substr(0, 10)).format(format);
      }
    } else {
        return '';
    }
  }
}
