import { Unidad } from './unidad.model';
import {ContratoUnidadConfiguracion} from './contrato-unidad-configuracion.model';
import {ContratoUnidadDetalle} from './contrato-unidad-detalle.model';
import {UnidadTipo} from './unidad-tipo.model';

export class ContratoUnidad {

    private _id: string;
    private _contratoId: string;
    private _unidadId: string;
    private _createdAt: string;
    private _createdBy: string;

    private _unidad: Unidad;
    private _contratoUnidadConfiguracion: ContratoUnidadConfiguracion;
    private _contratoUnidadDetalle: ContratoUnidadDetalle[];
    private _unidadTipo: UnidadTipo

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get contratoId(): string {
        return this._contratoId;
    }
    public set contratoId(value: string) {
        this._contratoId = value;
    }
    public get unidadId(): string {
        return this._unidadId;
    }
    public set unidadId(value: string) {
        this._unidadId = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    public get unidad(): Unidad {
        return this._unidad;
    }
    public set unidad(value: Unidad) {
        this._unidad = value;
    }

    get contratoUnidadConfiguracion(): ContratoUnidadConfiguracion {
        return this._contratoUnidadConfiguracion;
    }

    set contratoUnidadConfiguracion(value: ContratoUnidadConfiguracion) {
        this._contratoUnidadConfiguracion = value;
    }

    get contratoUnidadDetalle(): ContratoUnidadDetalle[] {
        return this._contratoUnidadDetalle;
    }

    set contratoUnidadDetalle(value: ContratoUnidadDetalle[]) {
        this._contratoUnidadDetalle = value;
    }

    get unidadTipo(): UnidadTipo {
        return this._unidadTipo;
    }

    set unidadTipo(value: UnidadTipo) {
        this._unidadTipo = value;
    }
}
