import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { CuentaRecaudadora } from '../models/model.index';

@Injectable({
    providedIn: 'root'
  })
  export class CuentaRecaudadoraAdapter implements Adapter<CuentaRecaudadora> {
    adapt(item: any): CuentaRecaudadora {
        const cuentaRecaudadora = new CuentaRecaudadora();
        cuentaRecaudadora.id = item.attributes.id;
        cuentaRecaudadora.nombre = item.attributes.nombre;
        cuentaRecaudadora.banco = item.attributes.banco;
        cuentaRecaudadora.tipoCuenta = item.attributes.tipo_cuenta;
        cuentaRecaudadora.numero = item.attributes.numero;
        cuentaRecaudadora.titular = item.attributes.titular;
        cuentaRecaudadora.identificador = item.attributes.identificador;
        cuentaRecaudadora.identificadorTipoId = item.attributes.identificador_tipo_id;
        cuentaRecaudadora.email = item.attributes.email;
        cuentaRecaudadora.telefono = item.attributes.telefono;
        cuentaRecaudadora.propiedadId = item.attributes.propiedad_id;
        cuentaRecaudadora.conceptos = [];
        if (item.relationship && item.relationship.Conceptos.data) {
          item.relationship.Conceptos.data.forEach(concepto => {
            cuentaRecaudadora.conceptos.push(concepto.attributes.id)
          })
        }
        return cuentaRecaudadora;
    }
  }
