import { CuentaRecaudadoraAdapter } from './cuenta-recaudadora.adapter';
import { ContratoAdapter } from 'src/app/adapter/contrato.adapter';
import { DivisaAdapter } from './divisa.adapter';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Contrato, EstadoCuenta, EstadoCuentaDetalle, EstadoCuentaEstado } from 'src/app/models/model.index';
import { ConceptoAdapter } from './concepto.adapter';
import { EstadoCuentaDetalleAdapter } from './estado-cuenta-detalle.adapter';
import { EstadoCuentaEstadoAdapter } from './estado-cuenta-estado.adapter';
import { UnidadAdapter } from './unidad.adapter';
import { UnidadTipoAdapter } from './unidad-tipo.adapter';

@Injectable({
  providedIn: 'root'
})

export class EstadoCuentaPreviewAdapter implements Adapter<EstadoCuenta> {

  constructor(
    public estadoCuentaDetalleAdapter: EstadoCuentaDetalleAdapter,
    public conceptoAdapter: ConceptoAdapter,
    public estadoCuentaEstadoAdapter: EstadoCuentaEstadoAdapter,
    public divisaAdapter: DivisaAdapter,
    public contratoAdapter: ContratoAdapter,
    public cuentaRecaudadoraAdapter: CuentaRecaudadoraAdapter,
    public unidadAdapter: UnidadAdapter,
    public unidadTipoAdapter: UnidadTipoAdapter
  ) {}

  adapt(item: any, contrato?: Contrato): EstadoCuenta {
    const estadoCuenta = new EstadoCuenta();
    estadoCuenta.id = 'FAKE' + item.folio;
    estadoCuenta.folio = item.folio;
    estadoCuenta.contratoId = item.contrato_id;
    estadoCuenta.estadoCuentaEstadoId = 999;
    estadoCuenta.fecha = `${item.fecha.year}-${item.fecha.month >= 10 ? item.fecha.month : '0' + item.fecha.month}-${item.fecha.day >= 10 ? item.fecha.day : '0' + item.fecha.day}`;
    estadoCuenta.fechaVencimiento = `${item.fechaVencimiento.year}-${item.fechaVencimiento.month >= 10 ? item.fechaVencimiento.month : '0' + item.fechaVencimiento.month}-${item.fechaVencimiento.day >= 10 ? item.fechaVencimiento.day : '0' + item.fechaVencimiento.day}`;
    estadoCuenta.divisaId = item.divisaCobro;
    estadoCuenta.divisaCobroId = item.divisaCobro;
    estadoCuenta.montoPactado = item.montoPactado;
    estadoCuenta.isPrimerPago = item.isPrimerPago;
    estadoCuenta.correoEnviado = item.correoEnviado;

    /* Includes*/
    const estadoCuentaEstado = new EstadoCuentaEstado();
    estadoCuentaEstado.id = 999;
    estadoCuentaEstado.nombre = 'Por Generar';
    estadoCuentaEstado.lang_resource = 'state_account_status.to_generate';
    estadoCuenta.estadoCuentaEstado = estadoCuentaEstado;
    estadoCuenta.contrato = contrato;

    /* Relationship */
    estadoCuenta.estadoCuentaDetalles = [];
    if (item.estadoCuentaDetalles) {
      item.estadoCuentaDetalles.forEach(detalle => {
        const obj = new EstadoCuentaDetalle();
        const temp = {...obj, ...detalle};
        const contratoUnidad = estadoCuenta.contrato.contratoUnidad.find(c => c.unidadId === detalle.unidad);
        if (contratoUnidad) {
          temp.unidad = contratoUnidad.unidad;
        }
        temp.divisaId = detalle.divisa;
        temp.divisaCobroId = detalle.divisaCobro;
        temp.fechaPago = estadoCuenta.fecha;
        temp.conceptoId = detalle.concepto;
        temp.unidadId = detalle.unidad;
        const concepto = estadoCuenta.estadoCuentaDetalles.find(e =>
          e.conceptoId === detalle.concepto && e.unidadId === detalle.unidad && [1, 2].includes(e.conceptoId)
        );
        if (concepto) {
          concepto.monto += detalle.monto;
          concepto.montoCobro += detalle.montoCobro;
        } else {
          estadoCuenta.estadoCuentaDetalles.push(temp);
        }
      });
    }

    return estadoCuenta;
  }
}
