import { Injectable } from '@angular/core';
import { Contacto, Cotizacion, Contrato, EstadoCuenta, Concepto, CuentaRecaudadora, Propiedad } from 'src/app/models/model.index';
import { CargoClasificacion } from 'src/app/models/cargoClasificacion.model';
import { PropiedadConfiguracionCargoClasificacion } from 'src/app/models/propiedad-configuracioncargo-clasificacion.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataShareService {

  private contacto = new BehaviorSubject<Contacto>( new Contacto());
  currentContacto = this.contacto.asObservable();

  private cotizacion = new BehaviorSubject<Cotizacion>( new Cotizacion());
  currentCotizacion = this.cotizacion.asObservable();

  private contrato = new BehaviorSubject<Contrato>( new Contrato());
  currentContrato = this.contrato.asObservable();

  private estadoCuenta = new BehaviorSubject<EstadoCuenta>( new EstadoCuenta());
  currentEstadoCuenta = this.estadoCuenta.asObservable();

  private conceptos = new BehaviorSubject<Concepto[]>([]);
  currentConceptos = this.conceptos.asObservable();

  private cargoClasificaciones = new BehaviorSubject<CargoClasificacion[]>([]);
  currentCargoClasificaciones = this.cargoClasificaciones.asObservable();

  private cuentaRecaudadoras = new BehaviorSubject<CuentaRecaudadora[]>([]);
  currentCuentaRecaudadoras = this.cuentaRecaudadoras.asObservable();

  private configuracionCargoClasificaciones =
  new BehaviorSubject<PropiedadConfiguracionCargoClasificacion[]>([]);
  currentConfiguracionCargoClasificaciones = this.configuracionCargoClasificaciones.asObservable();

  private propiedades = new BehaviorSubject<Propiedad[]>([]);
  currentPropiedades = this.propiedades.asObservable();

  constructor() {}

  selectContacto(contacto: Contacto) {
    this.contacto.next(contacto);
  }

  selectCotizacion(cotizacion: Cotizacion) {
    this.cotizacion.next(cotizacion);
  }

  selectContrato(contrato: Contrato) {
    this.contrato.next(contrato);
  }

  selectEstadoCuenta(estadoCuenta: EstadoCuenta) {
    this.estadoCuenta.next(estadoCuenta);
  }

  selectConceptos(conceptos: Concepto[]) {
    this.conceptos.next(conceptos);
  }

  selectCargoClasificaciones(cargoClasificaciones: CargoClasificacion[]) {
    this.cargoClasificaciones.next(cargoClasificaciones);
  }

  selectCuentaRecaudadoras(cuentaRecaudadoras: CuentaRecaudadora[]) {
    this.cuentaRecaudadoras.next(cuentaRecaudadoras);
  }

  selectConfiguracionCargoClasificaciones(configuracionCargoClasificaciones: PropiedadConfiguracionCargoClasificacion[]) {
    this.configuracionCargoClasificaciones.next(configuracionCargoClasificaciones);
  }

  selectPropiedades(propiedades: Propiedad[]) {
    this.propiedades.next(propiedades);
  }

  clearAll() {
    this.contacto.next(new Contacto());
    this.cotizacion.next(new Cotizacion());
    this.contrato.next(new Contrato());
    this.estadoCuenta.next(new EstadoCuenta());
    this.conceptos.next([]);
    this.cargoClasificaciones.next([]);
    this.cuentaRecaudadoras.next([]);
    this.propiedades.next([]);
    this.configuracionCargoClasificaciones.next([]);
  }

}
