import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { PropiedadTipo } from './../models/propiedad-tipo.model';

@Injectable({
    providedIn: 'root'
  })
  export class PropiedadTipoAdapter implements Adapter<PropiedadTipo> {
    adapt(item: any): PropiedadTipo {
        const propiedadTipo = new PropiedadTipo();
        propiedadTipo.id = item.attributes.id;
        propiedadTipo.nombre = item.attributes.nombre;
        propiedadTipo.isActive = item.attributes.is_active;
        return propiedadTipo;
    }
  }
