import { ContratoContactoTipo } from './contrato-contacto-tipo.model';
import { Contacto } from './contacto.model';
import { Contrato } from './model.index';

export class ContratoContacto {

    private _id: string;
    private _contratoId: string;
    private _contratoContactoTipoId: number;
    private _contactoId: string;
    private _isFirmante: boolean;
    private _isTitular: boolean;

    private _contrato: Contrato;
    private _contratoContactoTipo: ContratoContactoTipo;
    private _contacto: Contacto;


    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get contratoId(): string {
        return this._contratoId;
    }
    public set contratoId(value: string) {
        this._contratoId = value;
    }
    public get contratoContactoTipoId(): number {
        return this._contratoContactoTipoId;
    }
    public set contratoContactoTipoId(value: number) {
        this._contratoContactoTipoId = value;
    }
    public get contactoId(): string {
        return this._contactoId;
    }
    public set contactoId(value: string) {
        this._contactoId = value;
    }
    public get isFirmante(): boolean {
        return this._isFirmante;
    }
    public set isFirmante(value: boolean) {
        this._isFirmante = value;
    }
    public get isTitular(): boolean {
        return this._isTitular;
    }
    public set isTitular(value: boolean) {
        this._isTitular = value;
    }


    public get contrato(): Contrato {
        return this._contrato;
    }
    public set cotizacion(value: Contrato) {
        this._contrato = value;
    }
    public get contratoContactoTipo(): ContratoContactoTipo {
        return this._contratoContactoTipo;
    }
    public set contratoContactoTipo(value: ContratoContactoTipo) {
        this._contratoContactoTipo = value;
    }
    public get contacto(): Contacto {
        return this._contacto;
    }
    public set contacto(value: Contacto) {
        this._contacto = value;
    }

}

