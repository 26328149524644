<mat-dialog-content>
    <div class="stepper-modal-pay-register">
        <mat-horizontal-stepper #stepper labelPosition="bottom" class="payRegisterStepper">
            <mat-step [stepControl]="firstFormGroup" errorMessage="Cliente es requerido.">
                <form>
                    <ng-template matStepLabel>
                        {{ 'lease.tenant_info' | translate }}
                    </ng-template>
                    <div class="row justify-content-center stepTitleContainer mb-2">
                        <h2 class="stepTitle">{{ 'payment.register_payment' | translate }}</h2>
                    </div>

                    <app-contacto-lista-seleccionable #listaContacto (seleccionarEvent)="seleccionarContacto($event)" [opcionesListadoCliente]="opcionesListadoCliente" [contactoSelected]="contacto"></app-contacto-lista-seleccionable>

                    <div class="row justify-content-between">
                        <button mat-button [class]="'boton-anterior float-left'" (click)="onNoClick()">
                            {{ 'general.cancel' | translate }}
                        </button>
                        <button mat-button matStepperNext [class]="'btn btn-primary btn-siguiente float-right'">
                            {{ 'general.continue' | translate }}
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup">
                <ng-template matStepLabel>
                    {{ 'lease.pending_charges' | translate }}
                </ng-template>
                <div class="card-body card-body-balance-detail p-0">
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <thead class="table-detail-header">
                                <tr>
                                    <th scope="col">{{ 'lease.pending_charges' | translate }}</th>
                                    <th class="text-right" scope="col" width="15%"></th>
                                    <th class="text-right" scope="col" width="15%">{{ 'general.amount' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody class="table-detail-info">
                                <ng-container *ngFor="let unidad of listadoEstadoCuentaDetalles">
                                    <tr style="background-color: #efefef">
                                        <td class="font-weight-bold">{{unidad.unidadNombre}}</td>
                                        <td colspan="2"></td>
                                    </tr>
                                    <ng-container *ngFor="let estadoCuentaDetalle of unidad.estadoCuentaDetalle">
                                        <tr>
                                            <td class="pl-4">{{estadoCuentaDetalle.nombre}}</td>
                                            <td class="text-right">
                                                <span *ngIf="estadoCuentaDetalle.divisaCobroId !== estadoCuentaDetalle.divisaId">
                                                    {{estadoCuentaDetalle.monto | divisa:estadoCuentaDetalle.divisaId}}
                                                </span>
                                            </td>
                                            <td class="text-right">{{estadoCuentaDetalle.montoCobro | divisa:estadoCuentaDetalle.divisaCobroId}}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="interesAcumulado > 0">
                                    <tr style="background-color: #efefef">
                                        <td class="font-weight-bold">{{ 'general.other_expenses' | translate }}</td>
                                        <td></td>
                                    </tr>
                                    <tr *ngIf="interesAcumulado > 0">
                                        <td class="pl-4">{{ 'lease.accrued_interest' | translate }}</td>
                                        <td class="text-right">{{interesAcumulado | moneda}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>

                    <div class="row total-title-container">
                        <div class="col">
                            <h3>{{ 'settlement.total_to_pay' | translate }}</h3>
                        </div>
                    </div>
                    <div class="row total-amount-container">
                        <div class="col">
                            <h3>{{ estadoCuenta.montoPactado | divisa:estadoCuenta.divisaCobroId }}</h3>
                        </div>
                    </div>
                </div>
                <div class="row row justify-content-between">
                    <button mat-button matStepperPrevious [class]="'boton-anterior float-left'">
                        {{ 'general.back_to' | translate }}
                    </button>
                    <button mat-button matStepperNext [class]="'btn btn-primary btn-siguiente float-right'">
                        {{ 'general.continue' | translate }}
                    </button>
                </div>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup" errorMessage="Los datos son requeridos">
                <form ngNativeValidate>
                    <ng-template matStepLabel>
                        {{ 'general.opertation_record' | translate }}
                    </ng-template>
                    <div class="row">
                        <div class="col-sm-12">
                            <h5 class="payment-title mb-2">{{ 'lease.payment_amount' | translate }}: <b>{{ estadoCuenta.montoPactado | divisa:estadoCuenta.divisaCobroId }}</b></h5>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="date-picker full-width" appearance="outline">
                                <mat-label>{{ 'general.date' | translate }}</mat-label>
                                <input matInput [matDatepicker]="fecha" [(ngModel)]="estadoCuentaPago.fecha" id="fecha" name="fecha" required/>
                                <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                                <mat-datepicker #fecha></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>{{ 'lease.payment_method' | translate }}</mat-label>
                                <mat-select [(ngModel)]="estadoCuentaPago.medioPago" id="medioPago" name="medioPago" (selectionChange)="showInputs($event.value)" required>
                                    <mat-option *ngFor="let medioPago of medioPagos" [value]="medioPago.id">{{ medioPago.nombre }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6" *ngIf="showbanco">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>{{ 'lease.banking_entity' | translate }}</mat-label>
                                <mat-select [(ngModel)]="estadoCuentaPago.banco" id="banco" name="banco">
                                    <mat-option *ngFor="let banco of bancos" [value]="banco.id">{{ banco.nombre }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6" *ngIf="showbanco">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>{{ 'general.number' | translate }}</mat-label>
                                <input matInput [(ngModel)]="estadoCuentaPago.numero" id="numero" name="numero">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>{{ 'general.comment' | translate }}</mat-label>
                                <textarea maxlength="255" matInput [(ngModel)]="estadoCuentaPago.observacion" id="observacion" name="observacion"></textarea>
                                <mat-hint
                                    align="end"
                                    [ngStyle]="{ color: estadoCuentaPago.observacion?.length === 255 ? 'red' : 'inherit' }"
                                >
                                    {{ estadoCuentaPago.observacion?.length || 0 }} / {{ 255 }}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <ngx-dropzone (change)="onSelect($event)">
                                <ngx-dropzone-label>
                                    <svg height="46.473" id="prefix__surface1" viewBox="0 0 42.279 46.473" width="42.279" xmlns="http://www.w3.org/2000/svg">
                                        <path class="prefix__cls-1" d="M31.673 20.118v-8.795a1.018 1.018 0 00-.281-.668L21.532.3a.983.983 0 00-.707-.3H5.191A5.209 5.209 0 000 5.24V34.8A5.176 5.176 0 005.191 40h12.34a13.32 13.32 0 1014.142-19.882zM21.793
                                                3.4l6.615 6.964h-4.29a2.339 2.339 0 01-2.325-2.334zM5.191 38.065A3.245 3.245 0 011.937 34.8V5.24a3.272 3.272 0 013.254-3.3h14.665v6.09a4.27 4.27 0 004.262 4.27h5.618v7.565c-.29-.01-.523-.039-.775-.039a13.515
                                                13.515 0 00-8.833 3.332H7.826a.968.968 0 100 1.937h10.5a15.411 15.411 0 00-1.7 3h-8.8a.969.969 0 100 1.937h8.165a13.293 13.293 0 00.581 8.039H5.191zm23.76 6.48a11.381 11.381 0 1111.381-11.381 11.393 11.393 0
                                                01-11.381 11.381zm0 0" data-name="Trazado 459" id="prefix__Trazado_459"></path>
                                        <path class="prefix__cls-1" d="M76.519 203.621h9.812a.969.969 0 000-1.937h-9.812a.969.969 0 000 1.937zm0
                                                0" data-name="Trazado 460" id="prefix__Trazado_460" transform="translate(-68.693 -183.377)"></path>
                                        <path class="prefix__cls-1" d="M252.91 283.521a.961.961 0 00-1.414 0l-5.676 6.092a.965.965
                                                0 00.049 1.366.989.989 0 001.385-.048l4.01-4.291v10.577a.969.969 0 101.937 0V286.64l3.981 4.291a.966.966 0 001.414-1.318zm0 0" data-name="Trazado 461" id="prefix__Trazado_461" transform="translate(-223.271
                                                -257.505)"></path>
                                    </svg>
                                </ngx-dropzone-label>
                            </ngx-dropzone>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let adjunto of this.estadoCuentaPago.adjuntos; index as index;">
                                    <div class="d-flex justify-content-between">
                                        <div class="p-2">
                                            <i class="far fa-file mr-3"></i>{{ adjunto.nombre }}
                                        </div>
                                        <div class="p-2">
                                            <i class="fas fa-trash-alt pointer mr-3" (click)="onRemove(index)" matTooltip="{{ 'general.delete' | translate }}"></i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row row justify-content-between">
                        <button mat-button matStepperPrevious [class]="'boton-anterior float-left'">
                            {{ 'general.back_to' | translate }}
                        </button>
                        <button mat-button matStepperNext [class]="'btn btn-primary btn-siguiente float-right'" (click)="openConfimacion()">
                            {{ 'general.register' | translate }}
                        </button>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</mat-dialog-content>
