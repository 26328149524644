import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnidadEstadoAdapter } from '../../adapter/unidad-estado.adapter';
import { UnidadEstado } from '../../models/unidad-estado.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnidadEstadoService {

  public parametros: string;

  constructor(
    public http: HttpClient,
    public unidadEstadoAdapter: UnidadEstadoAdapter
  ) { }

  cargarUnidadEstado(): Observable <UnidadEstado[]> {
    return this.http.get(environment.urlApi + 'unidades_estado')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(unidad => this.unidadEstadoAdapter.adapt(unidad));
        })
      );
  }
}
