import { Pipe, PipeTransform } from '@angular/core';
import { PerfilService } from '../services/auxiliar/perfil.service';

@Pipe({
  name: 'moneda'
})
export class MonedaPipe implements PipeTransform {

  constructor(private perfil: PerfilService) { }

  transform(val: number, asNumber?: boolean): string {
    if (val !== undefined && val !== null && this.perfil.locale) {
      if (asNumber) {
        return val.toLocaleString(this.perfil.locale);
      }
      return val.toLocaleString(this.perfil.locale, { style: 'currency', currency: this.currency });
    } else {
      return '';
    }
  }

  get currency() {
    switch (this.perfil.locale) {
      case 'es-CL':
        return 'CLP';
      case 'es-PE':
        return 'PEN';
      case 'es-CO':
        return 'COP';
    }
  }
}
