<div class="d-inline-flex w-100" style="display: inline-flex; width: 100%">
    <div class="info-content mr-2 pt-3" style="padding-left: 16px; color: #4C4C4C">       
        <div class="row w-100">
            <ng-container *ngFor="let data of formInfoData">
                <div class="col mb-2">
                    <label class="d-block" style="font-size: 14px;margin-bottom: 6px;">{{data.title | translate}}</label>
                    <label class="d-block font-weight-bold" matTooltip="{{(data.value.length > 39) ? data.value : ''}}">{{ (data.value.length > 39) ? (data.value | slice:0:39) + '...' : (data.value) }}</label>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="h-100 align-self-center">
        <button class="boton-secundario boton-sm" (click)="editForm()"><i class="fa fa-edit"></i></button>
    </div>
</div>

