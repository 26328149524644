import { Component, OnInit, Input } from '@angular/core';


import { LanguageValue } from '../../models/lenguaje.model';

import { Store } from '@ngrx/store';
import { AppState } from '../../store/models/app.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ModalService, PerfilService } from 'src/app/services/service.index';
import { AbilityService } from 'src/app/services/ability/ability.service';

export const languagesList = [
  {
    title: 'language.chile',
    key: 'es-CL'
  },
  {
    title: 'language.colombia',
    key: 'es-CO'
  },
  {
    title: 'language.peru',
    key: 'es-PE'
  }
];

@Component({
  selector: 'app-lenguaje',
  templateUrl: './lenguaje.component.html'
})



export class LenguajeComponent implements OnInit {
  @Input() arrow: string;
  @Input() color: string;

  public languages:LanguageValue[];
  public selectedLanguage:LanguageValue;


  constructor(
    public modalService: ModalService,
    private store: Store<AppState>,
    private translate: TranslateService,
    private authService: AuthService,
    public perfilService: PerfilService,
    private ability: AbilityService
  ) {
    this.languages = languagesList;
    let currentLang = localStorage.getItem('locale-user') && localStorage.getItem('locale-user') !== 'null' && localStorage.getItem('locale-user') !== '' ? 
      localStorage.getItem('locale-user'):
      this.languages[0].key;
    this.usarLenguaje(currentLang);
  }

  ngOnInit() {
    this.arrow = this.arrow??'left';
    this.color = this.color??'white';
    if(this.authService.token){
      this.store.select('home').subscribe((home) => {
          this.usarLenguaje(home.locale);
      });
    }
  }

  usarLenguaje(language: string){
    this.selectedLanguage = this.languages.find(item=>item.key == language);
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    localStorage.setItem('locale-user',language);
  }

  cambiarIdioma(language: string){
    if(this.authService.token){
      if(this.ability.hasAccion('UserCambiaIdioma')){
        this.perfilService.cambiarIdioma(language).subscribe((res) => {
          this.usarLenguaje(res.data.attributes.locale);
        });
      }else{
        this.modalService.notificacion("Usted no tiene permisos para cambiar idioma",'error');
      }
    }else{
      this.usarLenguaje(language);
    }
  }

  get currentLanguage(){
      return localStorage.getItem('locale-user');
  }
}