import {Propiedad} from "./propiedad.model";
import {Contacto} from "./contacto.model";
import {User} from "./user.model";
import {ReservaEstado} from "./reserva-estado.model";
import {ReservaUnidad} from "./reserva-unidad.model";

interface ContratoSimpleinterface {
  id: string,
  folio: string,
  estado: number,
  fecha_inicio: string,
  fecha_termino: string
}
export class Reserva {

  private _id: string;
  private _folio: string;
  private _fecha: string;
  private _dias_vigencia: number;
  private _dias_pagar: number;
  private _fecha_vencimiento: string;
  private _fecha_vigencia: string;
  private _divisa_id: string;
  private _monto: number;
  private _estadoId: number;
  private _propiedadId: string;
  private _contactoId: string;
  private _ejecutivoId: string;

  private _estado: ReservaEstado;
  private _contacto: Contacto;
  private _propiedad: Propiedad;
  private _ejecutivo: User;

  private _unidades: ReservaUnidad[];
  private _contratos: ContratoSimpleinterface[];

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get folio(): string {
    return this._folio;
  }

  set folio(value: string) {
    this._folio = value;
  }

  get fecha(): string {
    return this._fecha;
  }

  set fecha(value: string) {
    this._fecha = value;
  }

  get dias_vigencia(): number {
    return this._dias_vigencia;
  }

  set dias_vigencia(value: number) {
    this._dias_vigencia = value;
  }

  get dias_pagar(): number {
    return this._dias_pagar;
  }

  set dias_pagar(value: number) {
    this._dias_pagar = value;
  }

  get fecha_vencimiento(): string {
    return this._fecha_vencimiento;
  }

  set fecha_vencimiento(value: string) {
    this._fecha_vencimiento = value;
  }

  get fecha_vigencia(): string {
    return this._fecha_vigencia;
  }

  set fecha_vigencia(value: string) {
    this._fecha_vigencia = value;
  }

  get divisa_id(): string {
    return this._divisa_id;
  }

  set divisa_id(value: string) {
    this._divisa_id = value;
  }

  get monto(): number {
    return this._monto;
  }

  set monto(value: number) {
    this._monto = value;
  }

  get estadoId(): number {
    return this._estadoId;
  }

  set estadoId(value: number) {
    this._estadoId = value;
  }

  get propiedadId(): string {
    return this._propiedadId;
  }

  set propiedadId(value: string) {
    this._propiedadId = value;
  }

  get contactoId(): string {
    return this._contactoId;
  }

  set contactoId(value: string) {
    this._contactoId = value;
  }

  get ejecutivoId(): string {
    return this._ejecutivoId;
  }

  set ejecutivoId(value: string) {
    this._ejecutivoId = value;
  }

  get estado(): ReservaEstado {
    return this._estado;
  }

  set estado(value: ReservaEstado) {
    this._estado = value;
  }

  get contacto(): Contacto {
    return this._contacto;
  }

  set contacto(value: Contacto) {
    this._contacto = value;
  }

  get propiedad(): Propiedad {
    return this._propiedad;
  }

  set propiedad(value: Propiedad) {
    this._propiedad = value;
  }

  get ejecutivo(): User {
    return this._ejecutivo;
  }

  set ejecutivo(value: User) {
    this._ejecutivo = value;
  }

  get unidades(): ReservaUnidad[] {
    return this._unidades;
  }

  set unidades(value: ReservaUnidad[]) {
    this._unidades = value;
  }

  public get reservaUnidadOrderByUnidadTipo(): ReservaUnidad[] {
    return this.unidades.sort((n1, n2) => n1.unidad.unidadTipo.orden - n2.unidad.unidadTipo.orden);
  }


  get contratos(): ContratoSimpleinterface[] {
    return this._contratos;
  }

  set contratos(value: ContratoSimpleinterface[]) {
    this._contratos = value;
  }
}
