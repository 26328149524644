import { AuthService } from 'src/app/services/service.index';
import { Component, DoCheck, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TitleService } from './services/title.service';
import { GoogleAnalitycsService } from './services/google-analitycs/google-analitycs.service';
import { Store } from '@ngrx/store';
import { AppState } from './store/models/app.model'
import { SetTokenRefresh } from './store/actions/home.action';
import { ModalService } from './services/service.index';
import {GlobalsService} from "./services/globals.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, DoCheck {

  public title = 'PopEstate';
  public sesion: any;
  public identity: any;

  constructor(
    private authService: AuthService,
    private titleService: TitleService,
    private googleAnalitycs: GoogleAnalitycsService,
    private store: Store<AppState>,
    private modal: ModalService,
    private globalsService: GlobalsService
  ) {
    this.initGtag();
    this.validateLocale();
  }

  validateLocale(){
    const locale = localStorage.getItem('locale-user');
    const locales: string[] = ['es-CL', 'es-CO', 'es-PE'];
    if (!locales.includes(locale)) {
      localStorage.setItem('locale-user', 'es-CL');
    }
  }

  ngOnInit() {
    this.titleService.getTitle().then( () =>
      this.googleAnalitycs.gtag()
    );
    this.authService.loadWorker();
    this.store.select('home').subscribe((home) => {
      if(this.authService.isAuth() && typeof Worker !== 'undefined' && home.refresh_token.api) {
        this.authService.worker.postMessage(this.tokenRefresh);
        this.authService.worker.onmessage = (event)=>{
          this.onWorkerMessage(event,this);
        }
      }
    });
    if(this.authService.isAuth()){
      this.dispatchRefresh();
    }
  }

  dispatchRefresh(){
    this.store.dispatch(new SetTokenRefresh({
      token: this.authService.token,
      refresh_token: this.authService.refresh_token,
      api: environment.urlApi,
      token_data: this.authService.getTokenData(),
      in_background: false
    }));
  }

  onWorkerMessage(event: MessageEvent,component: AppComponent){
    if(component.authService.isAuth()){
      this.modal.advertirExpiracion(()=>{
        this.authService.fetchRefreshToken(()=>{
          this.dispatchRefresh();
        },()=>{
          this.authService.logoutExpired();
        });
      },()=>{
        component.authService.logoutExpired()
      });
    }else{
      component.authService.logoutExpired()
    }
  }

  ngDoCheck() {
    this.sesion = this.authService.cargarStorageToken();
    this.identity = this.authService.cargarStorageIdentity();
  }

  initGtag() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalitycsTrackingId;
    document.body.prepend(script);
  }

  get tokenRefresh(){
    return {
      type: "ACTION",
      action: JSON.stringify({
        action: {
          type: "SET_CUSTOM_REFRESH_TOKEN",
          payload: {
            token: this.authService.token,
            refresh_token: this.authService.refresh_token,
            api: environment.urlApi,
            token_data: this.authService.getTokenData(),
            in_background: false
          }
        }
      })
    }
  }

}
