import {AppModel} from "./app.model";

export class BaseModel extends AppModel{
  private _id: number | string;
  private _nombre: string;

  get id(): number | string {
    return this._id;
  }

  set id(value: number | string) {
    this._id = value;
  }

  get nombre(): string {
    return this._nombre;
  }

  set nombre(value: string) {
    this._nombre = value;
  }
}
