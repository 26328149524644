import { Action } from '@ngrx/store'
import { Indicador } from 'src/app/models/indicador.model'
import { Publication } from 'src/app/models/publication.model'
import { Property } from '../models/home.model'

export enum HomeActionTypes  {
  SET_MONTHLY_VALUE = 'SET_MONTHLY_VALUE',
  SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY',
  SET_PROPERTIES = 'SET_PROPERTIES',
  SET_INFORMATION_LOADED = 'SET_INFORMATION_LOADED',
  SET_MOVEMENTS = 'SET_MOVEMENTS',
  SET_EXPIRATION = 'SET_EXPIRATION',
  SET_CONTRACTS_INFO = 'SET_CONTRACTS_INFO',
  SET_UNITS_INFO = 'SET_UNITS_INFO',
  SET_LANGUAGE = 'SET_LANGUAGE',
  CLEAN_STATE = 'CLEAN_STATE',
  SET_PUBLICATIONS = 'SET_PUBLICATIONS',
  SET_PUBLICATION_IMAGE = 'SET_PUBLICATION_IMAGE',
  SET_TOKEN_REFRESH = 'SET_TOKEN_REFRESH',
  SET_LOGBOOKS = 'SET_LOGBOOKS'
}

export class SetMonthlyValue implements Action {
  readonly type = HomeActionTypes.SET_MONTHLY_VALUE
  constructor(public payload: { type: string, values: any[] }[]) {}
}

export class SetSelectedProperty implements Action {
  readonly type = HomeActionTypes.SET_SELECTED_PROPERTY
  constructor(public payload: string) {}
}

export class SetProperties implements Action {
  readonly type = HomeActionTypes.SET_PROPERTIES
  constructor(public payload: Property[]) {}
}

export class SetInformationLoaded implements Action {
  readonly type = HomeActionTypes.SET_INFORMATION_LOADED
  constructor(public payload: { type: string, value: boolean  }[]) {}
}

export class SetMovements implements Action {
  readonly type = HomeActionTypes.SET_MOVEMENTS
  constructor(public payload: { type: string, values: any[] }[]) {}
}

export class SetExpiration implements Action {
  readonly type = HomeActionTypes.SET_EXPIRATION
  constructor(public payload: { cantidad: string, hasta: string, desde: string, fecha_desde: string, fecha_hasta: string }[]) {}
}

export class SetContractsInfo implements Action {
  readonly type = HomeActionTypes.SET_CONTRACTS_INFO
  constructor(public payload: { indicadores: Indicador[], indicadores_grafico: Indicador[]}) {}
}

export class SetUnitsInfo implements Action {
  readonly type = HomeActionTypes.SET_UNITS_INFO
  constructor(public payload: {unitsInfo:{ unidadTipoId: string, unidadTipoNombre: string, unidadEstadoId: number, cantidad: number }[], sortedUnitTypes: {id: string; langResource: string;}[]}) {}
}

export class SetLanguage implements Action {
  readonly type = HomeActionTypes.SET_LANGUAGE
  constructor(public payload: { language: string }) {}
}

export class CleanState implements Action {
  readonly type = HomeActionTypes.CLEAN_STATE
}

export class SetPublications implements Action {
  readonly type = HomeActionTypes.SET_PUBLICATIONS
  constructor(public payload: Publication[]) {}
}

export class SetPublicationImage implements Action {
  readonly type = HomeActionTypes.SET_PUBLICATION_IMAGE
  constructor(public payload: { id: string, src: string }) {}
}

export class SetTokenRefresh implements Action {
  readonly type = HomeActionTypes.SET_TOKEN_REFRESH
  constructor(public payload: { api: string, refresh_token: string, token: string, token_data: any, in_background: boolean }) {}
}

export class SetLogbooks implements Action {
  readonly type = HomeActionTypes.SET_LOGBOOKS
  constructor(public payload: Indicador[]) {}
}

export type HomeActions =
  SetMonthlyValue |
  SetSelectedProperty |
  SetProperties |
  SetInformationLoaded |
  SetMovements |
  SetExpiration |
  SetContractsInfo |
  SetUnitsInfo |
  SetLanguage |
  CleanState |
  SetPublications |
  SetPublicationImage |
  SetTokenRefresh |
  SetLogbooks
