import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContratoEstado } from './../../models/contrato-estado.model';
import { ContratoEstadoAdapter } from './../../adapter/contrato-estado.adapter';

@Injectable({
  providedIn: 'root'
})
export class ContratoEstadoService {

  constructor(
    public http: HttpClient,
    public contratoEstadoAdapter: ContratoEstadoAdapter
  ) { }

  cargarContratoEstados(): Observable <ContratoEstado[]> {
    return this.http.get(environment.urlApi + 'contrato_estado')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(contratoEstados => this.contratoEstadoAdapter.adapt(contratoEstados));
        })
      );
  }

}
