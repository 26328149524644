import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import {PropiedadConfiguracionComercial} from '../models/propiedad-configuracion-comercial.model';
import {ContratoConfiguracion} from '../models/contrato-configuracion.model';
import {ContratoConfiguracionInterface} from '../pages/contratos/shared/contratoConfiguracionInterface';

moment.defineLocale('es-CL', {
    monthsShort : [
        'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
        'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
    ],
    months: [
        'Enero', 'Febrero', 'Marzo', 'Abril',
        'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    weekdaysShort : ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb']
});

@Injectable({
    providedIn: 'root',
})

export class Utils {
    private static datePipe: DatePipe;

    constructor(
    ) {}

    static toJson(entity: any, filterAttributes?: Array<any>) {
        const cloneObject = this.cloneObject(entity);
        if (filterAttributes) {
            filterAttributes.filter( attribute => {
                cloneObject[attribute] = undefined;
            });
        }

        if (cloneObject.fechaNacimiento) {
            cloneObject.fechaNacimiento = this.jsonDate(cloneObject.fechaNacimiento)
        }

        return cloneObject;
    }

    static cloneObject(object) {
        const objectTemp = Object.assign({}, object);
        let json = JSON.stringify(objectTemp);
        Object.keys(objectTemp).filter(key => key[0] === '_').forEach(key => {
            json = json.replace(key, key.substring(1));
        });
        return JSON.parse(json);
    }

    static generarParametrosBusqueda(constFiltro: string, filtro: {}): string  {
        let parametros = '';
        Object.entries(filtro).forEach(
            ([key, value]) => {
                if (value === '' || value === undefined || value === null) {
                    return;
                }
                if (typeof(value) === 'string' || typeof(value) === 'number') {
                    parametros += '&' + constFiltro + '[' + key + ']=' + value;
                } else if (Object.prototype.toString.call(value) === '[object Date]' || value instanceof moment || moment.isDate(value)) {
                    const date = this.jsonDate(value);
                    parametros += '&' + constFiltro + '[' + key + '][year]=' + date.year;
                    parametros += '&' + constFiltro + '[' + key + '][month]=' + date.month;
                    parametros += '&' + constFiltro + '[' + key + '][day]=' + date.day;
                } else if(Array.isArray(value)){
                    value.forEach((item,idx)=>{
                        if(typeof(item) === 'object'){
                            parametros +=  this.generarParametrosBusqueda(constFiltro+'['+key+']['+idx+']',item);
                        }else{
                            parametros += '&' + constFiltro + '[' + key + '][]=' + item;
                        }
                    })
                }else if (typeof(value) === 'object') {
                    filtro[key].forEach(item => {
                        if(typeof(item) === 'object'){
                            console.log(item);
                            parametros +=  this.generarParametrosBusqueda(constFiltro+'['+key+']',item);
                        }else{
                            parametros += '&' + constFiltro + '[' + key + '][]=' + item;
                        }
                    });
                }
            }
        );
        return parametros;
    }

    static today(): string {
        return moment().format('YYYY-MM-DD');
    }

    static jsonDate(date) {
        const dateMoment = moment(date);
        return {
            year: +dateMoment.format('YYYY'),
            month: +dateMoment.format('MM'),
            day: +dateMoment.format('DD')
        };
    }

    static addDays(date: string, days: number, format?: string) {
        if (!format) {
            format = 'YYYY-MM-DD';
        }
        const dateMoment = moment(date);
        return dateMoment.add(days, 'day').format(format);
    }

    static daysDiff(date: string) {
        date = date.substring(0, 10);
        const dateMoment = moment(date);
        const endOfMonth = moment(date).endOf('months');
        return endOfMonth.diff(dateMoment, 'days') + 1;
    }

    static daysOfMoth(date: string) {
        date = date.substring(0, 10);
        const dateMoment = moment(date);
        return dateMoment.daysInMonth();
    }

    static momentDate(date: Date) {
        return moment(date);
    }

    static momentNow() {
        return moment();
    }

    static monthsDiff(dateInicio, dateFin) {
        const diff = moment(dateFin).diff(moment(dateInicio), 'months');
        return diff + 1;
    }

    static selectorCicloFecha(fechaInicio: {year: number, month: number, day: number }, cicloCantidad, isAnexo = false): {id: string, value: string}[] {
        const selector = [];
        for (let index = 0; index < cicloCantidad; index++) {
            // TODO comprobar periodo inicio
            const month = isAnexo ? fechaInicio.month : fechaInicio.month - 1;
            const momentDate = moment([fechaInicio.year, month, fechaInicio.day]);
            const monthFormat = isAnexo ? 'MMMM' : 'MMM';
            momentDate.add(index, 'M');
            const fecha = {id: momentDate.format('YYYY-MM'), value: momentDate.format(monthFormat) + ' - ' + momentDate.format('YYYY')};
            if (index === 0 && !isAnexo) {
                fecha.value = fecha.value + '(Se incluirá en 1er Pago)';
            }
            selector.push(fecha);
        }
        return selector;
    }

    // TODO Refactor
    static contratoConfiguracionToPropiedadConfiguracion(contratoConfiguracion: ContratoConfiguracion): PropiedadConfiguracionComercial {
        const propiedadConfiguracion = new PropiedadConfiguracionComercial();
        propiedadConfiguracion.contratoEnvioManualEstadoCuenta = false;
        propiedadConfiguracion.liquidacionCreacionEstadoId = contratoConfiguracion.liquidacionCreacionEstadoId;
        propiedadConfiguracion.reajusteMontoTipoId = contratoConfiguracion.reajusteMontoTipoId;
        propiedadConfiguracion.reajusteMonto = contratoConfiguracion.reajusteMonto;
        propiedadConfiguracion.contratoCobroPeriodicidadId = contratoConfiguracion.cobroPeriodicidadId;
        propiedadConfiguracion.retrasoMultaMontoTipoId = contratoConfiguracion.retrasoMultaMontoTipoId;
        propiedadConfiguracion.retrasoMultaMonto = contratoConfiguracion.retrasoMultaMonto;
        propiedadConfiguracion.retrasoInteresMonto = contratoConfiguracion.retrasoInteresMonto;
        propiedadConfiguracion.retrasoInteresPeriodicidadId = contratoConfiguracion.retrasoInteresPeriodicidadId;
        propiedadConfiguracion.retrasoInteresPeriodicidadValor = contratoConfiguracion.retrasoInteresPeriodicidadValor;
        propiedadConfiguracion.retrasoInteresTipoId = contratoConfiguracion.retrasoInteresTipoId;
        propiedadConfiguracion.contratoDiaGeneracion = contratoConfiguracion.diaGeneracion;
        propiedadConfiguracion.contratoDiaPago = contratoConfiguracion.diaPago;
        propiedadConfiguracion.isRenovacionAutomatica = contratoConfiguracion.isRenovacionAutomatica;
        return propiedadConfiguracion;
    }

    // TODO Refactor
    static propiedadConfiguracionToContratoConfiguracion(propiedadConfiguracion: PropiedadConfiguracionComercial): ContratoConfiguracion {
        const contratoConfiguracion = new ContratoConfiguracion();
        contratoConfiguracion.envioManualEstadoCuenta = false;
        contratoConfiguracion.liquidacionCreacionEstadoId = propiedadConfiguracion.liquidacionCreacionEstadoId;
        contratoConfiguracion.reajusteMontoTipoId = propiedadConfiguracion.reajusteMontoTipoId;
        contratoConfiguracion.reajusteMonto = propiedadConfiguracion.reajusteMonto;
        contratoConfiguracion.cobroPeriodicidadId = propiedadConfiguracion.contratoCobroPeriodicidadId;
        contratoConfiguracion.retrasoMultaMontoTipoId = propiedadConfiguracion.retrasoMultaMontoTipoId;
        contratoConfiguracion.retrasoMultaMonto = propiedadConfiguracion.retrasoMultaMonto;
        contratoConfiguracion.retrasoInteresMontoTipoId = propiedadConfiguracion.retrasoInteresMontoTipoId;
        contratoConfiguracion.retrasoInteresMonto = propiedadConfiguracion.retrasoInteresMonto;
        contratoConfiguracion.retrasoInteresPeriodicidadId = propiedadConfiguracion.retrasoInteresPeriodicidadId;
        contratoConfiguracion.retrasoInteresPeriodicidadValor = propiedadConfiguracion.retrasoInteresPeriodicidadValor;
        contratoConfiguracion.retrasoInteresTipoId = propiedadConfiguracion.retrasoInteresTipoId;
        contratoConfiguracion.diaGeneracion = propiedadConfiguracion.contratoDiaGeneracion;
        contratoConfiguracion.diaPago = propiedadConfiguracion.contratoDiaPago;
        contratoConfiguracion.isGeneracionAutomatica = !propiedadConfiguracion.contratoEnvioManualEstadoCuenta
        return contratoConfiguracion;
    }

    // TODO Refactor tslint:disable-next-line:max-line-length
    static parametrosComercialesToConfiguracionInterface(parametrosComerciales: PropiedadConfiguracionComercial): ContratoConfiguracionInterface {
        let contratoConfiguracionInterface: ContratoConfiguracionInterface;
        contratoConfiguracionInterface = {
            id: null,
            cobroPeriodicidad: parametrosComerciales.contratoCobroPeriodicidadId,
            retrasoMultaTipo: parametrosComerciales.retrasoMultaMontoTipoId,
            retrasoMultaMonto: parametrosComerciales.retrasoMultaMonto,
            retrasoInteresMontoTipo: 2,
            retrasoInteresMonto: parametrosComerciales.retrasoInteresMonto,
            retrasoInteresPeriodicidad: parametrosComerciales.retrasoInteresPeriodicidadId,
            retrasoInteresPeriodicidadValor: parametrosComerciales.retrasoInteresPeriodicidadValor,
            retrasoInteresTipoId: parametrosComerciales.retrasoInteresTipoId,
            diaGeneracion: parametrosComerciales.contratoDiaGeneracion,
            liquidacionCreacionEstado: parametrosComerciales.liquidacionCreacionEstadoId,
            diaPago: parametrosComerciales.contratoDiaPago,
            diaCobro: 1,
            diaCorteArriendo: 1,
            diaCorteGastoComun: 1,
            diaCorteServicios: 1,
            interesPorDia: true,
            isGeneracionAutomatica: !parametrosComerciales.contratoEnvioManualEstadoCuenta
        };
        return contratoConfiguracionInterface;
    }

    static contratoConfiguracionToContratoConfiguracionInterface(contratoConfiguracion: ContratoConfiguracion): ContratoConfiguracionInterface {
        const contratoConfiguracionInterface = {
            id: null,
            cobroPeriodicidad: contratoConfiguracion.cobroPeriodicidadId,
            reajusteMontoTipo: contratoConfiguracion.reajusteMontoTipoId,
            reajusteMonto: contratoConfiguracion.reajusteMonto,
            reajustePeriodicidad: contratoConfiguracion.reajustePeriodicidadId,
            reajustePeriodicidadValor: contratoConfiguracion.reajustePeriodicidadValor,
            retrasoMultaTipo: contratoConfiguracion.retrasoMultaMontoTipoId,
            retrasoMultaMonto: contratoConfiguracion.retrasoMultaMonto,
            retrasoInteresMontoTipo: 2,
            retrasoInteresMonto: contratoConfiguracion.retrasoInteresMonto,
            retrasoInteresPeriodicidad: contratoConfiguracion.retrasoInteresPeriodicidadId,
            retrasoInteresPeriodicidadValor: contratoConfiguracion.retrasoInteresPeriodicidadValor,
            retrasoInteresTipoId: contratoConfiguracion.retrasoInteresTipoId,
            diaGeneracion: contratoConfiguracion.diaGeneracion,
            liquidacionCreacionEstado: contratoConfiguracion.liquidacionCreacionEstadoId,
            diaPago: contratoConfiguracion.diaPago,
            diaCobro: 1,
            diaCorteArriendo: 1,
            diaCorteGastoComun: 1,
            diaCorteServicios: 1,
            interesPorDia: true,
            isGeneracionAutomatica: contratoConfiguracion.isGeneracionAutomatica
        }
        return contratoConfiguracionInterface;
    }

    static momentString(date: string, format: string) {
        const momentDate = moment(date, format);
        return momentDate.format('DD-MM-YYYY');
    }

    static dateObjectToString(date: {year, month, day}) {
        const month = date.month < 10 ? '0' + date.month : date.month;
        const day = date.day < 10 ? '0' + date.day : date.day;
        return date.year + '-' + month + '-' + day;
    }

    static dateStringToMMDDYYYY(date: string, format: string) {
        const momentDate = moment(date, format);
        return momentDate.format('MM-DD-YYYY');
    }

    // TODO Refactor
    static siguienteFechaReajuste(date: Date, periodicidadTipo: number, periodicidadCantidad: number) {
        switch (periodicidadTipo) {
            case 1:
                date.setDate(date.getDate() + periodicidadCantidad);
                break;
            case 2:
                date.setMonth(date.getMonth() + periodicidadCantidad);
                break;
            case 3:
                date.setFullYear(date.getFullYear() + periodicidadCantidad);
                break;
        }
        return date;
    }

    // TODO Refactor
    static fechaReajuste(date: Date, periodicidadTipo: number, periodicidadCantidad: number) {
        const dateMoment = moment(date);
        switch (periodicidadTipo) {
          case 1:
                dateMoment.subtract(periodicidadCantidad, 'day');
                break;
            case 2:
                dateMoment.subtract(periodicidadCantidad, 'month');
                break;
            case 3:
                dateMoment.subtract(periodicidadCantidad, 'year');
                break;
        }
        return new Date(dateMoment.format('YYYY-MM-DD HH:mm:ss'));
    }

    static contratoDuracionMeses(fechaInicio: Date, fechaFin: Date): number {
        const inicio = new Date (fechaInicio)
        const fin = new Date (fechaFin)
        const diffTime = Math.abs(fin.getTime() - inicio.getTime())
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        const contratoDuracionMeses = Math.round(diffDays / 30)
        return contratoDuracionMeses > 1 ? contratoDuracionMeses : 1
    }

    monthDiff(fechaInicio: Date, fechaFin: Date) {
        return Utils.contratoDuracionMeses(fechaInicio, fechaFin);
    }

}
