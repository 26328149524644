<h3 mat-dialog-title class="modal-title">{{ 'lease.reverse_row' | translate }}</h3>
<form>
    <mat-dialog-content>
        <div class="container-fluid">
            <div class="row">
                <div class="col col-lg-6 col-md-12 col-sm-12">
                    <div class="row section-title">
                        <div class="col">
                            <h5>{{ 'lease.customer_info' | translate }}</h5>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <pre></pre>
                        <div class="col col-6">
                            <span class="subtitulos-tarjetas-c" *ngIf="data.contacto.contactoTipoId === 1">{{ 'contact.first_last_name' | translate }} </span><br>
                            <span class="subtitulos-tarjetas-c" *ngIf="data.contacto.contactoTipoId === 2">{{ 'contact.company_name' | translate }} </span><br>
                            <span class="texto-contacto ">{{data.contacto.nombreCompleto}}</span>
                        </div>
                        <div class="col col-6" *ngIf="data.contacto.identificadorTipo">
                            <span class="subtitulos-tarjetas-c ">{{data.contacto.identificadorTipo.nombre}} </span><br>
                            <span class="texto-contacto ">{{ data.contacto.identificador | identificador: data.contacto.identificadorTipoId }}</span>
                        </div>
                        <div class="col col-6">
                            <span class="subtitulos-tarjetas-c ">{{ 'general.property' | translate }} </span><br>
                            <span class="texto-contacto ">{{data.pago.contrato.propiedad.nombre | titlecase}}</span>
                        </div>
                        <div class="col col-6">
                            <span class="subtitulos-tarjetas-c ">{{ 'general.unit' | translate }} </span><br>
                            <span class="texto-contacto" *ngFor="let contratoUnidad of data.pago.contrato.contratoUnidad">
                            {{contratoUnidad.unidadTipo.lang_resource | translate}} {{contratoUnidad.unidad.nombre}}
                        </span>
                        </div>
                        <div class="col col-6">
                            <span class="subtitulos-tarjetas-c ">{{ 'lease.contract_folio' | translate }} </span><br>
                            <span class="texto-contacto ">{{data.pago.contrato.folio}}</span>
                        </div>
                        <div class="col col-6">
                            <span class="subtitulos-tarjetas-c ">{{ 'lease.settlement_folio' | translate }} </span><br>
                            <span class="texto-contacto ">{{data.pago.estadoCuenta.folio}}</span>
                        </div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-12 col-sm-12">
                    <div class="row section-title">
                        <div class="col">
                            <h5>{{ 'lease.payment_data' | translate }}</h5>
                        </div>
                    </div>
                    <div class="row row-cont-l">
                        <div class="col col-6 ">
                            <span class="subtitulos-tarjetas-c ">{{ 'balance.payment_date' | translate }} </span><br>
                            <span class="texto-contacto ">{{data.pago.fecha | fecha}}</span>
                        </div>
                        <div class="col col-6 ">
                            <span class="subtitulos-tarjetas-c ">{{ 'lease.payment_method' | translate }} </span><br>
                            <span class="texto-contacto ">{{data.pago.medioPago.nombre}}</span>
                        </div>
                        <div class="col col-6 ">
                            <span class="subtitulos-tarjetas-c ">{{ 'general.amount' | translate }} </span><br>
                            <span class="texto-contacto ">{{data.pago.monto | moneda}}</span>
                        </div>
                        <div class="col col-6 ">
                            <span class="subtitulos-tarjetas-c ">{{ 'general.number' | translate }} </span><br>
                            <span class="texto-contacto ">{{data.pago.numero}}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row section-title">
                <div class="col">
                    <h5>{{ 'lease.reverse_data' | translate }}</h5>
                </div>
            </div>
            <div class="row row-cont-l">

                <div class="col col-lg-6 col-md-12 col-sm-12">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'general.motivation' | translate }}</mat-label>
                        <mat-select [(ngModel)]="reverso.motivoReverso" id="motivoReverso" name="motivoReverso" required>
                            <mat-option [value]="1">{{ 'lease.bad_check_issue' | translate }}</mat-option>
                            <mat-option [value]="2">{{ 'lease.bounced_check' | translate }}</mat-option>
                            <mat-option [value]="3">{{ 'lease.failed_transaction' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col col-lg-6 col-md-12 col-sm-12">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'lease.reverse_date' | translate }}</mat-label>
                        <input matInput [matDatepicker]="fecha" [(ngModel)]="reverso.fecha" id="fecha" name="fecha" required/>
                        <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                        <mat-datepicker #fecha></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col col-12">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'general.comment' | translate }}</mat-label>
                        <textarea maxlength="255" matInput placeholder="{{ 'general.comment' | translate }}" [(ngModel)]="reverso.comentario" id="comentario" name="comentario"></textarea>
                        <mat-hint
                            align="end"
                            [ngStyle]="{ color: reverso.comentario?.length === 255 ? 'red' : 'inherit' }"
                        >
                            {{ reverso.comentario?.length || 0 }} / {{ 255 }}
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-between">
        <button mat-dialog-close [class]="'boton-anterior'">
            {{ 'general.cancel' | translate }}
        </button>
        <button type="submit" [class]="'boton-agp'" (click)="openConfimacion()">
            {{ 'general.save' | translate }}
        </button>
    </mat-dialog-actions>
</form>
