import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-expiracion',
  templateUrl: './modal-expiracion.component.html',
  styleUrls: ['./modal-expiracion.component.css']
})

export class ModalExpiracionComponent implements OnInit {

  public secondsValidation = 30;
  public estaRefrescando = false;
  public haSidoRefrescado = false;
  private timer = null;
  public aceptarBtn: string;
  public cancelaBtn: string;
  public expireMsg: string;
  public timeUnit: string;
  public stillActive: string;


  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalExpiracionComponent>,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public actions: {onRefresh: Function, onCancel: Function},
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.setActionTexts();
    this.setModalMessages();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setActionTexts();
      this.setModalMessages();
    });
    this.runTimer();
  }

  setActionTexts(){
    this.aceptarBtn = this.translate.instant('general.yes');
    this.cancelaBtn = this.translate.instant('general.no');
  }

  setModalMessages(){
    this.expireMsg = this.translate.instant('session.expire_message');
    this.timeUnit = this.translate.instant('general.seconds');
    this.stillActive = this.translate.instant('session.still_active');
  }

  runTimer(){
    this.timer = setTimeout(()=>{
      this.secondsValidation--;
      if(this.secondsValidation===0){
        this.setModalMessages();
        this.onCancel();
      }else{
        this.runTimer();
      }
    },1000);
  }

  clearTimer(){
    clearTimeout(this.timer);
  }

  onAccept(){
    this.clearTimer();
    this.dialog.closeAll();
    this.actions.onRefresh();
  }

  onCancel(){
    this.dialog.closeAll();
    this.actions.onCancel();
  }
}
