import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { DescuentoRecargoConfiguracion } from '../models/descuento-recargo-configuracion.model';
import { ConceptoAdapter } from './concepto.adapter';
import { CargoClasificacionAdapter } from './cargo-clasificacion.adapter';

@Injectable({
    providedIn: 'root'
})

export class DescuentoRecargoConfiguracionAdapter implements Adapter<DescuentoRecargoConfiguracion> {

    constructor(
        public conceptoAdapter: ConceptoAdapter,
        public cargoClasificacionAdapter: CargoClasificacionAdapter
    ) {}

    adapt(item: any): DescuentoRecargoConfiguracion {
        const descuentoRecargoConfiguracion = new DescuentoRecargoConfiguracion();
        descuentoRecargoConfiguracion.id = item.attributes.id;
        descuentoRecargoConfiguracion.propiedadId = item.attributes.propiedad_id;
        descuentoRecargoConfiguracion.nombre = item.attributes.nombre;
        descuentoRecargoConfiguracion.conceptoId = item.attributes.concepto_id;
        descuentoRecargoConfiguracion.cargoClasificacionId = item.attributes.cargo_clasificacion_id;
        descuentoRecargoConfiguracion.tipoId = item.attributes.tipo_id;
        descuentoRecargoConfiguracion.montoTipoId = item.attributes.monto_tipo_id;
        descuentoRecargoConfiguracion.monto = +item.attributes.monto;
        descuentoRecargoConfiguracion.divisaId = item.attributes.divisa_id;
        descuentoRecargoConfiguracion.isActivo = item.attributes.is_activo;
        descuentoRecargoConfiguracion.cicloCantidad = item.attributes.ciclo_cantidad;
        descuentoRecargoConfiguracion.cicloOpcion = item.attributes.ciclo_opcion;
        descuentoRecargoConfiguracion.cicloFecha = item.attributes.ciclo_fecha;
        descuentoRecargoConfiguracion.descuentoRecargoConceptoAplicaId = item.attributes.descuento_recargo_concepto_aplica_id;
        descuentoRecargoConfiguracion.montoTotal = +item.attributes.monto_total;
        descuentoRecargoConfiguracion.isAfectoImpuesto = item.attributes.is_afecto_impuesto;
        descuentoRecargoConfiguracion.isFacturableInt = item.attributes.is_facturable_int;
        descuentoRecargoConfiguracion.createdAt = item.attributes.created_at;
        descuentoRecargoConfiguracion.createdBy = item.attributes.created_by;
        descuentoRecargoConfiguracion.cuentaRecaudadoraId = item.attributes.cuenta_recaudadora_id;

        if (item.included) {
            item.included.filter((valor) => {
                switch (valor.type) {
                    case 'Concepto':
                        descuentoRecargoConfiguracion.concepto = this.conceptoAdapter.adapt(valor);
                        break;
                    case 'CargoClasificacion':
                        descuentoRecargoConfiguracion.cargoClasificacion = this.cargoClasificacionAdapter.adapt(valor);
                        break;
                }
            });
        }
        return descuentoRecargoConfiguracion;
    }
}
