import { Contacto, Cotizacion, ContactoEvaluacionDetalle, ContactoEvaluacionEstado } from './model.index';

export class ContactoEvaluacion {

    private _id: string;
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    private _folio: string;
    public get folio(): string {
        return this._folio;
    }
    public set folio(value: string) {
        this._folio = value;
    }
    private _fecha: string;
    public get fecha(): string {
        return this._fecha;
    }
    public set fecha(value: string) {
        this._fecha = value;
    }
    private _estadoId: number;
    public get estadoId(): number {
        return this._estadoId;
    }
    public set estadoId(value: number) {
        this._estadoId = value;
    }
    private _contactoId: string;
    public get contactoId(): string {
        return this._contactoId;
    }
    public set contactoId(value: string) {
        this._contactoId = value;
    }
    private _estado: string;
    public get estado(): string {
        return this._estado;
    }
    public set estado(value: string) {
        this._estado = value;
    }
    private _contacto: Contacto;
    public get contacto(): Contacto {
        return this._contacto;
    }
    public set contacto(value: Contacto) {
        this._contacto = value;
    }
    private _contactoEvaluacionDetalles: ContactoEvaluacionDetalle[];
    public get contactoEvaluacionDetalles(): ContactoEvaluacionDetalle[] {
        return this._contactoEvaluacionDetalles;
    }
    public set contactoEvaluacionDetalles(value: ContactoEvaluacionDetalle[]) {
        this._contactoEvaluacionDetalles = value;
    }
    private _cotizaciones: Cotizacion[];
    public get cotizaciones(): Cotizacion[] {
        return this._cotizaciones;
    }
    public set cotizaciones(value: Cotizacion[]) {
        this._cotizaciones = value;
    }
    private _contactoEvaluacionEstado: ContactoEvaluacionEstado;
    public get contactoEvaluacionEstado(): ContactoEvaluacionEstado {
        return this._contactoEvaluacionEstado;
    }
    public set contactoEvaluacionEstado(value: ContactoEvaluacionEstado) {
        this._contactoEvaluacionEstado = value;
    }
}

