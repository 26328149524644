import { ContactoEvaluacionAdapter } from './../../adapter/contacto-evaluacion.adapter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ContactoEvaluacion, Paginador } from 'src/app/models/model.index';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactoEvaluacionService {

public filterAttributes: string[] = [];
public contactoEvaluacion: {};
public parametros: string;
public paginador: Paginador;

  constructor(
    public http: HttpClient,
    public paginadorAdapter: PaginadorAdapter,
    public contactoEvaluacionAdapter: ContactoEvaluacionAdapter
  ) {
    this.filterAttributes.push('id', 'contactoId', 'createdAt', 'createdby', 'estadoId');
  }

  actualizarContactoEvaluacion(contactoEvaluacion): Observable <any> {
    this.contactoEvaluacion = contactoEvaluacion;
    return this.http.put(environment.urlApi + 'contacto_evaluaciones/' + contactoEvaluacion.id, this.formatJson());
  }

  crearContactoEvaluacion(contactoEvaluacion): Observable <any> {
    this.contactoEvaluacion = contactoEvaluacion;
    return this.http.post(environment.urlApi + 'contacto_evaluaciones', this.formatJson());
  }

  formatJson() {
    return Utils.toJson(this.contactoEvaluacion, this.filterAttributes);
  }

  cargarContactoEvaluaciones(page, filtro?): Observable <ContactoEvaluacion[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'contacto_evaluaciones?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(contactoEvaluacionAdapter => this.contactoEvaluacionAdapter.adapt(contactoEvaluacionAdapter));
        })
      );
  }

  generarParametrosBusqueda(filtro)  {
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('contacto_evaluacion_filter', filtro);
  }

  paginadorContactoEvaluacion() {
    return this.paginador;
  }

  downloadFile(id: string, idAdjunto: string): Observable<any> {
    const URL = environment.urlApi + 'contacto_evaluaciones/' + id + '/adjuntos/' + idAdjunto + '/download';
    return this.http.get(URL, { responseType: 'blob' });
  }

  cargarContactoEvaluacion(contactoEvaluacionId): Observable <ContactoEvaluacion> {
    return this.http.get(environment.urlApi + 'contacto_evaluaciones/' + contactoEvaluacionId)
      .pipe(
        map( (resp: any) => this.contactoEvaluacionAdapter.adapt(resp.data))
      );
  }
}
