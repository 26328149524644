import {Injectable} from "@angular/core";
import {Adapter} from "./adapter";
import {PropiedadFirmante} from "../models/propiedad-firmante.model";

@Injectable({
  providedIn: 'root'
})
export class PropiedadFirmanteAdapter implements Adapter<PropiedadFirmante> {
  constructor() {
  }

  adapt(item: any): PropiedadFirmante {
    const propiedadFirmante = new PropiedadFirmante();
    propiedadFirmante.id = item.attributes.id;
    propiedadFirmante.nombre = item.attributes.nombre_completo;
    propiedadFirmante.email = item.attributes.email;
    propiedadFirmante.identificador = item.attributes.identificador;
    propiedadFirmante.isActive = item.attributes.is_active;
    return propiedadFirmante;
  }
}
