import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { RouterModule } from '@angular/router';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { LoaderComponent } from './loader/loader.component';
import { PaginacionComponent } from './paginacion/paginacion.component';
import { ConfirmacionesComponent } from './confirmaciones/confirmaciones.component';
import { MenuClienteComponent } from './menu-cliente/menu-cliente.component';
import {ModalContactoModule} from '../pages/clientes/shared/modal-contacto/modal.contacto.module';
import { ModalAgregarComentarioInternoModule } from '../pages/checkin/modal-agregar-comentario-interno/modal-agregar-comentario-interno.module'
import { ModalDetalleCheckCalendarioModule } from '../pages/checkestate/modal-detalle-check-calendario/modal-detalle-check-calendario.module'
import { ModalEnviarCorreoModule } from '../pages/checkestate/modal-enviar-correo/modal-enviar-correo.module'
import { TranslateModule } from '@ngx-translate/core';


import { StyleGuideComponent } from './style-guide/style-guide.component';
import { IconoComponent } from './icono/icono.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { FileDropzoneComponent } from './file-dropzone/file-dropzone.component';
import {DirectiveModule} from '../pages/directives/directive.module';
import { FormInfoComponent } from './form-info/form-info.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { LenguajeComponent } from './lenguaje/lenguaje.component';
import { SelectorFechasComponent } from './selector-fechas/selector-fechas.component';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DatepickerRangeComponent} from './datepicker-range/datepicker-range.component';
import {PipesModule} from '../pipes/pipes.module';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import { SelectorRangoFechasComponent } from './selector-rango-fechas/selector-rango-fechas.component';
import {MatInputModule} from '@angular/material/input';
import { InfoTextComponent } from './info-text/info-text.component';
import {MatButtonModule} from '@angular/material/button';
import { AbilityModule } from '@casl/angular';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { ModalGeneralModule } from './modal-general/modal-general.module';
import { TabsListComponent } from './tabs-list/tabs-list.component';
import { TableLoaderComponent } from './table-loader/table-loader.component';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";



@NgModule({
  imports: [
    RouterModule,
    TranslateModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    FormsModule,
    ModalContactoModule,
    ModalAgregarComentarioInternoModule,
    ModalDetalleCheckCalendarioModule,
    ModalEnviarCorreoModule,
    ModalGeneralModule,
    DirectiveModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatDatepickerModule,
    PipesModule,
    NgxDaterangepickerMd,
    MatInputModule,
    MatButtonModule,
    AbilityModule,
    MatCheckboxModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
      HeaderComponent,
      LenguajeComponent,
      SidebarComponent,
      NopagefoundComponent,
      LoaderComponent,
      PaginacionComponent,
      ConfirmacionesComponent,
      MenuClienteComponent,
      StyleGuideComponent,
      IconoComponent,
      BreadcrumbComponent,
      PageTitleComponent,
      FileDropzoneComponent,
      FormInfoComponent,
      SelectorFechasComponent,
      SelectorRangoFechasComponent,
      InfoTextComponent,
      TabsListComponent,
      TableLoaderComponent,
      DatepickerRangeComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    HeaderComponent,
    LenguajeComponent,
    SidebarComponent,
    NopagefoundComponent,
    LoaderComponent,
    PaginacionComponent,
    MenuClienteComponent,
    IconoComponent,
    BreadcrumbComponent,
    PageTitleComponent,
    FileDropzoneComponent,
    FormInfoComponent,
    TranslateModule,
    SelectorFechasComponent,
    SelectorRangoFechasComponent,
    AbilityModule,
    TabsListComponent,
    TableLoaderComponent,
    DatepickerRangeComponent,
    InfoTextComponent,
  ]
})

export class SharedModule { }
