import { ReversoService } from './../../../services/reverso/reverso.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EstadoCuentaPago, Contacto } from 'src/app/models/model.index';
import { ConfirmacionesComponent } from 'src/app/shared/confirmaciones/confirmaciones.component';
import { Utils } from 'src/app/shared/utils';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AbilityService } from 'src/app/services/ability/ability.service';

interface ReversoInterface {
  divisa: string;
  estadoCuentaPago: string;
  motivoReverso: number;
  monto: number;
  comentario: string;
  ejecutivo: string;
  fecha: any;
}

@Component({
  selector: 'app-modal-reverso',
  templateUrl: './modal-reverso.component.html',
  styleUrls: ['./modal-reverso.component.css']
})

export class ModalReversoComponent implements OnInit {

  public reverso = {} as ReversoInterface;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalReversoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {contacto: Contacto, pago: EstadoCuentaPago},
    public reversoService: ReversoService,
    public toastr: ToastrService,
    private translate: TranslateService,
    private ability: AbilityService
  ) { }

  ngOnInit() {
    this.reverso.estadoCuentaPago = this.data.pago.id;
    this.reverso.monto = this.data.pago.monto;
    this.reverso.divisa = this.data.pago.estadoCuenta.divisaCobroId;
  }

  openConfimacion() {
    if (!this.reverso.motivoReverso || !this.reverso.fecha) {
      return;
    }
    let fechaVencimiento = '';
    if (Utils.momentNow() > Utils.momentDate(this.reverso.fecha)) {
      fechaVencimiento = Utils.addDays(Utils.momentNow().toISOString(), +this.data.pago.contrato.contratoConfiguracion.diaPago, 'DD MMM YYYY');
    } else {
      fechaVencimiento = Utils.addDays(this.reverso.fecha, +this.data.pago.contrato.contratoConfiguracion.diaPago, 'DD MMM YYYY');
    }
    const confirmacionModal = this.dialog.open(
      ConfirmacionesComponent,
      {
        data: {
          titulo: this.translate.instant('balance.ask_make_reverse'),
          contenido: this.translate.instant("balance.make_reverse_confirm")+` <strong>${fechaVencimiento}</strong>.`,
        }
      }
    );
    confirmacionModal.afterClosed().subscribe(result => {
      if (result) {
        this.crearReverso();
      }
    });
  }

  crearReverso() {
    if(this.ability.hasAccion('ReversoCreate')){
      const reverso = Utils.cloneObject(this.reverso);
      reverso.fecha = Utils.jsonDate(this.reverso.fecha);
      this.reversoService.crearReverso(reverso).subscribe( resp => {
        if (resp.data && resp.data.id) {
          this.dialogRef.close(true);
          this.toastr.success('Se ha generado correctamente el reverso!');
        } else if (resp.errors) {
          resp.errors.forEach(error => {
            this.toastr.error(error.detail);
          });
        } else {
          this.toastr.error('Algo no anda bien! Por favor contacte al administrador');
        }
      });
    }else{
      this.toastr.error('Usted no tiene permisos para crear el reverso!');
    }
  }
}
