import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DescuentoRecargoConfiguracion } from 'src/app/models/descuento-recargo-configuracion.model';
import { DescuentoRecargoConfiguracionAdapter } from './../../adapter/descuento-recargo-configuracion.adapter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { Paginador } from 'src/app/models/model.index';

@Injectable({
  providedIn: 'root'
})
export class DescuentoRecargoConfiguracionService {

  public parametros: string;
  public paginador: Paginador;

  constructor(
    public http: HttpClient,
    public descuentoRecargoConfiguracionAdapter: DescuentoRecargoConfiguracionAdapter,
    public paginadorAdapter: PaginadorAdapter
  ) { }

  generarParametrosBusqueda(filtro)  {
    this.parametros = '';
    if (!filtro) {
      return;
    }
    if (filtro.isActivo) {
      this.parametros += '&descuento_recargo_configuracion_filter[isActivo]=' + filtro.isActivo;
    }
    if ( filtro.propiedadId) {
      this.parametros += '&descuento_recargo_configuracion_filter[propiedadId]=' + filtro.propiedadId;
    }
    if (filtro.nombre) {
      this.parametros += '&descuento_recargo_configuracion_filter[nombre]=' + filtro.nombre;
    }
    if (filtro.cicloCantidadMin) {
      this.parametros += '&descuento_recargo_configuracion_filter[cicloCantidadMin]=' + filtro.cicloCantidadMin;
    }
    if (filtro.cicloCantidadMax) {
      this.parametros += '&descuento_recargo_configuracion_filter[cicloCantidadMax]=' + filtro.cicloCantidadMax;
    }
  }

  paginadorDescuentoRecargo() {
    return this.paginador;
  }
}
