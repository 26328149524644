import { Unidad } from './unidad.model';

export class CotizacionUnidad {

    private _id: string;
    private _cotizacionId: string;
    private _unidadId: string;
    private _createdAt: string;
    private _createdBy: string;

    private _unidad: Unidad;
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get cotizacionId(): string {
        return this._cotizacionId;
    }
    public set cotizacionId(value: string) {
        this._cotizacionId = value;
    }
    public get unidadId(): string {
        return this._unidadId;
    }
    public set unidadId(value: string) {
        this._unidadId = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    public get unidad(): Unidad {
        return this._unidad;
    }
    public set unidad(value: Unidad) {
        this._unidad = value;
    }

}
