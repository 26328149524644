import {AppModel} from "./app.model";

export class CargoClasificacion extends AppModel{

    private _id: number;
    private _nombre: string;
    private _orden: number;
    private _lang_resource: string;

    public get id(): number {
        return this._id;
    }
    public set id(id: number) {
        this._id = id;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(nombre: string) {
        this._nombre = nombre;
    }
    public get orden(): number {
        return this._orden;
    }
    public set orden(orden: number) {
        this._orden = orden;
    }
    public get lang_resource(): string {
        return this._lang_resource;
    }
    public set lang_resource(nombre: string) {
        this._lang_resource = nombre;
    }
}
