import {Unidad} from './unidad.model';
import {UnidadTipo} from './unidad-tipo.model';
import {UnidadEstado} from './unidad-estado.model';
import {ContratoAnexoUnidadDetalle} from './contrato-anexo-unidad-detalle.model';
import {ContratoAnexoUnidadConfiguracion} from './contrato-anexo-unidad-configuracion.model';

export class ContratoAnexoUnidad {
  private _id: string;
  private _contratoAnexoId: string;
  private _unidadId: string;
  private _unidad: Unidad;
  private _unidadTipo: UnidadTipo;
  private _unidadEstado: UnidadEstado;
  private _contratoAnexoUnidadDetalle: ContratoAnexoUnidadDetalle[];
  private _contratoAnexoUnidadConfiguracion: ContratoAnexoUnidadConfiguracion;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get contratoAnexoId(): string {
    return this._contratoAnexoId;
  }

  set contratoAnexoId(value: string) {
    this._contratoAnexoId = value;
  }

  get unidadId(): string {
    return this._unidadId;
  }

  set unidadId(value: string) {
    this._unidadId = value;
  }

  get unidad(): Unidad {
    return this._unidad;
  }

  set unidad(value: Unidad) {
    this._unidad = value;
  }

  get unidadTipo(): UnidadTipo {
    return this._unidadTipo;
  }

  set unidadTipo(value: UnidadTipo) {
    this._unidadTipo = value;
  }

  get unidadEstado(): UnidadEstado {
    return this._unidadEstado;
  }

  set unidadEstado(value: UnidadEstado) {
    this._unidadEstado = value;
  }

  get contratoAnexoUnidadDetalle(): ContratoAnexoUnidadDetalle[] {
    return this._contratoAnexoUnidadDetalle;
  }

  set contratoAnexoUnidadDetalle(value: ContratoAnexoUnidadDetalle[]) {
    this._contratoAnexoUnidadDetalle = value;
  }

  get contratoAnexoUnidadConfiguracion(): ContratoAnexoUnidadConfiguracion {
    return this._contratoAnexoUnidadConfiguracion;
  }

  set contratoAnexoUnidadConfiguracion(value: ContratoAnexoUnidadConfiguracion) {
    this._contratoAnexoUnidadConfiguracion = value;
  }
}
