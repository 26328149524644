<section id="registro">
    <!-- CONTAINER UNO -->
    <div class="container">
        <div class="row">
            <div class="col col-lg-12 col-md-12 col-sm-12">
                <h2 class="title-loginbrand">pegasus </h2>
            </div>
        </div>
    </div>

    <div class="container container-form-l">
        <form ngNativeValidate [formGroup]="formRegistro" (ngSubmit)="registrarUsuario()">
            <div class="cont-formlog col-lg-6 offset-md-3">
                <div class="row">
                    <div class="col subtitulo-is">
                        <h5>{{ 'session.create_account' | translate }}</h5>
                        <p class="c-is">{{ 'session.already_account' | translate }} <a routerLink="/login">{{ 'session.start_session_msg' | translate }}</a></p>
                    </div>
                </div>

                <div class="form-row form-log">
                    <div class="col-lg-5">
                        <input formControlName="nombre" name="nombre" type="text" class="form-control" placeholder="{{ 'contact.name' | translate }}" required>
                    </div>
                    <div class="col-lg-5">
                        <input formControlName="apellido" name="apellido" type="text" class="form-control" placeholder="{{ 'contact.last_name_sing' | translate }}" required>
                    </div>
                </div>

                <div class="form-row form-log">
                    <div class="col-lg-5">
                        <input formControlName="email" name="email" type="email" class="form-control" placeholder="{{ 'contact.email' | translate }}" required>
                    </div>
                    <div class="col-lg-5">
                        <select formControlName="tipoCuenta" name="tipoCuenta" type="text" class="form-control select-tipocuenta" required>
                        <option class="option-tipocuenta" value="" selected>{{ 'session.select_account_type' | translate }}</option>
                        <option value="Operador">{{ 'session.operator' | translate }}</option>
                        <option value="Supervisor">{{ 'session.supervisor' | translate }}</option>
                        <option value="Ejecutivo">{{ 'session.executive' | translate }}</option>
                      </select>
                    </div>
                </div>
                <div class="form-row form-log">
                    <div class="col-lg-5">
                        <input formControlName="contrasena" name="contrasena" type="password" class="form-control" placeholder="{{ 'session.password' | translate }}" required>
                    </div>
                    <div class="col-lg-5">
                        <input formControlName="contrasena2" name="contrasena2" type="password" class="form-control" placeholder="{{ 'session.confirm_password' | translate }}" required>
                    </div>
                </div>
                <hr>
                <pre>
                    {{ formRegistro.errors | json }}
                </pre>
                <div class="col-lg-8 offset-md-2 box-b-reg">
                    <button class="b-reg">{{ 'session.regiter_btn' | translate | uppercase }}</button>
                </div>
                <hr>
                <div>
                    <p class="o-rc">{{ 'session.or_register_with' | translate }}</p>
                </div>
                <div class="row row-iconos-fg">
                    <div class="col-lg-2 offset-md-4">
                        <i class="fab fa-google"></i>
                    </div>
                    <div class="col-lg-2">
                        <i class="fab fa-facebook"></i>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
