import { RouterModule } from '@angular/router';
import { ContactoDetalleComponent } from './contacto-detalle.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { AbilityModule } from '@casl/angular';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PipesModule,
        TranslateModule,
        AbilityModule,
        MatTooltipModule
    ],
    declarations: [ContactoDetalleComponent],
    exports: [ ContactoDetalleComponent]
})

export class ContactoDetalleModule { }
