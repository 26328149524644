import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PerfilService } from './../auxiliar/perfil.service';
import { User } from 'src/app/models/model.index';
import { ToastrService } from 'ngx-toastr';
import { DataShareService } from '../auxiliar/data-share.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/models/app.model'
import { CleanState } from '../../store/actions/home.action'
import { AbilityService } from '../ability/ability.service';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {

  public token: string;
  public refresh_token: string;
  private _user: User;
  public _worker: Worker;
  private tokenData: any;
  public isRefreshing: boolean;

  constructor(
    public http: HttpClient,
    private httpBackend: HttpBackend,
    public router: Router,
    public perfilService: PerfilService,
    public toastr: ToastrService,
    public dataShareService: DataShareService,
    private store: Store<AppState>,
    private ability: AbilityService
  ) {
    this.cargarStorageToken();
    this.cargarStorageRefreshToken();
    this.cargarStorageIdentity();
    this.cargarMomentLocale();
  }

  loadWorker(){
    this.worker = new Worker('/assets/js/service-worker/ngsw-worker.js');
  }

  login(formLogin): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.urlApi + 'auth/login', formLogin, {headers})
    .pipe(
      map ( (resp: any) => {
        this.guardarTokenStorage( resp.token );
        this.guardarRefreshTokenStorage( resp.refresh_token );
        return resp;
      })
    );
  }

  fetchRefreshToken(success: any,fail: any){
    fetch(environment.urlApi + 'token/refresh', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      }, body: (JSON.stringify({refresh_token:this.refresh_token }))
    }).then((resp)=>{
      resp.json().then((tokenInfo)=>{
        this.guardarTokenStorage( tokenInfo.token );
        this.guardarRefreshTokenStorage( tokenInfo.refresh_token );
        success(resp);
      });
    }).catch((error)=>{
      console.log("fail");
      fail(error);
    });
  }

  refreshToken() : Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const newHttpClient = new HttpClient(this.httpBackend);
    return newHttpClient.post(environment.urlApi + 'token/refresh', {refresh_token: this.refresh_token}, {headers}).pipe(
      map ( (resp: any) => {
        this.guardarTokenStorage( resp.token );
        this.guardarRefreshTokenStorage( resp.refresh_token );
        return resp;
      })
    );
  }

  logout() {
    this.token = '';
    this.user = null;
    let locale = localStorage.getItem('locale-user');
    localStorage.clear();
    this.ability.clearAbilities();
    localStorage.setItem('locale-user', locale);
    this.router.navigate(['/login']);
  }

  logoutExpired() {
    this.token = '';
    this.user = null;
    let locale = localStorage.getItem('locale-user');
    localStorage.clear();
    this.ability.clearAbilities();
    localStorage.setItem('locale-user',locale);
    localStorage.setItem("expired","true");
    localStorage.setItem('locale-user',locale);
    this.router.navigate(['/login']);
  }

  guardarTokenStorage( token: string ) {
    localStorage.setItem('token', token );
    this.token = token;
  }

  guardarRefreshTokenStorage( refresh_token: string ) {
    localStorage.setItem('refresh_token', refresh_token );
    this.refresh_token = refresh_token;
  }

  cargarStorageToken() {
    if ( localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
    } else {
      this.token = '';
    }
  }

  cargarMomentLocale() {
    if ( localStorage.getItem('locale-user')) {
      this.perfilService.cargaMomentLocale(localStorage.getItem('locale-user'));
    }
  }

  cargarStorageRefreshToken() {
    if ( localStorage.getItem('refresh_token')) {
      this.refresh_token = localStorage.getItem('refresh_token');
    } else {
      this.refresh_token = '';
    }
  }

  cargarStorageIdentity() {
    if ( localStorage.getItem('identity')) {
      this.user = JSON.parse( localStorage.getItem('identity') );
    } else {
      this.user = null;
    }
  }

  cargarPerfil(): Observable<any> {
    return this.perfilService.cargarUsuario().pipe(
      map ( (user: any) => {
        this.user = user;
        this.guardarStorageIdentity();
        this.actualizarAbilities();
        return true;
      })
    );
  }

  setEmpresa(empresaId: string) {
    const empresa = {empresa_id: empresaId};
    this.perfilService.cambiarEmpresa(empresa).subscribe(
      user => {
        this.user = user;
        this.guardarStorageIdentity();
        this.dataShareService.clearAll();
        this.store.dispatch(new CleanState())
        this.router.navigate(['/home'], { queryParams: { companyId: empresaId } });
      },
      error => this.toastr.error('No se ha podido cambiar la empresa!')
    );
  }

  guardarStorageIdentity() {
    localStorage.setItem('identity', JSON.stringify(this.user.toJSON()) );
  }

  olvidoContraseña(payload: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.urlApi + 'auth/password-forgot', payload, {headers});
  }

  cambioContrasena(email: string, first: string, second: string, token: string, gToken: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {email, password: {first, second}, token, gToken};
    return this.http.post(environment.urlApi + 'auth/password-reset', data, {headers});
  }

  isTokenExpired(): boolean{
    if(this.token){
      this.tokenData = jwt_decode(this.token);
      return (new Date()).getTime()>(this.tokenData.exp*1000);
    }else{
      return false;
    }
  }


  actualizarAbilities() {
    if(this.hasIdentity()){
      this.ability.updateAbility(this.user.roles,this.user.acciones);
    }
  }

  getTokenData(): any{
    if(localStorage.getItem('token')!==null){
      return jwt_decode(this.token);
    }else{
      return null;
    }
  }

  hasExpiredSetting(){
    return (localStorage.getItem("expired")!==null);
  }

  hasIdentity(){
    return (localStorage.getItem("identity")!==null);
  }

  clearExpiredSetting(){
    return localStorage.removeItem("expired");
  }

  isAuth(){
    return (localStorage.getItem('token')!==null && this.isLive())
  }

  isLive(){
    if(this.getTokenData()===null) return false;
    let timestampExpire = (this.getTokenData().exp*1000);
    let currentTime = (new Date()).getTime()
    let liveSeconds = (timestampExpire-currentTime)/1000;
    return liveSeconds>0;
  }

  get user() {
    return this._user;
  }

  set user(user: User) {
    this._user = user;
  }

  get worker() {
    return this._worker;
  }

  set worker(worker: Worker) {
    this._worker = worker;
  }

}
