import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipologiaEspecialService {

    public tipologiaEspeciales: {id: string, nombre: string, lang_resource: string}[];

    constructor(
      public http: HttpClient,
    ) {
    }

    cargarTipologiaEspeciales(): Observable <string[]> {
      return this.http.get(environment.urlApi + 'tipologias-especiales')
        .pipe(
          map((resp: any) => {
            return resp?.data?.map(tipologiaEspecial => tipologiaEspecial.attributes.nombre);
          })
        );
    }
}
