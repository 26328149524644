<div class="container-fluid container-p" >
    <h1 class="mb-4">Guía de estilos y componentes</h1>
    <div class="row">
        <div class="col-sm-3">
            <div class="card-pop">
                <div class="card-header">
                    Titulos
                </div>
                <div class="card-body">
                    <h1>Titulo H1</h1>
                    <h2>Titulo H2</h2>
                    <h3>Titulo H3</h3>
                    <h4>Titulo H4</h4>
                    <h5>Titulo H5</h5>
                    <h6>Titulo H6</h6>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card-pop">
                <div class="card-header">Campos informativos</div>
                <div class="card-body">
                    <div class="form-group">
                        <label class="field-title text-sm">Campo 1</label>
                        <label class="field-value text-sm">Valor 1</label>
                    </div>
                    <div class="form-group">
                        <label class="field-title text-md">Campo 1</label>
                        <label class="field-value text-md">Valor 1</label>
                    </div>
                    <div class="form-group">
                        <label class="field-title text-lg">Campo 1</label>
                        <label class="field-value text-lg">Valor 1</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card-pop">
                <div class="card-header">Botones</div>
                <div class="card-body text-center">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="d-block mb-2">
                                <button class="boton-pop boton-pop-primario boton-pop-lg">Primario</button> <span class="field-value text-sm">(tamaño LG)</span>
                            </div>
                            <div class="d-block mb-2">
                                <button class="boton-pop boton-pop-primario boton-pop-md">Primario</button> <span class="field-value text-sm">(tamaño MD)</span>
                            </div>
                            <div class="d-block mb-2">
                                <button class="boton-pop boton-pop-primario boton-pop-sm">Primario</button> <span class="field-value text-sm">(tamaño SM)</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="d-block mb-2">
                                <button class="boton-pop boton-pop-secundario boton-pop-lg">Secundario</button> <span class="field-value text-sm">(tamaño LG)</span>
                            </div>
                            <div class="d-block mb-2">
                                <button class="boton-pop boton-pop-secundario boton-pop-md">Secundario</button> <span class="field-value text-sm">(tamaño MD)</span>
                            </div>
                            <div class="d-block mb-2">
                                <button class="boton-pop boton-pop-secundario boton-pop-sm">Secundario</button> <span class="field-value text-sm">(tamaño SM)</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="d-block mb-2">
                                <button class="boton-pop boton-pop-circular boton-pop-lg"><i class="fa fa-info"></i></button>
                                <span class="field-value text-sm">(tamaño LG)</span>
                            </div>
                            <div class="d-block mb-2">
                                <button class="boton-pop boton-pop-circular boton-pop-md"><i class="fa fa-info"></i></button>
                                <span class="field-value text-sm">(tamaño MD)</span>
                            </div>
                            <div class="d-block mb-2">
                                <button class="boton-pop boton-pop-circular boton-pop-sm"><i class="fa fa-info"></i></button>
                                <span class="field-value text-sm">(tamaño SM)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card-pop">
                <div class="card-header">Tablas</div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table tabla-pop">
                            <thead>
                                <tr>
                                    <th>Nombre <i class="fa fa-sort"></i></th>
                                    <th>Apellido <i class="fa fa-sort"></i></th>
                                    <th>Numero <i class="fa fa-sort"></i></th>
                                    <th>Edad <i class="fa fa-sort"></i></th>
                                    <th align="right">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="tr-subtitle">
                                    <td colspan="5">Sub-Titulo</td>
                                </tr>
                                <tr>
                                    <td>Juan</td>
                                    <td>Blanco</td>
                                    <td>27</td>
                                    <td>40</td>
                                    <td>
                                        <div class="btn-group" role="group">
                                            <button type="button" class="boton-pop-acciones dropdown-toggle" data-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item">Accion 1</a>
                                                <a class="dropdown-item">Accion 2</a>
                                                <a class="dropdown-item">Accion 3</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Paz</td>
                                    <td>Correa</td>
                                    <td>12</td>
                                    <td>44</td>
                                    <td>
                                        <div class="btn-group" role="group">
                                            <button type="button" class="boton-pop-acciones dropdown-toggle" data-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item">Accion 1</a>
                                                <a class="dropdown-item">Accion 2</a>
                                                <a class="dropdown-item">Accion 3</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pedro</td>
                                    <td>Robles</td>
                                    <td>42</td>
                                    <td>50</td>
                                    <td>
                                        <div class="btn-group" role="group">
                                            <button type="button" class="boton-pop-acciones dropdown-toggle" data-toggle="dropdown"><i class="fa fa-ellipsis-v"></i></button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item">Accion 1</a>
                                                <a class="dropdown-item">Accion 2</a>
                                                <a class="dropdown-item">Accion 3</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card-pop">
                <div class="card-header">Panel</div>
                <div class="card-body" style="background-color: #F8F8F8">
                    <div class="panel" style="width: 100%;">
                        <div class="d-block text-center pb-2">
                            <img alt="test-imagen" class="rounded img-fluid w-50" src="assets/img/placeholder.png">
                        </div>
                        <div class="d-block text-justify">
                            <p>orem ipsum dolor sit amet, consectetur adipiscing elit. Cras mollis dignissim turpis. Etiam scelerisque blandit elementum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card-pop">
                <div class="card-header">Cards</div>
                <div class="card-body">
                    <div class="card">
                        <div class="card-header">Titulo</div>
                        <div class="card-body">
                            Corem ipsum dolor sit amet, consectetur adipiscing elit. Cras mollis dignissim turpis. Etiam scelerisque blandit elementum.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card-pop">
                <div class="card-header">Wizard</div>
                <div class="card-body"></div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card-pop">
                <div class="card-header">Tabs</div>
                <div class="card-body">
                    <ul class="nav">
                        <li class="nav-item">
                            <a class="nav-link active" aria-current="page" href="#">Active</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Link</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Link</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="card-pop">
                <div class="card-header">Backgrounds & Textos</div>
                <div class="card-body"></div>
            </div>
        </div>
    </div>
</div>
