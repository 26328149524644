import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_ROUTES } from './app.routes';

// Store
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { HomeReducer } from './store/reducers/home'

// Temporal
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// Servicios
import { ServiceModule } from './services/service.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RecoveryComponent } from './login/recovery.component';
import { RegistroComponent } from './login/registro.component';
import { ContrasenaComponent } from './login/contrasena.component';

// Interceptors
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';

// Translations
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';

import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';

import { PagesComponent } from './pages/pages.component';
import { GoogleRecaptchaDirective } from './pages/directives/google-recaptcha.directive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsReducer } from './store/reducers/globals';
import { GooglePlacesModule } from './shared/google-places/google-places.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistroComponent,
    RecoveryComponent,
    ContrasenaComponent,
    PagesComponent,
    GoogleRecaptchaDirective
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    APP_ROUTES,
    HttpClientModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ServiceModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right'
    }),
    StoreModule.forRoot({
      home: HomeReducer,
      globals: GlobalsReducer
    }),
    StoreDevtoolsModule.instrument({
      logOnly: true, // Cambiar para producción a true
    }),
    AbilityModule,
    ServiceWorkerModule.register('/assets/js/service-worker/ngsw-worker.js'),
    MatCheckboxModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('/assets/js/service-worker/ngsw-worker.js'),
    MatCheckboxModule,
    NgbModule,
    GooglePlacesModule
  ],
  exports: [],
  providers: [
    ServiceWorker,
    { provide: MAT_DATE_LOCALE, useValue: 'es-cl' },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
