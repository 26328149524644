import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rut'
})
export class RutPipe implements PipeTransform {

  transform(val: string): string {
    if (!val) {
        return '';
    }
    if (val.indexOf('-') !== -1) {
        val = val.split('.').join('');
        val = val.split('-').join('');
    }
    const dv = val.substr(val.length - 1);
    const cuerpo = val.substr(0, val.length - 1).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return cuerpo + '-' + dv;
  }
}
