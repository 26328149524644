import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from 'src/app/services/service.index';
import { AuthService } from '../services/service.index';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent implements OnInit {

  public clicked: boolean;
  public error: boolean;
  public errorMsg: string;
  private isMobileResolution: boolean;
  private hash: string;

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public modalService: ModalService,
    public dialog: MatDialog,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.clicked = false;
    this.checkResolution(window.innerWidth);
    this.hash = this.activatedRoute.snapshot.paramMap.get('hash');
    this.errorMsg = '';
    this.error = false;
  }

  enviar(formReset: NgForm) {
    this.clicked = true;
    this.error = false;
    if ( formReset.invalid ) {
      this.clicked = false;
      return;
    }
    if (formReset.value.email && formReset.value.first && formReset.value.second) {
      if (formReset.value.first !== formReset.value.second) {
        this.error = true;
        this.errorMsg = 'Contraseñas deben ser identicas';
        this.clicked = false;
      } else {
        this.cambiarPass(formReset.value.email, formReset.value.first, formReset.value.second, formReset.value.gToken);
      }
    } else {
      this.error = true;
      this.clicked = false;
      this.errorMsg = 'Dede completar todos los campos';
    }
  }

  cambiarPass(email: string, first: string, second: string, gToken: string) {
    this.authService.cambioContrasena(email, first, second, this.hash, gToken)
    .subscribe((resp: any) => {
        if (resp.data) {
          this.toastr.info('Contraseña guardada con exito!');
          this.router.navigate(['/login']);
        } else {
          if (resp.errors) {
            resp.errors.forEach(error => {
              this.toastr.error(error.detail)
            })
          } else {
            this.toastr.error('Algo no anda bien! Por favor contacte al administrador');
          }
        }
        this.clicked = false;
      },
      error => {
        this.toastr.error('Algo no anda bien! Por favor contacte al administrador');
        this.clicked = false;
      }
    );
  }

  public getIsMobileResolution(): boolean {
    return this.isMobileResolution;
  }

  public checkResolution(innerWidth) {
    if (innerWidth <= 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  onResize(event: any) {
    this.checkResolution(event.target.innerWidth);
  }

}
