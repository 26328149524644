import { Pipe, PipeTransform } from '@angular/core';
import { PerfilService } from '../services/auxiliar/perfil.service';

@Pipe({
  name: 'numero'
})
export class NumeroPipe implements PipeTransform {

  constructor(private perfil: PerfilService) { }

    transform(val: string): number {
        const value = val.replace(this.regexp, '');
        if (isNaN(+value)) {
            return +value.replace(/[^\d-]/g, '');
        }
        return +value;
    }

    get decimalSeparator() {
        const numberWithDecimalSeparator = 1.1;
        return numberWithDecimalSeparator.toLocaleString(this.perfil.locale).substring(1, 2);
    }

    get regexp() {
      switch (this.decimalSeparator) {
        case '.':
            return /[^\d.-]/g;
        case ',':
            return /[^\d,-]/g;
      }
    }

    abbreviateNumber(numero: number, decimales: number): string {
      if (!numero) {
        return '0';
      }
      if (numero < 1000) {
        return numero.toString();
      }
      const exp = Math.floor(Math.log10(numero) / 3);
      const abreviacion = ['', 'K', 'M', 'B', 'T'];
      return (numero / Math.pow(1000, exp)).toFixed(decimales) + abreviacion[exp];
    }
}
