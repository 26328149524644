import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbilityService } from 'src/app/services/ability/ability.service';
import { AuthService, ModalService, PerfilService } from 'src/app/services/service.index';
import { languagesList } from '../lenguaje/lenguaje.component';
import { LanguageValue } from 'src/app/models/lenguaje.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isOpenUserMenu:boolean = false
  isOpenCompanyMenu:boolean = false
  isOpenLanguageMenu:boolean = false
  companiesToShow = []
  typingTimer: ReturnType<typeof setTimeout>
  selectedLanguage: LanguageValue
  languages: LanguageValue[] = languagesList

  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("userMenuRef") userMenuRef: ElementRef<HTMLInputElement>;
  @ViewChild("userMenuButton") userMenuButton: ElementRef<HTMLInputElement>;
  @HostListener('document:mousedown', ['$event'])

  onGlobalClick(event): void {
    if (!this.userMenuRef?.nativeElement.contains(event.target) && !this.userMenuButton?.nativeElement.contains(event.target) && this.isOpenUserMenu) {
      this.isOpenUserMenu = false
      this.isOpenCompanyMenu = false
      this.isOpenLanguageMenu = false
    }
  }

  constructor(
    public authService: AuthService,
    public ability: AbilityService,
    public perfilService: PerfilService,
    public modalService: ModalService,
    private translate: TranslateService,
  ) {
    this.languages = languagesList;
    this.setLanguage(this.currentLanguage || this.languages[0].key);
  }

  ngOnInit() {}

  addMenuClass() {
    const sidebar = document.getElementById('sidebar-wrapper');
    if (!sidebar.hasAttribute('focus')) {
      sidebar.focus();
      sidebar.setAttribute('focus', '');
    } else {
      sidebar.blur();
      sidebar.removeAttribute('focus');
    }
  }

  setEmpresa(empresaId: string) {
    this.authService.setEmpresa(empresaId);
    this.isOpenUserMenu = false
    this.isOpenCompanyMenu = false
    this.isOpenLanguageMenu = false
  }

  toggleOpenUserMenu = () => {
    this.isOpenUserMenu = !this.isOpenUserMenu
  }

  toggleOpenCompanyMenu = () => {
    if ( this.user.empresasAsociadas.length > 1) {
      this.isOpenCompanyMenu = !this.isOpenCompanyMenu
      this.companiesToShow = this.user.empresasAsociadas
    }
  }

  toggleOpenLanguageMenu = () => {
    this.isOpenLanguageMenu = !this.isOpenLanguageMenu
  }

  filterCompanies = (name: string) => {
    clearTimeout(this.typingTimer);
    const nameLC: string = name.toLowerCase()
    this.typingTimer = setTimeout(() => {
      this.companiesToShow = this.user.empresasAsociadas.filter(company =>
        company.nombre.toLowerCase().includes(nameLC)
      )
    }, 200);
  }

  setLanguage = (language: string) => {
    localStorage.setItem('locale-user',language);
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    this.selectedLanguage = languagesList.find(e => e.key === this.currentLanguage)
  }

  changeLanguage = (language: string) => {
    if(this.ability.hasAccion('UserCambiaIdioma')){
      this.perfilService.cambiarIdioma(language).subscribe((res) => {
        this.setLanguage(res.data.attributes.locale)
      });
    }else{
      this.modalService.notificacion("Usted no tiene permisos para cambiar idioma",'error');
    }
  }

  get user() {
    return this.authService.user;
  }

  get currentLanguage(){
    return localStorage.getItem('locale-user');
  }
}
