import {Injectable} from "@angular/core";
import {Adapter} from "./adapter";
import {Pet} from "../models/pet.model";

@Injectable({
  providedIn: 'root'
})
export class MascotaAdapter implements Adapter<Pet> {
  constructor() {
  }


  adapt(item: any): Pet {
    return {
      description: item.attributes.descripcion,
      isActive: item.attributes.is_active,
      name: item.attributes.nombre,
      petType: item.attributes.animal_tipo_id,
      petTypeName: item.attributes.animal_tipo.lang_resource,
      petId: item.attributes.id,
      size: item.attributes.tamanio_id,
      sizeName: item.attributes.mascota_tamanio.lang_resource
    }
  }
}
