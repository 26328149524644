import { CargoClasificacion } from './cargoClasificacion.model';
import { Concepto } from './concepto.model';
export class DescuentoRecargoConfiguracion {

    private _id?: string;
    private _propiedadId: string;
    private _nombre: string;
    private _conceptoId: number;
    private _cargoClasificacionId?: number;
    private _tipoId: number;
    private _montoTipoId: number;
    private _monto: number;
    private _divisaId: string;
    private _cicloCantidad: number;
    private _cicloOpcion: boolean;
    private _cicloFecha: any;
    private _descuentoRecargoConceptoAplicaId: number;
    private _cuentaRecaudadoraId: string;
    private _isActivo?: boolean;
    private _montoTotal?: number;
    private _isAfectoImpuesto?: boolean;
    private _isFacturableInt: boolean;
    private _createdAt?: string;
    private _createdBy?: string;

    private _concepto: Concepto;
    private _cargoClasificacion?: CargoClasificacion;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get propiedadId(): string {
        return this._propiedadId;
    }
    public set propiedadId(value: string) {
        this._propiedadId = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get conceptoId(): number {
        return this._conceptoId;
    }
    public set conceptoId(value: number) {
        this._conceptoId = value;
    }
    public get cargoClasificacionId(): number {
        return this._cargoClasificacionId;
    }
    public set cargoClasificacionId(value: number) {
        this._cargoClasificacionId = value;
    }
    public get tipoId(): number {
        return this._tipoId;
    }
    public set tipoId(value: number) {
        this._tipoId = value;
    }
    public get montoTipoId(): number {
        return this._montoTipoId;
    }
    public set montoTipoId(value: number) {
        this._montoTipoId = value;
    }
    public get monto(): number {
        return this._monto;
    }
    public set monto(value: number) {
        this._monto = value;
    }
    get divisaId(): string {
        return this._divisaId;
    }
    set divisaId(value: string) {
        this._divisaId = value;
    }
    public get cicloCantidad(): number {
        return this._cicloCantidad;
    }
    public set cicloCantidad(value: number) {
        this._cicloCantidad = value;
    }
    public get cicloOpcion(): boolean {
        return this._cicloOpcion;
    }
    public set cicloOpcion(value: boolean) {
        this._cicloOpcion = value;
    }
    public get cicloFecha(): any {
        return this._cicloFecha;
    }
    public set cicloFecha(value: any) {
        this._cicloFecha = value;
    }
    public get descuentoRecargoConceptoAplicaId(): number {
        return this._descuentoRecargoConceptoAplicaId;
    }
    public set descuentoRecargoConceptoAplicaId(value: number) {
        this._descuentoRecargoConceptoAplicaId = value;
    }
    public get cuentaRecaudadoraId(): string {
        return this._cuentaRecaudadoraId;
    }
    public set cuentaRecaudadoraId(value: string) {
        this._cuentaRecaudadoraId = value;
    }
    public get isActivo(): boolean {
        return this._isActivo;
    }
    public set isActivo(value: boolean) {
        this._isActivo = value;
    }
    public get montoTotal(): number {
        return this._montoTotal;
    }
    public set montoTotal(value: number) {
        this._montoTotal = value;
    }
    public get isAfectoImpuesto(): boolean {
        return this._isAfectoImpuesto;
    }
    public set isAfectoImpuesto(value: boolean) {
        this._isAfectoImpuesto = value;
    }
    public get isFacturableInt(): boolean {
        return this._isFacturableInt;
    }
    public set isFacturableInt(value: boolean) {
        this._isFacturableInt = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }


    public get concepto(): Concepto {
        return this._concepto;
    }
    public set concepto(value: Concepto) {
        this._concepto = value;
    }
    public get cargoClasificacion(): CargoClasificacion {
        return this._cargoClasificacion;
    }
    public set cargoClasificacion(cargoClasificacion: CargoClasificacion) {
        this._cargoClasificacion = cargoClasificacion;
    }
}
