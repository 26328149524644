import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import {EjecutivoInterface, Reverso} from '../models/reverso.model';
import { ContactoAdapter } from './contacto.adapter';
import { MotivoReversoAdapter } from './motivo-reverso.adapter';

@Injectable({
    providedIn: 'root'
})

export class ReversoAdapter implements Adapter<Reverso> {

    constructor(
      public contactoAdapter: ContactoAdapter,
      public motivoReversoAdapter: MotivoReversoAdapter
    ) {}

    adapt(item: any): Reverso {
        const reverso = new Reverso();
        reverso.id = item.attributes.id;
        reverso.ejecutivoId = item.attributes.ejecutivo_id;
        reverso.estadoCuentaPagoId = item.attributes.estado_cuenta_pago_id;
        reverso.fecha = item.attributes.fecha;
        reverso.folio = item.attributes.folio;
        reverso.folioNumerico = item.attributes.folio_numerico;
        reverso.monto = item.attributes.monto;
        reverso.motivoReversoId = item.attributes.motivo_reverso_id;
        reverso.comentario = item.attributes.comentario;

        /* Includes */
        if (item.included) {
            item.included.filter((valor) => {
                switch (valor.type) {
                    case 'Ejecutivo':
                        reverso.ejecutivo = {} as EjecutivoInterface;
                        reverso.ejecutivo.id = valor.attributes.id;
                        reverso.ejecutivo.contactoId = valor.attributes.contacto_id;
                        break;
                    case 'Contacto':
                        reverso.contacto = this.contactoAdapter.adapt(valor);
                        break;
                    case 'MotivoReverso':
                        reverso.motivoReverso = this.motivoReversoAdapter.adapt(valor);
                        break;
                }
            });
        }

        return reverso;
    }

}
