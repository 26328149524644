import { ContratoEstado, ContratoUnidad, Divisa, Propiedad, CustodiaDocumento } from './model.index';
import { ContratoContacto } from './contrato-contacto.model';
import { Contacto } from './contacto.model';
import { ContratoConfiguracion } from './contrato-configuracion.model';
import { Resident } from './resident.model'
import { Pet } from './pet.model'
import { Vehicle } from './vehicle.model'
import { ContratoSubEstado } from './contrato-subestado.model';
import {NegocioFirmanteInterface} from "../interfaces/negocio.interface";
import {Reserva} from "./reserva.model";

export class Contrato {

    private _id: string;
    private _folio: string;
    private _fecha: string;
    private _fechaInicio: string;
    private _fechaFin: string;
    private _fechaFinAnticipada: string;
    private _fechaRealTermino: string
    private _ejecutivoId: string;
    private _estadoId: number;
    private _contratoConfiguracionId: string;
    private _contratoTemplateId: string;
    private _observacion: string;
    private _createdAt: string;
    private _createdBy: string;
    private _tocDocumentId: string;
    private _documentoAdjuntoId: string;
    private _documentoBaseAdjuntoId: string;
    private _cotizacionid: string;
    private _isCreatePrimerCobro: boolean;
    private _propiedadId: string;
    private _esHistorico: boolean;
    private _folioNumerico: number;
    private _interesPorDia: boolean;
    private _renovacionCantidad: number;
    private _subEstadoId: number;
    private _reajusteFecha: string;

    private _contratoContacto: ContratoContacto[];
    private _contratoEstado: ContratoEstado;
    private _contratoSubEstado: ContratoSubEstado;
    private _propiedad: Propiedad;
    private _divisa: Divisa;

    private _contratoUnidad: ContratoUnidad[];
    private _custodiaDocumento: CustodiaDocumento[];
    // Titular
    private _titular?: Contacto;
    private _ejecutivo: Contacto;
    private _contratoConfiguracion: ContratoConfiguracion;

    private _residents: Resident[]
    private _pets: Pet[]
    private _vehicles: Vehicle[];
    private _firmantes: NegocioFirmanteInterface[];
    private _reservas: Reserva[];

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get folio(): string {
        return this._folio;
    }
    public set folio(value: string) {
        this._folio = value;
    }
    public get fecha(): string {
        return this._fecha;
    }
    public set fecha(value: string) {
        this._fecha = value;
    }
    public get fechaInicio(): string {
        return this._fechaInicio;
    }
    public set fechaInicio(value: string) {
        this._fechaInicio = value;
    }
    public get fechaFin(): string {
        return this._fechaFin;
    }
    public set fechaFin(value: string) {
        this._fechaFin = value;
    }
    public get fechaFinAnticipada(): string {
        return this._fechaFinAnticipada;
    }
    public set fechaFinAnticipada(value: string) {
        this._fechaFinAnticipada = value;
    }
    public get ejecutivoId(): string {
        return this._ejecutivoId;
    }
    public set ejecutivoId(value: string) {
        this._ejecutivoId = value;
    }
    public get estadoId(): number {
        return this._estadoId;
    }
    public set estadoId(value: number) {
        this._estadoId = value;
    }
    get contratoConfiguracionId(): string {
        return this._contratoConfiguracionId;
    }

    set contratoConfiguracionId(value: string) {
        this._contratoConfiguracionId = value;
    }
    public get contratoTemplateId(): string {
        return this._contratoTemplateId;
    }
    public set contratoTemplateId(value: string) {
        this._contratoTemplateId = value;
    }
    public get observacion(): string {
        return this._observacion;
    }
    public set observacion(value: string) {
        this._observacion = value;
    }
    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    public get tocDocumentId(): string {
        return this._tocDocumentId;
    }
    public set tocDocumentId(value: string) {
        this._tocDocumentId = value;
    }
    public get documentoAdjuntoId(): string {
        return this._documentoAdjuntoId;
    }
    public set documentoAdjuntoId(value: string) {
        this._documentoAdjuntoId = value;
    }
    public get documentoBaseAdjuntoId(): string {
        return this._documentoBaseAdjuntoId;
    }
    public set documentoBaseAdjuntoId(value: string) {
        this._documentoBaseAdjuntoId = value;
    }
    public get cotizacionid(): string {
        return this._cotizacionid;
    }
    public set cotizacionid(value: string) {
        this._cotizacionid = value;
    }
    public get isCreatePrimerCobro(): boolean {
        return this._isCreatePrimerCobro;
    }
    public set isCreatePrimerCobro(value: boolean) {
        this._isCreatePrimerCobro = value;
    }
    public get propiedadId(): string {
        return this._propiedadId;
    }
    public set propiedadId(value: string) {
        this._propiedadId = value;
    }
    public get esHistorico(): boolean {
        return this._esHistorico;
    }
    public set esHistorico(value: boolean) {
        this._esHistorico = value;
    }
    public get folioNumerico(): number {
        return this._folioNumerico;
    }
    public set folioNumerico(value: number) {
        this._folioNumerico = value;
    }
    public get interesPorDia(): boolean {
        return this._interesPorDia;
    }
    public set interesPorDia(value: boolean) {
        this._interesPorDia = value;
    }
    public get renovacionCantidad(): number {
        return this._renovacionCantidad;
    }
    public set renovacionCantidad(value: number) {
        this._renovacionCantidad = value;
    }
    public get subEstadoId(): number {
        return this._subEstadoId;
    }
    public set subEstadoId(value: number) {
        this._subEstadoId = value;
    }

    get reajusteFecha(): string {
        return this._reajusteFecha;
    }

    set reajusteFecha(value: string) {
        this._reajusteFecha = value;
    }

    public get contratoContacto(): ContratoContacto[] {
        return this._contratoContacto;
    }
    public set contratoContacto(value: ContratoContacto[]) {
        this._contratoContacto = value;
    }
    public get contratoEstado(): ContratoEstado {
        return this._contratoEstado;
    }
    public set contratoEstado(value: ContratoEstado) {
        this._contratoEstado = value;
    }
    public get contratoSubEstado(): ContratoSubEstado {
        return this._contratoSubEstado;
    }
    public set contratoSubEstado(value: ContratoSubEstado) {
        this._contratoSubEstado = value;
    }
    public get propiedad(): Propiedad {
        return this._propiedad;
    }
    public set propiedad(value: Propiedad) {
        this._propiedad = value;
    }
    public get divisa(): Divisa {
        return this._divisa;
    }
    public set divisa(value: Divisa) {
        this._divisa = value;
    }

    public get contratoUnidad(): ContratoUnidad[] {
        return this._contratoUnidad;
    }
    public set contratoUnidad(value: ContratoUnidad[]) {
        this._contratoUnidad = value;
    }
    public get contratoUnidadOrderByUnidadTipo(): ContratoUnidad[] {
      return this.contratoUnidad.sort((n1, n2) => n1.unidad.unidadTipo.orden - n2.unidad.unidadTipo.orden);
    }
    public get custodiaDocumento(): CustodiaDocumento[] {
        return this._custodiaDocumento;
    }
    public set custodiaDocumento(value: CustodiaDocumento[]) {
        this._custodiaDocumento = value;
    }
    public get titular(): Contacto {
        return this._titular;
    }
    public set titular(value: Contacto) {
        this._titular = value;
    }
    public get ejecutivo(): Contacto {
        return this._ejecutivo;
    }
    public set ejecutivo(value: Contacto) {
        this._ejecutivo = value;
    }

    get contratoConfiguracion(): ContratoConfiguracion {
        return this._contratoConfiguracion;
    }

    set contratoConfiguracion(value: ContratoConfiguracion) {
        this._contratoConfiguracion = value;
    }

    public get residents(): Resident[] {
        return this._residents
    }
    public set residents(value: Resident[]) {
        this._residents = value;
    }

    public get pets(): Pet[] {
        return this._pets
    }
    public set pets(value: Pet[]) {
        this._pets = value;
    }

    public get vehicles(): Vehicle[] {
        return this._vehicles
    }
    public set vehicles(value: Vehicle[]) {
        this._vehicles = value;
    }

    get firmantes(): NegocioFirmanteInterface[] {
        return this._firmantes;
    }

    set firmantes(value: NegocioFirmanteInterface[]) {
        this._firmantes = value;
    }
    public get fechaRealTermino(): string {
        return this._fechaRealTermino;
    }
    public set fechaRealTermino(value: string) {
        this._fechaRealTermino = value;
    }

    get reservas(): Reserva[] {
        return this._reservas;
    }

    set reservas(value: Reserva[]) {
        this._reservas = value;
    }
}
