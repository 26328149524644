import { Injectable } from '@angular/core';
import { Location } from "@angular/common";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { AbilityService } from '../ability/ability.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {
  previousUrl: string = null;
  currentUrl: string = null;
  subUrlControl: any = {
    'Cotizacion': {
      'CotizacionCreate': /^(\/cotizaciones\/crear)$/
    },
    'Propiedades': {
      'PropiedadCreate': /^(\/propiedades\/crear)$/
    },
    'Unidades': {},
    'Clientes': {
      'ContactoUpdate': /^(\/clientes\/)+([a-zA-Z0-9-]{36})+(\/editar)$/
    },
    'Balance': {
      'EstadoCuentaUpdate': /^(\/balances\/)+([a-zA-Z0-9-]{36})+(\/editar)$/
    },
    'Checkstate': {
      'CheckInCreate': /^(\/checkin\/agregar)$/,
      'CheckOutCreate': /^(\/checkout\/agregar)$/,
      'CheckUnidadUpdate': /^(\/checkin\/editar)$/
    },
    'Publicaciones': {
      'PublicacionCreate':  /^(\/publicaciones\/crear)$/,
      'PublicacionRead':  /^(\/publicaciones\/detalle)$/,
    },
    'Residentes': {},
    'Evaluaciones': {
      'ContactoEvaluacionCreate': /^(\/evaluaciones\/crear)$/
    },
    'Contactos': {},
    'Contratos': {
      'ContratoCreate': /^(\/contratos\/crear)$/,
      'ContratoPatch': /^(\/contratos\/)+([a-zA-Z0-9-]{36})+(\/editar)$/,
      'ContratoRead': /^(\/contratos\/)+([a-zA-Z0-9-]{36})+(\/ver)$/,
    },
    'Notificaciones':{},
    'EspaciosComunes':{},
    'Anexos':{},
    'Bitacora':{
      'BitacoraCreate': /^(\/bitacora\/crear)$/,
      'BitacoraRead': /^(\/bitacora\/detalle)$/,
    }
  }

  constructor(
    public authService: AuthService,
    public router: Router,
    public toastr: ToastrService,
    private location: Location,
    private ability: AbilityService
  ) {
    router.events.subscribe((val) => {
        if(val instanceof NavigationEnd && this.authService.token){
          this.previousUrl = this.currentUrl;
          this.currentUrl = val.url;
          if(!this.validateModule()){
            this.toastr.error("Usted no tiene permisos para realizar esta acción");
            if(this.previousUrl){
              this.router.navigate([this.previousUrl]);
            }else{
              this.router.navigate(['/home']);
            }
          }
        }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.token) {
      if(!this.authService.isAuth()){
        this.authService.logoutExpired();
        return false;
      }else{
        let canGo = this.validateModule()
        if(!canGo){
          this.router.navigate(['/home']);
        }else{
          return true;
        }
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }


  validateModule(): boolean {
    let canGo = true;
    const url = this.location.path();
    if(url.startsWith('/propiedades')){
      canGo = this.ability.canViewPropiedades() && this.validateSubActions('Propiedades');
    }else if (url.startsWith('/unidades')){
      canGo = this.ability.canViewUnidades() && this.validateSubActions('Unidades');
    }else if (url.startsWith('/cotizaciones')){
      canGo = this.ability.canViewCotizaciones() && this.validateSubActions('Cotizacion');
    }else if (url.startsWith('/clientes')){
      canGo = this.ability.canViewClientes() && this.validateSubActions('Clientes');
    }else if (url.startsWith('/balances')){
      canGo = this.ability.canViewBalance() && this.validateSubActions('Balance');
    }else if (url.startsWith('/checkestate') || url.startsWith('/checkin') || url.startsWith('/checkout')){
      canGo = this.ability.canViewCheckstate() && this.validateSubActions('Checkstate');
    }else if (url.startsWith('/publicaciones')){
      canGo = this.ability.canViewPublicaciones() && this.validateSubActions('Publicaciones');
    }else if (url.startsWith('/residentes')){
      canGo = this.ability.canViewResidentes() && this.validateSubActions('Residentes');
    }else if (url.startsWith('/evaluaciones')){
      canGo = this.ability.canViewEvaluaciones() && this.validateSubActions('Evaluaciones');
    }else if (url.startsWith('/clientes')){
      canGo = this.ability.canViewContactos() && this.validateSubActions('Contactos');
    }else if (url.startsWith('/contratos')){
      canGo = this.ability.canViewContratos() && this.validateSubActions('Contratos');
    }else if (url.startsWith('/configuraciones')){
      canGo = this.ability.canViewPropiedadConfigura();
    }else if (url.startsWith('/notificaciones')){
      canGo = this.ability.canViewConfiguraNotificaciones() && this.validateSubActions('Notificaciones');
    }else if (url.startsWith('/espacios-comunes')){
      canGo = this.ability.canViewEspaciosComunes() && this.validateSubActions('EspaciosComunes');
    }else if (url.startsWith('/anexos')){
      canGo = this.ability.canViewAnexos() && this.validateSubActions('Anexos');
    }else if (url.startsWith('/bitacora')){
      canGo = this.ability.canViewBitacora() && this.validateSubActions('Bitacora');
    }
    return canGo;
  }

  validateSubActions(module): boolean {
    /* URL sin parametros */
    const url = this.location.path().split('?')[0];
    let canGo = true;
    if(this.subUrlControl[module]){
      for (const permiso in this.subUrlControl[module]) {
        const urlControl = this.subUrlControl[module][permiso];
        if(url.match(urlControl) && !this.ability.hasAccion(permiso)){
          canGo = false;
          break;
        }
      }
    }
    return canGo;
  }
}
