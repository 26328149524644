import {ContratoAnexoUnidad} from './contrato-anexo-unidad.model';

export class ContratoAnexoUnidadDetalle {
  private _id: string;
  private _cargoClasificacionId: number;
  private _cicloCantidad: number;
  private _cicloFecha: any;
  private _cicloOpcion: boolean;
  private _conceptoId: number;
  private _contratoAnexoUnidadId: string;
  private _cuentaRecaudadoraId: string;
  private _divisaId: string;
  private _fechaInicio: string;
  private _fechaTermino: string;
  private _isAfectoImpuesto: boolean;
  private _isFacturableInt: boolean;
  private _monto: number;
  private _montoTipoId: number;
  private _montoTotal: string;
  private _nombre: string;
  private _contratoAnexoUnidad: ContratoAnexoUnidad;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get cargoClasificacionId(): number {
    return this._cargoClasificacionId;
  }

  set cargoClasificacionId(value: number) {
    this._cargoClasificacionId = value;
  }

  get cicloCantidad(): number {
    return this._cicloCantidad;
  }

  set cicloCantidad(value: number) {
    this._cicloCantidad = value;
  }

  get cicloFecha(): any {
    return this._cicloFecha;
  }

  set cicloFecha(value: any) {
    this._cicloFecha = value;
  }

  get cicloOpcion(): boolean {
    return this._cicloOpcion;
  }

  set cicloOpcion(value: boolean) {
    this._cicloOpcion = value;
  }

  get conceptoId(): number {
    return this._conceptoId;
  }

  set conceptoId(value: number) {
    this._conceptoId = value;
  }

  get contratoAnexoUnidadId(): string {
    return this._contratoAnexoUnidadId;
  }

  set contratoAnexoUnidadId(value: string) {
    this._contratoAnexoUnidadId = value;
  }

  get cuentaRecaudadoraId(): string {
    return this._cuentaRecaudadoraId;
  }

  set cuentaRecaudadoraId(value: string) {
    this._cuentaRecaudadoraId = value;
  }
  get divisaId(): string {
    return this._divisaId;
  }
  set divisaId(value: string) {
    this._divisaId = value;
  }
  get fechaInicio(): string {
    return this._fechaInicio;
  }

  set fechaInicio(value: string) {
    this._fechaInicio = value;
  }

  get fechaTermino(): string {
    return this._fechaTermino;
  }

  set fechaTermino(value: string) {
    this._fechaTermino = value;
  }

  get isAfectoImpuesto(): boolean {
    return this._isAfectoImpuesto;
  }

  set isAfectoImpuesto(value: boolean) {
    this._isAfectoImpuesto = value;
  }

  get isFacturableInt(): boolean {
    return this._isFacturableInt;
  }

  set isFacturableInt(value: boolean) {
    this._isFacturableInt = value;
  }

  get monto(): number {
    return this._monto;
  }

  set monto(value: number) {
    this._monto = value;
  }

  get montoTipoId(): number {
    return this._montoTipoId;
  }

  set montoTipoId(value: number) {
    this._montoTipoId = value;
  }

  get montoTotal(): string {
    return this._montoTotal;
  }

  set montoTotal(value: string) {
    this._montoTotal = value;
  }

  get nombre(): string {
    return this._nombre;
  }

  set nombre(value: string) {
    this._nombre = value;
  }

  get contratoAnexoUnidad(): ContratoAnexoUnidad {
    return this._contratoAnexoUnidad;
  }

  set contratoAnexoUnidad(value: ContratoAnexoUnidad) {
    this._contratoAnexoUnidad = value;
  }
}
