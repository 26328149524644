<section id="recovery" (window:resize)="onResize($event)">
    <!-- CONTAINER UNO -->

    <div class=" container-form-l desktop-form" *ngIf="!getIsMobileResolution()">
        <div class="row m-1">
            <div class="col-lg-6 col-md-6 offset-md-1 logo-img ">
                <img class="logo-login" src="/assets/img/logo_recovery.png" alt="">
            </div>

            <div class=" col-lg-3 col-md-4 logo-img login-form">
                <form ngNativeValidate #formReset="ngForm" (ngSubmit)="enviar(formReset)">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>{{ 'session.reset_password' | translate }}</h2>
                        </div>
                    </div>

                    <div class="input-mail">
                        <label class="login-label">{{ 'session.email' | translate }}</label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="inputGroup-sizing-lg"> <i class="fas fa-envelope"></i></span>
                            </div>
                            <input type="email" class="form-control  input-text" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" placeholder="{{ 'session.email' | translate }}" ngModel name="email" required>
                        </div>

                    </div>

                    <div class="input-pass">
                        <label class="login-label">{{ 'session.password' | translate }} </label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="basic-addon2"><i class="fas fa-lock"></i></span>
                            </div>
                            <input type="password" class="form-control input-text" aria-label="pass" aria-describedby="basic-addon2" placeholder="{{ 'session.password' | translate }}" ngModel name="first" required>
                        </div>
                    </div>

                    <div class="input-pass">
                        <label class="login-label">{{ 'session.confirm_password' | translate }} </label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="basic-addon3"><i class="fas fa-lock"></i></span>
                            </div>
                            <input type="password" class="form-control input-text" aria-label="pass2" aria-describedby="basic-addon3" placeholder="{{ 'session.confirm_password' | translate }}" ngModel name="second" required>
                        </div>
                    </div>

                    <div class="text-center mt-2">
                        <span class="span-error text-warning" >
                            <b *ngIf="error">{{ errorMsg }}</b>
                            &nbsp;
                        </span>
                    </div>

                    <div class="btn-login">
                        <button type="submit" class="btn btn-secondary btn-lg btn-block" [recaptchaForm]="formReset" recaptcha>
                            <span *ngIf="!clicked">{{ 'session.next' | translate }}</span>
                            <mat-progress-spinner style="margin:0 auto;" class="spinner" color="primary" mode="indeterminate" value="50" diameter="25" *ngIf="clicked">
                            </mat-progress-spinner>
                        </button>
                    </div>

                    <label class="back-login" [routerLink]="['/login']">Iniciar Sesión</label>
                </form>
                <hr/>
                <div class="text-center">
                    {{ 'general.language' | translate }}:
                    &nbsp;&nbsp;
                    <app-lenguaje arrow="up"></app-lenguaje>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid container-form-l responsive-form" *ngIf="getIsMobileResolution()">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 logo-img ">
                <img class="logo-login" src="/assets/img/logo.png" alt="">
            </div>

            <div class=" col-lg-3 logo-img login-form">
                <form ngNativeValidate #formReset="ngForm" (ngSubmit)="enviar(formReset)">
                    <div class="row">
                        <div class="col-lg-3">
                            <h2>{{ 'session.reset_password' | translate }}</h2>
                        </div>
                    </div>

                    <div class="input-mail">
                        <label class="login-label">{{ 'session.email' | translate }}</label>
                        <div class="input-group input-group-md ig-responsive">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="inputGroup-sizing-md"> <i class="fas fa-envelope"></i></span>
                            </div>
                            <input type="email" class="form-control  input-text" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-md" placeholder="{{ 'session.email' | translate }}" ngModel name="email" required>
                        </div>
                    </div>

                    <div class="input-pass">
                        <label class="login-label">{{ 'session.password' | translate }} </label>
                        <div class="input-group input-group-md ig-responsive">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="basic-addon2"><i class="fas fa-lock"></i></span>
                            </div>
                            <input type="password" class="form-control input-text" aria-label="pass" aria-describedby="basic-addon2" placeholder="{{ 'session.password' | translate }}" ngModel name="first" required>
                        </div>
                    </div>

                    <div class="input-pass">
                        <label class="login-label">{{ 'session.confirm_password' | translate }} </label>
                        <div class="input-group input-group-md ig-responsive">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="basic-addon3"><i class="fas fa-lock"></i></span>
                            </div>
                            <input type="password" class="form-control input-text" aria-label="pass2" aria-describedby="basic-addon3" placeholder="{{ 'session.confirm_password' | translate }}" ngModel name="second" required>
                        </div>
                    </div>

                    <div class="text-center mt-2">
                        <span class="span-error text-warning" >
                            <b *ngIf="error">{{ errorMsg }}</b>
                            &nbsp;
                        </span>
                    </div>

                    <div class="btn-login">
                        <button type="submit" class="btn btn-secondary btn-md btn-block" [recaptchaForm]="formReset" recaptcha>
                            <span *ngIf="!clicked">{{ 'session.next' | translate }}</span>
                            <mat-progress-spinner style="margin:0 auto;" class="spinner" color="primary" mode="indeterminate" value="50" diameter="25" *ngIf="clicked">
                            </mat-progress-spinner>
                        </button>
                    </div>

                    <label class="back-login" [routerLink]="['/login']">
                        {{ 'session.login' | translate }}
                    </label>
                </form>
                <hr/>
                <div class="text-center">
                    {{ 'general.language' | translate }}:
                    &nbsp;&nbsp;
                    <app-lenguaje arrow="up"></app-lenguaje>
                </div>

            </div>

            <img class="img-squares" src="/assets/img/squares.png" alt="">
            <img class="img-circle" src="/assets/img/inter-1.png" alt="">

        </div>

    </div>
</section>
