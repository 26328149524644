import {AppModel} from './app.model';

export class UnidadEstado extends AppModel{

    public static readonly DISPONIBLE = {id: 100, lang_resource: 'unit_status.available'};
    public static readonly ARRENDADO = {id: 200, lang_resource: 'unit_status.rented'};
    public static readonly RESERVADO = {id: 300, lang_resource: 'unit_status.booked'};
    public static readonly NO_DISPONIBLE = {id: 400, lang_resource: 'unit_status.unavailable'};

    private _id: number;
    private _nombre: string;
    private _lang_resource: string;

    public get id(): number {
        return this._id;
    }
    public set id(value: number) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get lang_resource(): string {
        return this._lang_resource;
    }
    public set lang_resource(value: string) {
        this._lang_resource = value;
    }
}
