import { AdjuntoAdapter } from './adjunto.adapter';
import { CotizacionAdapter } from './cotizacion.adapter';
import { ContactoEvaluacionDetalleAdapter } from './contacto-evaluacion-detalle.adapter';
import { ContactoEvaluacionEstadoAdapter } from './contacto-evaluacion-estado.adapter';
import { ContactoAdapter } from 'src/app/adapter/contacto.adapter';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContactoEvaluacion } from '../models/model.index';

@Injectable({
    providedIn: 'root'
})

export class ContactoEvaluacionAdapter implements Adapter<ContactoEvaluacion> {

    constructor(
        public contactoAdapter: ContactoAdapter,
        public contactoEvaluacionEstadoAdapter: ContactoEvaluacionEstadoAdapter,
        public contactoEvaluacionDetalleAdapter: ContactoEvaluacionDetalleAdapter,
        public cotizacionAdapter: CotizacionAdapter
    ) {}

    adapt(item: any): ContactoEvaluacion {
        const contactoEvaluacion = new ContactoEvaluacion();
        contactoEvaluacion.id = item.id;
        contactoEvaluacion.folio = item.attributes.folio;
        contactoEvaluacion.fecha = item.attributes.fecha;
        contactoEvaluacion.estadoId = item.attributes.estado_id;
        contactoEvaluacion.contactoId = item.attributes.contacto_id;

        /* Includes*/
        if (item.included) {
            item.included.filter((valor) => {
                if (valor.type === 'Contacto') {
                    contactoEvaluacion.contacto = this.contactoAdapter.adapt(valor);
                } else if (valor.type === 'ContactoEvaluacionEstado') {
                    contactoEvaluacion.contactoEvaluacionEstado = this.contactoEvaluacionEstadoAdapter.adapt(valor);
                }
            });
        }

        /* Relationship */
        if (item.relationship) {
            if (item.relationship.contactoEvaluacionDetalles) {
                contactoEvaluacion.contactoEvaluacionDetalles = item.relationship.contactoEvaluacionDetalles.data
                .map(contactoEvaluacionDetalle => this.contactoEvaluacionDetalleAdapter.adapt(contactoEvaluacionDetalle));
            }
            if (item.relationship.cotizaciones) {
                contactoEvaluacion.cotizaciones = item.relationship.cotizaciones.data
                .map(cotizacion => this.cotizacionAdapter.adapt(cotizacion));
            }
        }
        return contactoEvaluacion;
    }
  }
