export class UnidadDivisaMonto {
  private _id: string
  private _conceptoId: number;
  private _divisaId: string;
  private _monto: number
  private _tipoId: number
  private _unidadId: string;

  get conceptoId(): number {
    return this._conceptoId;
  }

  set conceptoId(value: number) {
    this._conceptoId = value;
  }

  get divisaId(): string {
    return this._divisaId;
  }

  set divisaId(value: string) {
    this._divisaId = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get monto(): number {
    return this._monto;
  }

  set monto(value: number) {
    this._monto = value;
  }

  get tipoId(): number {
    return this._tipoId;
  }

  set tipoId(value: number) {
    this._tipoId = value;
  }

  get unidadId(): string {
    return this._unidadId;
  }

  set unidadId(value: string) {
    this._unidadId = value;
  }
}
