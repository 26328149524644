import {User} from "./user.model";
import {Propiedad} from "./propiedad.model";

export class LiquidacionPeriodoOrquestador {
  private _id: string;
  private _propiedadId: string;
  private _periodo: string;
  private _ejecutivoId: string;
  private _estado: string;

  private _propiedad: Propiedad;
  private _ejecutivo: User;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get propiedadId(): string {
    return this._propiedadId;
  }

  set propiedadId(value: string) {
    this._propiedadId = value;
  }

  get periodo(): string {
    return this._periodo;
  }

  set periodo(value: string) {
    this._periodo = value;
  }

  get ejecutivoId(): string {
    return this._ejecutivoId;
  }

  set ejecutivoId(value: string) {
    this._ejecutivoId = value;
  }

  get estado(): string {
    return this._estado;
  }

  set estado(value: string) {
    this._estado = value;
  }

  get propiedad(): Propiedad {
    return this._propiedad;
  }

  set propiedad(value: Propiedad) {
    this._propiedad = value;
  }

  get ejecutivo(): User {
    return this._ejecutivo;
  }

  set ejecutivo(value: User) {
    this._ejecutivo = value;
  }
}
