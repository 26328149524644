<mat-form-field appearance="outline" class="full-width datepicker-range-container" [class.hide-ranges]="hideRanges">
    <mat-label>{{ label | translate }}</mat-label>
    <input matInput [formControl]="datepicker" ngxDaterangepickerMd #inputDatepicker
        [ranges]="rangos"
        [locale]="locale"
        [showCustomRangeLabel]="true"
        [alwaysShowCalendars]="true"
        [linkedCalendars]="true"
        [showCancel]="true"
        [showClearButton]="true"
        placeholder="{{ placeholder | translate }}"
        (change)="seleccionarRango()"
        autocomplete="off">
</mat-form-field> 