import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { User } from '../models/model.index';
import { EmpresaAdapter } from './empresa.adapter';
import { ContactoAdapter } from 'src/app/adapter/contacto.adapter';

@Injectable({
    providedIn: 'root'
})

export class UserAdapter implements Adapter<User> {

    constructor(
        public contactoAdapter: ContactoAdapter,
        public empresaAdapter: EmpresaAdapter
    ) {}

    adapt(item: any): User {
        const user = new User();

        user.id = item.attributes.id;
        user.email = item.attributes.email;
        user.contactoId = item.attributes.contacto_id;
        user.createdAt = item.attributes.created_at;
        user.createdBy = item.attributes.created_by;

        /* Relationship */
        if (item.relationship && item.relationship.empresasAsociadas && item.relationship.empresasAsociadas.data) {
            user.empresasAsociadas = item.relationship.empresasAsociadas.data.map(data =>
                this.empresaAdapter.adapt(data)
            );
        }
        if (item.relationship && item.relationship.permisos) {
            user.acciones = item.relationship.permisos.acciones;
            user.roles = item.relationship.permisos.roles;
        }

        /* Includes*/
        if (item.included) {
            item.included.filter((valor) => {
                if (valor.type === 'Contacto') {
                    user.contacto = this.contactoAdapter.adapt(valor);
                }
                if (valor.type === 'Empresa') {
                    user.empresa = this.empresaAdapter.adapt(valor);
                }
            });
        }
        return user;
    }
  }
