import { ModalService } from './../services/modals/modal.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from 'src/app/services/service.index';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

    accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)

    constructor(
        public authService: AuthService,
        public modalService: ModalService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = new HttpHeaders();
        headers.set('Access-Control-Allow-Origin','*');
        request = request.clone({
            headers: headers
        });
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if ([401].indexOf(error.status) !== -1) {
                    if(this.authService.token && !this.authService.isLive()){
                        this.authService.logoutExpired();
                    }
                    return next.handle(request);
                }
                if ([403].indexOf(error.status) !== -1) {
                    this.manejarError('Usted no posee los permisos necesarios para realizar esta petición');
                    return next.handle(request);
                }
                if ([500].indexOf(error.status) !== -1) {
                    this.manejarError('Algo no anda bien, por favor contacte al administrador');
                    return next.handle(request);
                }
                return throwError(error);
            })
        );
    }

    manejarError( mensaje: string ) {
        /* Generar LOG o algo */
        this.modalService.notificacion(mensaje, 'error');
    }
}
