import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransaccionTipo } from 'src/app/models/model.index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { TransaccionTipoAdapter } from 'src/app/adapter/transaccion-tipo.adapter';

@Injectable({
  providedIn: 'root'
})
export class TransaccionTipoService {

  constructor(
    public http: HttpClient,
    public transaccionTipoAdapter: TransaccionTipoAdapter
  ) { }

  cargarTransaccionTipos(): Observable <TransaccionTipo[]> {
    return this.http.get(environment.urlApi + 'transaccionTipo')
        .pipe(
            map( (resp: any) =>
                resp.data.map(transaccionTipos => this.transaccionTipoAdapter.adapt(transaccionTipos))
            )
        );
  }

}
