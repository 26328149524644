import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

export interface ModalActionInterface {
  callback?: Function,
  titulo: string,
  style?: string,
  enabled?: boolean
}

export interface ModalGeneralDataInterface { 
  titulo?: string, 
  cuerpo?: string, 
  actions?: ModalActionInterface[] 
}

@Component({
  selector: 'app-modal-general',
  templateUrl: './modal-general.component.html',
  styleUrls: ['./modal-general.component.css']
})
export class ModalGeneralComponent implements OnInit {

  isEjecutandoAccion = false;

  constructor(
    private dialogRef: MatDialogRef<ModalGeneralComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalGeneralDataInterface
  ) { }

  ngOnInit() { }

  ejecutarAccion(action){
    if(!this.isEjecutandoAccion){
      this.isEjecutandoAccion = true;
      if(action.callback && action.callback instanceof Function){
        let subscription:Subscription = action.callback();
        if(subscription instanceof Subscription){
          subscription.add(()=>{
            this.dialogRef.close();
            this.isEjecutandoAccion = false;
          })
        }else{
          this.dialogRef.close();
          this.isEjecutandoAccion = false;
        }
      }else{
        this.dialogRef.close();
        this.isEjecutandoAccion = false;
      }
    }
  }

  getEnabled(action){
    let enabled = true;
    if(this.isDefined(action.enabled)){
      enabled = action.enabled;
    }
    return enabled;
  }

  isDefined(value) {return typeof value !== 'undefined';}
  
}
