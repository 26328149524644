import { ContratoContactoTipoAdapter } from './contrato-contacto-tipo.adapter';
import { CotizacionContactoAdapter } from './cotizacion-contacto.adapter';
import { ConceptoAdapter } from './concepto.adapter';
import { UnidadAdapter } from './unidad.adapter';
import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { Cotizacion } from '../models/cotizacion.model';
import { ContactoAdapter } from './contacto.adapter';
import { CotizacionEstadoAdapter } from './cotizacion-estado.dapter';
import { CotizacionUnidadAdapter } from './cotizacion-unidad.adapter';
import { CotizacionUnidadDetalleAdapter } from './cotizacion-unidad-detalle.adapter';
import { PropiedadAdapter } from './propiedad.adapter';
import { UnidadTipoAdapter } from './unidad-tipo.adapter';
import { UnidadEstadoAdapter } from './unidad-estado.adapter';
import { PaisAdapter } from './pais.adapter';
import { MedioLlegadaAdapter } from './medio-llegada.adapter';
import { IdentificadorTipoAdapter } from './identificador-tipo.adapter';
import { Contacto } from '../models/model.index';
import {CotizacionConfiguracionAdapter} from "./cotizacion-configuracion.adapter";

@Injectable({
    providedIn: 'root'
})

export class CotizacionAdapter implements Adapter<Cotizacion> {

    constructor(
        public cotizacionEstadoAdapter: CotizacionEstadoAdapter,
        public cotizacionUnidadAdapter: CotizacionUnidadAdapter,
        public cotizacionUnidadDetalleAdapter: CotizacionUnidadDetalleAdapter,
        public unidadAdapter: UnidadAdapter,
        public propiedadAdapter: PropiedadAdapter,
        public unidadTipoAdapter: UnidadTipoAdapter,
        public conceptoAdapter: ConceptoAdapter,
        public unidadEstadoAdapter: UnidadEstadoAdapter,
        public cotizacionContactoAdapter: CotizacionContactoAdapter,
        public contactoAdapter: ContactoAdapter,
        public contratoContactoTipoAdapter: ContratoContactoTipoAdapter,
        public paisAdapter: PaisAdapter,
        public medioLlegadaAdapter: MedioLlegadaAdapter,
        public identificadorTipoAdapter: IdentificadorTipoAdapter,
        public cotizacionConfiguracionAdapter: CotizacionConfiguracionAdapter
    ) {}

    adapt(item: any): Cotizacion {

        const cotizacion = new Cotizacion();
        cotizacion.id = item.attributes.id;
        cotizacion.folio = item.attributes.folio;
        cotizacion.contactoId = item.attributes.contacto_id;
        cotizacion.ejecutivoId = item.attributes.ejecutivo_id;
        cotizacion.estadoId = item.attributes.estado_id;
        cotizacion.fecha = item.attributes.fecha;
        cotizacion.fechaVigencia = item.attributes.fecha_vigencia;
        cotizacion.fechaInicioContrato = item.attributes.fecha_inicio_contrato;
        cotizacion.fechaFinContrato = item.attributes.fecha_fin_contrato;
        cotizacion.diaPago = item.attributes.dia_pago;
        cotizacion.observacion = item.attributes.observacion;
        cotizacion.createdAt = item.attributes.created_at;
        cotizacion.createdBy = item.attributes.created_by;

        /* Includes*/
        if (item.included) {
            item.included.filter((valor) => {
                switch (valor.type) {
                    case 'CotizacionEstado':
                        cotizacion.cotizacionEstado = this.cotizacionEstadoAdapter.adapt(valor);
                        break;
                    case 'Propiedad':
                        cotizacion.propiedad = this.propiedadAdapter.adapt(valor);
                        break;
                    case 'CotizacionConfiguracion':
                        cotizacion.cotizacionConfiguracion = this.cotizacionConfiguracionAdapter.adapt(valor);
                }
            });
        }

        /* Relationship */
        const contactos: Contacto[] = [];
        const paises = [];
        const medioLlegadas = [];
        const identificadorTipos = [];
        const contratoContactoTipos = [];
        if (item.relationship && item.relationship.cotizacionContacto) {
            cotizacion.cotizacionContacto = item.relationship.cotizacionContacto.data.map(data =>
                this.cotizacionContactoAdapter.adapt(data)
            );
            item.relationship.cotizacionContacto.included.filter(included => {
                if (included.type === 'Contacto') {
                    contactos.push(this.contactoAdapter.adapt(included));
                } else if (included.type === 'ContratoContactoTipo') {
                    contratoContactoTipos.push(this.contratoContactoTipoAdapter.adapt(included));
                } else if (included.type === 'Pais') {
                    paises.push(this.paisAdapter.adapt(included));
                } else if (included.type === 'MedioLlegada') {
                    medioLlegadas.push(this.medioLlegadaAdapter.adapt(included));
                } else if (included.type === 'IdentificadorTipo') {
                    identificadorTipos.push(this.identificadorTipoAdapter.adapt(included));
                }
            });
            contactos.forEach(contacto => {
                const findNacionalidad = paises.find(pais => pais.id === contacto.paisIdNacionalidad);
                contacto.nacionalidad = findNacionalidad;
                const findMedio = medioLlegadas.find(medio => medio.id === contacto.medioLlegadaId);
                contacto.medioLlegada = findMedio;
                const findIdentificador = identificadorTipos.find(tipo => tipo.id === contacto.identificadorTipoId);
                contacto.identificadorTipo = findIdentificador;
            });
            cotizacion.cotizacionContacto.forEach(cotizacionContacto => {
                const findContacto = contactos.find(contacto => contacto.id === cotizacionContacto.contactoId);
                cotizacionContacto.contacto = findContacto;
                const findTipo = contratoContactoTipos.find(tipo => tipo.id === cotizacionContacto.contratoContactoTipoId);
                cotizacionContacto.contratoContactoTipo = findTipo;
                // Titular
                if (cotizacionContacto.contratoContactoTipoId === 1 && cotizacionContacto.isTitular) {
                    cotizacion.titular = cotizacionContacto.contacto;
                }
            });
        }
        if (item.relationship && item.relationship.cotizacionUnidad && item.relationship.cotizacionUnidad.data) {
            cotizacion.cotizacionUnidad = item.relationship.cotizacionUnidad.data.map(cotizacionUnidad =>
                this.cotizacionUnidadAdapter.adapt(cotizacionUnidad)
            );
            const unidadTipos = [];
            const unidadEstados = [];
            item.relationship.cotizacionUnidad.included.filter(included => {
                if (included.type === 'Unidad') {
                    const findUnidad = cotizacion.cotizacionUnidad.find(
                        cotizacionUnidad => cotizacionUnidad.unidadId === included.id && !cotizacionUnidad.unidad
                    );
                    findUnidad.unidad = this.unidadAdapter.adapt(included);
                    findUnidad.unidad.propiedad = cotizacion.propiedad;
                } else if (included.type === 'UnidadTipo') {
                    unidadTipos.push(this.unidadTipoAdapter.adapt(included));
                } else if (included.type === 'UnidadEstado') {
                    unidadEstados.push(this.unidadEstadoAdapter.adapt(included));
                }
            });
            cotizacion.cotizacionUnidad.forEach(cotizacionUnidad => {
                const findUnidadTipo = unidadTipos.find(unidaTipo => unidaTipo.id === cotizacionUnidad.unidad.unidadTipoId);
                cotizacionUnidad.unidad.unidadTipo = findUnidadTipo;
                const findUnidadEstado = unidadEstados.find(unidadEstado => unidadEstado.id === cotizacionUnidad.unidad.unidadEstadoId);
                cotizacionUnidad.unidad.unidadEstado = findUnidadEstado;
            });
        }
        if (item.relationship && item.relationship.cotizacionUnidadDetalle && item.relationship.cotizacionUnidadDetalle.data) {
            cotizacion.cotizacionUnidadDetalle = item.relationship.cotizacionUnidadDetalle.data.map(cotizacionUnidadDetalle =>
                this.cotizacionUnidadDetalleAdapter.adapt(cotizacionUnidadDetalle)
            );
            cotizacion.cotizacionUnidadDetalle.forEach(element => {
                const findConcepto = item.relationship.cotizacionUnidadDetalle.included
                .find(concepto => concepto.id === element.conceptoId);
                if (findConcepto) {
                    element.concepto = this.conceptoAdapter.adapt(findConcepto);
                }
                const finCotizacionUnidad = cotizacion.cotizacionUnidad.find(
                    cotizacionUnidad => cotizacionUnidad.id === element.cotizacionUnidadId
                );
                if (finCotizacionUnidad) {
                    element.cotizacionUnidad = finCotizacionUnidad;
                }
            });
        } else {
            cotizacion.cotizacionUnidadDetalle = [];
        }
        return cotizacion;
    }
  }
