import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { CotizacionUnidadDetalle } from './../models/cotizacion-unidad-detalle.model';

@Injectable({
    providedIn: 'root'
  })
  export class CotizacionUnidadDetalleAdapter implements Adapter<CotizacionUnidadDetalle> {
    adapt(item: any): CotizacionUnidadDetalle {
        const cotizacionUnidadDetalle = new CotizacionUnidadDetalle();
        cotizacionUnidadDetalle.id = item.attributes.id;
        cotizacionUnidadDetalle.cotizacionUnidadId = item.attributes.cotizacion_unidad_id;
        cotizacionUnidadDetalle.nombre = item.attributes.nombre;
        cotizacionUnidadDetalle.conceptoId = item.attributes.concepto_id;
        cotizacionUnidadDetalle.cargoClasificacionId = item.attributes.cargo_clasificacion_id;
        cotizacionUnidadDetalle.divisaId = item.attributes.divisa_id;
        cotizacionUnidadDetalle.monto = +item.attributes.monto;
        cotizacionUnidadDetalle.montoTipoId = item.attributes.monto_tipo_id;
        cotizacionUnidadDetalle.descuentoRecargoConfiguracionId = item.attributes.descuento_recargo_configuracion_id;
        cotizacionUnidadDetalle.observacion = item.attributes.observacion;
        cotizacionUnidadDetalle.isAfectoImpuesto = item.attributes.is_afecto_impuesto;
        cotizacionUnidadDetalle.cicloFecha = item.attributes.ciclo_fecha;
        cotizacionUnidadDetalle.cicloCantidad = item.attributes.ciclo_cantidad;
        cotizacionUnidadDetalle.cicloOpcion = item.attributes.ciclo_opcion;
        cotizacionUnidadDetalle.cuentaRecaudadoraId = item.attributes.cuenta_recaudadora_id;
        cotizacionUnidadDetalle.fechaInicio = item.attributes.fecha_inicio;
        cotizacionUnidadDetalle.fechaTermino = item.attributes.fecha_termino;
        cotizacionUnidadDetalle.descuentoRecargoConceptoAplicaId = item.attributes.descuento_recargo_concepto_aplica_id;
        cotizacionUnidadDetalle.montoTotal = +item.attributes.monto_total;
        cotizacionUnidadDetalle.isFacturableInt = item.attributes.is_facturable_int;
        cotizacionUnidadDetalle.createdAt = item.attributes.created_at;
        cotizacionUnidadDetalle.createdBy = item.attributes.created_by;
        return cotizacionUnidadDetalle;
    }
  }
