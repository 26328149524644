import { Directive, Input, OnChanges, SimpleChanges, OnInit, Renderer2, Inject } from '@angular/core';
import { LoaderService } from 'src/app/services/shared/loader.service';
import { DOCUMENT } from '@angular/common';
import { interval, Subscription } from 'rxjs';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[valuesOrSpinner]',
})

export class ValuesOrSpinnerDirective implements OnInit, OnChanges {

  @Input() valuesOrSpinner: boolean;

  public isLoading = false;
  public isActive = false;
  public myIntervalSubscription: Subscription;

  constructor(
    public loaderService: LoaderService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document
  ) { }

  ngOnInit() {
    this.onStartInterval();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.valuesOrSpinner.currentValue) {
        const img: Element = this.renderer.createElement('img');
        this.renderer.setProperty(img, 'src', 'assets/img/loading.gif');
        this.renderer.setStyle(img, 'width', '700px');
        this.renderer.setStyle(img, 'height', '400px');
        const divSpinner: Element = this.renderer.createElement('div');
        this.renderer.addClass(divSpinner, 'spinner');
        const divOverlay: Element = this.renderer.createElement('div');
        this.renderer.addClass(divOverlay, 'div-spinner');
        this.renderer.addClass(divOverlay, 'overlay');
        this.renderer.appendChild(divSpinner, img);
        this.renderer.appendChild(divOverlay, divSpinner);
        this.renderer.appendChild(this.document.body, divOverlay);
    } else {
        this.removeSpinner();
    }
  }

  onStartInterval() {
    this.myIntervalSubscription = interval(1000).subscribe(value => {
      if (value === 12) {
        this.removeSpinner();
      }
    });
  }

  removeSpinner() {
    Array.from(this.document.body.children).forEach((child: Element) => {
        if (child.classList.contains('div-spinner')) {
            this.renderer.removeChild(this.document.body, child);
        }
    });
    if (this.myIntervalSubscription) {
      this.myIntervalSubscription.unsubscribe();
    }
  }

}
