import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { MedioPago } from '../models/model.index';

@Injectable({
    providedIn: 'root'
})

export class MedioPagoAdapter implements Adapter<MedioPago> {
    adapt(item: any): MedioPago {
        const medioLlegada = new MedioPago();
        medioLlegada.id = item.attributes.id;
        medioLlegada.nombre = item.attributes.nombre;
        return medioLlegada;
    }
}
