import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { ContratoContactoTipo } from '../models/model.index';

@Injectable({
    providedIn: 'root'
})

export class ContratoContactoTipoAdapter implements Adapter<ContratoContactoTipo> {

    constructor() {}

    adapt(item: any): ContratoContactoTipo {
        const contratoContactoTipo = new ContratoContactoTipo();
        contratoContactoTipo.id = item.attributes.id;
        contratoContactoTipo.nombre = item.attributes.nombre;
        contratoContactoTipo.lang_resource = item.attributes.lang_resource;
        return contratoContactoTipo;
    }

}
