import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UnidadTipo } from './../../models/unidad-tipo.model';
import { UnidadTipoAdapter } from 'src/app/adapter/unidad-tipo.adapter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnidadTipoService {

  constructor(
    public http: HttpClient,
    private unidadTipoAdapter: UnidadTipoAdapter
  ) { }

  cargarUnidadTipos(): Observable <UnidadTipo[]> {
    return this.http.get(environment.urlApi + 'unidades_tipo')
      .pipe(
        map( (resp: any[any]) =>
          resp.data.map(item => this.unidadTipoAdapter.adapt(item) )
        )
      );
  }

}
