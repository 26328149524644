export class Periodicidad {
    private _id: number;
    private _nombre: string;
    private _formato: string;
    private _descripcion: string;

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get nombre(): string {
        return this._nombre;
    }

    set nombre(value: string) {
        this._nombre = value;
    }

    get formato(): string {
        return this._formato;
    }

    set formato(value: string) {
        this._formato = value;
    }

    get descripcion(): string {
        return this._descripcion;
    }

    set descripcion(value: string) {
        this._descripcion = value;
    }
}
