import { ContratoAnexoEstado } from './contrato-anexo-estado.model';
import { ContratoAnexoTipo } from './contrato-anexo-tipo.model';
import {ContratoUnidadDetalle} from './contrato-unidad-detalle.model';
import {ContratoAnexoUnidad} from './contrato-anexo-unidad.model';
import {ContratoAnexoContacto} from './contrato-anexo-contacto.model';
import {ContratoConfiguracion} from './contrato-configuracion.model';
import {ContratoAnexoUnidadDetalle} from './contrato-anexo-unidad-detalle.model';
import { CustodiaDocumento } from './custodia-documento.model';
import {NegocioFirmanteInterface} from "../interfaces/negocio.interface";

export interface EjecutivoInterface {
    id: string;
}

export interface ContactoInterface {
    apellido: string;
    contactoTipoId: number;
    email: string;
    id: string;
    nombre: string;
}

export class ContratoAnexo {

    private _id: string;
    private _contratoBaseId: string;
    private _contratoId: string;
    private _documentoId: string;
    private _documentoFirmadoId: string;
    private _ejecutivoId: string;
    private _esFirmable: boolean;
    private _estadoId: number;
    private _estadoFirmaId: number;
    private _estadoFirmaNombre: string;
    private _fecha: string;
    private _folio: string;
    private _folioNumerico: number;
    private _templateId: string;
    private _contratoFechaFin: string;
    private _contratoReajusteFecha: string;

    private _ejecutivo: EjecutivoInterface;
    private _contacto: ContactoInterface;
    private _contratoAnexoEstado: ContratoAnexoEstado;
    private _contratoAnexoTipos: ContratoAnexoTipo[];
    private _contratoAnexoUnidad: ContratoAnexoUnidad[];
    private _contratoAnexoContacto: ContratoAnexoContacto[];
    private _contratoAnexoConfiguracion: ContratoConfiguracion;
    private _contratoAnexoCustodiaDocumentos: CustodiaDocumento[];
    private _firmantes: NegocioFirmanteInterface[];

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get contratoBaseId(): string {
        return this._contratoBaseId;
    }
    public set contratoBaseId(value: string) {
        this._contratoBaseId = value;
    }
    public get contratoId(): string {
        return this._contratoId;
    }
    public set contratoId(value: string) {
        this._contratoId = value;
    }
    public get documentoId(): string {
        return this._documentoId;
    }
    public set documentoId(value: string) {
        this._documentoId = value;
    }
    public get documentoFirmadoId(): string {
        return this._documentoFirmadoId;
    }
    public set documentoFirmadoId(value: string) {
        this._documentoFirmadoId = value;
    }
    public get ejecutivoId(): string {
        return this._ejecutivoId;
    }
    public set ejecutivoId(value: string) {
        this._ejecutivoId = value;
    }
    public get esFirmable(): boolean {
        return this._esFirmable;
    }
    public set esFirmable(value: boolean) {
        this._esFirmable = value;
    }
    public get estadoId(): number {
        return this._estadoId;
    }
    public set estadoId(value: number) {
        this._estadoId = value;
    }
    public get estadoFirmaId(): number {
        return this._estadoFirmaId;
    }
    public set estadoFirmaId(value: number) {
        this._estadoFirmaId = value;
    }
    get estadoFirmaNombre(): string {
        return this._estadoFirmaNombre;
    }

    set estadoFirmaNombre(value: string) {
        this._estadoFirmaNombre = value;
    }
    public get fecha(): string {
        return this._fecha;
    }
    public set fecha(value: string) {
        this._fecha = value;
    }
    public get folio(): string {
        return this._folio;
    }
    public set folio(value: string) {
        this._folio = value;
    }
    public get folioNumerico(): number {
        return this._folioNumerico;
    }
    public set folioNumerico(value: number) {
        this._folioNumerico = value;
    }
    get templateId(): string {
        return this._templateId;
    }
    set templateId(value: string) {
        this._templateId = value;
    }
    public get contratoAnexoEstado(): ContratoAnexoEstado {
        return this._contratoAnexoEstado;
    }
    public set contratoAnexoEstado(value: ContratoAnexoEstado) {
        this._contratoAnexoEstado = value;
    }
    public get contratoAnexoTipos(): ContratoAnexoTipo[] {
        return this._contratoAnexoTipos;
    }
    public set contratoAnexoTipos(value: ContratoAnexoTipo[]) {
        this._contratoAnexoTipos = value;
    }
    public get ejecutivo(): EjecutivoInterface {
        return this._ejecutivo;
    }
    public set ejecutivo(value: EjecutivoInterface) {
        this._ejecutivo = value;
    }
    public get contacto(): ContactoInterface {
        return this._contacto;
    }
    public set contacto(value: ContactoInterface) {
        this._contacto = value;
    }
    get contratoAnexoUnidad(): ContratoAnexoUnidad[] {
        return this._contratoAnexoUnidad;
    }
    set contratoAnexoUnidad(value: ContratoAnexoUnidad[]) {
        this._contratoAnexoUnidad = value;
    }
    get contratoAnexoContacto(): ContratoAnexoContacto[] {
        return this._contratoAnexoContacto;
    }
    set contratoAnexoContacto(value: ContratoAnexoContacto[]) {
        this._contratoAnexoContacto = value;
    }
    get contratoAnexoConfiguracion(): ContratoConfiguracion {
        return this._contratoAnexoConfiguracion;
    }
    set contratoAnexoConfiguracion(value: ContratoConfiguracion) {
        this._contratoAnexoConfiguracion = value;
    }
    public get contratoAnexoCustodiaDocumentos(): CustodiaDocumento[] {
      return this._contratoAnexoCustodiaDocumentos;
    }
    public set contratoAnexoCustodiaDocumentos(value: CustodiaDocumento[]) {
      this._contratoAnexoCustodiaDocumentos = value;
    }
    get contratoFechaFin(): string {
        return this._contratoFechaFin;
    }
    set contratoFechaFin(value: string) {
        this._contratoFechaFin = value;
    }
    public get contratoReajusteFecha(): string {
      return this._contratoReajusteFecha;
    }
    public set contratoReajusteFecha(value: string) {
      this._contratoReajusteFecha = value;
    }

    get firmantes(): NegocioFirmanteInterface[] {
        return this._firmantes;
    }

    set firmantes(value: NegocioFirmanteInterface[]) {
        this._firmantes = value;
    }
}

