import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Paginador } from 'src/app/models/paginador.model';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { Observable } from 'rxjs';
import { Utils } from 'src/app/shared/utils';
import { Contrato } from 'src/app/models/model.index';
import { ContratoAdapter } from 'src/app/adapter/contrato.adapter';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ContratoService {

  public paginador: Paginador;
  public parametros: string;
  public cotizacion: any;

  constructor(
    public http: HttpClient,
    public contratoAdapter: ContratoAdapter,
    public paginadorAdapter: PaginadorAdapter,
  ) { }

  paginadorContrato() {
    return this.paginador;
  }

  cargarContratos(page, filtro?): Observable <Contrato[]> {
    this.generarParametrosBusqueda(filtro);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }

    return this.http.get(environment.urlApi + 'contratos?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(contratos => this.contratoAdapter.adapt(contratos));
        })
      );
  }

  cargarContrato(id: string): Observable <Contrato> {
    return this.http.get(environment.urlApi + 'contratos/' + id)
      .pipe(
        map( (resp: any) => this.contratoAdapter.adapt(resp.data) )
      );
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = '';
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('contrato_filter', filtro);
  }

  crearContrato(contrato): Observable <any> {
    return this.http.post(environment.urlApi + 'contratos', contrato);
  }

  editarContrato(contrato): Observable <any> {
    return this.http.put(environment.urlApi + 'contratos/' + contrato.id, contrato);
  }

  formatJson() {
    const filter = [];
    return Utils.toJson(this.cotizacion, filter);
  }

  enviarContrato(id: string): Observable <any> {
    return this.http.get(environment.urlApi + 'cotizaciones/' + id + '/email');
  }

  pdfPreview(contrato): Observable<any> {
    return this.http.post(environment.urlApi + 'contratos/pdf_preview', contrato, {responseType: 'arraybuffer'})
    .pipe(
      map( (res: any) => {
        return new Blob([res], {type: 'application/pdf'});
      })
    );
  }

  textPdfPreview(payload): Observable<any> {
    return this.http.post(`${environment.urlApi}contratos/text-preview`, payload).pipe(
      map((resp: any) => {
        return resp;
      })
    )
  }

  pdf(contratoId: string): Observable<any> {
    return this.http.get(environment.urlApi + 'contratos/' + contratoId + '/pdf', {responseType: 'arraybuffer'})
    .pipe(
      map( (res: any) => {
        console.log(res);
        return new Blob([res], {type: 'application/pdf'});
      })
    );
  }

  pdfFirmado(contratoId: string, adjuntoId: string): Observable<any> {
    return this.http
    .get(environment.urlApi + 'contratos/' + contratoId + '/adjuntos/' + adjuntoId + '/download', {responseType: 'arraybuffer'})
    .pipe(
      map( (res: any) => {
        return new Blob([res], {type: 'application/pdf'});
      })
    );
  }

  contratoValidate(contrato) {
    return this.http.post(environment.urlApi + 'contratos/validate', contrato);
  }

  firmar(contratoId: string) {
    return this.http.post(environment.urlApi + 'contratos/' + contratoId + '/firmar', '')
    .pipe(
      map( (resp: any) => this.contratoAdapter.adapt(resp.data) )
    );
  }

  estadoFirma(contratoId: string) {
    return this.http.get(environment.urlApi + 'contratos/' + contratoId + '/estado-firma');
  }

  enviarRecordatorioFirma(contratoId: string) {
    return this.http.post(environment.urlApi + 'contratos/' + contratoId + '/enviar-recordatorio','');
  }

  descargarDocumentoFirmado(contratoId: string) {
    return this.http.put(environment.urlApi + 'contratos/' + contratoId + '/descargar-documento-firmado','')
      .pipe(
        map((resp: any) => this.contratoAdapter.adapt(resp.data))
      );
  }

  exportar(filtro) {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'contratos/export?' + this.parametros, {responseType: 'blob'});
  }

  updateEstadoContrato(contratoId, updateData) {
    return this.http.patch(environment.urlApi + 'contratos/' + contratoId, updateData);
  }

  activarContrato(contratoId) {
    return this.http.get(environment.urlApi + 'contratos/' + contratoId + '/activar');
  }

  uploadDocumentoBase(contratoId: string, adjunto: any) {
    return this.http.put(environment.urlApi + 'contratos/' + contratoId + '/upload/documento', {adjunto: adjunto});
  }

  uploadDocumentoFirmado(contratoId: string, adjunto: any) {
    return this.http.put(environment.urlApi + 'contratos/' + contratoId + '/upload/documento-firmado', {adjunto: adjunto});
  }

  cargarIndicadoresVencimiento = (filter?) => {
    if(filter) {
      this.generarParametrosBusqueda(filter)
    }

    return this.http.get(`${environment.urlApi}contratos/indicador/vencimientos?${this.parametros}`)
    .pipe(
      map( (res: any) => {
        return res
      })
    )
  }

  cargarIndicadoresEstado = (filter?) => {
    if(filter){ 
      this.generarParametrosBusqueda(filter)
    }

    return this.http.get(`${environment.urlApi}contratos/indicador/estados?${this.parametros}`)
    .pipe(
      map( (res: any) => {
        return res
      })
    )
  }

  cambiarFirmante(updates): Observable <any> {
    return this.http.put(`${environment.urlApi}contratos/${updates.contratoId}/cambiar-firmante`, updates);
  }

  enviarCorreoTercero = (emails:Object, id:string): Observable <any> => {
    return this.http.post(`${environment.urlApi}contratos/${id}/email-tercero`, emails);
  }

  actualizarRenovacion(contratoId: string, updates): Observable <any> {
    return this.http.put(`${environment.urlApi}contratos/${contratoId}/renovacion`, updates);
  }
}
