export class Ciudad {

    private _id: number;
    private _nombre: string;
    private _regionId: number;

    public get id(): number {
        return this._id;
    }
    public set id(value: number) {
        this._id = value;
    }
    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get regionId(): number {
        return this._regionId;
    }
    public set regionId(value: number) {
        this._regionId = value;
    }
}
