import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { UnidadEstado } from '../models/unidad-estado.model';

@Injectable({
    providedIn: 'root'
  })
  export class UnidadEstadoAdapter implements Adapter<UnidadEstado> {
    adapt(item: any): UnidadEstado {
        const unidadEstado = new UnidadEstado();
        unidadEstado.id = item.attributes.id;
        unidadEstado.nombre = item.attributes.nombre;
        unidadEstado.lang_resource = item.attributes.lang_resource;
        return unidadEstado;
    }
  }
