
import { TitleCasePipe } from '@angular/common';

export class MedioLlegada {
    private _id: string;
    private _nombre: string;
    private _isActive: string;
    private _lang_resource: string;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get nombre(): string {
        return new TitleCasePipe().transform(this._nombre);
    }
    public set nombre(value: string) {
        this._nombre = value;
    }
    public get isActive(): string {
        return this._isActive;
    }
    public set isActive(value: string) {
        this._isActive = value;
    }
    public get lang_resource(): string {
        return this._lang_resource;
    }
    public set lang_resource(value: string) {
        this._lang_resource = value;
    }
}
