<div class="row mb-3">
    <div class="col col-lg-12 col-md-12 col-md-12 MagicScroll pl-0" data-options="width: 640; items: 3; step: 2;"
        style="overflow-y: auto; padding-top: 1em">
        <nav class="navbar">
            <ul class="ul-clientes-c">
                <!-- CONTACTOS -->
                <li *ngIf="ability.canViewClientes()" class="nav-item caja-item-clientes" routerLinkActive="active">
                    <a [routerLink]="['/clientes']" trackCategory="Ver" trackLabel="Listado Clientes" trackOnClick>
                        <span class="cont-icon">
                            <img class="iconos-modulo-clientes" style="max-width: 35px;" src="assets/img/iconos/clientes/contacto.svg" alt="">
                        </span> {{ 'home.contacts' | translate }}
                    </a>
                    <span>
                        <button *ngIf="canCreateContacto" style="margin-right: 30px;" class="button-nuevo-redondo ml-3" (click)="createContact.emit()"
                            trackCategory="Crear" trackLabel="Nuevo Contacto (Botón Redondo)" trackOnClick>+</button>
                    </span>
                </li>
                <!-- COTIZACIONES -->
                <li *ngIf="ability.canViewCotizaciones()" class="nav-item caja-item-clientes pointer" routerLinkActive="active">
                    <a [routerLink]="['/cotizaciones']" trackCategory="Ver" trackLabel="Listado Cotizaciones"
                        trackOnClick>
                        <span class="cont-icon">
                            <img class="iconos-modulo-clientes" style="max-width: 30px;" src="assets/img/iconos/clientes/cotizacion.svg" alt="">
                        </span>{{ 'home.quotes' | translate }}
                    </a>
                    <span>
                        <button *ngIf="canCreateCotizacion"  style="margin-right: 25px;" class="button-nuevo-redondo ml-3" [routerLink]="['/cotizaciones/crear']"
                            trackCategory="Crear" trackLabel="Nueva Cotización (Botón Redondo)" trackOnClick>+</button>
                    </span>
                </li>
                <!-- EVALUACIONES -->
                <li *ngIf="ability.canViewEvaluaciones()" class="nav-item caja-item-clientes pointer" routerLinkActive="active">
                    <a [routerLink]="['/evaluaciones']" trackCategory="Ver" trackLabel="Listado Evaluaciones"
                        trackOnClick>
                        <span class="cont-icon">
                            <img class="iconos-modulo-clientes" style="max-width: 30px;" src="assets/img/iconos/clientes/evaluaciones.svg">
                        </span>{{ 'home.evaluations' | translate }}
                    </a>
                    <span>
                        <button *ngIf="canCreateEvaluacion" style="margin-right: 25px;" class="button-nuevo-redondo ml-3" [routerLink]="['/evaluaciones/crear']"
                            trackCategory="Crear" trackLabel="Nueva Evaluación (Botón Redondo)" trackOnClick>+</button>
                    </span>
                </li>
                <!-- RESERVAS -->
                <li *ngIf="ability.canViewReservas()" class="nav-item caja-item-clientes pointer" routerLinkActive="active">
                    <a [routerLink]="['/reservas']" trackCategory="Ver" trackLabel="Listado Reservas" trackOnClick>
                        <span class="cont-icon">
                            <img class="iconos-modulo-clientes" style="max-width: 30px;" src="assets/img/iconos/clientes/reserva.svg">
                        </span>{{ 'home.bookings' | translate }}
                    </a>
                    <span>
                        <button *ngIf="canCreateReserva" style="margin-right: 25px;" class="button-nuevo-redondo ml-3" [routerLink]="['/reservas/crear']"
                                 trackCategory="Crear" trackLabel="Nueva Reserva (Botón Redondo)" trackOnClick>+</button>
                    </span>
                </li>
                <!-- CONTRATOS -->
                <li *ngIf="ability.canViewContratos()" class="nav-item caja-item-clientes pointer" routerLinkActive="active">
                    <a [routerLink]="['/contratos']" trackCategory="Ver" trackLabel="Listado Contratos" trackOnClick>
                        <span class="cont-icon">
                            <img class="iconos-modulo-clientes"  style="max-width: 30px;" src="assets/img/iconos/clientes/contrato.svg" alt="">
                        </span>{{ 'home.contracts' | translate }}
                    </a>
                    <span>
                        <button *ngIf="canCreateContrato" style="margin-right: 25px;" class="button-nuevo-redondo ml-3" [routerLink]="['/contratos/crear']"
                            trackCategory="Crear" trackLabel="Nuevo Contrato (Botón Redondo)" trackOnClick>+</button>
                    </span>
                </li>
                <!-- ANEXOS -->
                <li *ngIf="ability.canViewAnexos()"  class="nav-item caja-item-clientes pointer" routerLinkActive="active">
                    <a [routerLink]="['/anexos']" trackCategory="Ver" trackLabel="Listado anexos" trackOnClick style="margin-right: 25px;" >
                        <span class="cont-icon">
                            <img class="iconos-modulo-clientes" style="max-width: 30px;" src="assets/img/iconos/clientes/anexos.svg" alt="">
                        </span>{{ 'home.annexes' | translate }}
                    </a>
                </li>
                <!-- TAREAS  -->
                <li class="nav-link caja-item-clientes pointer" *ngIf="false">
                    <a href="javascript: void(0)" trackCategory="Ver" trackLabel="Listado Tareas" trackOnClick>
                        <span class="cont-icon"><img class="iconos-modulo-clientes"
                                src="assets/img/iconos/clientes/tareas.svg" alt="">
                        </span>{{ 'home.tasks' | translate }}
                    </a>
                    <span>
                        <button class="button-nuevo-redondo ml-4" trackCategory="Crear"
                            trackLabel="Nueva Tarea (Botón Redondo)" trackOnClick>+</button>
                    </span>
                </li>
            </ul>

        </nav>
    </div>

</div>
