import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Banco, MedioPago, Contacto, EstadoCuenta } from 'src/app/models/model.index';
import { PropiedadService } from 'src/app/services/propiedades/propiedad.service';
import { BancoService } from 'src/app/services/auxiliar/banco.service';
import { MedioPagoService } from 'src/app/services/auxiliar/medio-pago.service';
import { EstadoCuentaPagoService } from 'src/app/services/estado-cuenta-pago/estado-cuenta-pago.service';
import { Utils } from 'src/app/shared/utils';
import { ConfirmacionesComponent } from 'src/app/shared/confirmaciones/confirmaciones.component';
import { MatStepper } from '@angular/material/stepper';
import {ToastrService} from 'ngx-toastr';
import { AbilityService } from 'src/app/services/ability/ability.service';
import { TranslateService } from '@ngx-translate/core';

interface EstadoCuentaPagoInterface {
  divisa: string;
  estadoCuenta: string;
  fecha: any;
  medioPago: number;
  estadoPagoEstado: number;
  monto: number;
  estadoCuentaDetalles: string[];
  banco: string;
  numero: string;
  observacion: string;
  adjuntos: any[];
}

interface ModalDataInteface {
  contacto: Contacto;
  estadoCuenta: EstadoCuenta;
  listadoEstadoCuentaDetalles: any[];
  interesAcumulado: number;
}

@Component({
  selector: 'app-modal-registro-pago-cuentas',
  templateUrl: './modal-registro-pago-cuentas.component.html',
  styleUrls: ['./modal-registro-pago-cuentas.component.css']
})

export class ModalRegistroPagoCuentasComponent implements OnInit, AfterViewInit {
  public propiedades: any;
  public sent = false;
  public bancos: Banco[];
  public medioPagos: MedioPago[];
  public opcionesListadoCliente: any;
  public contacto: Contacto;
  public estadoCuenta: EstadoCuenta;
  public estadoCuentaPago: EstadoCuentaPagoInterface = {} as EstadoCuentaPagoInterface;
  public firstFormGroup: UntypedFormGroup;
  public secondFormGroup: UntypedFormGroup;
  public thirdFormGroup: UntypedFormGroup;
  public showbanco: boolean;
  public listadoEstadoCuentaDetalles: any[];
  public interesAcumulado: number;

  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalRegistroPagoCuentasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalDataInteface,
    public formBuilder: UntypedFormBuilder,
    public propiedadesService: PropiedadService,
    public bancoService: BancoService,
    public medioPagoService: MedioPagoService,
    public estadoCuentaPagoService: EstadoCuentaPagoService,
    public toastr: ToastrService,
    private ability: AbilityService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    if (this.data.estadoCuenta.reserva) {
      this.opcionesListadoCliente = {}
    } else {
      this.opcionesListadoCliente = {arrendatario: true};
    }
    this.contacto = this.data.contacto;
    this.estadoCuenta = this.data.estadoCuenta;
    this.listadoEstadoCuentaDetalles = this.data.listadoEstadoCuentaDetalles;
    this.interesAcumulado = this.data.interesAcumulado;
    this.preSetData();
  }

  ngAfterViewInit() {
    // TODO
    // this.stepper.selectedIndex = 2;
    setTimeout(() => {
      this.cargarMedioPagos();
      this.cargarBancos();
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  preSetData() {
    this.estadoCuentaPago.estadoCuenta = this.estadoCuenta.id;
    this.estadoCuentaPago.estadoPagoEstado = 100;
    this.estadoCuentaPago.monto = this.estadoCuenta.montoPactado;
    this.estadoCuentaPago.estadoCuentaDetalles = [];
    // TODO
    this.estadoCuenta.estadoCuentaDetalles.forEach(detalle =>
      this.estadoCuentaPago.estadoCuentaDetalles.push(detalle.id)
    );
    this.estadoCuentaPago.adjuntos = [];
  }

  crearPago() {
    if (this.ability.hasAccion('EstadoCuentaPagoCreate')){
      this.estadoCuentaPago.fecha = Utils.jsonDate(this.estadoCuentaPago.fecha);
      this.estadoCuentaPago.divisa = this.estadoCuenta.divisaCobroId;
      this.estadoCuentaPagoService.crearEstadoCuentaPago(this.estadoCuentaPago).subscribe(
        (resp: any) => {
          if (resp.data && resp.data.id) {
            this.dialogRef.close(true);
            this.toastr.success('Se ha generado correctamente el pago!');
          } else if (resp.errors) {
            resp.errors.forEach(error => {
              this.toastr.error(error.detail);
            });
          } else {
            this.toastr.error('Algo no anda bien! Por favor contacte al administrador');
          }
        },
        error => {
          this.toastr.error('Algo no anda bien! Por favor contacte al administrador');
        }
      );
    }else{
      this.toastr.success('Usted no tiene perrmisos para crear un pago!');
    }
  }

  cargarBancos() {
    if (this.ability.hasAccion('BancoIndex')){
      this.bancoService.cargarBancos().subscribe(bancos => this.bancos = bancos);
    }
  }

  cargarMedioPagos() {
    if (this.ability.hasAccion('MedioPagoIndex')){
      this.medioPagoService.cargarMedioPagos().subscribe(medioPagos => this.medioPagos = medioPagos);
    }
  }

  seleccionarContacto(contacto: Contacto) {
    this.contacto = contacto;
  }
  showInputs(medioPago: number) {
    this.estadoCuentaPago.banco = '';
    this.estadoCuentaPago.numero = '';
    if ([200, 300].indexOf(medioPago) >= 0) {
      this.showbanco = true;
    } else {
      this.showbanco = false;
    }
  }

  onSelect(event) {
    const file = event.addedFiles[0];
    this.readFile(file).then(fileContents => {
      const splitB64 = fileContents.toString().split(',');
      this.estadoCuentaPago.adjuntos.push({ fileAdjunto: splitB64[1], nombre: file.name, adjuntoTipoId: 1 });
    });
  }

  onRemove(index) {
    this.estadoCuentaPago.adjuntos.splice(index, 1);
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      if (file.size > 100000000) {
        this.toastr.error(this.translate.instant('general.error_max_filesize', { filename: file.name, maxSize: '100MB' }));
        return reject(null);
      }
      const reader = new FileReader();
      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };
      reader.onerror = e => {
        this.toastr.error('Error al subir archivo ' + file.name);
        return reject(null);
      };
      if (!file) {
        this.toastr.error('Error al subir archivo ' + file.name);
        return reject(null);
      }
      reader.readAsDataURL(file);
    });
  }

  openConfimacion() {
    if (!this.estadoCuentaPago.fecha || !this.estadoCuentaPago.medioPago) {
      return;
    }
    const confirmacionModal = this.dialog.open(
      ConfirmacionesComponent,
      {
        data: {
          titulo: '¿Está seguro que desea generar el pago?',
          contenido: `Estimado usuario, al confirmar esta operación el pago será generado.`,
        }
      }
    );
    confirmacionModal.afterClosed().subscribe(result => {
      if (result) {
        this.crearPago();
      }
    });
  }

}
