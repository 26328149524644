
export class Usuario {

    private _nombre: string;
    private _email: string;
    private _roles: any;

    get nombre(): string {
        return this._nombre;
    }

    set nombre(nombre: string) {
        this._nombre = nombre;
    }

    get email(): string {
        return this._email;
    }

    set email(email: string) {
        this._email = email;
    }

    get roles(): any {
        return this._roles;
    }

    set roles(roles: any ) {
        this._roles = roles;
    }

}
