import { Divisa } from "./divisa.model";

export class CotizacionConfiguracion {

    private _cotizacionId: string;
    private _divisaId: string;
    private _divisaCobroId: string;
    private _divisaConversionId: number;

    private _divisa: Divisa;
    private _divisaCobro: Divisa;


    get cotizacionId(): string {
        return this._cotizacionId;
    }
    set cotizacionId(value: string) {
        this._cotizacionId = value;
    }
    get divisaId(): string {
        return this._divisaId;
    }
    set divisaId(value: string) {
        this._divisaId = value;
    }
    get divisaCobroId(): string {
        return this._divisaCobroId;
    }
    set divisaCobroId(value: string) {
        this._divisaCobroId = value;
    }
    get divisaConversionId(): number {
        return this._divisaConversionId;
    }
    set divisaConversionId(value: number) {
        this._divisaConversionId = value;
    }

    get divisa(): Divisa {
        return this._divisa;
    }
    set divisa(value: Divisa) {
        this._divisa = value;
    }
    get divisaCobro(): Divisa {
        return this._divisaCobro;
    }
    set divisaCobro(value: Divisa) {
        this._divisaCobro = value;
    }
}
