<label class="pop-section-title">{{ 'general.dates' | translate }}</label>
<label class="pop-section-description" *ngIf="type === 'Contrato'">{{ 'contract.define_dates' | translate }}</label>
<label class="pop-section-description" *ngIf="type === 'Cotizacion'">{{ 'quote.define_dates' | translate }}</label>
<div class="pop-section">
    <div *ngIf="!negocioData.fechaInicio && !negocioData.fechaFin; else periodoContratoTemplate">
        <a class="pointer" (click)="seleccionarFechasEmitter.emit()"><i class="fa fa-calendar mr-2"></i> Seleccionar Fechas</a>
        <app-info-text
                *ngIf="showTexto"
                [text]="textInfoEdition"
        ></app-info-text>
    </div>
</div>

<ng-template #periodoContratoTemplate>
    <app-info-text
            [text]="textInfoDates"
            [enableEdition]="true"
            (editEmitter)="seleccionarFechasEmitter.emit()"
    ></app-info-text>
</ng-template>
