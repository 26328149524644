import {Contacto} from './contacto.model';
import {ContratoContactoTipo} from './contrato-contacto-tipo.model';
import {Pais} from './pais.model';
import {IdentificadorTipo} from './identificador-tipo.model';

export class ContratoAnexoContacto {
  private _id: string;
  private _contactoId: string;
  private _contratoAnexoId: string;
  private _contratoContactoTipoId: number;
  private _isFirmante: boolean;
  private _isTitular: boolean;
  private _contacto: Contacto;
  private _contratoContactoTipo: ContratoContactoTipo;
  private _pais: Pais;
  private _identificadorTipo: IdentificadorTipo;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get contactoId(): string {
    return this._contactoId;
  }

  set contactoId(value: string) {
    this._contactoId = value;
  }

  get contratoAnexoId(): string {
    return this._contratoAnexoId;
  }

  set contratoAnexoId(value: string) {
    this._contratoAnexoId = value;
  }

  get contratoContactoTipoId(): number {
    return this._contratoContactoTipoId;
  }

  set contratoContactoTipoId(value: number) {
    this._contratoContactoTipoId = value;
  }

  get isFirmante(): boolean {
    return this._isFirmante;
  }

  set isFirmante(value: boolean) {
    this._isFirmante = value;
  }

  get isTitular(): boolean {
    return this._isTitular;
  }

  set isTitular(value: boolean) {
    this._isTitular = value;
  }

  get contacto(): Contacto {
    return this._contacto;
  }

  set contacto(value: Contacto) {
    this._contacto = value;
  }

  get contratoContactoTipo(): ContratoContactoTipo {
    return this._contratoContactoTipo;
  }

  set contratoContactoTipo(value: ContratoContactoTipo) {
    this._contratoContactoTipo = value;
  }

  get pais(): Pais {
    return this._pais;
  }

  set pais(value: Pais) {
    this._pais = value;
  }

  get identificadorTipo(): IdentificadorTipo {
    return this._identificadorTipo;
  }

  set identificadorTipo(value: IdentificadorTipo) {
    this._identificadorTipo = value;
  }
}
