export class CustodiaDocumento {
    private _createdAt: string;
    private _createdBy: string;
    private _id: string;
    private _custodiaDocumentoTipoId: number;
    private _custodiaDocumentoEstadoId: number;
    private _fechaCustodia: string;
    private _folio: string;
    private _banco: string;
    private _monto: number;
    private _isDepositableSolamente: boolean;
    private _isCobrableAlPortador: boolean;
    private _giradoA: string;

    public get createdAt(): string {
        return this._createdAt;
    }
    public set createdAt(value: string) {
        this._createdAt = value;
    }
    public get createdBy(): string {
        return this._createdBy;
    }
    public set createdBy(value: string) {
        this._createdBy = value;
    }
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get custodiaDocumentoTipoId(): number {
        return this._custodiaDocumentoTipoId;
    }
    public set custodiaDocumentoTipoId(value: number) {
        this._custodiaDocumentoTipoId = value;
    }
    public get custodiaDocumentoEstadoId(): number {
        return this._custodiaDocumentoEstadoId;
    }
    public set custodiaDocumentoEstadoId(value: number) {
        this._custodiaDocumentoEstadoId = value;
    }
    public get fechaCustodia(): string {
        return this._fechaCustodia;
    }
    public set fechaCustodia(value: string) {
        this._fechaCustodia = value;
    }
    public get folio(): string {
        return this._folio;
    }
    public set folio(value: string) {
        this._folio = value;
    }
    public get banco(): string {
        return this._banco;
    }
    public set banco(value: string) {
        this._banco = value;
    }
    public get monto(): number {
        return this._monto;
    }
    public set monto(value: number) {
        this._monto = value;
    }
    public get isDepositableSolamente(): boolean {
        return this._isDepositableSolamente;
    }
    public set isDepositableSolamente(value: boolean) {
        this._isDepositableSolamente = value;
    }
    public get isCobrableAlPortador(): boolean {
        return this._isCobrableAlPortador;
    }
    public set isCobrableAlPortador(value: boolean) {
        this._isCobrableAlPortador = value;
    }
    public get giradoA(): string {
        return this._giradoA;
    }
    public set giradoA(value: string) {
        this._giradoA = value;
    }
}
