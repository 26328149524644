import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { UnidadSubEstado } from '../models/unidad-subestado.model';

@Injectable({
    providedIn: 'root'
  })
  export class UnidadSubEstadoAdapter implements Adapter<UnidadSubEstado> {
    adapt(item: any): UnidadSubEstado {
        const unidadSubEstado = new UnidadSubEstado();
        unidadSubEstado.id = item.attributes.id;
        unidadSubEstado.nombre = item.attributes.nombre;
        unidadSubEstado.lang_resource = item.attributes.lang_resource;
        return unidadSubEstado;
    }
  }
