<section id="login" (window:resize)="onResize($event)">
    <!-- CONTAINER UNO -->

    <div class=" container-form-l desktop-form" *ngIf="!getIsMobileResolution()">
        <div class="row m-1">
            <div class="col-lg-6 col-md-6 offset-md-1 logo-img ">
                <img class="logo-login" src="/assets/img/logo_login_small.png" alt="">
            </div>

            <div class="col-lg-3 col-md-4 logo-img login-form">
                <form ngNativeValidate #formLogin="ngForm" (ngSubmit)="ingresar(formLogin)">
                    <div class="row">
                        <div class="col-12">
                            <h2>{{ 'session.login' | translate }}</h2>
                        </div>
                    </div>

                    <div class="input-mail">
                        <label class="login-label">{{ 'session.email' | translate }}</label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="inputGroup-sizing"> <i class="fas fa-envelope"></i></span>
                            </div>
                            <input class="form-control input-text" aria-label="Sizing example input" aria-describedby="inputGroup-sizing" type="email" [(ngModel)]="username" id="username" name="username" required>
                        </div>

                    </div>

                    <div class="input-pass">
                        <label class="login-label">{{ 'session.password' | translate }} </label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="inputGroup-sizing"><i class="fas fa-lock"></i></span>
                            </div>
                            <input class="form-control input-text" aria-label="Sizing example input" aria-describedby="inputGroup-sizing" ngModel type="password" name="password" name="password" required>
                        </div>
                        <label class="forgot-pass" routerLink="/olvido-contrasena">{{ 'session.forget_question' | translate }}</label>
                        <div class="text-center mt-4" *ngIf="error">
                            <span class="span-error">
                                <b>{{ 'session.wrong_credentials' | translate }}</b>
                            </span>
                        </div>
                    </div>

                    <div class="btn-login" [class.mt-4]="error">
                        <button class="btn btn-secondary btn-lg btn-block" type="submit" [disabled]="clicked" [recaptchaForm]="formLogin" recaptcha>
                            <span *ngIf="!clicked">{{ 'session.start_session' | translate }}</span>
                            <mat-progress-spinner style="margin:0 auto; left: 0" class="spinner" color="primary" mode="indeterminate" value="50" diameter="25" *ngIf="clicked">
                            </mat-progress-spinner>
                        </button>
                    </div>

                </form>
                <hr/>
                <div class="text-center">
                    {{ 'general.language' | translate }}:
                    &nbsp;&nbsp;
                    <app-lenguaje arrow="up"></app-lenguaje>
                </div>
            </div>
        </div>

    </div>

    <!-- Formulario Responsive Mobile  -->

    <div class="container-fluid container-form-l responsive-form" *ngIf="getIsMobileResolution()">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 logo-img ">
                <img class="logo-login" src="/assets/img/logo.png" alt="">
            </div>

            <div class=" col-lg-3 logo-img login-form">

                <form ngNativeValidate #formLogin="ngForm" (ngSubmit)="ingresar(formLogin)">
                    <div class="row">
                        <div class="col-lg-3">
                            <h2>{{ 'session.login' | translate }}</h2>
                        </div>
                    </div>

                    <div class="input-mail">
                        <label class="login-label">{{ 'session.email' | translate }}</label>
                        <div class="input-group input-group-md ig-responsive">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="inputGroup-sizing-md"> <i class="fas fa-envelope"></i></span>
                            </div>
                            <input class="form-control input-text" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-md" [(ngModel)]="username" type="email" id="username" name="username" required>
                        </div>
                    </div>

                    <div class="input-pass">
                        <label class="login-label">{{ 'session.password' | translate }}</label>
                        <div class="input-group input-group-md ig-responsive">
                            <div class="input-group-prepend">
                                <span class="input-group-text input-icon" id="inputGroup-sizing-md"><i class="fas fa-lock"></i></span>
                            </div>
                            <input type="email" class="form-control input-text" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-md" ngModel type="password" name="password" name="password" required>
                        </div>
                        <label class="forgot-pass" routerLink="/olvido-contrasena">{{ 'session.forget_question' | translate }}</label>

                        <div class="text-center mt-4" *ngIf="error">
                            <span class="span-error text-warning">
                                <b>{{ 'session.wrong_credentials' | translate }}</b>
                            </span>
                        </div>
                    </div>


                    <div class="text-center btn-login" [class.mt-5]="error">
                        <!-- <div class="col"> -->
                        <button class="btn btn-secondary btn-lg" type="submit" [disabled]="clicked" [recaptchaForm]="formLogin" recaptcha>
                            <span *ngIf="!clicked">{{ 'session.start_session' | translate }}</span>
                            <mat-progress-spinner style="margin:0 auto; left: 0" class="spinner" color="primary" mode="indeterminate" value="50" diameter="20" *ngIf="clicked">
                            </mat-progress-spinner>
                        </button>
                        <!-- </div> -->
                    </div>

                </form>
                <hr/>
                <div class="text-center">
                    {{ 'general.language' | translate }}:
                    &nbsp;&nbsp;
                    <app-lenguaje arrow="up"></app-lenguaje>
                </div>

            </div>

            <img class="img-squares" src="/assets/img/squares.png" alt="">
            <img class="img-circle" src="/assets/img/inter-1.png" alt="">

        </div>

    </div>

</section>
