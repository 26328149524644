import { Component, OnInit, Input } from '@angular/core';

type iconType = 'administracion' | 'home' | 'propiedades' | 'unidades' | 'balances' | 'clientes' | 'checkestate' | 'checkin' | 'checkout' | 'checkSinData' | 'configuracion'
                | 'comunidad' | 'publicaciones' | 'residentes' | 'espaciosComunes' | 'bitacora' | 'ayuda' | 'manuales' | 'propietarios'

@Component({
  selector: 'app-icono',
  templateUrl: './icono.component.html',
  styleUrls: ['./icono.component.css']
})

export class IconoComponent implements OnInit {
  @Input() type: iconType;
  @Input() color: string
  @Input() width: number
  @Input() customStyle: string = ''

  constructor() { }

  ngOnInit() {
  }
}
