import {Injectable} from '@angular/core';
import {CargoClasificacionService} from '../auxiliar/cargo-clasificacion.service';
import {ConceptoService} from '../auxiliar/concepto.service';
import {CargoClasificacion} from '../../models/cargoClasificacion.model';
import {Concepto} from '../../models/concepto.model';
import {CuentaRecaudadora} from '../../models/cuenta-recaudadora.model';
import {PropiedadService} from '../propiedades/propiedad.service';
import {Propiedad} from '../../models/propiedad.model';
import {DivisaService} from '../divisa/divisa.service';
import {Divisa} from '../../models/divisa.model';
import {PropiedadConfiguracionCargoClasificacion} from '../../models/propiedad-configuracioncargo-clasificacion.model';
import {Contrato} from '../../models/contrato.model';
import {ContratoService} from '../contrato/contrato.service';
import {ContratoContactoTipo} from '../../models/contrato-contacto-tipo.model';
import {ContratoContactoTiposService} from '../auxiliar/contrato-contacto-tipos.service';
import {Cotizacion} from '../../models/cotizacion.model';
import {CotizacionService} from '../cotizacion/cotizacion.service';
import {IdentificadorTipoService} from "../identificador-tipo/identificador-tipo.service";
import {IdentificadorTipo} from "../../models/identificador-tipo.model";
import {UnidadEstadoService} from "../unidad/unidad-estado.service";
import {UnidadTipoService} from "../unidad-tipo/unidad-tipo.service";
import {UnidadEstado} from "../../models/unidad-estado.model";
import {UnidadTipo} from "../../models/unidad-tipo.model";
import {Globals} from "../../store/models/globals.model";
import {SetAll} from "../../store/actions/globals.action";
import {LocalService} from "../local.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../store/models/app.model";
import {PropiedadConfiguracionService} from "../propiedades/propiedad-configuracion.service";
import {BaseModel} from "../../models/base.model";
import {ReajusteTipoService} from "../reajustes/reajuste-tipo.service";

@Injectable({
  providedIn: 'root'
})
export class NegocioService {
  public cuentasRecaudadoras: CuentaRecaudadora[];
  constructor(
    private clasificacionService: CargoClasificacionService,
    private conceptoService: ConceptoService,
    private propiedadService: PropiedadService,
    private divisaService: DivisaService,
    private contratoService: ContratoService,
    private cotizacionService: CotizacionService,
    private contratoContactoTipoService: ContratoContactoTiposService,
    private identificadorTiposService: IdentificadorTipoService,
    private unidadEstadoService: UnidadEstadoService,
    private unidadTipoService: UnidadTipoService,
    private propiedadConfiguracionService: PropiedadConfiguracionService,
    private reajusteTipoService: ReajusteTipoService
  ) { }

  async cargarClasificaciones(): Promise<CargoClasificacion[]> {
    return await this.clasificacionService.cargarCargosClasificacion().toPromise();
  }

  async cargarConceptos(): Promise<Concepto[]> {
    return await this.conceptoService.cargarConceptos().toPromise();
  }

  async cargarCuentasRecaudadoras(propiedadId: string) {
    return await this.propiedadConfiguracionService.cargarCuentaRecaudadoras(propiedadId).toPromise();
  }

  async cargarPropiedad(propiedadId: string): Promise<Propiedad> {
    return await this.propiedadService.cargarPropiedad(propiedadId).toPromise();
  }

  async cargarDivisas(): Promise<Divisa[]> {
    return await this.divisaService.cargarDivisas().toPromise();
  }

  async cargarPropiedadCargoClasificacion(propiedadId: string): Promise<PropiedadConfiguracionCargoClasificacion[]> {
    return await this.propiedadService.cargarConfiguracionCargoClasificaciones(propiedadId).toPromise();
  }

  async cargarContrato(contratoId: string): Promise<Contrato> {
    return await this.contratoService.cargarContrato(contratoId).toPromise();
  }

  async cargarCotizacion(cotizacionId: string): Promise<Cotizacion> {
    return await this.cotizacionService.cargarCotizacion(cotizacionId).toPromise();
  }

  async cargarContactoTipos(): Promise<ContratoContactoTipo[]> {
    return await this.contratoContactoTipoService.cargarTipos().toPromise();
  }

  async cargarValorUF(fecha: string) {
    return await this.divisaService.cargarValorDivisa(fecha, 'UF', 'CLP').toPromise();
  }

  async cargarIdentificadorTipos(): Promise<IdentificadorTipo[]> {
    return await this.identificadorTiposService.cargarTiposIdentificacion().toPromise();
  }

  async cargarUnidadEstados(): Promise<UnidadEstado[]> {
    return await this.unidadEstadoService.cargarUnidadEstado().toPromise();
  }

  async cargarUnidadTipos(): Promise<UnidadTipo[]> {
    return await this.unidadTipoService.cargarUnidadTipos().toPromise();
  }

  async cargarReajusteTipos(): Promise<BaseModel[]> {
    return await this.reajusteTipoService.cargarReajusteTipos().toPromise();
  }
}
