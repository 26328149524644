import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbInterface} from '../../interfaces/breadcrumb.interface';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input() breadcrumb: BreadcrumbInterface[];
  constructor() { }

  ngOnInit(): void {}

}
