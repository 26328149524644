import update from 'immutability-helper'
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-modal-enviar-correo',
  templateUrl: './modal-enviar-correo.component.html',
  styleUrls: ['./modal-enviar-correo.component.css']
})
export class ModalEnviarCorreo implements OnInit {
  @ViewChild('emailsInput') inputName
  readonly separatorKeysCodes = [ENTER, COMMA] as const
  emails: string[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalEnviarCorreo>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  add = (event: MatChipInputEvent) => {
    const isEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(event.value)
    const mailExists = this.emails.some(e => e === event.value)

    if (isEmail && !mailExists) {
      this.emails = update(this.emails, {
        $push: [event.value]
      })
      this.inputName.nativeElement.value = ''
    }
  }

  remove = (email: string) => {
    const index = this.emails.findIndex(e => e === email)

    if (index >= 0) {
      this.emails = update(this.emails, {
        $splice: [[index, 1]]
      })
    }
  }

  submit = () => {
    this.dialogRef.close({
      emails: this.emails
    })
  }
}
