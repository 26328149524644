import { CargoClasificacionAdapter } from './../../adapter/cargo-clasificacion.adapter';
import { CargoClasificacion } from './../../models/cargoClasificacion.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CargoClasificacionService {

  constructor(
    public http: HttpClient,
    public cargoClasificacionAdapter: CargoClasificacionAdapter
  ) { }

  cargarCargosClasificacion(): Observable <CargoClasificacion[]> {
    return this.http.get(environment.urlApi + 'cargos-clasificacion')
      .pipe(
        map( (resp: any) => {
          return resp.data.map(cargoClasificacion => this.cargoClasificacionAdapter.adapt(cargoClasificacion));
        })
      );
  }

}
