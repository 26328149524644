import {Adapter} from './adapter';
import {Injectable} from '@angular/core';
import {DivisaAdapter} from './divisa.adapter';
import {MontoTipoAdapter} from './monto-tipo.adapter';
import {PeriodicidadAdapter} from './periodicidad.adapter';
import {ContratoConfiguracion} from '../models/contrato-configuracion.model';
import {EstadoCuentaEstadoAdapter} from './estado-cuenta-estado.adapter';

@Injectable({
    providedIn: 'root'
})

export class ContratoConfiguracionAdapter implements Adapter<ContratoConfiguracion> {
    constructor(
        public divisaAdapter: DivisaAdapter,
        public montoTipoAdapter: MontoTipoAdapter,
        public periodicidadAdapter: PeriodicidadAdapter,
        public estadoCuentaEstadoAdapter: EstadoCuentaEstadoAdapter
    ) {}
    adapt(item: any): ContratoConfiguracion {
        const contratoConfiguracion = new ContratoConfiguracion();
        contratoConfiguracion.id = item.attributes.id;
        contratoConfiguracion.contratoId = item.attributes.contrato_id;
        contratoConfiguracion.divisaId = item.attributes.divisa_id;
        contratoConfiguracion.divisaCobroId = item.attributes.divisa_cobro_id;
        contratoConfiguracion.divisaConversionId = item.attributes.divisa_conversion_id;
        contratoConfiguracion.reajusteMontoTipoId = item.attributes.reajuste_monto_tipo_id;
        contratoConfiguracion.reajusteMonto = item.attributes.reajuste_monto;
        contratoConfiguracion.reajustePeriodicidadValor = item.attributes.reajuste_periodicidad_valor;
        contratoConfiguracion.retrasoMultaMonto = item.attributes.retraso_multa_monto;
        contratoConfiguracion.retrasoMultaMontoDivisa = item.attributes.retraso_multa_monto_divisa_id;
        contratoConfiguracion.retrasoMultaMontoTipoId = item.attributes.retraso_multa_monto_tipo_id;
        contratoConfiguracion.retrasoInteresMonto = item.attributes.retraso_interes_monto;
        contratoConfiguracion.retrasoInteresMontoDivisa = item.attributes.retraso_interes_monto_divisa_id;
        contratoConfiguracion.retrasoInteresPorDia = item.attributes.retraso_interes_por_dia;
        contratoConfiguracion.retrasoInteresNoAplicar = item.attributes.retraso_interes_no_aplicar;
        contratoConfiguracion.retrasoInteresPeriodicidadId = item.attributes.retraso_interes_periodicidad_id;
        contratoConfiguracion.retrasoInteresPeriodicidadValor = item.attributes.retraso_interes_periodicidad_valor;
        contratoConfiguracion.retrasoInteresTipoId = item.attributes.retraso_interes_tipo_id;
        contratoConfiguracion.diaGeneracion = item.attributes.dia_generacion;
        contratoConfiguracion.diaCobro = item.attributes.dia_cobro;
        contratoConfiguracion.diaCorteArriendo = item.attributes.dia_corte_arriendo;
        contratoConfiguracion.diaCorteGastoComun = item.attributes.dia_corte_gasto_comun;
        contratoConfiguracion.diaCorteServicios = item.attributes.dia_corte_servicios;
        contratoConfiguracion.diaPago = item.attributes.dia_pago;
        contratoConfiguracion.cobroPeriodicidadId = item.attributes.cobro_periodicidad_id;
        contratoConfiguracion.liquidacionCreacionEstadoId = item.attributes.liquidacion_creacion_estado_id;
        contratoConfiguracion.avisoTerminoDias = item.attributes.aviso_termino_dias;
        contratoConfiguracion.interesPorDia = item.attributes.interes_por_dia
        contratoConfiguracion.isRenovacionAutomatica = item.attributes.is_renovacion_automatica;
        contratoConfiguracion.isGeneracionAutomatica = item.attributes.is_generacion_automatica;
        if (item.included) {
            item.included.filter((valor) => {
                switch (valor.type) {
                    case 'Divisa':
                        contratoConfiguracion.divisa = this.divisaAdapter.adapt(valor);
                        break;
                    case 'DivisaCobro':
                        contratoConfiguracion.divisaCobro = this.divisaAdapter.adapt(valor);
                        break;
                    case 'EstadoCuentaEstado':
                        contratoConfiguracion.estadoCuentaEstado = this.estadoCuentaEstadoAdapter.adapt(valor);
                        break;
                    case 'reajusteMontoTipo':
                        contratoConfiguracion.reajusteMontoTipo = this.montoTipoAdapter.adapt(valor);
                        break;
                    case 'reajustePeriodicidad':
                        contratoConfiguracion.reajustePeriodicidad = this.periodicidadAdapter.adapt(valor);
                        contratoConfiguracion.reajustePeriodicidadId = contratoConfiguracion.reajustePeriodicidad.id;
                        break;
                    case 'retrasoMultaMontoTipo':
                        contratoConfiguracion.retrasoMultaMontoTipo = this.montoTipoAdapter.adapt(valor);
                        contratoConfiguracion.retrasoMultaMontoTipoId = contratoConfiguracion.retrasoMultaMontoTipo.id;
                        break;
                    case 'retrasoInteresMontoTipo':
                        contratoConfiguracion.retrasoInteresMontoTipo = this.montoTipoAdapter.adapt(valor);
                        // tslint:disable-next-line:max-line-length
                        contratoConfiguracion.retrasoInteresMontoTipoId = contratoConfiguracion.retrasoInteresMontoTipo.id;
                        break;
                    case 'retrasoInteresPeriodicidad':
                        contratoConfiguracion.retrasoInteresPeriodicidad = this.periodicidadAdapter.adapt(valor);
                }
            });
        }
        return contratoConfiguracion;
    }
}
