import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RecoveryComponent } from './login/recovery.component';
import { RegistroComponent } from './login/registro.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { ContrasenaComponent } from './login/contrasena.component';
import { PagesComponent } from './pages/pages.component';
import { AuthGuard } from './services/service.index';


const appRoute: Routes = [
    {path: 'login', component: LoginComponent},
    {path: 'restablecer-contrasena/:hash', component: RecoveryComponent},
    {path: 'registro', component: RegistroComponent},
    {path: 'olvido-contrasena', component: ContrasenaComponent},
    {
        path: '',
        component: PagesComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {path: '**', component: NopagefoundComponent}
];

export const APP_ROUTES = RouterModule.forRoot(appRoute, { useHash: true, relativeLinkResolution: 'legacy' });
