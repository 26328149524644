import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { ContratoTemplate } from '../models/contrato-template.model';
import {ContratoAnexoTipoAdapter} from './contrato-anexo-tipo.adapter';

@Injectable({
    providedIn: 'root'
  })
export class ContratoTemplateAdapter implements Adapter<ContratoTemplate> {
  constructor(
    private anexoTipoAdapter: ContratoAnexoTipoAdapter
  ) {
  }
  adapt(item: any): ContratoTemplate {
      const contratoTemplate = new ContratoTemplate();
      contratoTemplate.id = item.attributes.id;
      contratoTemplate.nombre = item.attributes.nombre;
      contratoTemplate.contenido = item.attributes.contenido;
      contratoTemplate.propiedadId = item.attributes.propiedad_id;
      contratoTemplate.isActive = item.attributes.is_active;
      contratoTemplate.isAnexo = item.attributes.is_anexo;
      contratoTemplate.contratoAnexoTipoId = item.attributes.contrato_anexo_tipo_id;
      contratoTemplate.createdAt = item.attributes.created_at;
      contratoTemplate.createdBy = item.attributes.created_by;

      if (item.included) {
        item.included.filter((valor) => {
          switch (valor.type) {
            case 'ContratoAnexoTipo':
              contratoTemplate.contratoAnexoTipo = this.anexoTipoAdapter.adapt(valor);
              break;
          }
        })
      }
      return contratoTemplate;
  }
}
