import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {PropiedadConfiguracionContabilidad} from '../models/propiedad-configuracion-contabilidad.model';

@Injectable({
  providedIn: 'root'
})
export class PropiedadConfiguracionContabilidadAdapter implements Adapter<PropiedadConfiguracionContabilidad> {
  constructor() {
  }
  adapt(item: any): PropiedadConfiguracionContabilidad {
    switch (item.type) {  
      case 'Propiedad':
        return this.fromPropiedad(item);
      case 'PropiedadConfiguracionContabilidad':
        return this.fromConfiguracion(item);
      default:
        throw Error(`Tipo inválido "${item.type}"`);
    }
  }

  private fromPropiedad(item: any): PropiedadConfiguracionContabilidad {

    const config = new PropiedadConfiguracionContabilidad();
    config.propiedadId = item.id;
    
    let data = item.relationship?.contabilidad?.data;
    if (!data || !Array.isArray(data) ||data.length === 0) {
      return config;
    }

    const configItem = data[0];
    config.id = configItem.id;
    config.enabled = configItem.attributes.enabled;
    config.codigoCentroCostos = configItem.attributes.codigo_centro_costos;

    return config;
  }

  private fromConfiguracion(item: any): PropiedadConfiguracionContabilidad {

    const config = new PropiedadConfiguracionContabilidad();
    config.propiedadId = item.attributes.propiedadId;
    config.id = item.id;
    config.enabled = item.attributes.enabled;
    config.codigoCentroCostos = item.attributes.codigoCentroCostos;

    return config;
  }
}
