import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Unidad } from 'src/app/models/unidad.model';
import { Observable } from 'rxjs';
import { UnidadAdapter } from 'src/app/adapter/unidad.adapter';
import { Utils } from 'src/app/shared/utils';
import { PaginadorAdapter } from 'src/app/adapter/paginador.adapter';
import { environment } from 'src/environments/environment';
import {UnidadInterface} from "../../interfaces/unidad.interface";

@Injectable({
  providedIn: 'root'
})
export class UnidadService {

  public propiedades: [];
  public unidad: Unidad;
  public filterAttributes: any;
  public parametros: any;
  public paginador: any;

  constructor(
    public http: HttpClient,
    public unidadAdapter: UnidadAdapter,
    public paginadorAdapter: PaginadorAdapter,
    public utils: Utils,
  ) {
    this.parametros = '';
    this.filterAttributes = [
      'id', 'propiedad', 'unidadCobro', 'unidadEstado' , 'unidadTipo', 'createdAt', 'createdBy', 'updatedAt' , 'updatedBy', 'imagenes',
      'totalMensual', 'adjuntos', 'fechaCompromisoEntrega'
    ];
  }

  paginadorUnidad() {
    return this.paginador;
  }

  cargarUnidades(page, filtro?): Observable <Unidad[]> {
    let filter = null;
    if (filtro) {
      const { tipologia, ...rest } = filtro;
      filter = {
        ...rest,
        ...(tipologia
          ? {
              tipologia: tipologia
                .replaceAll("+", "")
                .replaceAll("-", "")
                .replaceAll(" ", "")
                .replaceAll(",", ".")
            }
          : {}),
      };
    }
    
    this.generarParametrosBusqueda(filter);
    if (page && page > 0) {
      this.parametros += '&page=' + page;
    }
    return this.http.get(environment.urlApi + 'unidades?' + this.parametros)
      .pipe(
        map( (resp: any) => {
          this.paginador = this.paginadorAdapter.adapt(resp.meta);
          return resp.data.map(unidad => this.unidadAdapter.adapt(unidad));
        })
      );
  }

  cargarUnidad(id: string): Observable<Unidad> {
    return this.http.get(environment.urlApi + 'unidades/' + id)
    .pipe(
      map((resp: any) => this.unidadAdapter.adapt(resp.data))
      );
  }

  actualizarUnidad(unidadId: string, unidad: any): Observable<any> {
    return this.http.put(environment.urlApi + 'unidades/' + unidadId, unidad);
  }

  formatJson(unidad: Unidad) {
    return Utils.toJson(unidad, this.filterAttributes);
  }

  generarParametrosBusqueda(filtro)  {
    this.parametros = '';
    if (!filtro) {
      return;
    }
    this.parametros = Utils.generarParametrosBusqueda('unidad_filter', filtro);
  }

  exportar(filtro) {
    this.generarParametrosBusqueda(filtro);
    return this.http.get(environment.urlApi + 'unidades/export?' + this.parametros, {responseType: 'blob'});
  }

  downloadImage = (imageId: string, unitId: string) => {
    const URL = `${environment.urlApi}unidades/${unitId}/imagenes/${imageId}/download`
    return this.http.get(URL, { responseType: 'blob' });
  }

  getPricesRecord = (unitId: string): Observable<any> => {
    return this.http.get(`${environment.urlApi}unidades/${unitId}/historial/precios`)
    .pipe(
      map((resp: any) => resp.data.map(e => ({
        date: e.attributes.fecha?.split(' ')[0],
        commonExpense: e.attributes.precio_gasto_comun || 0,
        price: e.attributes.precio_lista
      })))
      )
  }

  agregarPropietario(unidadId: string, propietario: any) {
    return this.http.post(`${environment.urlApi}unidades/${unidadId}/propietario`, propietario);
  }

  editarPropietario(unidadId: string, propietarioId: string, payload: any) {
    return this.http.put(`${environment.urlApi}unidades/${unidadId}/propietario/${propietarioId}`, payload);
  }

  createUnit = (newUnit: Object, propertyId): Observable <any> =>
    this.http.post(`${environment.urlApi}propiedades/${propertyId}/unidades`, newUnit);

  agregarFirmante = (unidadId: string, payload: any) => this.http.post(`${environment.urlApi}unidades/${unidadId}/firmante`, payload);

  editarFirmante = (unidadId: string, payload: any) =>
    this.http.put(`${environment.urlApi}unidades/${unidadId}/firmante/${payload.id}`, payload);

  agregarPrecio = (unidadId: string, payload: any) => this.http.post(`${environment.urlApi}unidades/${unidadId}/precio`, payload);

  editarPrecio = (unidadId: string, precioId: string, payload: any) =>
    this.http.put(`${environment.urlApi}unidades/${unidadId}/precio/${precioId}`, payload);

  eliminarPrecio = (unidadId: string, precioId: string) => this.http.delete(`${environment.urlApi}unidades/${unidadId}/precio/${precioId}`);

  downloadFile = (fileId: string, unitId: string) => {
    const URL = `${environment.urlApi}unidades/${unitId}/adjuntos/${fileId}/download`
    return this.http.get(URL, { responseType: 'blob' });
  }

  publicarEnWasi(unidadId: string, info: any): Observable <any> {
    return this.http.post(`${environment.urlApi}unidades/${unidadId}/portal/wasi`, info);
  }

  cambiarSubestado(unidadId: string, data: any): Observable <any> {
    return this.http.patch(`${environment.urlApi}unidad/${unidadId}`, data);
  }
}
