import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IdentificadorPipe } from 'src/app/pipes/identificador.pipe';

@Directive({
  selector: '[appFormatIdentificador]',
  providers: [IdentificadorPipe]
})

export class FormatIdentificadorDirective implements OnInit, OnChanges {

  @Input() appFormatIdentificadorTipo: number;

  private element: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private identificador: IdentificadorPipe
  ) { }

  @HostListener('focusout') onFocusOut() {
    this.element.value = this.formatIdentificador;
  }

  @HostListener('focus') onFocus() {
    this.element.value = this.unformatedIdentificador;
  }

  ngOnInit() {
    this.element = this.elementRef.nativeElement;
    this.element.value = this.formatIdentificador;
  }

  ngOnChanges(changes: SimpleChanges) {
    const tipo = changes.appFormatIdentificadorTipo;
    if (!tipo.firstChange && tipo.currentValue !== tipo.previousValue) {
      switch (tipo.currentValue) {
        case 1:
          this.element.value = this.formatIdentificador;          
          break;      
        default:
          this.element.value = this.unformatedIdentificador;
          break;
      }
    }
  }

  get unformatedIdentificador(): string {
    return this.element.value.split('.').join('').split('-').join('');
  }

  get formatIdentificador(): string {
    return this.identificador.transform(this.element.value, this.appFormatIdentificadorTipo);
  }
}
