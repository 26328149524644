import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import {MetaStats, Propiedad} from '../models/propiedad.model';
import { PaisAdapter } from './pais.adapter';
import { PropiedadTipoAdapter } from './propiedad-tipo.adapter';
import {PropiedadConfiguracionComercialAdapter} from './propiedad-configuracion-comercial.adapter';
import { CiudadAdapter } from './ciudad.adapter';

@Injectable({
  providedIn: 'root'
})
export class PropiedadAdapter implements Adapter<any> {

  constructor(
    public paisAdapter: PaisAdapter,
    public ciudadAdapter: CiudadAdapter,
    public propiedadTipoAdapter: PropiedadTipoAdapter,
    public propiedadConfiguracionAdapter: PropiedadConfiguracionComercialAdapter
  ) {}


  adapt(item: any): Propiedad {
    const propiedad = new Propiedad();
    propiedad.createdAt = item.attributes.created_at;
    propiedad.createdBy = item.attributes.created_by;
    propiedad.id = item.attributes.id;
    propiedad.nombre = item.attributes.nombre;
    propiedad.direccion_nivel1 = item.attributes.direccion_nivel1;
    propiedad.direccion_nivel2 = item.attributes.direccion_nivel2;
    propiedad.direccion_nivel3 = item.attributes.direccion_nivel3;
    propiedad.direccion_nivel4 = item.attributes.direccion_nivel4;
    propiedad.direccion_nivel5 = item.attributes.direccion_nivel5;
    propiedad.direccion_nivel6 = item.attributes.direccion_nivel6;
    let propiedad_direccion = item.attributes.direccion_nivel4;
    propiedad_direccion += item.attributes.direccion_nivel5 ? ' ' + item.attributes.direccion_nivel5 : '';
    const propiedad_direccion_list = [propiedad_direccion, item.attributes.direccion_nivel3, item.attributes.direccion_nivel2, item.attributes.direccion_nivel1]
    propiedad.direccion = propiedad_direccion_list.join(', ');
    propiedad.paisId = item.attributes.pais_id;
    propiedad.region = item.attributes.region;
    propiedad.ciudadId = item.attributes.ciudad_id;
    propiedad.comuna = item.attributes.comuna;
    propiedad.zipCode = item.attributes.zip_code;
    propiedad.propiedadTipoId = item.attributes.propiedad_tipo_id;
    propiedad.descripcion = item.attributes.descripcion;
    propiedad.fechaConstruccion = item.attributes.fecha_construccion;
    propiedad.fechaEntrega = item.attributes.fecha_entrega;
    propiedad.fechaRecepcionMunicipio = item.attributes.fecha_recepcion_municipio;
    propiedad.pisoCantidad = item.attributes.piso_cantidad;
    propiedad.subterraeoCantidad = item.attributes.subterraeo_cantidad;
    propiedad.web = item.attributes.web;
    propiedad.superficieTerreno = item.attributes.superficie_terreno;
    propiedad.superficieConstruida = item.attributes.superficie_construida;
    propiedad.inversionTotal = item.attributes.inversion_total;
    propiedad.codigo = item.attributes.codigo
    propiedad.estrato = item.attributes.estrato;
    propiedad.latitud = item.attributes.latitud;
    propiedad.longitud = item.attributes.longitud;
    propiedad.wasiIdEmpresa = item.attributes.wasi_id_company

    // Includes
    if (item.included) {
      item.included.filter((valor) => {
        switch (valor.type) {
          case 'Pais':
            propiedad.pais = this.paisAdapter.adapt(valor);
            break;
          case 'Ciudad':
            propiedad.ciudad = this.ciudadAdapter.adapt(valor);
            break;
          case 'PropiedadTipo':
            propiedad.propiedadTipo = this.propiedadTipoAdapter.adapt(valor);
            break;
          case 'PropiedadConfiguracion':
            propiedad.configuracion = this.propiedadConfiguracionAdapter.adapt(valor);
            break;
        }
      });
    }
    /* Relationship */
    if (item.relationship) {
      if (item.relationship && item.relationship.imagenes) {
          propiedad.imagenes = item.relationship.imagenes.data;
      }
      if (item.relationship?.propiedadAdjunto?.data) {
          propiedad.adjuntos = item.relationship.propiedadAdjunto.data.map(e => ({
              ...e.attributes,
              ...(e.included?.length ? { ...e.included[0].attributes } : {}),
              editarId: e.id
          }));
      }
    }

    if (item.meta) {
      if (item.meta.stats) {
        const stats: MetaStats[] = [] as MetaStats[];
        item.meta.stats.forEach(s =>  {
          const stat: MetaStats = {
            unidadEstadoId: s.unidadEstadoId,
            nombre: s.nombre,
            cantidad: s.cantidad
          };
          stats.push(stat);
        });
        propiedad.stats = stats;
      }
    }
    return propiedad;
  }
}
