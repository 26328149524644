import {Injectable} from '@angular/core';
import {Adapter} from './adapter';
import {ContratoUnidadConfiguracion} from '../models/contrato-unidad-configuracion.model';

@Injectable({
  providedIn: 'root'
})
export class ContratoUnidadConfiguracionAdapter implements Adapter<ContratoUnidadConfiguracion> {
  constructor() {
  }
  adapt(item: any): ContratoUnidadConfiguracion {
    const contratoUnidadConfiguracion = new ContratoUnidadConfiguracion();
    contratoUnidadConfiguracion.id = item.attributes.id;
    contratoUnidadConfiguracion.contratoUnidadId = item.attributes.contrato_unidad_id;
    contratoUnidadConfiguracion.reajusteCheck = item.attributes.reajuste_check;
    contratoUnidadConfiguracion.reajustePeriodicidadId = item.attributes.reajuste_periodicidad_id;
    contratoUnidadConfiguracion.reajustePeriodicidadValor = item.attributes.reajuste_periodicidad_valor;
    contratoUnidadConfiguracion.reajusteTipoId = item.attributes.reajuste_tipo_id;
    contratoUnidadConfiguracion.reajusteMontoTipo = item.attributes.reajuste_monto_tipo_id;
    contratoUnidadConfiguracion.reajusteValor = item.attributes.reajuste_valor;
    contratoUnidadConfiguracion.reajusteFecha = item.attributes.reajuste_fecha;
    contratoUnidadConfiguracion.reajusteDivisa = item.attributes.reajuste_divisa_id;
    const propiedadConfiguracion = item.included?.find(e => e.type === 'PropiedadConfiguracion')
    if (propiedadConfiguracion) {
      contratoUnidadConfiguracion.propiedadConfiguracion = {
        reajusteMonto: propiedadConfiguracion.attributes.reajuste_monto,
        reajusteMontoDivisaId: propiedadConfiguracion.attributes.reajuste_monto_divisa_id,
        reajusteMontoTipoId: propiedadConfiguracion.attributes.reajuste_monto_tipo_id
      }
    }

    return contratoUnidadConfiguracion;
  }
}
