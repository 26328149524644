import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Paginador } from 'src/app/models/paginador.model';

export interface PaginadorInterface {
  paginaActual: number;
}

@Component({
  selector: 'app-paginacion',
  templateUrl: './paginacion.component.html',
  styleUrls: ['./paginacion.component.css']
})

export class PaginacionComponent implements OnInit, OnChanges {

  @Input() paginador: Paginador;
  @Output() changePage = new EventEmitter();

  public pages: any[];

  constructor() { }

  ngOnInit() {
    if (this.paginador.pageTotal) {
      this.CrearPaginas();
    }
  }

  ngOnChanges() {
    if (this.paginador.pageTotal) {
      this.CrearPaginas();
    }
  }

  setPagina(page: number) {
    if (page > this.paginador.itemTotal) {
      this.paginador.pageCurrent = this.paginador.itemTotal;
    } else if (page < 1) {
      this.paginador.pageCurrent = 1;
    } else {
      this.paginador.pageCurrent = page;
    }
    this.changePage.emit(this.paginador);
  }

  CrearPaginas() {
    this.pages = [];
    let limite = this.paginador.pageTotal;
    let inicio = 1;
    if (this.paginador.pageTotal > 10) {
      limite = 10;
      if (this.paginador.pageCurrent > 5) {
        limite = this.paginador.pageCurrent + 5 > this.paginador.pageTotal ? this.paginador.pageTotal : this.paginador.pageCurrent + 5;
        inicio = this.paginador.pageCurrent === this.paginador.pageTotal ? this.paginador.pageCurrent - 9 : this.paginador.pageCurrent - 5;
      }
    }
    for (let i = inicio; i <= limite; i++) {
      this.pages.push(i);
    }
  }

}
