import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { UserAdapter } from './../../adapter/user.adapter';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import * as moment from 'moment';

import localeCl from '@angular/common/locales/es-CL';
import localePE from '@angular/common/locales/es-PE';
import localeCO from '@angular/common/locales/es-CO';
import localeUS from '@angular/common/locales/en';

import { Store } from '@ngrx/store';
import { AppState } from '../../store/models/app.model';
import {
  SetLanguage
} from '../../store/actions/home.action'

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  private _locale: string;

  public get locale(): string {
    return this._locale;
  }
  public set locale(value: string) {
    this._locale = value;
  }

  constructor(
    public http: HttpClient,
    public userAdapter: UserAdapter,
    private store: Store<AppState>
  ) {
    this.registerCulture('es-CL')
  }

  cargarUsuario(): Observable <User> {
    return this.http.get(environment.urlApi + 'perfil')
      .pipe(
        map( (resp: any) => {
          this.cargarLocale(resp.data.attributes.locale);
          this.cargaMomentLocale(resp.data.attributes.locale.split('-')[0].toLowerCase());
          return this.userAdapter.adapt(resp.data);
        })
      );
  }

  cargaMomentLocale(locale:string){
    moment.locale(locale.split('-')[0].toLowerCase());
  }

  cambiarEmpresa(empresa): Observable <any> {
    return this.http.post(environment.urlApi + 'perfil/cambia-empresa', empresa)
      .pipe(
        map( (resp: any) => {
          return this.userAdapter.adapt(resp.data);
        })
      );
  }

  registerCulture(culture: string) {
    if (!culture) {
      return;
    }
    this.locale = culture;
    switch (culture) {
      case 'es-CL': {
        registerLocaleData(localeCl);
        break;
      }
      case 'es-PE': {
        registerLocaleData(localePE);
        break;
      }
      case 'es-CO': {
        registerLocaleData(localeCO);
        break;
      }
    }
  }

  cargarLocale(locale: string) {
    localStorage.setItem('locale-user',locale);
    this.store.dispatch(new SetLanguage({language:locale}))
  }

  cambiarIdioma(locale: string): Observable <any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.urlApi + 'perfil/cambia-idioma', {locale:locale}, {headers}).pipe();
  }
}
