<div class="container">
    <div class="modal-general-container row">
      <div *ngIf="data.titulo" class="col-12 mb-2 bold text-center font-weight-bold">
        {{ data.titulo | translate }}
      </div>
      <div *ngIf="data.cuerpo" class="col-12 mb-2 bold text-center text-sm mb-3">
        {{ data.cuerpo | translate }}
        <br/>
      </div>
      <div class="row col-12 footer-container">
        <button
          *ngFor="let action of data.actions"
          [disabled]="!getEnabled(action) || isEjecutandoAccion"
          class="col-3 btn {{action.style ? action.style : 'boton-anterior'}}"
          (click)="getEnabled(action) && !isEjecutandoAccion && ejecutarAccion(action)"
        >
          {{ action.titulo | translate }}
        </button>
      </div>
    </div>
  </div>