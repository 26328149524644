import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransaccionConcepto } from 'src/app/models/model.index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { TransaccionConceptoAdapter } from 'src/app/adapter/transaccion-concepto.adapter';

@Injectable({
  providedIn: 'root'
})
export class TransaccionConceptoService {

  constructor(
    public http: HttpClient,
    public transaccionConceptoAdapter: TransaccionConceptoAdapter
  ) { }

  cargarTransaccionConceptos(): Observable <TransaccionConcepto[]> {
    return this.http.get(environment.urlApi + 'transaccionConcepto')
        .pipe(
            map( (resp: any) =>
                resp.data.map(transaccionConceptos => this.transaccionConceptoAdapter.adapt(transaccionConceptos))
            )
        );
  }

}
