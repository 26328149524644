  import { Injectable } from '@angular/core';
  import { environment } from 'src/environments/environment';

  @Injectable({
    providedIn: 'root'
  })
  export class GoogleRecaptchaService {

    private script: any;
    private isloaded: boolean;

    static readonly STYLEID = 'grecaptcha-badge-hidden'

    constructor() { }

    initRecaptcha() {
      if (!this.isloaded) {
        this.script = document.createElement('script')
        this.script.async = true
        this.script.src = 'https://www.google.com/recaptcha/api.js?render=' + environment.googleRecaptchaSiteKey
        document.body.prepend(this.script)
        this.isloaded = true
      }
      this.removeStyle()
    }

    hideRecaptcha() {
      const element = document.createElement('style')
      element.id = GoogleRecaptchaService.STYLEID
      const styleSheet = document.createTextNode('.grecaptcha-badge { visibility: hidden; }')
      element.appendChild(styleSheet)
      document.body.appendChild(element)
      this.isloaded = false
    }

    private removeStyle(): void {
      var style = document.getElementById(GoogleRecaptchaService.STYLEID);
      if (style instanceof Node) {
        document.body.removeChild(style);
      }
    }

  }
