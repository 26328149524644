import { Adapter } from './adapter';
import { Injectable } from '@angular/core';
import { CotizacionEstado } from '../models/cotizacion-estado.model';

@Injectable({
    providedIn: 'root'
  })
  export class CotizacionEstadoAdapter implements Adapter<CotizacionEstado> {
    adapt(item: any): CotizacionEstado {
        const cotizacionEstado = new CotizacionEstado();
        cotizacionEstado.id = item.attributes.id;
        cotizacionEstado.nombre = item.attributes.nombre;
        cotizacionEstado.lang_resource = item.attributes.lang_resource;
        return cotizacionEstado;
    }
  }
