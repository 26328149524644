import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { ContactoAdapter } from './contacto.adapter';
import { ContratoContactoTipoAdapter } from './contrato-contacto-tipo.adapter';
import { CotizacionContacto } from '../models/cotizacion-contacto.model';


@Injectable({
    providedIn: 'root'
})

export class CotizacionContactoAdapter implements Adapter<CotizacionContacto> {

    constructor(
        public contactoAdapter: ContactoAdapter,
        public contratoContactoTipoAdapter: ContratoContactoTipoAdapter
    ) {}

    adapt(item: any): CotizacionContacto {

        const cotizacionContacto = new CotizacionContacto();

        cotizacionContacto.id = item.attributes.id;
        cotizacionContacto.contactoId = item.attributes.contacto_id;
        cotizacionContacto.contratoContactoTipoId = item.attributes.contrato_contacto_tipo_id;
        cotizacionContacto.cotizacionId = item.attributes.cotizacion_id;
        cotizacionContacto.isFirmante = item.attributes.is_firmante;
        cotizacionContacto.isTitular = item.attributes.is_titular;
        return cotizacionContacto;
    }

  }
